import {Component, ComponentFactoryResolver, ComponentRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {TabContentContainerDirective} from './tab-content-container.directive';
import {TabBarItem} from '../../../../../models/shared/stylesheet/tab-bar-item';
import {Tabbable} from '../../../../../models/protocols/tabbable';
import {ApprovedComponent} from '../../../../guides/components/segmented-guides/approved/approved.component';
import {ArchivedComponent} from '../../../../guides/components/segmented-guides/archived/archived.component';
import {DraftsComponent} from '../../../../guides/components/segmented-guides/drafts/drafts.component';
import {PendingComponent} from '../../../../guides/components/segmented-guides/pending/pending.component';
import {CompanyPendingComponent} from '../../../../guides/components/segmented-guides/company-pending/company-pending.component';

@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss']
})
export class TabContentComponent implements OnInit, OnChanges {

  @Input() tab: TabBarItem;
  @Input() associatedInsiderId: string;
  @ViewChild(TabContentContainerDirective, {static: true})
  contentContainer: TabContentContainerDirective;
  private reference: ComponentRef<Tabbable>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    const tab: TabBarItem = this.tab;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<Tabbable>(tab.componentType);
    this.reference = this.contentContainer.viewContainerRef.createComponent(componentFactory);
    this.setData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setData();
  }

  private setData() {
    if (!!this.reference) {
      if (this.reference.instance instanceof ApprovedComponent) {
        this.reference.instance.associatedInsiderId = this.associatedInsiderId;
      } else if (this.reference.instance instanceof ArchivedComponent) {
        this.reference.instance.associatedInsiderId = this.associatedInsiderId;
      } else if (this.reference.instance instanceof DraftsComponent) {
        this.reference.instance.associatedInsiderId = this.associatedInsiderId;
      } else if (this.reference.instance instanceof PendingComponent) {
        this.reference.instance.associatedInsiderId = this.associatedInsiderId;
      } else if (this.reference.instance instanceof CompanyPendingComponent) {
        this.reference.instance.associatedInsiderId = this.associatedInsiderId;
      }
    }
  }

}
