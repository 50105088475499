<div class="guide-container">
  <div #image
       class="image-container">
    <app-asset matRipple
               class="asset"
               fxFlex="none"
               [asset]="guide.getGuidePhoto()"
               (click)="onGuideClick(guide)"
               [style.object-fit]="'cover'">
    </app-asset>
    <div [hidden]="!(vm.isInErrorStatus$|async)"
         popper
         [popper]="errorPopper"
         [popperShowOnStart]="false"
         [popperTrigger]="'click'"
         [popperModifiers]="vm.popperModifier"
         [popperHideOnClickOutside]="true"
         [popperHideOnScroll]="true"
         [popperPreventOverflow]="false"
         [popperApplyClass]="'custom-popper-content'"
         [popperStyles]="vm.popperStyles"
         [popperPlacement]="'top-start'"
         (mouseover)="errorPopper.show()"
         (mouseout)="errorPopper.hide()"
         class="error-info-box">
      <img class="error-info" [src]="'assets/icons/light/outline/information-circle.svg'" alt="">
    </div>
    <popper-content #errorPopper [style.z-index]="100">
      {{vm.errorMessage$|async}}
    </popper-content>
    <div matRipple
         [hidden]="vm.featuredHidden$|async"
         class="guide-button star-button"
         (click)="featuredClicked(); $event.stopPropagation()">
      <img *ngIf="guide?.featuredGuide" class="star" [src]="'assets/icons/yellow/star.svg'" alt="">
      <img *ngIf="!guide?.featuredGuide" class="star" [src]="'assets/icons/dark/outline/star.svg'" alt="">
    </div>
    <div matRipple
         popper
         class="guide-button options-button"
         (click)="$event.stopPropagation()"
         [popper]="popperContent"
         [popperShowOnStart]="false"
         [popperTrigger]="'click'"
         [popperModifiers]="vm.popperModifier"
         [popperHideOnClickOutside]="true"
         [popperHideOnScroll]="true"
         [popperPreventOverflow]="false"
         [popperApplyClass]="'custom-popper-content'"
         [popperStyles]="vm.popperStyles"
         [popperPlacement]="'bottom-end'">
      <img class="options" [src]="'assets/icons/dark/solid/dots-vertical.svg'" alt="">
    </div>
    <popper-content #popperContent [style.z-index]="100">
      <div *ngIf="(vm.dropDownMenuSections$|async).header"
           class="custom-dropdown-menu-header">
        {{(vm.dropDownMenuSections$|async).header.title}}
      </div>
      <div *ngFor="let item of (vm.dropDownMenuSections$|async).items"
           role="menuitem"
           (click)="menuItemSelected(item.value); popperContent.hide(); $event.stopPropagation();">
        <button [ngClass]="item.styling.add('custom-dropdown-menu-item')">
          {{item.title}}
        </button>
      </div>
    </popper-content>
  </div>
  <div class="guide-title">{{guide?.title}}</div>
  <div class="d-flex flex-row">
    <img class="private-icon"
         *ngIf="vm.isPrivate$|async"
         [ngbTooltip]="'Private Guide'"
         placement="right"
         [src]="'assets/icons/dark/outline/lock-closed.svg'" alt="">
    <div *ngIf="guide?.tags?.length > 0"
         class="tag-container flex-grow-1"
         fxLayout="row wrap">
      <app-tag *ngFor="let tag of guide?.tags"
               class="tag"
               [tag]="tag">
      </app-tag>
    </div>
  </div>
</div>
