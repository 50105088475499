<router-outlet *ngIf="isNotMobile$|async"></router-outlet>

<div *ngIf="isMobile$|async"
     class='mobile-not-supported'
     fxLayout="column"
     fxLayoutAlign="center center">
  <img class="word-mark"
       src="/assets/logo/Partners_Wordmark.png"
       alt="" />
  <div class="text-404">
    To access the Krugo Partners dashboard, please sign-in from a desktop, laptop or tablet device.
  </div>
</div>

