<div class="button-container"
     fxLayout="row"
     fxLayoutAlign="center center" (click)="colorCircle.click()">
    <input #colorCircle
           fxFlex="none"
           class="color-circle"
           [style.background]="color"
           [cpPosition]="'top'"
           [cpOutputFormat]="'hex'"
           [cpAlphaChannel]="'disabled'"
           [colorPicker]="color"
           (colorPickerChange)="colorChange.emit($event)" />
  <div fxFlex class="font-title">{{color | uppercase}}</div>
</div>
