<div matRipple class="button-container" fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex>
    <div appFont
         [primary]="primary"
         [secondary]="secondary"
         [hierarchy]="hierarchy"
         [type]="type"
         (fontName)="name = $event"
         class="font-title">{{name | titlecase}}</div>
    <div class="font-sub-title">{{subHeaderText | titlecase}}</div>
  </div>
  <div class="arrow" fxFlex="none">
    <img [src]="'assets/icons/dark/solid/chevron-right.svg'" alt="">
  </div>
</div>
