import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {InsiderInsightChartViewModel} from './insider-insight-chart-view-model';
import {BaseComponent} from '../../../../../models/base/base-component';
import {ChartDataAs} from '../models/chart-data-as.enum';
import {InsiderInsight} from '../../../../../models/guide/dto/insider-insight';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-insider-insight-chart',
  templateUrl: './insider-insight-chart.component.html',
  styleUrls: ['./insider-insight-chart.component.scss'],
  providers: [InsiderInsightChartViewModel],
})
export class InsiderInsightChartComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  /** [width, height] If dimension not set, graph fills parent */
  @Input() dimension: [number, number];
  @Input() showLegend: boolean = true;
  @Input() showTimeLine: boolean = true;

  /** Color Scheme for line data */
  @Input() colorScheme = {
    domain: ['#222222', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  // X Axis
  @Input() hasXAxis: boolean = true;
  @Input() xAxisLabel: string;
  @Input() showXAxisLabel: boolean = true;
  @Input() trimXAxisTicks: boolean = false;

  // Y Axis
  @Input() hasYAxis = true;
  @Input() yAxisLabel: string;
  @Input() showYAxisLabel: boolean = true;
  @Input() trimYAxisTicks: boolean = true;

  // Data
  @Input() companyId: string;
  @Input() insiderId: string;
  @Input() chartAs: ChartDataAs = ChartDataAs.GuideViews;

  @Output() insiderInsight = new EventEmitter<InsiderInsight>(true);
  @Output() insightsLoaded = new EventEmitter<boolean>(true);
  @Output() dataSelected = new EventEmitter<any>(true);
  @Output() dataActivated = new EventEmitter<any>(true);
  @Output() dataDeactivated = new EventEmitter<any>(true);

  constructor(
    public vm: InsiderInsightChartViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setDataInVM();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.vm.insiderInsights$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(insiderInsights => {
        if (!!insiderInsights && insiderInsights.length > 0) {
          this.insiderInsight.emit(insiderInsights[0]);
        } else {
          this.insiderInsight.emit(null);
        }
      });

    this.vm.insightsLoaded$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(insightsLoaded => {
        this.insightsLoaded.emit(insightsLoaded);
      });
  }

  onSelect(data): void {
    this.dataSelected.emit(JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    this.dataActivated.emit(JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    this.dataDeactivated.emit(JSON.parse(JSON.stringify(data)));
  }

  setDataInVM() {
    this.vm.setChartDataAs(this.chartAs);
    this.vm.setCompanyId(this.companyId);
    this.vm.setInsiderId(this.insiderId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartData) {
      this.vm.setChartDataAs(this.chartAs);
    }
    if (changes.companyId) {
      this.vm.setCompanyId(this.companyId);
    }
    if (changes.insiderId) {
      this.vm.setInsiderId(this.insiderId);
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
