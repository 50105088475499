import {Deserializable, DeserializeHelper} from '../protocols/deserializable';
import {AutoCompletedLocationType} from '../enum/shared/auto-completed-location-type.enum';
import {Coordinates} from './coordinates';

export class AutoCompletedLocation implements Deserializable {
  public name: string;
  public address: string;
  public formattedAddress: string;
  public city: string;
  public countryCode: string;
  public state: string;
  public coordinates: Coordinates;
  public metadata: Map<string, string>;
  public objectType: AutoCompletedLocationType;

  onDeserialize() {
    this.coordinates = DeserializeHelper.deserializeToInstance(Coordinates, this.coordinates);
    if (!(this.metadata instanceof Map)) {
      this.metadata = DeserializeHelper.deserializeGenericMap<string, string>(this.metadata);
    }
  }
}
