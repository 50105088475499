import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from '../../../services/session-service';
import {Router} from '@angular/router';
import {BaseComponent} from '../../../models/base/base-component';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  public loadingOpts: LoadingOptions = LoadingOptions.default(false, true);
  public sidebarMinimized = false;

  constructor(
    private sessionService: SessionService,
    public router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setupLoadingOpts();
  }

  setupLoadingOpts() {
    this.loadingOpts.zIndex = 100;
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.sessionService.refreshingSession
      .notNull()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((ref) => {
        const lm = 'Refreshing Session';
        if (!this.loadingOpts.containsRequest(lm) && ref) {
          this.loadingOpts.addRequest(lm);
        } else if (this.loadingOpts.containsRequest(lm) && !ref) {
          this.loadingOpts.removeRequest(lm);
        }
      });

    this.sessionService.sessionContainer
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => {
        // TODO - hide sidebar when no valid session
        // if (!sess) {
        //   this.sidebarMinimized = true;
        // } else {
        //   this.sidebarMinimized = !sess.validSession();
        // }
          this.sidebarMinimized = false;
      });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
