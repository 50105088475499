import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InsightsComponent} from './components/insights/insights.component';
import {ContributorsComponent} from './components/contributors/contributors.component';
import {SettingsComponent} from './components/settings/settings.component';
import {GuideInsightsComponent} from './components/insights/guide-insights/guide-insights.component';
import {AuthGuard} from '../../services/guards/auth.guard';
import {PermissionGuard} from '../../services/guards/permission.guard';
import {ProfileComponent} from '../profile/components/profile.component';
import {PreviewGuidesComponent} from './components/preview-guides/preview-guides.component';
import {GuideFeaturesComponent} from './components/guide-features/guide-features.component';

const routes: Routes = [
  {
    path: ':companyId',
    redirectTo: ':companyId/guides',
    pathMatch: 'full',
  },
  {
    path: ':companyId/guides',
    loadChildren: () => import('../../views/guides/guides.module').then(m => m.GuidesModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      title: 'Company Guides',
    },
  },
  {
    path: ':companyId/preview-guides',
    component: PreviewGuidesComponent,
    data: {
      title: 'Company Insights',
    },
  },
  {
    path: ':companyId/insights',
    component: InsightsComponent,
    data: {
      title: 'Company Insights',
    },
  },
  {
    path: ':companyId/insights/:guideId/insights',
    component: GuideInsightsComponent,
    data: {
      title: 'Guide Insights',
    },
  },
  {
    path: ':companyId/guide-features',
    component: GuideFeaturesComponent,
    data: {
      title: 'Guide Categories',
    },
  },
  {
    path: ':companyId/contributors',
    component: ContributorsComponent,
    data: {
      title: 'Company Contributors',
    }
  },
  {
    path: ':companyId/settings',
    component: SettingsComponent,
    data: {
      title: 'Company Settings',
    }
  },
  {
    path: ':companyId/profile',
    component: ProfileComponent,
    data: {
      title: 'User Profile',
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }
