import {Deserializable} from '../protocols/deserializable';

export class Coordinates implements Deserializable {
  public lat: number;
  public lng: number;

  constructor(lat?: number, lng?: number) {
    this.lat = lat;
    this.lng = lng;
  }

  onDeserialize() {
  }
}
