import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApprovedViewModel} from './approved-view-model';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Tabbable} from '../../../../../models/protocols/tabbable';
import {TypeUtils} from '../../../../../utils/type-utils';

@Component({
  selector: 'app-approved',
  templateUrl: './approved.component.html',
  styleUrls: ['./approved.component.scss'],
  providers: [ApprovedViewModel],
})
export class ApprovedComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy, Tabbable {

  @Input() associatedInsiderId: string;
  types = TypeUtils;

  constructor(
    public vm: ApprovedViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
