import {FormValidator} from '../../protocols/form-validator';
import {FormGroup} from '@angular/forms';
import {Validatable} from '../../protocols/validatable';
import {Selectable} from '../../protocols/selectable';
import {Searchable} from '../../protocols/searchable';
import {EventEmitter} from '@angular/core';
import {Checkbox} from './checkbox';
import {CheckboxContainerOptions} from './checkbox-container-options';
import {AutoCapitalize} from '../../enum/shared/input-options.enum';

export enum FormInputType {
  Date = 'date',
  Email = 'email',
  Color = 'color',
  Number = 'number',
  Password = 'password',
  ConfirmPassword = 'password',
  Search = 'search',
  Tel = 'tel',
  Text = 'text',
  Time = 'time',
}

export enum FormItemType {
  Input = 'input',
  Dropdown = 'dropdown',
  Textarea = 'textarea',
  Checkbox = 'checkbox',
  Switch = 'switch',
  Divider = 'divider',
  Hidden = 'hidden',
  Title = 'title',
  CheckboxGroup = 'checkboxgroup'
}

export class FormInputItem implements Validatable {
  // Properties
  public itemType: FormItemType = FormItemType.Input;
  public inputName: string;
  public label: string;
  public placeholder: string;
  public editable: boolean = true;
  public bindingProperty: string;
  public inputType: FormInputType;
  public mustMatchInputName: string;
  public inputMask: Array<string | RegExp> = null;
  public dropdownOptions: Selectable[] = [];
  public dropdownIsObject: boolean = false;
  public tooltipText: string;
  public valueChanged: EventEmitter<any> = new EventEmitter<any>();
  public enabled: boolean = true;
  public customValueParser: (s: any) => any;
  public boundInputs: FormInputItem[];
  public customClass: string;
  public inputStep: string = '1';
  // Grouped Checkbox
  public groupCheckboxes: Checkbox[] = [];
  public groupCheckboxOptions: CheckboxContainerOptions = new CheckboxContainerOptions();
  public groupCheckboxesChanged: (checkboxes: Checkbox[]) => void;
  // Input Button
  public inputHasButton: boolean = false;
  public inputButtonText: string;
  public inputButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  // Layout Properties
  public showRequiredAsterisk: boolean = false;
  public inlineLabel: boolean = false;
  public inlineLabelWidthPercent: number = 50;
  public hideLabel: boolean = false;
  public overrideFullWidth: boolean = false;
  public autoCapitalize: AutoCapitalize = AutoCapitalize.On;
  public autoComplete: boolean = false;
  // Validation Properties
  public required: boolean = false;
  public minLength: number;
  public maxLength: number;
  public minValue: number = -1;
  public maxValue: number = -1;
  public customValidator: FormValidator;
  // Custom error messages
  public customError: Map<string, string>; // Options: required, minlength, maxlength, and custom FormValidator.errorName()
  // Form Delegate
  public formDelegate: FormGroup;
  // Searchable
  public searchable: Searchable[] = [];
  // Text Area
  public autoGrowTextArea: boolean = false;
  public autoGrowMinRows: number = 1;
  public autoGrowMaxRows: number = 4;

  static getItemValue(items: FormInputItem[], inputName: string): any {
    let val: any = null;
    items.forEach((i) => {
      if (i.inputName === inputName) {
        val = i.getValue();
      }
    });
    return val;
  }

  getCustomErrorMessage(key: string): string {
    let customErr;
    if (this.customError) {
      this.customError.forEach((val, k) => {
        if (key === k) {
          customErr = val;
        }
      });
    }
    return customErr ? customErr : null;
  }

  clearValue() {
    if (this.itemType === FormItemType.Divider ||
      this.itemType === FormItemType.Title ||
      this.itemType === FormItemType.Hidden) {
      return;
    }
    if (this.formDelegate?.get(this.inputName)) {
      return this.formDelegate.get(this.inputName).reset('');
    }
    return;
  }

  selectFirstDropdown() {
    if (this.itemType === FormItemType.Dropdown) {
      this.formDelegate.get(this.inputName).setValue(this.dropdownOptions[0].getSelectionValue());
    }
  }

  selectDropDown(s: Selectable) {
    if (s && this.itemType === FormItemType.Dropdown) {
      if (this.formDelegate.get(this.inputName).value !== s.getSelectionValue()) {
        this.formDelegate.get(this.inputName).setValue(s.getSelectionValue());
      }
    }
  }

  getValue(): any {
    if (this.itemType === FormItemType.Divider ||
      this.itemType === FormItemType.Title ||
      this.itemType === FormItemType.Hidden) {
      return null;
    }

    if (this.formDelegate?.get(this.inputName)) {
      const val = this.formDelegate?.get(this.inputName).value;
      if (this.itemType === FormItemType.Dropdown) {
        return this.dropdownOptions.find(o => o.getSelectionValue() === val)?.getSelectionValue() || (this.dropdownIsObject ? null : '');
      }
      return val;
    }
    return null;
  }

  handleValueChanged() {
    // Handle changes to input if necessary
    this.valueChanged.emit([this.getValue(), this.boundInputs]);
  }

  handleSearchableItemSelected(ev: any) {
    const name = ev.target.value;
    const list = this.searchable.filter(x => x.lookupKey === name)[0];
    this.valueChanged.emit(list);
  }

  // Validatable Methods

  hasErrors(): boolean {
    if (!this.enabled) {
      // dont validate disabled fields
      return false;
    } else if (!this.formDelegate ||
      this.itemType === FormItemType.Divider ||
      this.itemType === FormItemType.Title ||
      this.itemType === FormItemType.Hidden) {
      return false;
    } else if (this.itemType === FormItemType.CheckboxGroup) {
      if (this.groupCheckboxOptions.requireMinimumSelection > 0) {
        return this.groupCheckboxes?.map(ch => ch.checked).filter(ch => ch).length < this.groupCheckboxOptions.requireMinimumSelection;
      } else {
        return false;
      }
    } else if (this.formDelegate.get(this.inputName).touched) {
      // check for error on touched field
      if (this.mustMatchInputName) {
        if (this.formDelegate.get(this.inputName).value !== '' &&
          this.formDelegate.get(this.inputName).value !== this.formDelegate.get(this.mustMatchInputName).value) {
          return true;
        }
      }
      return (this.formDelegate.get(this.inputName).invalid && this.formDelegate.get(this.inputName).errors !== null);
    } else {
      return false;
    }
  }

  getErrorMessage(): string {
    if (!this.enabled) {
      // dont validate disabled fields
      return '';
    } else if (!this.formDelegate ||
      this.itemType === FormItemType.Divider ||
      this.itemType === FormItemType.Title ||
      this.itemType === FormItemType.Hidden) {
      return '';
    } else if (this.itemType === FormItemType.CheckboxGroup) {
      if (this.groupCheckboxOptions.requireMinimumSelection > 0 &&
        this.groupCheckboxOptions.touched &&
        this.groupCheckboxes.map(ch => ch.checked).filter(ch => ch).length < this.groupCheckboxOptions.requireMinimumSelection) {
        return `At least ${this.groupCheckboxOptions.requireMinimumSelection} option must be selected.`;
      } else {
        return '';
      }
    } else {
      const errors = this.formDelegate.get(this.inputName).errors;
      if (errors === null) {
        if (this.mustMatchInputName && this.formDelegate.get(this.inputName).value !== this.formDelegate.get(this.mustMatchInputName).value) {
          return `Does not match ${this.mustMatchInputName}`;
        } else {
          return null;
        }
      } else {
        let returnError: string = '';
        Object.keys(errors).forEach(keyError => {
          if (keyError === 'required') {
            returnError = this.getCustomErrorMessage(keyError) || `${this.label} is required.`;
          } else if (keyError === 'minlength') {
            returnError = this.getCustomErrorMessage(keyError) || `${this.label} must be more than ${this.minLength} characters.`;
          } else if (keyError === 'maxlength') {
            returnError = this.getCustomErrorMessage(keyError) || `${this.label} must be at less than ${this.maxLength} characters.`;
          } else if (keyError === 'min') {
            // If min value is 0.01 then show error message as 0 since it is inclusive
            returnError = this.getCustomErrorMessage(keyError) || (this.minValue === 0.01) ? `${this.label} must be greater than 0.` : `${this.label} must be greater than or equal to ${this.minValue}.`;
          } else if (keyError === 'max') {
            returnError = this.getCustomErrorMessage(keyError) || `${this.label} must be less than ${this.maxValue}.`;
          } else if (keyError === 'email') {
            returnError = this.getCustomErrorMessage(keyError) || `Must be a valid email address.`;
          } else if (keyError === 'searchFor') {
            returnError = this.getCustomErrorMessage(keyError) || `Search list must contain selection.`;
          } else if (keyError === 'phoneNumber') {
            returnError = this.getCustomErrorMessage(keyError) || `Invalid phone number.`;
          } else if (keyError === this.customValidator?.errorName()) {
            returnError = this.getCustomErrorMessage(keyError) || errors[keyError];
          }
        });
        return returnError;
      }
    }
  }

  canSubmit(): boolean {
    if (!this.formDelegate) {
      return false;
    }
    if (this.itemType === FormItemType.Divider ||
      this.itemType === FormItemType.Title ||
      this.itemType === FormItemType.Hidden) {
      return true;
    }
    if (this.required) {
      // can submit if has no errors and the items has either been touched/dirty or is disabled
      return !this.hasErrors() && (this.formDelegate.get(this.inputName).touched || this.formDelegate.get(this.inputName).dirty || !this.enabled);
    } else {
      return !this.hasErrors();
    }
  }

}
