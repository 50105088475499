import {HttpErrorResponse} from '@angular/common/http';

const GenericAPIErrorTitle = 'An error has occurred.';

export class CustomError {
  public code: number;
  public title: string;
  public message: string;
  public suggestion: string;

  constructor(err?: HttpErrorResponse, serviceName?: string) {
    if (err) {
      this.code = err?.status;
      if (err?.error?.title === GenericAPIErrorTitle) {
        this.title = `${serviceName} Error`;
      } else if (err?.error) {
        this.title = err?.error?.title;
      }
      this.message = err?.error?.message;
      this.suggestion = err?.error?.suggestion;
    }
  }
}
