import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {ContributorsViewModel} from './contributors-view-model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalUtils} from '../../../../utils/modal-utils';
import {AddContributorComponent} from './add-contributor/add-contributor.component';
import {ConfirmationModalComponent} from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import {ConfirmationOptions} from '../../../../models/shared/stylesheet/confirmation-options';
import {Insider} from '../../../../models/guide/dto/insider';

@Component({
  selector: 'app-contributors',
  templateUrl: './contributors.component.html',
  styleUrls: ['./contributors.component.scss'],
  providers: [ContributorsViewModel],
})
export class ContributorsComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  // Popper Data
  public popperModifier = {
    flip: {
      behavior: ['right', 'bottom', 'top']
    }
  };
  public popperStyles = {
    'background-color': '#FFFFFF'
  };

  constructor(
    public vm: ContributorsViewModel,
    private modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy() {
    this.destroy();
  }

  openAddContributorModal() {
    const modalRef = this.modalService.open(
      AddContributorComponent,
      ModalUtils.defaultModalOptions());
    const compInstance = modalRef.componentInstance as AddContributorComponent;
    compInstance.vm.insiderCompany = this.vm.company;
    modalRef.result.then(() => {
      this.vm.setupWithCurrentCompany();
    });
  }

  openRemoveContributorModal(contributor: Insider): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = `Removing Contributor`;
    opts.bodyText = `All guides authored by this contributor will now be marked as authored by ${this.vm.company?.companyName}. Are you sure you want to remove this contributor?`;
    opts.cancelText = 'Remove Contributor';
    opts.cancelButtonClass = 'destructive-button mr-3';
    opts.continueText = 'Keep Editing';
    opts.continueButtonClass = 'preferred-button mr-3';
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((keepEditing) => {
      if (!keepEditing) {
        this.vm.removeContributor(contributor);
      }
    });
  }

}
