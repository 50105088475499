import { Component, OnInit } from '@angular/core';
import {GuideClickType} from '../../../../../models/guide/enum/guide-click-type.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-insight-info-modal',
  templateUrl: './insight-info-modal.component.html',
  styleUrls: ['./insight-info-modal.component.scss', '../../../../guides/components/guide/add-place-modal/add-place-modal.component.scss']
})
export class InsightInfoModalComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {

  }

  closeModal() {
    this.activeModal.dismiss();
  }

}
