import {BaseViewModel} from '../../../../models/base/base-view-model';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, takeUntil, tap} from 'rxjs/operators';
import FuzzySearch from 'fuzzy-search';
import {HydratedGuide} from '../../../../models/guide/dto/hydrated-guide';
import {DistinctUtils} from '../../../../utils/distinct.utils';
import {FilterUtils} from '../../../../utils/filter-utils';
import {GuidesDomainModel} from '../../../../domain-models/guides-domain-model';
import {GuideStatus} from '../../../../models/guide/enum/guide-status.enum';
import {PillItem} from '../../../../models/shared/stylesheet/pill-item';
import {BaseCreateGuideComponent} from '../../../shared/modals/create-guide/components/base-create-guide/base-create-guide.component';
import {ModalUtils} from '../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SortUtils} from '../../../../utils/sort-utils';
import {GuidesGridType} from '../guides-grid/guides-grid-type.enum';
import {Guide} from '../../../../models/guide/dto/guide';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable()
export class SearchGuideViewModel extends BaseViewModel implements OnDestroy {

  // Data
  private guidesGridType = new BehaviorSubject<GuidesGridType>(GuidesGridType.ThreeWide);
  public guidesGridType$ = this.guidesGridType.asObservable();
  private associatedInsiderId = new BehaviorSubject<string>(null);
  public associatedInsiderId$ = this.associatedInsiderId.asObservable();
  private guideStatus = new BehaviorSubject<GuideStatus>(null);
  private guides$ = combineLatest([
    this.associatedInsiderId,
    this.guideStatus,
    this.dm.allGuides$,
  ]).pipe(map(([insiderId, status, guides]) => {
    return guides
      ?.filter(guide => FilterUtils.GuidesByInsiderId(guide, insiderId))
      ?.filter(guide => FilterUtils.GuidesByStatus(guide, status))
      ?.sort(SortUtils.sortGuidesByMostRecent);
  }));
  public tags$ = this.guides$.pipe(
    map(guides => {
      if (!!guides) {
        const tagStrings = new Set(guides?.flatMap(guide => guide.tags));
        const tags = [];
        tagStrings.forEach(tag => {
          if (tag) {
            tags.push(new PillItem(tag));
          }
        });
        return tags;
      } else {
        return [];
      }
   })
  );

  // Filter
  public searchString = new BehaviorSubject<string>('');
  public filterTags = new BehaviorSubject<string[]>([]);
  public filteredGuides$ = combineLatest([
    this.guides$,
    this.searchString,
    this.filterTags,
  ]).pipe(
    tap(_ => this.loadingGuides.next(true)),
    debounceTime(250),
    map(([guides, searchText, tags]) => {
      let filteredGuides: HydratedGuide[] = guides;
      if (guides) {
        if (searchText) {
          const searcher = new FuzzySearch(filteredGuides, ['title'], {caseSensitive: false});
          filteredGuides = searcher.search(searchText);
        }
        filteredGuides = filteredGuides.filter(guide => FilterUtils.GuidesByTags(guide, tags));
      }
      return filteredGuides;
    }),
    tap(_ => this.loadingGuides.next(false)),
    distinctUntilChanged(DistinctUtils.distinctHydratedGuides),
  );
  private loadingGuides = new BehaviorSubject<boolean>(true);
  public loadingGuides$ = this.loadingGuides.pipe(debounceTime(150), distinctUntilChanged());

  // Empty state
  public emptyStateTitle$ = combineLatest([
    this.guideStatus,
    this.searchString,
    this.filterTags
  ]).pipe(map(([status, search]) => {
    if (!!search) {
      return 'No Results';
    }
    switch (status) {
      case GuideStatus.Draft:
        return 'No Draft Guides';
      case GuideStatus.Pending:
        return 'No Pending Guides';
      case GuideStatus.Approved:
        return 'No Approved Guides';
      case GuideStatus.Archived:
        return 'No Archived Guides';
      default: return '';
    }
  }));
  public emptyStateDesc$ = combineLatest([
    this.guideStatus,
    this.searchString
  ]).pipe(map(([status, search]) => {
    if (!!search) {
      return 'None of your guides match your search criteria. Check the spelling or reset the search to view all of your guides.';
    }
    switch (status) {
      case GuideStatus.Draft:
        return 'Any guides that you’re working on but aren’t quite finished' +
          ' with will appear in the drafts tab. Anyone with access can edit guides in the drafts tab.';
      case GuideStatus.Pending:
        return 'Guides that are finished but awaiting approval will appear on this tab.' +
          ' Only administrators can approve guides.';
      case GuideStatus.Approved:
        return 'Any guides that that have been approved by Krugo' +
          ' will appear in the approved tab.';
      case GuideStatus.Archived:
        return 'Guides that have been archived will appear here. Archive guides when ' +
          'they are irrelevant and don’t want them to appear on your website anymore.';
      default: return '';
    }
  }));
  public emptyStateButton$ = combineLatest([
    this.guideStatus,
    this.searchString
  ]).pipe(map(([status, search]) => {
    if (!!search) {
      return 'Clear Search';
    }
    switch (status) {
      case GuideStatus.Draft: return 'Create Guide';
      default: return '';
    }
  }));
  public emptyStateButtonPress = new BehaviorSubject<boolean>(false);
  public clearSearchBar = new Subject<boolean>();
  public createGuideElseClearSearch$ = combineLatest([
    this.emptyStateButtonPress,
    this.searchString
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([pressed, search]) => {
      if (pressed) {
        if (!!search) {
          this.clearSearchBar.next(true);
        } else {
          this.openCreateModal.next(true);
        }
        this.emptyStateButtonPress.next(false);
      }
    });

  // open create modal
  public openCreateModal = new Subject<boolean>();
  private openMechanism = combineLatest([
    this.associatedInsiderId$,
    this.openCreateModal,
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
    .subscribe(([id, open]) => {
      if (open) {
        this.openCreateGuideModal(id);
        this.openCreateModal.next(false);
      }
    });

  constructor(
    private dm: GuidesDomainModel,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  setStatusType(status: GuideStatus) {
    this.guideStatus.next(status);
  }

  setGuidesGridType(t: GuidesGridType) {
    this.guidesGridType.next(t);
  }

  setAssociatedInsiderId(id: string) {
    this.associatedInsiderId.next(id);
  }

  filterTagSelected(pillItems: PillItem[]) {
    if (!!pillItems) {
      this.filterTags.next(pillItems.filter(it => it.selected).map(it => it.text));
    } else {
      this.filterTags.next([]);
    }
  }

  openCreateGuideModal(id: string) {
    const modalRef = this.modalService.open(
      BaseCreateGuideComponent,
      ModalUtils.defaultModalOptions()
    );
    const compInstance = modalRef.componentInstance as BaseCreateGuideComponent;
    compInstance.associatedId = id;
    modalRef.result.then((guide) => {
      if (guide instanceof Guide) {
        this.router.navigate(
          [`edit/${!!guide.companyId ? guide.companyId : guide.insiderId}/${guide.id}`],
          { relativeTo: this.route }
        ).then(_ => {});
      }
    }).catch((_) => {});
  }

  ngOnDestroy() {
    this.destroy();
  }

}
