import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LabelGroupItem, LabelItemType} from '../../../../models/shared/stylesheet/label-group-item';
import {UploadFileModalComponent} from '../../../shared/modals/upload-file-modal/upload-file-modal.component';
import {ModalUtils} from '../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomFile} from '../../../../models/shared/custom-file';
import {UploadImageInterface} from '../../../shared/components/upload-asset/upload-image-interface';
import {HydratedGuide} from '../../../../models/guide/dto/hydrated-guide';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {AddPlaceModalComponent} from './add-place-modal/add-place-modal.component';
import {GuidesDomainModel} from '../../../../domain-models/guides-domain-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {PlaceCategory} from '../../../../models/guide/dto/place-category';
import {Asset} from '../../../../models/image/dto/asset';
import {ToastService} from '../../../../services/toast-service';
import {RenameSectionComponent} from './section-options/rename-section/rename-section.component';
import {GuideSection} from '../../../../models/guide/dto/guide-section';
import {RearrangeSectionComponent} from './section-options/rearrange-section/rearrange-section.component';
import {RemoveSectionComponent} from './section-options/remove-section/remove-section.component';
import {ActivatedRoute, Router} from '@angular/router';
import {MapModalComponent} from './map-modal/map-modal.component';
import {GuideStatus} from '../../../../models/guide/enum/guide-status.enum';
import {BehaviorSubject, combineLatest, forkJoin} from 'rxjs';
import {debounceTime, delay, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ConfirmationOptions} from '../../../../models/shared/stylesheet/confirmation-options';
import {ConfirmationModalComponent} from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import {Insider} from '../../../../models/guide/dto/insider';
import {CustomError} from '../../../../models/shared/custom-error';
import {GuideAuthor} from '../../../../models/guide/shared/guide-author';
import {
  ChooseAuthorComponent
} from '../../../shared/modals/create-guide/components/choose-author/choose-author.component';
import {environment} from '../../../../../environments/environment';
import {SortUtils} from '../../../../utils/sort-utils';
import {GuideImage} from '../../../../models/guide/shared/guide-image';
import {ReorderImageModalComponent} from './reorder-image-modal/reorder-image-modal.component';
import {CustomPlace} from '../../../../models/guide/dto/custom-place';
import {AddPlaceFlow} from '../../../../models/guide/enum/add-place-flow.enum';

@Injectable()
export class GuideViewModel extends BaseViewModel implements UploadImageInterface, OnDestroy {

  labelGroupItems: LabelGroupItem[] = [];
  initialFiles: CustomFile[] = null;

  guide: HydratedGuide = null;
  loadingOpts: LoadingOptions = LoadingOptions.default();
  guideStatus = GuideStatus;
  company: Insider = null;
  insider: Insider = null;

  categoryMap: Map<string, string> = new Map<string, string>();
  nameMap: Map<string, string> = new Map<string, string>();

  placeCategories: PlaceCategory[] = [];

  // Guide Info Form (Title & Description)
  guideInfoOptions: FormOptions = new FormOptions();
  guideInfoStyling: FormGroupStyling = new FormGroupStyling();
  guideInfoItems: FormInputItem[] = [];
  hydrateInputObject: EventEmitter<any> = new EventEmitter<any>();
  authorPillClicked: EventEmitter<any> = new EventEmitter<any>();

  // Author Info Form
  authorInfoOptions: FormOptions = new FormOptions();
  authorInfoStyling: FormGroupStyling = new FormGroupStyling();
  authorInfoItems: FormInputItem[] = [];

  canEdit: boolean = false;
  canSubmit: boolean = true;

  // Author Label
  authorLabelGroupItems: LabelGroupItem[] = [];

  // Image Handling
  guideImages: GuideImage[] = [];

  // Orphan Custom Places
  orphanPlaces: CustomPlace[] = [];

  // Listen for guide being edited
  private insiderIdSubject = new BehaviorSubject<string>(null);
  private guideIdSubject = new BehaviorSubject<string>(null);
  private guideLoadingMessage = 'Loading your Guide!';
  private listenForGuide = this.guideIdSubject.notNull().pipe(
    takeUntil(this.onDestroy),
    debounceTime(100),
    tap(() => {
      this.loadingOpts.addRequest(this.guideLoadingMessage);
    }),
    switchMap(guideId => this.domainModel.getGuides([guideId]))
  ).subscribe(([guide]) => {
    this.loadingOpts.removeRequest(this.guideLoadingMessage);
    this.getGuide(guide);
  });

  constructor(
    private modalService: NgbModal,
    private domainModel: GuidesDomainModel,
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  setupBindings() {
    this.activatedRoute.params.subscribe(p => {
      if (p?.insiderId) {
        this.insiderIdSubject.next(p.insiderId);
      }
      if (p?.guideId) {
        this.guideIdSubject.next(p.guideId);
      }
    });
    this.authorPillClicked.subscribe(_ => {
      this.openChangeAuthorModal();
    });
  }

  generateGuideInfoLabelGroupItems() {
    this.labelGroupItems = [];
    const divider = new LabelGroupItem();
    divider.itemType = LabelItemType.Divider;

    this.labelGroupItems.push(divider);
    const title = new LabelGroupItem();
    title.itemType = LabelItemType.Title;
    title.value = this.guide.title;
    title.label = 'Title';
    this.labelGroupItems.push(title);

    const editButton = new LabelGroupItem();
    editButton.itemType = LabelItemType.ButtonListItem;
    editButton.actionButtonText = 'Edit';
    editButton.actionEmitter.subscribe((_: LabelGroupItem) => {
      this.canEdit = !this.canEdit;
    });
    editButton.actionButtonClass = 'edit-action-button';
    this.labelGroupItems.push(editButton);
    this.labelGroupItems.push(divider);

    const description = new LabelGroupItem();
    description.itemType = LabelItemType.Text;
    description.value = this.guide?.description;
    description.label = 'Description';
    this.labelGroupItems.push(description);
    this.labelGroupItems.push(divider);

    this.authorLabelGroupItems = [];
    const author = new LabelGroupItem();
    author.pillClickable = true;
    author.actionEmitter = this.authorPillClicked;
    author.disablePillActiveClass = true;
    author.itemType = LabelItemType.Pill;
    author.value = this.getAuthorName();
    author.pillSubtext = this.getAuthorSubtext();
    author.pillIcon = this.getAuthorImage();
    this.authorLabelGroupItems.push(author);

    this.generateGuideInfoFormItems();
  }

  generateGuideInfoFormItems() {
    this.guideInfoItems = [];
    this.authorInfoItems = [];

    const divider = new FormInputItem();
    divider.itemType = FormItemType.Divider;

    this.guideInfoItems.push(divider);
    const title = new FormInputItem();
    title.inputType = FormInputType.Text;
    title.itemType = FormItemType.Textarea;
    title.inputName = 'title';
    title.label = 'Title';
    title.bindingProperty = 'title';
    title.required = true;
    title.placeholder = 'Title';
    title.customClass = 'edit-guide-title';
    title.autoGrowTextArea = true;
    title.autoGrowMinRows = 1;
    title.autoGrowMaxRows = 3;
    this.guideInfoItems.push(title);

    const description = new FormInputItem();
    description.itemType = FormItemType.Textarea;
    description.inputType = FormInputType.Text;
    description.inputName = 'description';
    description.label = 'Description';
    description.placeholder = 'Description';
    description.bindingProperty = 'description';
    description.required = true;
    description.customClass = 'edit-guide-description';
    description.autoGrowTextArea = true;
    description.autoGrowMinRows = 2;
    description.autoGrowMaxRows = 9;
    this.guideInfoItems.push(description);

    const showCompanyInsiderInput = new FormInputItem();
    showCompanyInsiderInput.itemType = FormItemType.Switch;
    showCompanyInsiderInput.inputName = 'showCompanyInsider';
    showCompanyInsiderInput.label = 'Show Contributor Name';
    showCompanyInsiderInput.bindingProperty = 'showCompanyInsider';
    showCompanyInsiderInput.tooltipText = 'Show the contributor name along with the company name.';
    showCompanyInsiderInput.required = true;
    this.authorInfoItems.push(showCompanyInsiderInput);
  }

  setInsiderInformation() {
    this.insider = this.guide?.insider;
    this.company = this.guide?.company;
  }

  getAuthorName(): string {
    if (this.company) {
      return this.company.companyName;
    } else {
      return this.insider.getFullName();
    }
  }

  getAuthorImage(): Asset {
    if (this.company) {
      return this.company.profilePicture;
    } else {
      return this.insider.profilePicture;
    }
  }

  getAuthorSubtext(): string {
    if (this.guide.showCompanyInsider && this.guide.insiderId !== this.guide.companyId) {
      return 'Contributed by ' + this.insider.getFullName();
    } else {
      return null;
    }
  }

  guideInfoChanges() {
    let canSubmit = true;
    this.guideInfoItems.forEach((fi) => {
      if (!fi.canSubmit() && this.canEdit) {
        canSubmit = false;
        return;
      }
    });
    this.canSubmit = canSubmit;
  }

  getFeaturedImage(): Asset {
    if (this.guideImages?.length > 0) {
      return this.guideImages[0].asset;
    } else {
      return null;
    }
  }

  fileList(f: CustomFile[], id?: number) {
    if (f.length > 0) {
      this.initialFiles = f;
      this.openUploadModal();
    }
  }

  openUploadModal() {
    this.updateGuide(false);
    const modalRef = this.modalService.open(
      UploadFileModalComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.componentInstance.initialFiles = this.initialFiles;
    modalRef.result.then((files) => {
      this.uploadImages(files);
    }).catch((_) => {
    });
  }

  openRenameModal(section: GuideSection) {
    const modalRef = this.modalService.open(
      RenameSectionComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.componentInstance.oldSectionName = section.title;
    modalRef.result.then((newSectionName) => {
      section.title = newSectionName;
      this.updateGuide();
    }).catch((_) => {
    });
  }

  openRearrangeModal(section: GuideSection) {
    const sectionPlaces = this.guide.customPlaces.filter(p => section.sortedIds.contains(p.id));
    const modalRef = this.modalService.open(
      RearrangeSectionComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.componentInstance.sections = this.guide.sections;
    modalRef.componentInstance.selectedSection = section;
    modalRef.componentInstance.customPlaces = sectionPlaces;
    modalRef.result.then((updatedSections) => {
      this.guide.sections = updatedSections;
      this.guide.sections.sort(SortUtils.sortGuideSections);
      this.updateGuide();
    }).catch((_) => {
    });
  }

  openRemoveModal(section: GuideSection) {
    const modalRef = this.modalService.open(
      RemoveSectionComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.componentInstance.suggestionNumber = section.ids.size;
    modalRef.result.then(() => {
      this.removeSection(section);
    }).catch((_) => {
    });
  }

  uploadImages(files: CustomFile[]) {
    const loadingMessage = 'Uploading Your Files';
    if (!this.loadingOpts.containsRequest(loadingMessage)) {
      this.loadingOpts.addRequest(loadingMessage);
      const uploadQueue = [];
      files.forEach(f => {
        uploadQueue.push(this.domainModel.uploadGuideAsset(f, null, this.guide.id, this.guide.id));
      });
      forkJoin(uploadQueue).pipe(delay(5000), take(1)).subscribe(_ => {
        this.toastService.publishSuccessMessage('Asset(s) Uploaded', null);
        this.pollForUploadedAssets(files, 10, loadingMessage);
      }, (error: CustomError) => {
        this.loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishError(error);
        this.toastService.publishErrorMessage('Failed to upload asset(s). Please try again.', 'Asset Upload');
        this.initialFiles = [];
      });
    }
  }

  pollForUploadedAssets(uploadQueue: CustomFile[], numOfRetries: number, loadingMessage: string) {
    const uploadCopy = uploadQueue;
    const ids = [this.guide.id];
    this.domainModel.getGuides(ids).subscribe(guides => {
      uploadQueue.forEach((file, index) => {
        if (guides[0].images.find(asset => asset.fileName.includes(file.name))) {
          uploadCopy.splice(index, 1);
          if (uploadCopy.length === 0) {
            this.refreshGuide();
            this.loadingOpts.removeRequest(loadingMessage);
            this.initialFiles = [];
            numOfRetries = 0;
          }
        }
      });
      if (numOfRetries > 0 && uploadCopy.length > 0) {
        setTimeout(() => {
          this.pollForUploadedAssets(uploadCopy, numOfRetries - 1, loadingMessage);
        }, 6000);
      } else {
        this.refreshGuide();
        this.loadingOpts.removeRequest(loadingMessage);
        this.initialFiles = [];
        numOfRetries = 0;
      }
    });
  }

  openAddPlaceModal() {
    const modalRef = this.modalService.open(
      AddPlaceModalComponent,
      ModalUtils.addNewPlaceModalOptions()
    );
    modalRef.componentInstance.guide = this.guide;
    modalRef.result.then((updatedGuide) => {
      this.guide = updatedGuide;
      this.generateMaps();
    }).catch((_) => {
      this.updateGuide(false);
    });
  }

  openMapModal() {
    const modalRef = this.modalService.open(
      MapModalComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.componentInstance.mapMarkers = this.guide.customPlaces;
    modalRef.result.then(() => {
    }).catch((_) => {
    });
  }

  getGuide(currGuide: HydratedGuide) {
    this.guideImages = [];
    const loadingMessage = 'Loading your Guide!';
    this.loadingOpts.addRequest(loadingMessage);
    this.guide = currGuide;
    this.checkForOrphanPlaces();
    if (!!currGuide) {
      this.setInsiderInformation();
      this.generateGuideImages();
      this.generateGuideInfoLabelGroupItems();
      this.generateMaps();
    }
    this.loadingOpts.removeRequest(loadingMessage);
  }

  generateGuideImages() {
    this.guideImages = [];
    const assets = [];
    if (this.guide?.imageMap?.get(this.guide.id)) {
      const imgHashes = this.guide?.imageMap?.get(this.guide.id);
      imgHashes.forEach(hash => {
        assets.push(this.guide.images.find(img => img.md5Hash === hash));
      });
      this.sortGuideImages(assets);
    }
  }

  sortGuideImages(assets: Asset[]) {
    if (assets.length > 0) {
      const sorted = SortUtils.sortAssetsByMap(assets, this.guide?.imageOrderMap?.get(this.guide.id));
      sorted.forEach((asset, i) => {
        const img = new GuideImage(asset, i);
        this.guideImages.push(img);
      });
    }
  }

  generateMaps() {
    this.placeCategories = this.domainModel.placeCategories.getValue();
    this.guide.customPlaces?.forEach(p => {
      this.nameMap.set(p.id, p.name);
      this.categoryMap.set(p.id, this.placeCategories?.find(pc => pc.parentId === p.categoryIds[0])?.parentName ?? '');
    });

    this.guide.places?.forEach(p => {
      this.nameMap.set(p.placeId, p.name);
      this.categoryMap.set(p.placeId, this.placeCategories?.find(pc => pc.parentId === p.categoryIds[0])?.parentName ?? '');
    });

    this.guide.stays?.forEach(s => {
      this.nameMap.set(s.bookingId, s.name);
      this.categoryMap.set(s.bookingId, '');
    });
  }

  isPlaceOrStay(id: string): boolean {
    return !!(this.guide.places.find(p => p.placeId === id) || this.guide.stays.find(s => s.bookingId === id));
  }

  getPlaceCategory(pid: string): string {
    return this.categoryMap.get(pid);
  }

  getPlaceName(pid: string): string {
    return this.nameMap.get(pid);
  }

  getPlaceDescription(pid: string) {
    return this.guide.contentDescription.get(pid) ?? '';
  }

  updateGuide(showToast: boolean = true) {
    if (this.canSubmit) {
      // Validate form to bind all input changes to object
      this.hydrateInputObject.next();
      setTimeout(() => {
        this.domainModel.updateGuide(this.guide).subscribe(guide => {
          this.guide = guide;
          this.checkForOrphanPlaces();
          if (showToast) {
            this.toastService.publishSuccessMessage('Guide Updated', null);
          }
        }, err => {
          console.log(err);
          if (showToast) {
            this.toastService.publishErrorMessage(err, 'Unable to Update Guide');
          }
        });
      }, 150);
    }
  }

  refreshGuide() { // Only used when images are uploaded/deleted
    const ids = [];
    ids.push(this.guide.id);
    this.domainModel.getGuides(ids).subscribe(guide => {
      this.domainModel.triggerUpdatedGuideMech(guide[0]);
      this.guide = guide[0];
      this.generateGuideImages();
      this.toastService.publishSuccessMessage('Guide Updated', null);
    }, err => {
      console.log(err);
      this.toastService.publishErrorMessage(err, 'Unable to Update Guide');
    });
  }

  deleteGuideImage(img: Asset) {
    this.domainModel.deleteGuideImage(this.guide.id, img.id, img.md5Hash).subscribe(_ => {
      this.toastService.publishSuccessMessage('Image Deleted', null);
      this.refreshGuide();
    }, err => {
      console.log(err);
    });
  }

  removeSection(section: GuideSection) {
    // Removing the Section
    const deleteSectionIndex = this.guide.sections.map(s => s.ids).indexOf(section.ids);
    this.guide.sections.splice(deleteSectionIndex, 1);
    // Removing the Custom Places and Content Descriptions that were assigned to the Section
    const deletedPlaces = [];
    section.ids.forEach((value, key) => {
      this.guide?.contentDescription.delete(key);
      const deletedPlace = this.guide.customPlaces.find(p => p.id === key);
      deletedPlaces.push(deletedPlace);
    });
    this.domainModel.deleteGuidePlaces(deletedPlaces).subscribe(_ => {
      this.updateGuide();
      this.toastService.publishSuccessMessage('Section Removed', null);
    }, err => {
      this.toastService.publishErrorMessage(err, 'Unable to Remove Section');
      console.log(err);
    });
  }

  placeClicked(placeId: string) {
    if (!this.isPlaceOrStay(placeId)) {
      this.router.navigate(
        ['places', placeId],
        {relativeTo: this.activatedRoute}
      ).then();
    }
  }

  getPlaceThumb(placeId: string): Asset {
    if (this.guide?.imageMap?.get(placeId)) {
      const imgHashes = this.guide.imageMap.get(placeId);
      return this.guide.images?.find(img => img.md5Hash === imgHashes[0]);
    } else {
      return null;
    }
  }

  getGuideStatus(status: GuideStatus): string {
    switch (status) {
      case GuideStatus.Approved:
        return 'Approved';
      case GuideStatus.Declined:
        return 'Declined';
      case GuideStatus.Archived:
        return 'Archived';
      case GuideStatus.Draft:
        return 'Draft';
      case GuideStatus.Pending:
        return 'Pending';
    }
  }


  updateGuideStatus(status: GuideStatus) {
    if (this.canSubmit) {
      // Validate form to bind all input changes to object
      this.hydrateInputObject.next();
      if (this.guide.status === GuideStatus.Approved) {
        const modalOptions = new ConfirmationOptions();
        modalOptions.title = 'Your guide will no longer be approved';
        modalOptions.bodyText = 'By changing the status to ' + this.getGuideStatus(status) +
          ' this guide will no longer be visible to the public. In the future, you can submit this Guide for approval ' +
          'to have it published once again. Do you want to continue?';
        modalOptions.cancelButtonClass = 'grey-button';
        modalOptions.continueButtonClass = 'preferred-button';
        modalOptions.cancelText = 'Cancel';
        modalOptions.continueText = 'Update Status';

        const modalRef = this.modalService.open(
          ConfirmationModalComponent,
          ModalUtils.defaultModalOptions()
        );
        modalRef.componentInstance.confirmationOptions = modalOptions;
        modalRef.result.then((result) => {
          if (result) {
            this.guide.status = status;
            this.canEdit = false;
            this.updateGuide();
          }

        }).catch((_) => {
        });
      } else {
        this.guide.status = status;
        this.canEdit = false;
        this.updateGuide();
      }
    } else {
      this.toastService.publishErrorMessage('Please ensure there are no missing/incorrect fields.', 'Unable to change guide status');
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  openChangeAuthorModal() {
    const currentAuthor = new GuideAuthor();
    currentAuthor.krugoId = this.guide.insiderId;
    currentAuthor.companyId = this.guide.companyId;
    if (this.canEdit) {
      const modalRef = this.modalService.open(
        ChooseAuthorComponent,
        ModalUtils.defaultModalOptions()
      );
      modalRef.componentInstance.modalStyle = true;
      modalRef.componentInstance.currentAuthor = currentAuthor;
      modalRef.componentInstance.selectedAuthor.subscribe(ev => {
        this.updateGuideAuthor(ev);
      });
      modalRef.result.then(() => {
      }).catch((_) => {
      });
    }
  }

  updateGuideAuthor(a: GuideAuthor) {
    if (!!a) {
      this.guide.companyId = a?.companyId;
      this.guide.insiderId = a?.krugoId;
      this.guide.showCompanyInsider = !!a?.subtitle;
      this.updateGuide();
      this.modalService.dismissAll();
    }
  }

  copyGuideUrl() {
    const baseUrl = environment.production ? 'embed.krugopartners.com' : 'staging.embed.krugopartners.com';
    const url = `https://${baseUrl}/#/guides/${this.guide.companyId ?? this.guide.insiderId}/guide/${this.guide.id}`;
    navigator.clipboard.writeText(url).then((_) => {
      this.toastService.publishInfoMessage('Copied to clipboard', 'Copy Successful!');
    });
  }

  openReorderImageModal() {
    const oldImageOrder = Object.assign([], this.guideImages);
    const modalRef = this.modalService.open(
      ReorderImageModalComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.componentInstance.images = this.guideImages;
    modalRef.result.then((updatedImages) => {
      this.generateImagePriorityMap();
    }, rej => {
      this.guideImages = oldImageOrder;
    }).catch((_) => {
    });
  }

  generateImagePriorityMap() {
    const imagePrioMap = new Map<string, number>();
    this.guideImages.forEach(img => {
      imagePrioMap.set(img.asset.md5Hash, img.sortOrder);
    });
    if (!!this.guide.imageOrderMap.get(this.guide.id)) {
      this.guide.imageOrderMap.delete(this.guide.id);
    }
    this.guide.imageOrderMap.set(this.guide.id, imagePrioMap);
    this.updateGuide();
  }

  showSwitchForContributor(): boolean {
    if (this.canEdit) {
      return !!this.company &&
        this.guide.insiderId !== this.guide.companyId &&
        this.domainModel.session.getInsider()?.adminCompanyIds.contains(this.company.id);
    } else {
      return false;
    }
  }

  checkForOrphanPlaces() {
    const assignedIds = this.guide?.sections?.flatMap(s => Array.from(s.ids.keys()));
    this.orphanPlaces = this.guide?.customPlaces?.filter(cp => assignedIds.indexOf(cp.id) === -1);
  }

  assignPlaceToSection(p: CustomPlace) {
    const modalRef = this.modalService.open(
      AddPlaceModalComponent,
      ModalUtils.addNewPlaceModalOptions()
    );
    modalRef.componentInstance.guide = this.guide;
    modalRef.componentInstance.viewModel.newCustomPlace = p;
    modalRef.componentInstance.viewModel.createdCustomPlaceId = p.id;
    modalRef.componentInstance.skipToAssignSection = true;
    modalRef.result.then((updatedGuide) => {
      this.guide = updatedGuide;
      this.generateMaps();
    }).catch((_) => {
      this.updateGuide(false);
    });
  }

}
