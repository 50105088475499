import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ProfileViewModel} from './profile-view-model';
import {BaseComponent} from '../../../models/base/base-component';
import {Insider} from '../../../models/guide/dto/insider';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ProfileViewModel]
})
export class ProfileComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  constructor(public vm: ProfileViewModel) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  updateInsiderObject(i: Insider) {
    this.vm.updateInsiderObject(i);
  }

  ngOnDestroy() {
    this.destroy();
  }

  saveChangesClicked() {
    this.vm.updateInsider();
  }
}
