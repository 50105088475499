<app-form-group
  [formItems]="this.viewModel.citySearchItems"
  [options]="this.viewModel.citySearchOptions"
  [styling]="this.viewModel.citySearchStyling">
</app-form-group>
<div class="modal-subtitle mb-3">
  Recent
</div>
<div class="item-list">
  <div *ngFor="let city of viewModel.recentCities" class="item" (click)="viewModel.citySelected(city)">
    {{city.lookupKey}}
  </div>
</div>
