<div>
  <div class="insider-title" [class.company]="insider?.companyName">
    {{getTitle()}}
  </div>
  <div class="insider-img"
       (click)="profilePictureClicked()">
    <app-asset
      [hidden]="profilePicLoadingOpts?.isLoading"
      [asset]="insider?.profilePicture"
      [borderRadius]="'50%'">
    </app-asset>
    <app-loading
      [hidden]="!profilePicLoadingOpts?.isLoading"
      [options]="profilePicLoadingOpts">
    </app-loading>
  </div>
  <div class="insider-description">
    <pre (click)="descriptionClicked()" [hidden]="editDescription">{{getDescription()}}</pre>
    <app-form-group
      #descriptionForm
      (focusout)="descriptionFocusOut()"
      [hidden]="!editDescription"
      [formItems]="descriptionFormInput"
      [options]="formOptions"
      [formObject]="insider"
      [styling]="formStyling">
    </app-form-group>
  </div>
  <div class="divider black"></div>
  <div class="social-links-container" (click)="openEditSocialLinksModal()">
    <div class="social-link">
      <div class="social-logo">
        <img [src]="'assets/icons/dark/solid/facebook-logo.svg'" alt="">
      </div>
      <div class="social-text-container">
        <div class="social-title">
          Facebook
        </div>
        <div class="social-url" [hidden]="!insider?.facebookUrl">
          {{insider?.facebookUrl}}
        </div>
      </div>
      <div class="social-add" [hidden]="insider?.facebookUrl">
        <img [src]="'assets/icons/dark/plus.svg'" alt="">
      </div>
    </div>
    <div class="social-link">
      <div class="social-logo">
        <img [src]="'assets/icons/dark/solid/instagram-logo.svg'" alt="">
      </div>
      <div class="social-text-container">
        <div class="social-title">
          Instagram
        </div>
        <div class="social-url" [hidden]="!insider?.instagramUrl">
          {{insider?.instagramUrl}}
        </div>
      </div>
      <div class="social-add" [hidden]="insider?.instagramUrl">
        <img [src]="'assets/icons/dark/plus.svg'" alt="">
      </div>
    </div>
    <div class="social-link">
      <div class="social-logo">
        <img [src]="'assets/icons/dark/solid/twitter-logo.svg'" alt="">
      </div>
      <div class="social-text-container">
        <div class="social-title">
          Twitter
        </div>
        <div class="social-url" [hidden]="!insider?.twitterUrl">
          {{insider?.twitterUrl}}
        </div>
      </div>
      <div class="social-add" [hidden]="insider?.twitterUrl">
        <img [src]="'assets/icons/dark/plus.svg'" alt="">
      </div>
    </div>
    <div class="social-link">
      <div class="social-logo">
        <img [src]="'assets/icons/dark/solid/laptop.svg'" alt="">
      </div>
      <div class="social-text-container">
        <div class="social-title">
          Website
        </div>
        <div class="social-url" [hidden]="!insider?.websiteUrl">
          {{insider?.websiteUrl}}
        </div>
      </div>
      <div class="social-add" [hidden]="insider?.websiteUrl">
        <img [src]="'assets/icons/dark/plus.svg'" alt="">
      </div>
    </div>
    <div class="social-link" [hidden]="!insiderIsCompany()">
      <div class="social-logo">
        <img [src]="'assets/icons/dark/solid/code.svg'" alt="">
      </div>
      <div class="social-text-container">
        <div class="social-title">
          Website Embed
        </div>
        <div class="social-url" [hidden]="!insider?.embeddedWebsiteUrl">
          {{insider?.embeddedWebsiteUrl}}
        </div>
      </div>
      <div class="social-add" [hidden]="insider?.embeddedWebsiteUrl">
        <img [src]="'assets/icons/dark/plus.svg'" alt="">
      </div>
    </div>
  </div>
</div>
