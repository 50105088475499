import {NgModule} from '@angular/core';
import {GuidesComponent} from './components/guides.component';
import {RouterModule, Routes} from '@angular/router';
import {GuideComponent} from './components/guide/guide.component';
import {EditPlaceComponent} from './components/guide/edit-place/edit-place.component';
import {CanDeactivateGuard} from '../../services/guards/can-deactivate.guard';

const routes: Routes = [
  {
    path: '',
    component: GuidesComponent,
    data: {
      title: 'Guides'
    }
  },
  {
    path: 'edit/:insiderId/:guideId',
    children: [
      {
        path: '',
        component: GuideComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'places/:placeId',
        component: EditPlaceComponent,
        canDeactivate: [CanDeactivateGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GuidesRoutingModule {
}
