import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {SignUpRequest} from '../../../../../models/account/requests/sign-up-request';
import {Router} from '@angular/router';
import {AuthViewModel} from '../../../viewModels/auth-view-model';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {ToastService} from '../../../../../services/toast-service';
import {zip} from 'rxjs';
import {AuthFlow} from '../../../../../models/account/enum/auth-flow.enum';


@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss',
              '../auth.component.scss',
              '../../../../../../scss/stylesheet.scss']
})
export class ConfirmAccountComponent extends BaseComponent implements OnInit, OnDestroy {

  // View
  confirmCodeFormItems: FormInputItem[] = [];
  formStyling: FormGroupStyling = new FormGroupStyling();
  formOptions: FormOptions = new FormOptions();
  req: SignUpRequest = new SignUpRequest();
  public loadingOpts: LoadingOptions = LoadingOptions.default();

  private hasForgottenPassword: boolean = false;
  private canResendCode: boolean = true;

  constructor(
    private router: Router,
    public viewModel: AuthViewModel,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupBindings();
    this.setupViews();
  }

  ngOnDestroy() {
    this.destroy();
  }

  setupViews() {
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupBindings() {
    zip(this.viewModel.signUpRequest, this.viewModel.signInRequest, this.viewModel.forgotPasswordRequest).subscribe(o => {
      if (o.every(i => i === null)) {
        this.viewModel.nextAuthFlow.next(AuthFlow.SignIn);
      }
    });
    if (!!this.viewModel.forgotPasswordRequest.value && !this.viewModel.signUpRequest.value && !this.viewModel.signInRequest.value) {
      this.hasForgottenPassword = true;
    }
  }

  setupFormItems() {
    this.confirmCodeFormItems = [];
    const confirmCodeInput = new FormInputItem();
    confirmCodeInput.itemType = FormItemType.Input;
    confirmCodeInput.inputName = 'confirmationCode';
    confirmCodeInput.label = 'Confirmation Code';
    confirmCodeInput.hideLabel = true;
    confirmCodeInput.placeholder = 'Confirmation Code';
    confirmCodeInput.bindingProperty = 'confirmationCode';
    confirmCodeInput.inputType = FormInputType.Number;
    confirmCodeInput.required = true;
    this.confirmCodeFormItems.push(confirmCodeInput);

  }

  setupFormStyling() {
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.submitButtonText = 'Confirm';
    this.formStyling.secondaryButtonFloat = 'left';
    this.formStyling.secondaryButtonText = 'Resend Code';
  }

  formSubmitted(req: SignUpRequest) {
    if (!this.hasForgottenPassword) {
      this.confirmAccount(req);
    } else {
      this.changePassword(req);
    }
  }

  resendCode() {
    if (!this.hasForgottenPassword) {
      this.resendConfirmationCode();
    } else {
      this.resendForgotPasswordCode();
    }
  }

  confirmAccount(req: SignUpRequest) {
    const loadingMessage = 'Confirming Account';
    if (!this.loadingOpts.containsRequest(loadingMessage)) {
      this.loadingOpts.addRequest(loadingMessage);
      this.viewModel.confirmCode(req).subscribe(_ => {
        this.loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishSuccessMessage('You are good to go!', 'Account Confirmed');
      }, err => {
        console.log(err);
        this.loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishErrorMessage('We were unable to confirm your account at this time', 'Confirming Account Failed');
      });
    }
  }

  changePassword(req: SignUpRequest) {
    const loadingMessage = 'Changing Password';
    if (!this.loadingOpts.containsRequest(loadingMessage)) {
      this.loadingOpts.addRequest(loadingMessage);
      this.viewModel.resetForgottenPassword(req).subscribe(_ => {
        this.loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishSuccessMessage('We went ahead and logged you in ' +
          'using your new password!', 'Password Changed Successfully');
      }, err => {
        console.log(err);
        this.loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishErrorMessage('We were unable to change your password at this time', 'Changing Password Failed');
      });
    }
  }

  resendConfirmationCode() {
    this.viewModel.resendCode().subscribe(_ => {
      this.toastService.publishSuccessMessage('We have sent another code to your phone number!', 'Code Sent');
    }, err => {
      console.log(err);
      this.toastService.publishErrorMessage('We were unable to send you a code at this time', 'Sending Code Failed');
    });

  }

  resendForgotPasswordCode() {
    if (this.canResendCode) {
      this.canResendCode = false;
      setTimeout(() => {
        this.canResendCode = true;
      }, 30000);
      this.viewModel.sendForgotPasswordCode(this.viewModel.forgotPasswordRequest.value).subscribe(_ => {
        this.toastService.publishSuccessMessage('We have sent another code to your phone number!', 'Code Sent');
      }, err => {
        console.log(err);
        this.toastService.publishErrorMessage('We were unable to send you a code at this time', 'Sending Code Failed');
      });
    } else {
      this.toastService.publishInfoMessage('Please wait 30 sec before trying to send another code', 'Slow down Speed Racer!');
    }
  }

}
