import {Injectable, OnDestroy} from '@angular/core';
import {BaseDomainModel} from '../models/base/base-domain-model';
import {SessionService} from '../services/session-service';
import {InsiderAPI} from '../api/insider-api';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Insider} from '../models/guide/dto/insider';
import {tap} from 'rxjs/operators';
import {CompanyInsiderLookup} from '../models/guide/dto/company-insider-lookup';
import {CompanyInsiderReq} from '../models/guide/requests/company-insider-req';
import {CacheService} from '../services/cache-service';

@Injectable({
  providedIn: 'root'
})
export class ContributorsDomainModel extends BaseDomainModel implements OnDestroy {

  companies = new BehaviorSubject<Insider[]>(null);
  private companyContributors = new Map<string, Insider[]>();
  private companyInsiderLookups = new Map<string, CompanyInsiderLookup[]>();

  constructor(
    public session: SessionService,
    private insiderAPI: InsiderAPI,
    private cacheService: CacheService,
  ) {
    super();
    this.init();
  }

  public init() {
    this.setupBindings();
  }

  private setupBindings() {
    const sessionSub = this.session.sessionContainer.notNull().subscribe(s => {
      this.companies.next(s.insider?.companies);
    });
    this.pushSub(sessionSub);
  }

  public getCompanyContributors(companyId: string): Observable<Insider[]> {
    const cachedMapContributors = this.companyContributors.get(companyId);
    if (!!cachedMapContributors) {
      return of(cachedMapContributors);
    } else {
      const cachedCompanyContributors = this.cacheService.getCachedArray(Insider, Insider.buildArrayCacheKey(companyId));
      if (!!cachedCompanyContributors) {
        this.companyContributors.set(companyId, cachedCompanyContributors);
        return of(cachedCompanyContributors);
      } else {
        return this.insiderAPI.GetCompanyInsiders(companyId).pipe(tap(insiders => {
          this.cacheService.cacheArray<Insider>(Insider.buildArrayCacheKey(companyId), insiders);
          this.companyContributors.set(companyId, insiders);
        }));
      }
    }
  }

  public getCompanyInsiderLookups(companyId: string): Observable<CompanyInsiderLookup[]> {
    const insiders = this.companyInsiderLookups.get(companyId);
    if (!!insiders) {
      return of(insiders);
    }
    return this.insiderAPI.GetCompanyInsiderLookups(companyId).pipe(tap(results => {
      this.companyInsiderLookups.set(companyId, results);
    }));
  }

  public updateCompanyInsider(contributor: Insider, companyId: string, isAdmin: boolean): Observable<CompanyInsiderLookup[]> {
    const req = new CompanyInsiderReq();
    req.companyId = companyId;
    req.insiderId = contributor.id;
    req.isAdmin = isAdmin;

    return this.insiderAPI.UpdateCompanyInsider(req).pipe(tap(results => {
      this.companyInsiderLookups.set(companyId, results);
    }));
  }

  public addCompanyInsider(companyInsiderReq: CompanyInsiderReq): Observable<CompanyInsiderLookup[]> {
    return this.insiderAPI.AddCompanyInsider(companyInsiderReq).pipe(tap(results => {
      this.companyInsiderLookups.delete(companyInsiderReq.companyId);
      this.companyContributors.delete(companyInsiderReq.companyId);
      if (this.cacheService.getCachedArray(Insider, Insider.buildArrayCacheKey(companyInsiderReq.companyId))) {
        this.cacheService.removeCachedObject(Insider.buildArrayCacheKey(companyInsiderReq.companyId));
        this.getCompanyContributors(companyInsiderReq.companyId);
      }
    }));
  }

  public removeCompanyInsider(companyId: string, contributor: Insider): Observable<string> {
    return this.insiderAPI.DeleteCompanyInsider(companyId, contributor.id).pipe(tap(() => {
      this.companyInsiderLookups.delete(companyId);
      this.companyContributors.delete(companyId);
      if (this.cacheService.getCachedArray(Insider, Insider.buildArrayCacheKey(companyId))) {
        this.cacheService.removeCachedObject(Insider.buildArrayCacheKey(companyId));
        this.getCompanyContributors(companyId);
      }
    }));
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
