<app-loading
  [hidden]="!viewModel.loadingOpts.isLoading"
  [options]="viewModel?.loadingOpts">
</app-loading>

<div class="add-place-modal">
  <img *ngIf="viewModel.addPlaceFlow.valueOf() > 1 && viewModel.addPlaceFlow.valueOf() !== 3"
       (click)="viewModel.goBack()"
       style="cursor: pointer;"
       [src]="'assets/icons/dark/arrow-left.svg'">
  <div class="title-bar">
    <div class="modal-title">
      {{viewModel.getTitle()}}
    </div>
    <button *ngIf="viewModel.addPlaceFlow.valueOf() < 2" (click)="togglePlaceLookup()"
            class="custom-button grey-button"
            style="margin-right: 0.5rem;">
      {{ viewModel.addPlaceFlow === addPlaceFlow.PlaceSearch ? 'Drop Pin' : 'Search Place' }}
    </button>
    <button *ngIf="viewModel.addPlaceFlow.valueOf() < 2" (click)="closeModal()" class="custom-button text-button">
      Cancel
    </button>
  </div>
  <div class="add-place-action-container">
    <ng-container *ngIf="viewModel.addPlaceFlow === addPlaceFlow.PlaceSearch">
      <div class="auto-complete-search">
        <app-form-group (formChanges)="viewModel?.searchChanged()"
                        [formItems]="viewModel.searchItems"
                        [options]="viewModel.searchOptions"
                        [styling]="viewModel.searchStyling">
        </app-form-group>
        <div class="auto-complete-results-container">
          <app-loading
            [hidden]="!viewModel.searchLoadingOpts.isLoading"
            [options]="viewModel?.searchLoadingOpts">
          </app-loading>
          <ng-container *ngIf="!viewModel.searchLoadingOpts.isLoading">
            <div *ngFor="let place of viewModel.autoCompletedLocations" class="auto-complete-result mb-4"
                 (click)="viewModel.placeClicked(place)">
              <div class="result-title">{{place.name}}</div>
              <div class="result-address">{{place.address}}</div>
            </div>
          </ng-container>
          <img [src]="'assets/misc/powered-by-google.svg'" alt='Powered by Google'>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="viewModel.addPlaceFlow === addPlaceFlow.PlacePin">
      <div class="drop-pin-map-container">
        <app-map
          [mapMarkers]="viewModel.getCustomPlacePinArray()"
          [selectedMarker]="viewModel.getCustomPlacePin()"
          [allowPinDrop]="true"
          [fitBounds]="false"
          [zoomLevel]="15"
          [lng]="viewModel.getCustomPlacePin() ? viewModel.getCustomPlacePin().getLng() : viewModel.guideCity.lng"
          [lat]="viewModel.getCustomPlacePin() ? viewModel.getCustomPlacePin().getLat() : viewModel.guideCity.lat"
          (pinDropped)="viewModel.updateCustomPlacePin($event)">
        </app-map>
      </div>
      <div class="selected-pin-details">
        <app-form-group
          [formItems]="viewModel.customPinFormItems"
          [options]="viewModel.formOptions"
          [formObject]="viewModel.customPlacePin"
          [styling]="viewModel.formStyling"
          [hydrateInputObject]="viewModel.hydrateCustomPinObject"
          (formChanges)="viewModel.handleCustomPinFormChanges()">
        </app-form-group>
        <app-label-group
          [labelItems]="viewModel.labelGroupItems">
        </app-label-group>
      </div>
      <button class="custom-button preferred-button"
              [disabled]="!viewModel.canAddCustomPin"
              [ngClass]="{ 'disabled' : !viewModel.canAddCustomPin}"
              (click)="viewModel.generateCustomPlaceFromPin()">
        Add Place
      </button>
    </ng-container>

    <ng-container *ngIf="viewModel.addPlaceFlow === addPlaceFlow.PlaceInfo">
      <app-form-group (formChanges)="viewModel?.placeInfoChanged()"
                      [formItems]="viewModel.placeInfoItems"
                      [options]="viewModel.placeInfoOptions"
                      [styling]="viewModel.placeInfoStyling"
                      [formObject]="viewModel.newCustomPlace"
                      [hydrateInputObject]="viewModel?.hydratePlaceInfoObject">
      </app-form-group>
      <hr class="map-divider">
      <div class="map">
        <div class="misc-title mb-3">Map</div>
        <div class="map-container">
          <app-map
            [mapMarkers]="viewModel.mapMarkers"
            [selectedMarker]="viewModel.newCustomPlace"
            [fitBounds]="false"
            [zoomLevel]="17"
            [lng]="viewModel.newCustomPlace.getLng()"
            [lat]="viewModel.newCustomPlace.getLat()">
          </app-map>
        </div>
      </div>
      <div class="section-options-footer-container">
        <hr>
        <div class="button-container">
          <button class="custom-button grey-button" (click)="closeModal()">Cancel</button>
          <button class="custom-button preferred-button ml-2"
                  (click)="viewModel.checkForDuplicatePlace()"
                  [disabled]="!viewModel.canAddToGuide"
                  [ngClass]="{ 'disabled' : !viewModel.canAddToGuide}">
            Add to Guide
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="viewModel.addPlaceFlow === addPlaceFlow.AssignSection">
      <div class="mb-3">
        <div>{{viewModel.newCustomPlace.getTitle()}}</div>
        <div>{{viewModel.newCustomPlace.address}}</div>
      </div>
      <div class="section-container mt-2">
        <div class="section mb-4" *ngFor="let s of guide.sections">
          <div style="display: flex" (click)="viewModel.sectionSelected(s)">
            <img [src]="'assets/icons/dark/section.svg'" alt="section icon">
            <div class="misc-title ml-4">{{s.title}}</div>
          </div>
        </div>
        <div (click)="viewModel.addNewSectionFlow()" class="section mb-4">
          <img class="mr-1" [src]="'assets/icons/dark/plus.svg'" alt="section icon">
          <div class="misc-title ml-4">Create New Section</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="viewModel.addPlaceFlow === addPlaceFlow.CreateSection">
      <app-form-group (formChanges)="viewModel?.sectionTitleChanged()"
                      [formItems]="viewModel.addSectionItems"
                      [options]="viewModel.addSectionOptions"
                      [styling]="viewModel.addSectionStyling"
                      [formObject]="viewModel.newSection"
                      [hydrateInputObject]="viewModel?.hydrateSectionObject">
      </app-form-group>
      <div style="position: absolute; width: calc(100% - 2rem);" class="footer-container">
        <hr>
        <div class="button-container">
          <button class="custom-button preferred-button ml-2"
                  (click)="viewModel.createNewSection()"
                  [disabled]="!viewModel.canCreateNewSection"
                  [ngClass]="{ 'disabled' : !viewModel.canCreateNewSection}"
          >Done
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
