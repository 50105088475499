import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'keepIfInsiderId'
})
export class KeepIfInsiderIdPipe implements PipeTransform {

  transform(id: string, insiderId: string): string {
    if (!!id && !!insiderId) {
      return id === insiderId ? id : '';
    }
    return '';
  }

}
