import * as moment from 'moment';


export class DateUtils {

  // Calendar

  static buildDateKey(id: string, dateKey: string): string {
    return `${id}-${dateKey}`;
  }

  static defaultCalendarFormat(): string {
    return 'MMM DD, YYYY';
  }

  static defaultRanges(): any {
    return {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
  }

  static defaultRangesAsMap(): Map<string, [moment.Moment, moment.Moment]> {
    return new Map()
      .set('Today', [moment(), moment()])
      .set('Yesterday', [moment().subtract(1, 'days'), moment().subtract(1, 'days')])
      .set('Last 7 Days', [moment().subtract(6, 'days'), moment()])
      .set('Last 30 Days', [moment().subtract(29, 'days'), moment()])
      .set('This Month', [moment().startOf('month'), moment().endOf('month')])
      .set('Last Month', [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]);

  }

  // Comparisons

  static unixAfterDaysAgo(ts: number, days: number): boolean {
    return (DateUtils.currentTimestamp() - days * DateUtils.unixOneDay()) < ts;
  }

  static unixAfterHoursAgo(ts: number, hours: number): boolean {
    return (DateUtils.currentTimestamp() - hours * DateUtils.unixOneHour()) < ts;
  }

  static unixAfterMinutesAgo(ts: number, minutes: number): boolean {
    return (DateUtils.currentTimestamp() - minutes * 60) < ts;
  }

  // Formatters

  static formatUnixToDateTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('lll');
  }

  static formatUnixToDateTimeNoYear(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('MMM D, LT');
  }

  static formatUnixToTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('LT');
  }

  static formatUnixToDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('ll');
  }

  static formatUnixToShorthandDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('MMM D');
  }

  static formatUnixForDateInput(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('YYYY-MM-DD');
  }

  static formatToUnixYear(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('YYYY');
  }

  // Unix Helpers

  static currentTimestamp(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  static unixOneYear(): number {
    return DateUtils.unixOneDay() * 365;
  }

  static unixOneMonth(): number {
    return 30 * 24 * 60 * 60;
  }

  static unixOneWeek(): number {
    return 7 * 24 * 60 * 60;
  }

  static unixOneDay(): number {
    return 24 * 60 * 60;
  }

  static unixOneHour(): number {
    return 60 * 60;
  }
}
