import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AccountDomainModel} from '../../domain-models/account-domain-model';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    public accountDomainModel: AccountDomainModel,
    public router: Router
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const observer = this.accountDomainModel.isAuthenticated().map(r => r != null);
    observer.subscribe(res => {
      if (!res) {
        this.router.navigate(['/auth/sign-in']).then(_ => {});
      }
    });
    return observer;
  }

}
