<div style="height: 40rem" class="modal-body">
  <div class="title-bar">
    <div class="modal-title">
      Add Images
    </div>
    <button matRipple (click)="closeModal()" class="custom-button text-button">Cancel</button>
  </div>
  <div class="upload-container">
    <app-upload-asset
    [id]="1"
    [modalStyle]="true"
    [uploadAreaCustomClass]="'modalUploadArea'"
    [parentHandler]="this"
    [initialFiles]="initialFiles ? initialFiles : null">
    </app-upload-asset>
  </div>
  <div class="footer-container">
    <hr>
    <div class="button-container">
      <button matRipple class="custom-button grey-button" (click)="closeModal()">Cancel</button>
      <button matRipple class="custom-button preferred-button ml-2" (click)="uploadItems()">Upload</button>
    </div>
  </div>
</div>
