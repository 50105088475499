import {Injectable, OnDestroy} from '@angular/core';
import {BaseDomainModel} from '../models/base/base-domain-model';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {InsiderInsight} from '../models/guide/dto/insider-insight';
import {InsiderAPI} from '../api/insider-api';
import {SharedAPI} from '../api/shared-api';
import {SessionService} from '../services/session-service';
import {ToastService} from '../services/toast-service';
import {InsiderInsightReq} from '../models/guide/requests/insider-insight-req';
import {GuideInsight} from '../models/guide/dto/guide-insight';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';
import {GuideAPI} from '../api/guide-api';
import {GuideInsightReq} from '../models/guide/requests/guide-insight-req';
import {CustomError} from '../models/shared/custom-error';

@Injectable({ providedIn: 'root' })
export class InsightsDomainModel extends BaseDomainModel implements OnDestroy {

  /** Insider Insight Data */
  private insiderInsights = new BehaviorSubject<Map<string, InsiderInsight>>(new Map()); // <(id-time), InsiderInsight>
  private updateInsiderInsightsSubject = new Subject<[InsiderInsightReq, InsiderInsight]>();
  private updateInsiderInsightsMechanism = combineLatest([
    this.updateInsiderInsightsSubject,
    this.insiderInsights
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
    .subscribe(([[req, insight], insights]) => {
      if (!!req && !!insight) {
        insights.set(req.buildKey(), insight);
        this.updateInsiderInsightsSubject.next([null, null]);
        this.insiderInsights.next(insights);
      }
    });
  public insiderInsights$ = this.insiderInsights.asObservable();

  /** Guide Insight Data */
  private guideInsights = new BehaviorSubject<Map<string, GuideInsight>>(new Map()); // <(id-time), GuideInsight>
  private updateGuideInsightsSubject = new Subject<[GuideInsightReq, GuideInsight]>();
  private updateGuideInsightsMechanism = combineLatest([
    this.updateGuideInsightsSubject,
    this.guideInsights
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
    .subscribe(([[req, insight], insights]) => {
      if (!!req && !!insight) {
        insights.set(req.buildKey(), insight);
        this.updateGuideInsightsSubject.next([null, null]);
        this.guideInsights.next(insights);
      }
    });
  public guideInsights$ = this.guideInsights.asObservable();

  /** Fetching Data Queues */
  private insiderInsightReqQueue: InsiderInsightReq[] = [];
  private guideInsightReqQueue: GuideInsightReq[] = [];

  constructor(
    private guideApi: GuideAPI,
    private insiderApi: InsiderAPI,
    private sharedApi: SharedAPI,
    public session: SessionService,
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  public GetGuideInsights(req: GuideInsightReq): Observable<GuideInsight> {
    return this.guideApi.GetGuideInsights(req).pipe(tap(insight => {
      this.updateGuideInsightsSubject.next([req, insight]);
    }));
  }

  public GetInsiderInsights(req: InsiderInsightReq): Observable<InsiderInsight> {
    return this.insiderApi.GetInsiderInsights(req).pipe(tap(insight => {
      this.updateInsiderInsightsSubject.next([req, insight]);
    }));
  }

  public addInsiderInsightReq(req: InsiderInsightReq) {
    if (this.insiderInsightReqQueue.findIndex(qItem => qItem.equalityCheck(req)) < 0) {
      this.insiderInsightReqQueue.push(req);
      this.GetInsiderInsights(req).subscribe(_ => {
        const index = this.insiderInsightReqQueue.findIndex(it => it.equalityCheck(req));
        if (index > -1) { this.insiderInsightReqQueue.splice(index, 1); }
      }, (e: CustomError) => {
        const index = this.insiderInsightReqQueue.findIndex(it => it.equalityCheck(req));
        if (index > -1) { this.insiderInsightReqQueue.splice(index, 1); }
        this.toastService.publishError(e);
      });
    }
  }

  public addGuideInsightReq(req: GuideInsightReq) {
    if (this.guideInsightReqQueue.findIndex(qItem => qItem.equalityCheck(req)) < 0) {
      this.guideInsightReqQueue.push(req);
      this.GetGuideInsights(req).subscribe(_ => {
        const index = this.guideInsightReqQueue.findIndex(it => it.equalityCheck(req));
        if (index > -1) { this.guideInsightReqQueue.splice(index, 1); }
      }, (e: CustomError) => {
        const index = this.guideInsightReqQueue.findIndex(it => it.equalityCheck(req));
        if (index > -1) { this.guideInsightReqQueue.splice(index, 1); }
        this.toastService.publishError(e);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
