import {Cachable} from '../../protocols/cachable';
import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {DateUtils} from '../../../utils/date-utils';
import {CachePolicy} from '../../enum/shared/cachable-image-policy.enum';
import {Orderable} from '../../protocols/orderable';
import {Asset} from '../../image/dto/asset';

export class GuideFeature implements Cachable, Deserializable, Orderable {

  public insiderId: string;
  public id: string;
  public title: string;
  public description: string;
  public tags: string[];
  public priority: number;
  // Cache
  cachedTime: number;

  constructor() {
    this.insiderId = '';
    this.id = '';
    this.title = '';
    this.description = '';
    this.tags = [];
  }

  static buildArrayCacheKey(insiderId: string): string {
    return `GuideFeatures-${insiderId}`;
  }

  static buildCacheKey(id: string): string {
    return `GuideFeature-${id}`;
  }

  onDeserialize() {
    if (this.tags && this.tags.length > 0) {
      this.tags = Array.from(this?.tags || []);
    }
  }

  // Caching

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(): string {
    return GuideFeature.buildCacheKey(this.id);
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Service;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  getOrderValue(): number {
    return this.priority;
  }

  getOrderableAsset(): Asset {
    return null;
  }

  getOrderableTitle(): string {
    return this.title;
  }

  getOrderableUniqueId(): string {
    return this.id;
  }

  setOrderableValue(val: number) {
    this.priority = val;
  }


}
