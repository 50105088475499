<app-loading
  [hidden]="!viewModel.loadingOpts.isLoading"
  [options]="viewModel?.loadingOpts">
</app-loading>

<div class="edit-guide-feature-modal">
  <div class="title-bar">
    <div class="modal-title">
      {{viewModel.title}}
    </div>
  </div>
  <div class="edit-guide-feature-action-container">

    <app-form-group (formChanges)="viewModel?.formChanges()"
                    [formItems]="viewModel.formItems"
                    [options]="viewModel.formOptions"
                    [styling]="viewModel.formStyling"
                    [formObject]="viewModel.guideFeature"
                    [hydrateInputObject]="viewModel?.hydrateForm">
    </app-form-group>

    <app-search-bar-add class="block mt-16px"
                        [placeHolder]="'Add Tag Here...'"
                        [maxLength]="25"
                        (enterClickedOutput)="viewModel.addTag($event)"></app-search-bar-add>
    <app-pill-container class="modal-description mt-16px"
                        [pills]="viewModel.tagPills|async"
                        (pillClicked)="this.viewModel.removeTag($event)">
    </app-pill-container>

    <div class="section-options-footer-container">
      <hr>
      <div class="button-container">
        <button class="custom-button grey-button" (click)="closeModal()">Cancel</button>
        <button class="custom-button preferred-button ml-2"
                (click)="viewModel.saveGuideFeature()"
                [disabled]="!viewModel.canSubmitForm"
                [ngClass]="{ 'disabled' : !viewModel.canSubmitForm}">
          {{viewModel.cta}}
        </button>
      </div>
    </div>

  </div>
</div>
