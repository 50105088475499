<label class="custom-checkbox-container"
       [ngClass]="{ 'inline-checkbox' : inline }"
       [ngStyle]="{ 'margin': customCheckboxMargin ? customCheckboxMargin : null }"
       for="{{cid}}">
  <input type="checkbox"
         class="custom-checkbox"
         id="{{cid}}"
         name="custom-cb"
         [checked]="checked"
         [disabled]="disabled"
         (change)="onClick()">
  <span class="custom-checkbox-label">
    <ng-content *ngIf="!label"></ng-content>
    <ng-container *ngIf="label && !innerHTMLLabel">{{label}}</ng-container>
    <ng-container *ngIf="innerHTMLLabel">
      <span [innerHTML]="label"></span>
    </ng-container>
  </span>
  <span class="custom-checkmark"></span>
</label>
