import {Asset} from '../dto/asset';
import {AssetSize} from '../../enum/dto/image-size.enum';

export class FullImage extends Asset {
  onDeserialize() {
    this.sizeOverrides = [AssetSize.Medium, AssetSize.Large];
    // AssetSize.Small, AssetSize.Thumb - dont include so they dont download unnecessarily
    super.onDeserialize();
  }
}
