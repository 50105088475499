import {Injectable} from '@angular/core';
import {BaseDomainModel} from '../models/base/base-domain-model';
import {BehaviorSubject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SegmentedGuidesDomainModel extends BaseDomainModel {

  public positions = new BehaviorSubject<Map<string, number>>(new Map<string, number>());

  constructor(
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  updatePosition(id: string, p: number) {
    const map = this.positions.getValue();
    map.set(id, p);
    this.positions.next(map);
  }

}
