import {Deserializable} from '../../protocols/deserializable';
import {Cachable} from '../../protocols/cachable';
import {CachePolicy} from '../../enum/shared/cachable-image-policy.enum';
import {DateUtils} from '../../../utils/date-utils';

export class PlaceCategory implements Deserializable, Cachable {
  childId: string;
  childName: string;
  childPluralName: string;
  childShortName: string;
  parentId: string;
  parentName: string;
  parentPluralName: string;
  parentShortName: string;

  cachedTime: number;

  static buildCacheKey(): string {
    return 'PlaceCategories';
  }

  onDeserialize() {
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneMonth();
  }

  cacheKey(...params: any): string {
    return '';
  }

  imageCachePolicy(): CachePolicy {
    return undefined;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

}
