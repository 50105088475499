import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Insider} from '../../../../../models/guide/dto/insider';
import {ToastService} from '../../../../../services/toast-service';
import {environment} from '../../../../../../environments/environment';

@Component({
  selector: 'app-copy-embed-code',
  templateUrl: './copy-embed-code.component.html',
  styleUrls: [
    './copy-embed-code.component.scss',
    '../../../../../../scss/stylesheet.scss'
  ]
})
export class CopyEmbedCodeComponent extends BaseComponent
  implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() ctaText: string;
  @Input() insider: Insider;
  value: string = null;

  constructor(
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  setupBindings() {
  }

  setupViews() {
    this.generateEmbedString();
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.value).then((_) => {
      this.toastService.publishInfoMessage('Copied to clipboard', 'Copy Successful!');
    });
  }

  private generateEmbedString() {
    const baseUrl = environment.production ? 'embed.krugopartners.com' : 'staging.embed.krugopartners.com';
    if (!!this.insider) {
      const url = `https://${baseUrl}/#/guides/${this.insider.id}`;
      // noinspection UnnecessaryReturnStatementJS,ThisExpressionReferencesGlobalObjectJS
      this.value = `
<div id="mainDiv" class="embedded-container" style="min-height:50vh;">
    <iframe id="krugo-partners-iframe" src="${url}" width="100%" height="100%" allow="clipboard-read; clipboard-write"></iframe>
</div>

<script type="text/javascript" src="https://krugopartners.s3-us-west-2.amazonaws.com/embedScript.js"></script>
`;
    }
  }
}
