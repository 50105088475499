import {BaseViewModel} from './base-view-model';
import {Subject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

export class BaseModalViewModel extends BaseViewModel {

  constructor(
    public router: Router,
    public modalService: NgbModal,
  ) {
    super();
    this.bindToBackNav();
  }

  public dismissModalSubject: Subject<any> = new Subject<any>();

  cancel() {
    this.dismissModalSubject.next(true);
  }

  bindToBackNav() {
    const backNavSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });
    this.pushSub(backNavSub);
  }

}
