import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-choose-color-button',
  templateUrl: './choose-color-button.component.html',
  styleUrls: ['./choose-color-button.component.scss']
})
export class ChooseColorButtonComponent implements OnInit {

  @Input() color: string;
  @Output() colorChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}
