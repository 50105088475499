import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {AuthViewModel} from '../../../viewModels/auth-view-model';
import {ForgotPasswordRequest} from '../../../../../models/account/requests/forgot-password-request';
import {AuthFlow} from '../../../../../models/account/enum/auth-flow.enum';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {ToastService} from '../../../../../services/toast-service';
import {PasswordValidatorDirective} from '../../../../shared/components/form-group/validators/password-validator.directive';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss',
              '../auth.component.scss',
              '../../../../../../scss/stylesheet.scss']
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit, OnDestroy {

  // View
  public forgotPasswordFormItems: FormInputItem[] = [];
  public formStyling: FormGroupStyling = new FormGroupStyling();
  public formOptions: FormOptions = new FormOptions();
  public req: ForgotPasswordRequest = new ForgotPasswordRequest();
  public loadingOpts: LoadingOptions = LoadingOptions.default();


  constructor(
    public viewModel: AuthViewModel,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupBindings();
    this.setupViews();
  }

  ngOnDestroy() {
    this.destroy();
  }

  setupBindings() {
  }

  setupViews() {
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    this.forgotPasswordFormItems = [];

    const phoneNumInput = new FormInputItem();
    phoneNumInput.itemType = FormItemType.Input;
    phoneNumInput.inputName = 'username';
    phoneNumInput.label = 'Phone Number';
    phoneNumInput.hideLabel = true;
    phoneNumInput.placeholder = 'Phone Number';
    phoneNumInput.bindingProperty = 'username';
    phoneNumInput.inputType = FormInputType.Tel;
    phoneNumInput.required = true;
    phoneNumInput.inputMask = ['1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.forgotPasswordFormItems.push(phoneNumInput);

    const password = new FormInputItem();
    password.itemType = FormItemType.Input;
    password.inputType = FormInputType.Password;
    password.inputName = 'password';
    password.label = 'Password';
    password.hideLabel = true;
    password.placeholder = 'New Password';
    password.bindingProperty = 'password';
    password.required = true;
    password.customValidator = new PasswordValidatorDirective();
    this.forgotPasswordFormItems.push(password);

    const confirmPassword = new FormInputItem();
    confirmPassword.itemType = FormItemType.Input;
    confirmPassword.inputType = FormInputType.Password;
    confirmPassword.inputName = 'confirmPassword';
    confirmPassword.label = 'Confirm Password';
    confirmPassword.hideLabel = true;
    confirmPassword.placeholder = 'Confirm New Password';
    confirmPassword.bindingProperty = 'confirmPassword';
    confirmPassword.required = true;
    confirmPassword.mustMatchInputName = 'password';
    confirmPassword.customValidator = new PasswordValidatorDirective();
    this.forgotPasswordFormItems.push(confirmPassword);

  }

  setupFormStyling() {
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.submitButtonText = 'Change Password';
  }

  formSubmitted(req: ForgotPasswordRequest) {
    const loadingMessage = 'Sending you a code!';
    if (!this.loadingOpts.containsRequest(loadingMessage)) {
      this.loadingOpts.addRequest(loadingMessage);
      this.viewModel.sendForgotPasswordCode(req).subscribe(_ => {
        this.loadingOpts.removeRequest(loadingMessage);
        this.viewModel.nextAuthFlow.next(AuthFlow.ConfirmAccount);
      }, err => {
        console.log(err);
        this.loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishError(err);
      });
    }

  }

  signIn() {
    this.viewModel.nextAuthFlow.next(AuthFlow.SignIn);
  }

}
