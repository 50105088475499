<div class="menu-position">
  <ng-content></ng-content>
  <div class="btn-group">
    <ul *dropdownMenu
        class="dropdown-menu custom-dropdown-menu"
        role="menu">
      <ng-container *ngFor="let section of sections">
        <li *ngIf="section.header">
          <div class="custom-dropdown-menu-header">
            {{section.header.title}}
          </div>
        </li>
        <li *ngFor="let item of section.items"
            role="menuitem"
            (click)="clicked(item.value)">
          <button [ngClass]="item.styling.add('custom-dropdown-menu-item')">
            {{item.title}}
          </button>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

