<div class="modal-subtitle mb-3">
  {{this.viewModel.selectedCity.lookupKey}}
</div>
<div class="modal-divider mb-3"></div>
<app-form-group
  [formItems]="this.viewModel.addGuideInfoItems"
  [formObject]="this.viewModel.guide"
  [options]="this.viewModel.addGuideInfoOptions"
  [styling]="this.viewModel.addGuideInfoStyling"
  [hydrateInputObject]="viewModel?.hydrateInputObject"
  (formChanges)="this.viewModel.guideInfoChanges()">
</app-form-group>
<div class="mt-3">
  <div class="modal-divider mb-3"></div>
  <app-pill [pillIcon]="this.viewModel?.selectedAuthor?.profilePicture" [clickable]="false">{{this.viewModel?.selectedAuthor?.title}}</app-pill>
  <div class="mt-1 contributor-subtext">
    {{this.viewModel?.selectedAuthor?.subtitle}}
  </div>
</div>
<div class="modal-divider mt-3"></div>
<div class="footer-container">
  <hr>
  <div class="button-container">
    <button matRipple class="custom-button grey-button" (click)="this.viewModel.closeModal()">Cancel</button>
    <button matRipple
            class="custom-button preferred-button ml-2"
            (click)="this.viewModel.submitGuide()"
            [disabled]="!this.viewModel.canSubmitGuide"
            [ngClass]="{ 'disabled' : !this.viewModel.canSubmitGuide}"
    >Continue
    </button>
  </div>
</div>
