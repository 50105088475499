<app-loading
  [hidden]="!viewModel.loadingOpts.isLoading"
  [options]="viewModel?.loadingOpts">
</app-loading>

<ng-container *ngIf="viewModel.guide">
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 create-title">
        Edit Guide
      </div>
      <div class="ml-auto">
        <button matRipple
                *ngIf="viewModel.guide && viewModel.guide.status === GuideStatus.Approved"
                (click)="viewModel.copyGuideUrl()"
                class="custom-button grey-button"
                style="margin-left: 1rem;">
          <img [src]="'assets/icons/dark/outline/code.svg'" alt="Code">
          Copy Guide Url
        </button>
        <button matRipple
                *ngIf="viewModel.guide?.customPlaces?.length > 0" (click)="viewModel.openMapModal()"
                class="custom-button grey-button"
                style="margin-left: 1rem;">
          <img [src]="'assets/icons/dark/map.svg'" alt="Map Icon">
          View On Map
        </button>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-4 offset-2 mb-4">
        <app-pill [clickable]="false">{{viewModel.getGuideStatus(viewModel.guide.status)}}</app-pill>
      </div>
      <div class="col-4 ml-auto">
        <button *ngIf="viewModel.guideImages.length > 1" (click)="viewModel.openReorderImageModal()" class="custom-button">Reorder Images</button>
      </div>
      <div *ngIf="viewModel.getFeaturedImage()"
           class="col-8 offset-2 featured-image-container"
           fxLayout="row" fxLayoutAlign="start">
        <div>
          <div class="remove-featured-button-container"
               (click)="viewModel.deleteGuideImage(viewModel.guideImages[0].asset)">
            <img class="remove-button-icon" [src]="'assets/icons/dark/x.svg'" alt="remove image">
          </div>
          <app-asset #myself
                     class="featured-image"
                     [borderRadius]="'0.625rem'"
                     (ratio)="setFeaturedRatio($event)"
                     [style.width.px]="(featuredImageRatio$ | async | multiplyBy: myself.el.nativeElement.clientHeight)"
                     [asset]="viewModel.getFeaturedImage()">
          </app-asset>
        </div>
      </div>
      <div *ngIf="!viewModel.getFeaturedImage()" class="col-8 offset-2 mb-5 image-upload-container">
        <app-upload-asset
          [uploadAreaCustomClass]="'guideUploadArea'"
          [allowImage]="!featuredImage"
          [allowVideo]="!featuredImage"
          [maxAssets]="9"
          [displayList]="false"
          [parentHandler]="viewModel">
        </app-upload-asset>
      </div>
      <div class="col-8 offset-2 mt-2" *ngIf="viewModel.guideImages?.length > 0">
        <ul class="custom-upload-list">
          <li *ngFor="let img of viewModel.guideImages | slice:1">
            <div class="remove-button-container" (click)="viewModel.deleteGuideImage(img.asset)">
              <img class="remove-button-icon" [src]="'assets/icons/dark/x.svg'" alt="remove image">
            </div>
            <div class="custom-upload-preview-image">
              <app-asset [asset]="img.asset" [borderRadius]="'0.5rem'"></app-asset>
            </div>
          </li>
          <li *ngIf="viewModel.guideImages?.length < 9" class="custom-upload-preview-image">
            <img class="add-more-icon" (click)="viewModel.openUploadModal()" [src]="'assets/icons/dark/plus.svg'"
                 alt="plus">
          </li>
        </ul>
      </div>
    </div>
    <div class="row label-group-container">
      <div class="col-8 offset-2">
        <div *ngIf="!viewModel.canEdit" class="mt-5">
          <app-label-group
            [labelItems]="viewModel.labelGroupItems">
          </app-label-group>
        </div>
        <div *ngIf="viewModel.canEdit" class="mt-5">
          <app-form-group #formGroupComponent
                          (formChanges)="viewModel?.guideInfoChanges()"
                          [formItems]="this.viewModel.guideInfoItems"
                          [options]="this.viewModel.guideInfoOptions"
                          [formObject]="this.viewModel.guide"
                          [hydrateInputObject]="viewModel?.hydrateInputObject"
                          [styling]="this.viewModel.guideInfoStyling">
          </app-form-group>
        </div>

        <!-- Author Container -->
        <div class="author-container">
          <!-- Author Pill -->
          <div class="author-child-container"
               [ngClass]="{'no-pointer': !viewModel.canEdit}">
            <app-label-group [labelItems]="viewModel.authorLabelGroupItems"></app-label-group>
          </div>
          <!-- Switch to hide contributor -->
          <div class="author-child-container"
               [hidden]="!viewModel.showSwitchForContributor()">
            <app-form-group
              [formItems]="viewModel.authorInfoItems"
              [options]="viewModel.authorInfoOptions"
              [formObject]="viewModel.guide"
              [styling]="viewModel.authorInfoStyling"
              (formChanges)="viewModel.guideInfoChanges()"
              [hydrateInputObject]="viewModel.hydrateInputObject"></app-form-group>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf="viewModel.orphanPlaces.length > 0" class="row section-container col-8 offset-2 mt-3">
      <div class="unassigned-section-header">
        <div class="section-title red-text">Unassigned Places</div>
        <div class="section-title-subtext mb-2">Places will not display in your guide until they are assigned to a section. Please use the button to assign one now.</div>
      </div>
      <hr class="item-divider">
      <ng-container *ngFor="let p of viewModel.orphanPlaces, let last = last">
        <div matRipple fxLayout="row" class="pt-16px pb-16px unassigned-section-body section-body">
          <div class="guide-image" fxFlex="none">
            <app-asset [asset]="viewModel.getPlaceThumb(p.id)" [borderRadius]="'0.5rem'"></app-asset>
          </div>
          <div class="col-10 ml-3" fxFlex>
            <div class="section-title">{{viewModel.getPlaceName(p.id)}}
              <div class="assign-button-container ml-auto">
                <button class="custom-button text-button" (click)="viewModel.assignPlaceToSection(p)">Assign to Section</button>
              </div>
            </div>
            <div class="place-description">{{viewModel.getPlaceDescription(p.id)}}</div>
          </div>
        </div>
        <hr class="item-divider">
      </ng-container>
    </div>
    <div class="row section-container col-8 offset-2 mt-3">
      <div *ngFor="let s of viewModel.guide.sections"
           class="section mt-5"
           [style.padding-right.rem]="0.625">
        <div class="section-header">
          <div class="section-title">{{s.title}}</div>
          <div class="edit-section-button">
            <div class="section-item-action"
                 matRipple
                 popper
                 [popper]="popperContent"
                 [popperShowOnStart]="false"
                 [popperTrigger]="'click'"
                 [popperModifiers]="popperModifier"
                 [popperHideOnClickOutside]="true"
                 [popperHideOnScroll]="true"
                 [popperPreventOverflow]="false"
                 [popperApplyArrowClass]="'d-none'"
                 [popperApplyClass]="'custom-popper-content'"
                 [popperStyles]="popperStyles"
                 [popperPlacement]="'bottom-end'">
              <img [src]="'assets/icons/dark/solid/dots-horizontal.svg'" alt="options">
            </div>
            <popper-content #popperContent [style.z-index]="1">
              <div role="menuitem">
                <button matRipple
                        class="custom-dropdown-menu-item"
                        (click)="renameSection(s); popperContent.hide();">Rename
                </button>
              </div>
              <div role="menuitem">
                <button matRipple
                        class="custom-dropdown-menu-item"
                        (click)="rearrangeSection(s); popperContent.hide();">Rearrange
                </button>
              </div>
              <div role="menuitem">
                <button matRipple
                        class="custom-dropdown-menu-item red-text"
                        (click)="removeSection(s); popperContent.hide();">Remove
                </button>
              </div>
            </popper-content>
          </div>
        </div>
        <hr class="item-divider">
        <ng-container *ngFor="let id of s.sortedIds, let last = last">
          <div matRipple fxLayout="row" [ngClass]="viewModel.isPlaceOrStay(id) ? 'disabled-section' : 'section-body'"
               class="pt-16px pb-16px" (click)="viewModel.placeClicked(id)">
            <div class="guide-image" fxFlex="none">
              <app-asset [asset]="viewModel.getPlaceThumb(id)" [borderRadius]="'0.5rem'"></app-asset>
            </div>
            <div class="col-10 ml-3" fxFlex>
              <div class="section-title">{{viewModel.getPlaceName(id)}}</div>
              <div class="place-description">{{viewModel.getPlaceDescription(id)}}</div>
            </div>
          </div>
          <hr class="item-divider">
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<div class="add-place-button-container">
  <button class="add-place-button" (click)="viewModel.openAddPlaceModal()">
    <img class="add-place-icon" [src]="'assets/icons/light/plus.svg'" alt="plus"></button>
</div>

<div class="sticky-footer-container" style="padding-left: 30px; padding-right: 30px">
  <hr>
  <div class="footer-button-container">
    <div class="float-left">
      <button matRipple
              *ngIf="viewModel.getGuideStatus(viewModel?.guide?.status) != 'Archived'"
              class="custom-button grey-button"
              (click)="viewModel.updateGuideStatus(viewModel?.guideStatus.Archived)">Archive
      </button>
    </div>
    <div class="save-submit-buttons">
      <button matRipple
              class="custom-button grey-button"
              (click)="viewModel.updateGuideStatus(viewModel?.guideStatus.Draft)">
        Save as Draft
      </button>
      <button matRipple
              class="custom-button preferred-button ml-2"
              (click)="viewModel.updateGuideStatus(viewModel?.guideStatus.Pending)">Submit Guide
      </button>
    </div>
  </div>
</div>
