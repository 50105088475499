import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Tabbable} from '../../../../../models/protocols/tabbable';
import {PendingViewModel} from './pending-view-model';
import {TypeUtils} from '../../../../../utils/type-utils';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss'],
  providers: [PendingViewModel],
})
export class PendingComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy, Tabbable {

  @Input() associatedInsiderId: string;
  types = TypeUtils;

  constructor(
    public vm: PendingViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
