import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountComponent} from './components/account/account.component';
import {AccountRoutingModule} from './account-routing.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {SignInComponent} from './components/auth/sign-in/sign-in.component';
import {SignUpComponent} from './components/auth/sign-up/sign-up.component';
import {AuthComponent} from './components/auth/auth.component';
import {AuthViewModel} from './viewModels/auth-view-model';
import {ConfirmAccountComponent} from './components/auth/confirm-account/confirm-account.component';
import {ForgotPasswordComponent} from './components/auth/forgot-password/forgot-password.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { IsAuthFlowPipe } from './components/auth/is-auth-flow.pipe';

@NgModule({
  declarations: [
    AccountComponent,
    SignInComponent,
    SignUpComponent,
    AuthComponent,
    ConfirmAccountComponent,
    ForgotPasswordComponent,
    IsAuthFlowPipe
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    FormsModule,
    SharedModule,
    FlexLayoutModule
  ],
  providers: [
    AuthViewModel
  ]
})
export class AccountModule { }
