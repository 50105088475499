import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AbandonFormComponent} from '../../views/shared/modals/abandon-form/abandon-form.component';
import {ComponentCanDeactivate} from '../../models/protocols/component-can-deactivate';
import {ModalUtils} from '../../utils/modal-utils';

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
};

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(
    private modalService: NgbModal
  ) {
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Promise<any> {
    return component.canDeactivate() || this.modalService.open(AbandonFormComponent,
      ModalUtils.defaultModalOptions()).result.finally(() => {
      this.modalService.dismissAll();
    });
    if (CanDeactivateState.defendAgainstBrowserBackButton) {
      history.pushState(null, '', '');
    }
  }

}
