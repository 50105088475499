import {Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {ContributorsDomainModel} from '../../../../domain-models/contributors-domain-model';
import {ActivatedRoute} from '@angular/router';
import {Insider} from '../../../../models/guide/dto/insider';
import {ToastService} from '../../../../services/toast-service';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {LoadingSpinnerSize} from '../../../../models/enum/shared/loading-spinner-size.enum';


@Injectable()
export class ContributorsViewModel extends BaseViewModel implements OnDestroy {

  title: string;
  company: Insider;
  companyContributors: Insider[];
  companyAdmins: Insider[];
  public loadingOpts: LoadingOptions;


  constructor(
    private dm: ContributorsDomainModel,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupLoadingSpinner();
    this.setupBindings();
  }

  setupBindings() {
    this.loadingOpts.isLoading = true;
    this.route.params.firstNotNull().subscribe(params => {
      this.dm.companies.firstNotNull().subscribe(companies => {
        this.company = companies.find(c => c.id === params.companyId);
        this.setupWithCurrentCompany();
      });
    });
  }

  setupWithCurrentCompany() {
    this.title = this.company.companyName;
    this.dm.getCompanyContributors(this.company.id).subscribe(contributors => {
      this.dm.getCompanyInsiderLookups(this.company.id).subscribe(companyInsiderLookup => {
        this.companyAdmins = contributors.filter(c => companyInsiderLookup.find(i => i.insiderId === c.id && i.isCompanyAdmin));
        this.companyContributors = contributors.filter(c => !this.companyAdmins.find(a => a.id === c.id));
        this.loadingOpts.isLoading = false;
      });
    });
  }

  makeAdmin(contributor: Insider) {
    this.loadingOpts.isLoading = true;
    this.dm.updateCompanyInsider(contributor, this.company?.id, true).subscribe(() => {
      this.toastService.publishSuccessMessage(`${contributor.getFullName()} is now an Administrator for ${this.company.companyName}`, null);
      this.setupWithCurrentCompany();
    });
  }

  makeContributor(contributor: Insider) {
    this.loadingOpts.isLoading = true;
    this.dm.updateCompanyInsider(contributor, this.company?.id, false).subscribe(() => {
      this.toastService.publishSuccessMessage(`${contributor.getFullName()} is now a Contributor for ${this.company.companyName}`, null);
      this.setupWithCurrentCompany();
    });
  }

  removeContributor(contributor: Insider) {
    this.loadingOpts.isLoading = true;
    this.dm.removeCompanyInsider(this.company.id, contributor).subscribe(() => {
      this.toastService.publishSuccessMessage(`${contributor.getFullName()} was removed from ${this.company.companyName}`, null);
      this.setupWithCurrentCompany();
    });
  }

  private setupLoadingSpinner() {
    this.loadingOpts = LoadingOptions.default();
    this.loadingOpts.showLoadingText = false;
    this.loadingOpts.isLoading = false;
    this.loadingOpts.spinnerSize = LoadingSpinnerSize.Medium;
    this.loadingOpts.backgroundColor = '#FCFCFC';
    this.loadingOpts.spinnerColor = '#222222';
  }

  ngOnDestroy() {
    this.destroy();
  }

}
