import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from '../../../../services/toast-service';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {FormGroupComponent} from '../../../shared/components/form-group/form-group.component';

@Component({
  selector: 'app-reject-guide-modal',
  templateUrl: './reject-guide-modal.component.html',
  styleUrls: ['./reject-guide-modal.component.scss']
})
export class RejectGuideModalComponent extends BaseModal
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('form') form: FormGroupComponent;
  public rejectFormInput: FormInputItem[] = [];
  public rejection = { rejectionMessage: '' };
  public formStyling: FormGroupStyling = new FormGroupStyling();
  public formOptions: FormOptions = new FormOptions();

  constructor(
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setupFormItems();
  }

  setupFormItems() {
    const rejectInput = new FormInputItem();
    rejectInput.itemType = FormItemType.Input;
    rejectInput.inputName = 'Rejection_Description';
    rejectInput.label = 'Rejection Description';
    rejectInput.hideLabel = true;
    rejectInput.placeholder = 'Rejection Description';
    rejectInput.bindingProperty = 'rejectionMessage';
    rejectInput.required = true;
    rejectInput.inputType = FormInputType.Text;
    this.rejectFormInput.push(rejectInput);
  }

  formSubmitted(e) {
    this.activeModal.close(this.rejection.rejectionMessage);
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  cancel() {
    this.activeModal.close(false);
  }

  save() {
    if (this.form.canSubmitForm()) {
      this.form.submitForm();
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
