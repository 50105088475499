import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {BaseComponent} from '../../../../../../models/base/base-component';
import {BaseCreateViewModel} from '../../viewmodels/base-create-view-model';
import {GuideAuthor} from '../../../../../../models/guide/shared/guide-author';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-choose-author',
  templateUrl: './choose-author.component.html',
  styleUrls: ['./choose-author.component.scss',
    './../../../../../../../scss/stylesheet.scss',
    './../base-create-guide/base-create-guide.component.scss'
  ]
})
export class ChooseAuthorComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() associatedId: string;
  @Output() selectedAuthor = new EventEmitter<GuideAuthor>();
  modalStyle: boolean = false;
  currentAuthor: GuideAuthor = null;

  constructor(
    public viewModel: BaseCreateViewModel,
    private activeModal: NgbActiveModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.viewModel.generateGuideAuthors(this.associatedId);
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.associatedId) {
      this.viewModel.generateGuideAuthors(this.associatedId);
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  authorSelected(a: GuideAuthor) {
      this.selectedAuthor.emit(a);
  }

  close() {
    this.activeModal.close();
  }

  isCurrentAuthor(a: GuideAuthor): boolean {
    if (!!this.currentAuthor) {
      if (a.companyId === this.currentAuthor?.companyId && a.krugoId === this.currentAuthor?.krugoId) {
        return true;
      } else {
        return a.krugoId === this.currentAuthor?.krugoId && this.currentAuthor?.companyId === '' && a.companyId === null;
      }
    } else {
      return false;
    }

  }


}
