<div class="modal-body">
  <app-loading
    [hidden]="!vm?.loadingOpts?.isLoading"
    [options]="vm?.loadingOpts">
  </app-loading>
  <div class="modal-title mt-16px">Add Contributor</div>
  <div class="modal-description">
    Only existing Krugo users can be added as contributors. If the person you want to invite doesn’t already have a
    Krugo account, they can create one through the mobile app or the partners web app.
  </div>
  <app-form-group
    #addContributorForm
    (formSubmitted)="formSubmitted($event)"
    (secondaryButtonPressed)="cancel()"
    [formItems]="vm.formItems"
    [options]="vm.formOptions"
    [formObject]="vm.formReq"
    [styling]="vm.formStyling">
  </app-form-group>
  <hr class="mb-32px">
  <div class="modal-footer-flex-end">
    <button matRipple
            (click)="cancel()"
            class="custom-button grey-button mr-3">
      Cancel
    </button>
    <button matRipple
            (click)="addContributorClicked()"
            class="custom-button preferred-button">
      Send Invitation
    </button>
  </div>
</div>
