import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {Asset} from '../../image/dto/asset';
import {CodeDeliveryDetails} from './code-delivery-details';
import {Session} from './session';
import {User} from './user';
import {UserIdentity} from './user-identity';
import {Credentials} from './credentials';
import {Device} from './devices/device';

export class HydratedUser extends User implements Deserializable {
  public profilePicture: Asset;
  public identities: UserIdentity[];
  public codeDeliveryDetails: CodeDeliveryDetails[];
  public session: Session;
  public credentials: Credentials;
  public devices: Device[];

  public onDeserialize() {
    super.onDeserialize();
    this.profilePicture = DeserializeHelper.deserializeToInstance(Asset, this.profilePicture);
    this.codeDeliveryDetails = DeserializeHelper.deserializeArray(CodeDeliveryDetails, this.codeDeliveryDetails);
    this.session = DeserializeHelper.deserializeToInstance(Session, this.session);
  }

  public user(): User {
    return DeserializeHelper.deserializeToInstance(User, this);
  }

  public profilePictureExists(): boolean {
    return this?.hasProfilePicture && !this?.profilePicture.isEmpty();
  }
}
