<div class="modal-body">
  <div class="modal-title mt-16px">Reject Guide</div>
  <app-form-group
    #form
    class="form-group"
    (formSubmitted)="formSubmitted($event)"
    (secondaryButtonPressed)="cancel()"
    [formItems]="rejectFormInput"
    [options]="formOptions"
    [formObject]="rejection"
    [styling]="formStyling">
  </app-form-group>
  <hr class="mb-32px">
  <div class="modal-footer-flex-end">
    <button matRipple
            (click)="cancel()"
            class="custom-button grey-button mr-3">
      Cancel
    </button>
    <button matRipple
            [matRippleDisabled]="!form.canSubmitForm()"
            [disabled]="!form.canSubmitForm()"
            [ngClass]="{ 'disabled': !form.canSubmitForm() }"
            (click)="save()"
            class="custom-button preferred-button">
      Reject
    </button>
  </div>
</div>
