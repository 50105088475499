import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Injectable, OnDestroy} from '@angular/core';
import {BaseModalViewModel} from '../../../../../models/base/base-modal-view-model';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {Insider} from '../../../../../models/guide/dto/insider';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {CompanyInsiderReq} from '../../../../../models/guide/requests/company-insider-req';
import {ContributorsDomainModel} from '../../../../../domain-models/contributors-domain-model';
import {ToastService} from '../../../../../services/toast-service';

@Injectable()
export class AddContributorViewModel extends BaseModalViewModel implements OnDestroy {

  public loadingOpts: LoadingOptions;
  public insiderCompany: Insider;
  public formItems: FormInputItem[] = [];
  public formStyling: FormGroupStyling = new FormGroupStyling();
  public formOptions: FormOptions = new FormOptions();
  public formReq: CompanyInsiderReq = new CompanyInsiderReq();

  constructor(
    public router: Router,
    public modalService: NgbModal,
    private toastService: ToastService,
    private dm: ContributorsDomainModel,
  ) {
    super(router, modalService);
    this.init();
  }

  init(): void {
    this.setupLoadingSpinner();
    this.setupFormItems();
  }

  private setupLoadingSpinner() {
    this.loadingOpts = LoadingOptions.default();
    this.loadingOpts.loadingText = 'Adding Contributor';
    this.loadingOpts.isLoading = false;
  }

  setupFormItems() {
    this.formReq.phoneNumber = '';
    this.formItems = [];
    // Set up form items
    const phoneNumInput = new FormInputItem();
    phoneNumInput.itemType = FormItemType.Input;
    phoneNumInput.inputName = 'phoneNumber';
    phoneNumInput.label = 'Phone Number';
    phoneNumInput.hideLabel = true;
    phoneNumInput.placeholder = 'Phone Number';
    phoneNumInput.bindingProperty = 'phoneNumber';
    phoneNumInput.inputType = FormInputType.Tel;
    phoneNumInput.required = true;
    phoneNumInput.inputMask = ['1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.formItems.push(phoneNumInput);
  }

  addContributor() {
    const addCompanyInsiderReq = new CompanyInsiderReq();
    addCompanyInsiderReq.phoneNumber = `+${this.formReq.phoneNumber}`;
    addCompanyInsiderReq.companyId = this.insiderCompany.id;
    addCompanyInsiderReq.isAdmin = false;
    this.loadingOpts.isLoading = true;
    this.dm.addCompanyInsider(addCompanyInsiderReq).subscribe(result => {
      this.toastService.publishSuccessMessage('Invitation Sent', null);
      this.loadingOpts.isLoading = false;
      this.dismissModalSubject.next(true);
    }, error => {
      this.toastService.publishError(error);
      this.loadingOpts.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

}
