import {HttpBackend, HttpClient} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Deserializable, DeserializeHelper} from '../models/protocols/deserializable';
import 'rxjs-compat/add/operator/map';
import {APIRequestType} from '../models/enum/shared/api-request-type.enum';
import {StringifyUtils} from '../utils/stringify-utils';
import {ApiPagination} from '../models/shared/api-pagination';
import {Pagable} from '../models/protocols/pagable';
import {expand, reduce} from 'rxjs/operators';

export const DEFAULT_PAGINATION_COUNT = 25;

@Injectable({
  providedIn: 'root'
})
export class ApiClient {

  private backendHttp: HttpClient;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
  ) {
    this.backendHttp = new HttpClient(handler);
  }

  public getObj<T extends Deserializable>(respObjectType: new () => T, url: string,
                                          additionalHeaders: any = null): Observable<T> {
    return this.http.get<T>(url, {headers: additionalHeaders}).map(r => {
      return DeserializeHelper.deserializeToInstance(respObjectType, r);
    });
  }

  public getArr<T extends Deserializable>(respObjectType: new () => T, url: string,
                                          additionalHeaders: any = null): Observable<T[]> {
    return this.http.get<T[]>(url, {headers: additionalHeaders}).map(r => {
      return r.map(rr => DeserializeHelper.deserializeToInstance(respObjectType, rr)) as T[];
    });
  }

  public getBlob<Blob>(url: string): Observable<Blob> {
    return this.backendHttp.get<Blob>(url, {
      responseType: 'blob' as 'json'
    });
  }

  public postObj<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                           responseType: string = 'json'): Observable<T> {
    return this.http.post<T>(url, JSON.stringify(payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).map(r => {
      return DeserializeHelper.deserializeToInstance(respObjectType, r);
    });
  }

  public postArr<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                           responseType: string = 'json'): Observable<T[]> {
    return this.http.post<T[]>(url, JSON.stringify(payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).map(r => {
      return r.map(rr => DeserializeHelper.deserializeToInstance(respObjectType, rr)) as T[];
    });
  }

  public postMapArr<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                              responseType: string = 'json'): Observable<Map<string, T[]>> {
    return this.http.post<Map<string, T[]>>(url, JSON.stringify(payload, StringifyUtils.replacer), {
      headers: additionalHeaders,
      responseType: responseType as 'json'
    }).map(r => {
      return DeserializeHelper.deserializeTypedArrayMap(respObjectType, r);
    });
  }

  public deleteStr(url, payload, additionalHeaders: any = null, responseType: string = 'text'): Observable<string> {
    return this.http.request<string>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    });
  }

  public deleteObj<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                             responseType: string = 'json'): Observable<T> {
    return this.http.request<T>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    }).map(r => {
      return DeserializeHelper.deserializeToInstance(respObjectType, r);
    });
  }

  public deleteArr<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                             responseType: string = 'json'): Observable<T[]> {
    return this.http.request<T[]>(APIRequestType.DELETE, url, {
      headers: additionalHeaders,
      body: JSON.stringify(payload, StringifyUtils.replacer),
      responseType: responseType as 'json'
    }).map(r => {
      return r.map(rr => DeserializeHelper.deserializeToInstance(respObjectType, rr)) as T[];
    });
  }

  public recursiveGet<T extends Pagable>(
    respObjectType: new () => T,
    url: string,
    additionalHeaders: any = null,
    pagination: ApiPagination
  ): Observable<T[]> {
    let paginationUrl: string;
    const params = url.includes('?');
    if (pagination) {
      const c = pagination.count || DEFAULT_PAGINATION_COUNT;
      const sk = pagination.startKey || '';
      paginationUrl = `${url}${params ? '&' : '?'}Count=${c}&StartKey=${sk}`;
    } else {
      pagination = new ApiPagination(DEFAULT_PAGINATION_COUNT);
      paginationUrl = `${url}${params ? '&' : '?'}Count=${DEFAULT_PAGINATION_COUNT}`;
    }
    return this.getArr<T>(respObjectType, paginationUrl || url, additionalHeaders).pipe(
      expand((res) => {
        if (res && res.length === pagination.count) {
          pagination.startKey = (res.last() as Pagable).getStartKey();
          const parameters = url.includes('?');
          paginationUrl = `${url}${parameters ? '&' : '?'}Count=${pagination.count}&StartKey=${pagination.startKey}`;
          return this.getArr<T>(respObjectType, paginationUrl || url, additionalHeaders);
        } else {
          return EMPTY;
        }
      }),
      reduce((acc, val) => {
        acc.push(...val);
        return acc;
      })
    );
  }

}

