<div>
  <div class="main-title">
    {{vm.title}}
  </div>
  <div class="divider"></div>
  <div class="container mb-5">
    <div class="row mx-0">
      <div class="col-10 mx-auto">
        <app-loading
          [hidden]="!vm?.loadingOpts?.isLoading"
          [options]="vm?.loadingOpts">
        </app-loading>
        <div class="guide-features-section">
          <div class="guide-features-section-title">
            Guide Categories
            <span *ngIf="(vm.guideFeatures$ | async)?.length > 1" class="rearrange-button"><button (click)="vm.openRearrangeFeaturesModal()" class="custom-button">Reorder Categories</button></span>
          </div>
          <div class="divider"></div>
          <!-- Guide Feature Placeholder -->
          <div class="empty-state-container"
               [hidden]="(vm.guideFeatures$ | async)?.length > 0"
               [style.height]="'14rem'"
               fxLayout="column"
               fxLayoutAlign="center center">
            <div class="empty-state-inside-container"
                 fxLayout="column"
                 fxLayoutAlign="center start">
              <div class="title">
                No Categories Created
              </div>
              <div class="desc">
                Featured Categories can be created to group Guides together into separate sections on your website (e.g. Casual Dining, Shopping,  etc.).
              </div>
            </div>
          </div>
          <!-- List of Guide Features -->
          <div class="row mx-0" *ngFor="let gf of vm.guideFeatures$ | async">
            <div class="col guide-feature-item">
              <div class="guide-feature-item-content">
                <div class="guide-feature-item-title">
                  {{ gf.title }}
                </div>
                <div class="guide-feature-item-subtitle">
                  {{ gf.description }}
                </div>
                <!-- Guide Feature Tags-->
                <div *ngIf="gf?.tags?.length > 0"
                     class="tag-container flex-grow-1"
                     fxLayout="row wrap">
                  <app-tag *ngFor="let tag of gf?.tags"
                           class="tag"
                           [tag]="tag">
                  </app-tag>
                </div>

              </div>
              <div class="guide-feature-item-action"
                   matRipple
                   popper
                   [popper]="popperContent"
                   [popperShowOnStart]="false"
                   [popperTrigger]="'click'"
                   [popperModifiers]="popperModifier"
                   [popperHideOnClickOutside]="true"
                   [popperHideOnScroll]="true"
                   [popperPreventOverflow]="false"
                   [popperApplyArrowClass]="'d-none'"
                   [popperApplyClass]="'custom-popper-content'"
                   [popperStyles]="popperStyles"
                   [popperPlacement]="'bottom-end'">
                <img [src]="'assets/icons/dark/solid/dots-horizontal.svg'" alt="options">
              </div>
              <popper-content #popperContent [style.z-index]="1">
                <div role="menuitem">
                  <button matRipple
                          class="custom-dropdown-menu-item"
                          (click)="openEditGuideFeatureModal(gf); popperContent.hide();">Edit
                  </button>
                </div>
                <div role="menuitem">
                  <button matRipple
                          class="custom-dropdown-menu-item red-text"
                          (click)="openDeleteGuideFeatureModal(gf); popperContent.hide();">Delete
                  </button>
                </div>
              </popper-content>
            </div>
            <div class="divider"></div>
          </div>
        </div>

        <div class="mt-4">
          <button matRipple
                  class="custom-button"
                  (click)="openEditGuideFeatureModal(null)">
            <img [src]="'assets/icons/dark/plus.svg'" alt="">
            Add New Guide Feature
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
