import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';

export class SessionChallenge implements Deserializable {
  public challengeName: string;
  public challengeParameters: Map<string, string>;
  public authSession: string;

  public onDeserialize() {
    if (!(this.challengeParameters instanceof Map)) {
      this.challengeParameters = DeserializeHelper.deserializeGenericMap(this.challengeParameters);
    }
  }
}
