import {Deserializable} from '../protocols/deserializable';
import {Cachable} from '../protocols/cachable';
import {CachePolicy} from '../enum/shared/cachable-image-policy.enum';
import {DateUtils} from '../../utils/date-utils';

export class City implements Deserializable, Cachable {
  public state: string;
  public id: string;
  public city: string;
  public countryCode: string;
  public lat: number;
  public lng: number;
  public timezone: string;
  public searchCount: number;
  public lastUpdated: number;

  cachedTime: number;

  static buildCacheKey(): string {
    return 'Cities';
  }

  onDeserialize() {
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneMonth();
  }

  cacheKey(...params: any): string {
    return '';
  }

  imageCachePolicy(): CachePolicy {
    return undefined;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

}
