<div class="auth-flow-wrapper">
  <app-loading
    [hidden]="!loadingOpts.isLoading"
    [options]="loadingOpts">
  </app-loading>
  <div class="auth-flow-container">
    <img [src]="'assets/logo/krugo-bullhorn.svg'">
    <div class="auth-flow-title mt-5">
      {{ viewModel.authFlowTitle$|async }}
    </div>
    <div class="auth-flow-subtext my-4">
      {{ viewModel.authFlowSubText$|async }}

    </div>
    <app-form-group
      (formSubmitted)="formSubmitted($event)"
      (secondaryButtonPressed)="resendCode()"
      [formItems]="confirmCodeFormItems"
      [options]="formOptions"
      [formObject]="req"
      [styling]="formStyling">
    </app-form-group>
  </div>
</div>
