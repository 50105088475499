import {GuideSection} from './guide-section';
import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {GuideStatus} from '../enum/guide-status.enum';
import {SortUtils} from '../../../utils/sort-utils';
import {DateUtils} from '../../../utils/date-utils';
import {CachePolicy} from '../../enum/shared/cachable-image-policy.enum';
import {Cachable} from '../../protocols/cachable';
import {Pagable} from '../../protocols/pagable';

export class Guide implements Cachable, Deserializable, Pagable {

  public cityId: string;
  public companyId: string;
  public contentDescription: Map<string, string>;
  public description: string = '';
  public favoriteCount: number;
  public id: string;
  public imageMap: Map<string, string[]>;
  public insiderId: string;
  public lastUpdated: number;
  public performerId: string;
  public placeIds: string[];
  public sections: GuideSection[];
  public shortDescription: string;
  public showCompanyInsider: boolean;
  public isPrivate: boolean;
  public status: GuideStatus;
  public statusNote: string;
  public stayIds: string[];
  public tags: string[];
  public title: string = '';
  public viewCount: number;
  public imageOrderMap: Map<string, Map<string, number>>;
  // Cache
  cachedTime: number;

  constructor() {
  }

  static buildArrayCacheKey(insiderId: string): string {
    return `Guides-${insiderId}`;
  }

  static buildCacheKey(id: string): string {
    return `Guide-${id}`;
  }

  onDeserialize() {
    if (!(this.contentDescription instanceof Map)) {
      this.contentDescription = DeserializeHelper.deserializeGenericMap<string, string>(this.contentDescription);
    }
    if (!(this.imageMap instanceof Map)) {
      this.imageMap = DeserializeHelper.deserializeGenericArrayMap<string, string>(this.imageMap);
    }
    if (!!this.imageOrderMap && !(this.imageOrderMap instanceof Map)) {
      this.imageOrderMap = DeserializeHelper.deserializeNestedGenericMap<string, string, number>(this.imageOrderMap);
    } else {
      this.imageOrderMap = new Map<string, Map<string, number>>();
    }
    if (this.placeIds && this.placeIds.length > 0) {
      this.placeIds = Array.from(this.placeIds || []);
    }
    if (this.sections && this.sections.length > 0) {
      this.sections = DeserializeHelper.deserializeArray(GuideSection, this.sections);
      this.sections.sort(SortUtils.sortGuideSections);
    }
    if (this.stayIds && this.stayIds.length > 0) {
      this.stayIds = Array.from(this?.stayIds || []);
    }
    if (this.tags && this.tags.length > 0) {
      this.tags = Array.from(this?.tags || []);
    }
  }

  // Caching

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(): string {
    return Guide.buildCacheKey(this.id);
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Service;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  findPlanItemIndex(id: string): number {
    let offset = 0;
    const sectionIndex = this.sections.findIndex(it => it.sortedIds.contains(id));
    if (sectionIndex > -1) {
      for (let i = 0; i < sectionIndex; i++) {
        offset += this.sections[i].sortedIds.length;
      }
      const indexInSection = this.sections[sectionIndex].sortedIds.findIndex(it => it === id);
      return offset + indexInSection;
    } else {
      return -1;
    }
  }

  getStartKey(): string {
    return this.id;
  }

}
