<div class="modal-body">
  <app-loading
    [hidden]="!vm?.loadingOpts?.isLoading"
    [options]="vm?.loadingOpts">
  </app-loading>
  <div class="modal-title mt-16px">Delete {{guide.title}}?</div>
  <div class="modal-description">
    If you delete this guide it will be gone forever. Are you sure you want to delete it?
  </div>
  <hr class="mb-32px">
  <div class="modal-footer-flex-end">
    <button matRipple (click)="cancel()" class="custom-button grey-button">Cancel</button>
    <button matRipple (click)="vm.deleteGuide(guide)"
            class="custom-button destructive-button">Delete</button>
  </div>
</div>
