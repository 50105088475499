import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {BaseDirective} from '../../../../models/base/base-directive';
import {FontType} from '../../../../models/enum/shared/font-type.enum';
import {debounceTime, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {Font} from '../../../../models/enum/shared/font.enum';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {FontUtils} from '../../../../utils/font-utils';

@Directive({
  selector: '[appSelectFont]'
})
export class SelectFontDirective extends BaseDirective
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() font: Font = Font.WorkSans;
  @Input() type: FontType = FontType.Regular;

  private fontSubject = new BehaviorSubject<Font>(Font.WorkSans);
  private fontTypeSubject = new BehaviorSubject<FontType>(FontType.Regular);

  public fontClass = combineLatest([
    this.fontSubject.pipe(distinctUntilChanged()),
    this.fontTypeSubject.pipe(distinctUntilChanged()),
  ]).pipe(
    debounceTime(1),
    map(([font, type]) => FontUtils.getFontClass(type, font)),
    distinctUntilChanged()
  );

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setFont(this.font);
    this.setType(this.type);
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.fontClass
      .pipe(takeUntil(this.onDestroy))
      .subscribe(fontClass => {
        if (fontClass) {
          this.renderer.addClass(this.el.nativeElement, fontClass);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.font) {
      this.setFont(this.font);
    }
    if (changes.type) {
      this.setType(this.type);
    }
  }

  setFont(f: Font) {
    this.fontSubject.next(f);
  }

  setType(t: FontType) {
    this.fontTypeSubject.next(t);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
