import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {Asset} from '../../image/dto/asset';
import {Room} from './room';
import {Coordinates} from '../../shared/coordinates';
import {GuideChildObject} from '../../protocols/guide-child-object';
import {MediaType} from '../../enum/dto/media-type.enum';
import {AssetSize} from '../../enum/dto/image-size.enum';

export class Accommodation implements Deserializable, GuideChildObject {
  public address: string;
  public bookingId: string;
  public checkInFrom: string;
  public checkInTo: string;
  public checkOutFrom: string;
  public checkOutTo: string;
  public city: string;
  public coordinates: Coordinates;
  public country: string;
  public description: string;
  public email: string;
  public facilities: string[];
  public formattedAddress: string;
  public hotelCurrency: string;
  public hotelTypeId: number;
  public importantInformation: string;
  public name: string;
  public numberOfReviews: number;
  public numberOfRooms: number;
  public paidFacilities: string[];
  public payAtProperty: string;
  public payNow: string;
  public photos: Asset[];
  public postalCode: string;
  public ranking: number;
  public reviewScore: number;
  public rooms: Room[];
  public exactStars: number;
  public stars: number;
  public url: string;
  // Inherited
  public images: Asset[] = [];
  public customDescription: string;

  onDeserialize() {
    this.coordinates = DeserializeHelper.deserializeToInstance(Coordinates, this.coordinates);
    this.facilities = Array.from(this.facilities || []);
    this.paidFacilities = Array.from(this.paidFacilities || []);
    (this.photos ?? []).forEach(p => {
      if (!p.fileName) {
        // default values for Booking.com assets
        p.sizeOverrides = [AssetSize.Medium, AssetSize.Large];
        p.mediaType = MediaType.JPEG;
      }
    });
    this.photos = DeserializeHelper.deserializeArray(Asset, this.photos);
    this.rooms = DeserializeHelper.deserializeArray(Room, this.rooms);
  }

  setAssets(imageMap: Map<string, string[]>, assets: Asset[]) {
    if (!this.images) {
      this.images = [];
    }
    const imageHashes = imageMap?.get(this.bookingId);
    if (imageHashes) {
      imageHashes.forEach((hash) => {
        const asset = assets.find(a => a.md5Hash === hash);
        if (asset) {
          this.images.push(asset);
        }
      });
    }
  }

  setCustomDescription(descMap: Map<string, string>) {
    const customDesc = descMap[this.bookingId];
    if (customDesc) {
      this.customDescription = customDesc;
    }
  }
}
