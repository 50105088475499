import { Pipe, PipeTransform } from '@angular/core';
import {GuideClickType} from '../../../models/guide/enum/guide-click-type.enum';

@Pipe({
  name: 'clickTypeFormatter'
})
export class ClickTypeFormatterPipe implements PipeTransform {

  transform(value: GuideClickType): string {
    if (value) {
      return value.trim().replace('GuideClickType_', '') + ' Views';
    }
    return '';
  }

}
