import {Pipe, PipeTransform} from '@angular/core';
import {InsiderInsight} from '../../../models/guide/dto/insider-insight';

@Pipe({
  name: 'totalClickMapNotEmpty'
})
export class TotalClickMapNotEmptyPipe implements PipeTransform {

  transform(insiderInsight: InsiderInsight): boolean {
    if (insiderInsight?.totalClickMap?.size > 0) {
      return !(insiderInsight.totalClickMap.size === 1 && !!insiderInsight.totalClickMap.get(insiderInsight.insiderId));
    }
    return false;
  }

}
