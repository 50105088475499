import {ApiClient} from './api-client';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {LoggingService} from '../services/logging-service';
import {catchError} from 'rxjs/operators';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {MediaUtils} from '../utils/media-utils';
import {FullImage} from '../models/image/shared/full-image';
import {CustomError} from '../models/shared/custom-error';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {GenerateUploadUrlRequest} from '../models/image/requests/generate-upload-url-request';
import {SignedUploadUrl} from '../models/shared/signed-upload-url';
import * as buffer from 'buffer';

@Injectable({
  providedIn: 'root'
})

export class ImageAPI implements LoggableAPI {

  private http: HttpClient;

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
    private handler: HttpBackend,
  ) {
    this.http = new HttpClient(handler);
  }

  // Variables

  public serviceName = 'Image';

  // Image

  public DeleteGuideImage(guideId, id, md5: string): Observable<string> {
    const url = Endpoints.DeleteGuideImage(guideId, id, md5);
    return this.apiClient.deleteStr(url, null, null, 'text').pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteAsset', err));
        return throwError(err);
      })
    );
  }

  public GetGuideImageUploadUrl(req: GenerateUploadUrlRequest): Observable<SignedUploadUrl> {
    const url = Endpoints.GetGuideImageUploadUrl(req.fileName, req.guideId, req.placeId);
    return this.apiClient.getObj<SignedUploadUrl>(SignedUploadUrl, url).pipe(
      catchError(e => {
        console.log(e);
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetGuideImageUrl', err));
        return throwError(err);
      })
    );
  }

  public GetInsiderImageUploadUrl(req: GenerateUploadUrlRequest): Observable<SignedUploadUrl> {
    const url = Endpoints.GetInsiderImageUploadUrl(req.fileName, req.insiderId);
    return this.GetImageUploadUrl(url, req);
  }

  private GetImageUploadUrl(url: string, req: GenerateUploadUrlRequest): Observable<SignedUploadUrl> {
    return this.apiClient.getObj<SignedUploadUrl>(SignedUploadUrl, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GenerateUploadUrl', err));
        return throwError(err);
      })
    );
  }

  public PutImageUploadUrl(url: string, file: string, fileName: string): Observable<any> {
    const adjustedfileName = fileName.replace(' ', '').toLowerCase();
    const type = MediaUtils.getMediaType(adjustedfileName.split('.').pop());
    let newFileContents = file.replace(/^data:image\/\w+;base64,/, '');
    newFileContents = newFileContents.replace(/^data:video\/\w+;base64,/, '');

    const buff = buffer.Buffer.from(newFileContents, 'base64');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', type);
    headers = headers.append('Content-Encoding', 'base64');

    const blob = new Blob([new Uint8Array(buff)]);
    return this.http.put<any>(url, blob, {headers}).pipe(
      catchError(e => {
        console.log(e);
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'PutImageUploadUrl', err));
        return throwError(err);
      })
    );
  }

  public GetAsset(id, md5Hash: string): Observable<FullImage> {
    const url = Endpoints.GetAsset(id, md5Hash);
    return this.apiClient.getObj<FullImage>(FullImage, url).pipe(
      catchError(e => {
        console.log(e);
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetAsset', err));
        return throwError(err);
      })
    );
  }

  public GetBlobFromUrl(url: string): Observable<Blob> {
    return this.apiClient.getBlob<Blob>(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetBlobFromUrl', err));
        return throwError(err);
      })
    );
  }

}
