import {CustomError} from './custom-error';

export class ApiErrorLog {
  public service: string;
  public method: string;
  public errorCode: number;
  public errorMessage: string;

  constructor(service, method: string, error: CustomError) {
    this.service = service;
    this.method = method;
    this.errorCode = error.code;
    this.errorMessage = error.message;
  }

  public logParams(): { [key: string]: any } {
    const params = new Map<string, any>();
    params['service'] = this.service;
    params['method'] = this.method;
    params['errorCode'] = this.errorCode;
    params['errorMessage'] = this.errorMessage;
    return params;
  }
}
