import {Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../../../models/base/base-view-model';

@Injectable()
export class PendingViewModel extends BaseViewModel implements OnDestroy {

  constructor(
  ) {
    super();
  }

  ngOnDestroy() {
    this.destroy();
  }

}
