import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TabBarComponent} from './components/tab-bar/tab-bar.component';
import {MaterialModule} from './material/material.module';
import {TestTabComponent} from './components/tab-bar/test-tab/test-tab.component';
import {TabContentComponent} from './components/tab-bar/tab-content/tab-content.component';
import {TabContentContainerDirective} from './components/tab-bar/tab-content/tab-content-container.directive';
import {LoadingComponent} from './components/loading/loading.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ColorPickerModule} from 'ngx-color-picker';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {PillComponent} from './components/pills/pill/pill.component';
import {PillContainerComponent} from './components/pills/pill-container/pill-container.component';
import {DropDownComponent} from './components/drop-down/drop-down.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormGroupComponent} from './components/form-group/form-group.component';
import {LetterOnlyValidatorDirective} from './components/form-group/validators/letter-only-validator.directive';
import {PasswordValidatorDirective} from './components/form-group/validators/password-validator.directive';
import {CheckboxContainerComponent} from './components/checkboxes/checkbox-container/checkbox-container.component';
import {CheckboxComponent} from './components/checkboxes/checkbox/checkbox.component';
import {DropDownMenuComponent} from './components/drop-down-menu/drop-down-menu.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PhoneValidatorDirective} from './components/form-group/validators/phone-validator.directive';
import {AbandonFormComponent} from './modals/abandon-form/abandon-form.component';
import {LocationPickerComponent} from './modals/location-picker/location-picker.component';
import {CardDeckComponent} from './components/card-deck/card-deck.component';
import {CardComponent} from './components/card-deck/card/card.component';
import {ConfirmationCodeValidatorDirective} from './components/form-group/validators/confirmation-code-validator.directive';
import {UploadItemComponent} from './components/upload-asset/upload-item/upload-item.component';
import {UploadAssetComponent} from './components/upload-asset/upload-asset.component';
import {DndDirective} from './directives/dnd-directive';
import {HeaderComponent} from './components/header/header.component';
import {DataTableComponent} from './components/data-table/data-table.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CardTableComponent} from './components/card-table/card-table.component';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {LabelGroupComponent} from './components/label-group/label-group.component';
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component';
import {ReorderModalComponent} from './components/reorder-modal/reorder-modal.component';
import {ReorderListComponent} from './components/reorder-list/reorder-list.component';
import {ActionListComponent} from './components/action-list/action-list.component';
import {ActionComponent} from './components/action-list/action/action.component';
import {RangeSliderComponent} from './components/range-slider/range-slider.component';
import {ActiveComponent} from './components/active/active.component';
import {SingleSelectionListComponent} from './components/single-selection-list/single-selection-list.component';
import {AssetPreviewComponent} from './components/asset-preview/asset-preview.component';
import {SegmentedControlComponent} from './components/segmented-control/segmented-control.component';
import {LogoUploadComponent} from './components/logo-upload/logo-upload.component';
import {ThemeCarouselImageComponent} from './components/card-deck/theme-carousel-image/theme-carousel-image.component';
import {AssetComponent} from './components/asset/asset.component';
import {TextMaskModule} from 'angular2-text-mask';
import {BaseCreateGuideComponent} from './modals/create-guide/components/base-create-guide/base-create-guide.component';
import {ChooseAuthorComponent} from './modals/create-guide/components/choose-author/choose-author.component';
import {ChooseCityComponent} from './modals/create-guide/components/choose-city/choose-city.component';
import {AddGuideInfoComponent} from './modals/create-guide/components/add-guide-info/add-guide-info.component';
import {UploadFileModalComponent} from './modals/upload-file-modal/upload-file-modal.component';
import {MapComponent} from './components/map/map.component';
import {IconGeneratorComponent} from './components/map/components/icon-generator/icon-generator.component';
import {AgmCoreModule} from '@agm/core';
import {SearchBarComponent} from './components/search-bar/search-bar.component';
import {SearchBarAddComponent} from './components/search-bar-add/search-bar-add.component';
import {NgStringPipesModule} from 'ngx-pipes';
import {SocialLinksModalComponent} from './components/insider-settings/social-links-modal/social-links-modal.component';
import {EditProfilePhotoModalComponent} from './modals/edit-profile-photo-modal/edit-profile-photo-modal.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {DragScrollDirective} from './directives/drag-scroll.directive';
import {InsiderSettingsComponent} from './components/insider-settings/insider-settings.component';
import {FontDirective} from './directives/font-directive/font.directive';
import {SelectFontDirective} from './directives/select-font/select-font.directive';
import {NgxShimmerLoadingModule} from 'ngx-shimmer-loading';
import {CopyEmbedCodeComponent} from '../company/components/settings/copy-embed-code/copy-embed-code.component';
import {TagComponent} from '../guides/components/guide-tags/tags/tag/tag.component';


@NgModule({
  providers: [],
  declarations: [
    AssetComponent,
    TabBarComponent,
    TestTabComponent,
    TabContentComponent,
    TabContentContainerDirective,
    DropDownComponent,
    PillComponent,
    PillContainerComponent,
    LoadingComponent,
    ProgressBarComponent,
    FormGroupComponent,
    LetterOnlyValidatorDirective,
    PasswordValidatorDirective,
    ConfirmationCodeValidatorDirective,
    PhoneValidatorDirective,
    CheckboxContainerComponent,
    CheckboxComponent,
    DropDownMenuComponent,
    AbandonFormComponent,
    LocationPickerComponent,
    CardDeckComponent,
    CardComponent,
    DataTableComponent,
    UploadItemComponent,
    UploadAssetComponent,
    DndDirective,
    HeaderComponent,
    CardTableComponent,
    BreadcrumbsComponent,
    LabelGroupComponent,
    ConfirmationModalComponent,
    ReorderModalComponent,
    ReorderListComponent,
    ActionListComponent,
    ActionComponent,
    ReorderListComponent,
    RangeSliderComponent,
    ActiveComponent,
    SingleSelectionListComponent,
    AssetPreviewComponent,
    SegmentedControlComponent,
    SearchBarComponent,
    SearchBarAddComponent,
    LogoUploadComponent,
    ThemeCarouselImageComponent,
    BaseCreateGuideComponent,
    ChooseAuthorComponent,
    ChooseCityComponent,
    AddGuideInfoComponent,
    UploadFileModalComponent,
    MapComponent,
    IconGeneratorComponent,
    EditProfilePhotoModalComponent,
    DragScrollDirective,
    InsiderSettingsComponent,
    SocialLinksModalComponent,
    EditProfilePhotoModalComponent,
    FontDirective,
    SelectFontDirective,
    CopyEmbedCodeComponent,
    TagComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxSpinnerModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    NgbTooltipModule,
    TextMaskModule,
    AgmCoreModule,
    NgStringPipesModule,
    ImageCropperModule,
    NgxShimmerLoadingModule,
  ],
  exports: [
    // Shared Components
    AssetComponent,
    TabBarComponent,
    MaterialModule,
    TestTabComponent,
    LoadingComponent,
    NgxSpinnerModule,
    DropDownComponent,
    PillComponent,
    PillContainerComponent,
    ColorPickerModule,
    ProgressBarComponent,
    FormsModule,
    ReactiveFormsModule,
    FormGroupComponent,
    LetterOnlyValidatorDirective,
    CheckboxComponent,
    CheckboxContainerComponent,
    BsDropdownModule,
    DropDownMenuComponent,
    AbandonFormComponent,
    CardDeckComponent,
    CardComponent,
    DataTableComponent,
    UploadItemComponent,
    UploadAssetComponent,
    HeaderComponent,
    CardTableComponent,
    BreadcrumbsComponent,
    LabelGroupComponent,
    ConfirmationModalComponent,
    ReorderModalComponent,
    RangeSliderComponent,
    ActionListComponent,
    ActiveComponent,
    SingleSelectionListComponent,
    AssetPreviewComponent,
    SegmentedControlComponent,
    LogoUploadComponent,
    BaseCreateGuideComponent,
    MapComponent,
    SearchBarComponent,
    PasswordValidatorDirective,
    SearchBarAddComponent,
    AddGuideInfoComponent,
    UploadFileModalComponent,
    ReorderListComponent,
    InsiderSettingsComponent,
    SocialLinksModalComponent,
    EditProfilePhotoModalComponent,
    DragScrollDirective,
    FontDirective,
    SelectFontDirective,
    CopyEmbedCodeComponent,
    TagComponent
  ],
})
export class SharedModule {
}
