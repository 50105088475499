<!--For use as the create/edit guide uploader-->
<ng-container *ngIf="!modalStyle">
  <li class="custom-upload-preview"
      [ngClass]="{
        'success': file?.success,
        'error': file?.success
    }">
    <div class="remove-button-container" (click)="removeMe()">
      <img class="remove-button-icon" [src]="'assets/icons/dark/x.svg'">
    </div>
    <img *ngIf="file?.isImage()" class="custom-upload-preview-image"
         src="{{file?.url}}"
         alt="/assets/placeholder/list-image-dark.jpg">
  </li>
</ng-container>

<!--For use as the uploader in the modal-->
<ng-container *ngIf="modalStyle">
  <li class="modal-custom-upload-preview"
      [ngClass]="{
        'success': file?.success,
        'error': file?.success
    }">
    <img *ngIf="file?.isImage()"
         class="modal-custom-upload-preview-image"
         src="{{file?.url}}"
         alt="/assets/placeholder/list-image-dark.jpg">

    <video *ngIf="file?.isVideo()"
           class="modal-custom-upload-preview-image"
           [controls]="false"
           [src]="file?.url">
    </video>

    <div class="custom-upload-preview-text-container">
    <span class="modal-custom-upload-preview-text-name">
      {{file?.name}}
    </span>
    </div>
    <div class="custom-upload-button-group">
      <button *ngIf="file?.success"
              class="custom-upload-button">
        <img class="custom-upload-button-icon"
             src="/assets/icons/dark/x.svg"
             alt="x"
             (click)="removeMe()">
      </button>
    </div>
  </li>
</ng-container>

