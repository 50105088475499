import * as moment from 'moment';
import {DateUtils} from '../../../../../../utils/date-utils';

export class KrugoDateRange {

  public chosenLabel: string;
  public startDate: moment.Moment;
  public endDate: moment.Moment;

  static today(): KrugoDateRange {
    return new KrugoDateRange('Today', moment().startOf('day'), moment());
  }

  static yesterday(): KrugoDateRange {
    return new KrugoDateRange('Yesterday', moment().subtract(1, 'days'), moment().subtract(1, 'days'));
  }

  static last7Days(): KrugoDateRange {
    return new KrugoDateRange('Last 7 Days', moment().subtract(6, 'days'), moment());
  }

  static last30Days(): KrugoDateRange {
    return new KrugoDateRange('Last 30 Days', moment().subtract(29, 'days'), moment());
  }

  static thisMonth(): KrugoDateRange {
    return new KrugoDateRange('This Month', moment().startOf('month'), moment().endOf('month'));
  }

  static lastMonth(): KrugoDateRange {
    return new KrugoDateRange(
      'Last Month',
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month')
    );
  }

  constructor(chosenLabel: string, start: moment.Moment, end: moment.Moment) {
    this.chosenLabel = chosenLabel;
    this.startDate = start;
    this.endDate = end;
  }

  getDateRangeString(): string {
    const ranges = DateUtils.defaultRangesAsMap();
    for (const [key, [start, end]] of ranges.entries()) {
      if (start.isSame(this.startDate, 'day') && end.isSame(this.endDate, 'day')) {
        return key;
      }
    }
    return this.chosenLabel;
  }

}
