import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyComponent} from './components/company.component';
import {CompanyRoutingModule} from './company-routing.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {InsightsComponent} from './components/insights/insights.component';
import {ContributorsComponent} from './components/contributors/contributors.component';
import {SettingsComponent} from './components/settings/settings.component';
import {GuideInsightChartComponent} from './components/insights/guide-insight-chart/guide-insight-chart.component';
import {LineChartModule} from '@swimlane/ngx-charts';
import {FlexModule} from '@angular/flex-layout';
import {ClickTypeFormatterPipe} from './pipes/click-type-formatter.pipe';
import {InsightCardComponent} from './components/insights/insight-card/insight-card.component';
import {InsightCardsComponent} from './components/insights/insight-cards/insight-cards.component';
import {InsiderInsightChartComponent} from './components/insights/insider-insight-chart/insider-insight-chart.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {NgxPopperModule} from 'ngx-popper';
import {KrugoDatePickerComponent} from './components/insights/krugo-date-picker/krugo-date-picker.component';
import {InsightCardLoadingComponent} from './components/insights/insight-card-loading/insight-card-loading.component';
import {NgxShimmerLoadingModule} from 'ngx-shimmer-loading';
import {AddContributorComponent} from './components/contributors/add-contributor/add-contributor.component';
import {GuideInsightTableComponent} from './components/insights/guide-insight-table/guide-insight-table.component';
import {GuideNamePipe} from './pipes/guide-name.pipe';
import {GuideInsightsComponent} from './components/insights/guide-insights/guide-insights.component';
import {TotalClickMapNotEmptyPipe} from './pipes/total-click-map-not-empty.pipe';
import {KeepIfInsiderIdPipe} from './pipes/keep-if-insider-id.pipe';
import {KeepIfCompanyIdPipe} from './pipes/keep-if-company-id.pipe';
import {InsiderDesignComponent} from './components/settings/insider-design/insider-design.component';
import {ChooseFontButtonComponent} from './components/settings/choose-font-button/choose-font-button.component';
import {ChooseFontModalComponent} from './components/settings/choose-font-modal/choose-font-modal.component';
import {ChooseColorButtonComponent} from './components/settings/choose-color-button/choose-color-button.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {InsightInfoModalComponent} from './components/insights/insight-info-modal/insight-info-modal.component';
import {PreviewGuidesComponent} from './components/preview-guides/preview-guides.component';
import {GuideFeaturesComponent} from './components/guide-features/guide-features.component';
import {EditGuideFeatureComponent} from './components/guide-features/edit-guide-feature/edit-guide-feature.component';
import {TagComponent} from '../guides/components/guide-tags/tags/tag/tag.component';
import { RearrangeCategoryModalComponent } from './components/guide-features/rearrange-category-modal/rearrange-category-modal.component';

@NgModule({
  declarations: [
    CompanyComponent,
    InsightsComponent,
    ContributorsComponent,
    SettingsComponent,
    GuideInsightChartComponent,
    InsightCardsComponent,
    InsightCardComponent,
    ClickTypeFormatterPipe,
    InsiderInsightChartComponent,
    KrugoDatePickerComponent,
    InsightCardLoadingComponent,
    AddContributorComponent,
    GuideInsightTableComponent,
    GuideNamePipe,
    GuideInsightsComponent,
    TotalClickMapNotEmptyPipe,
    KeepIfInsiderIdPipe,
    KeepIfCompanyIdPipe,
    InsiderDesignComponent,
    ChooseFontButtonComponent,
    ChooseFontModalComponent,
    ChooseColorButtonComponent,
    PreviewGuidesComponent,
    InsightInfoModalComponent,
    GuideFeaturesComponent,
    EditGuideFeatureComponent,
    RearrangeCategoryModalComponent,
  ],
    imports: [
      CommonModule,
      CompanyRoutingModule,
      FormsModule,
      SharedModule,
      LineChartModule,
      FlexModule,
      NgxDaterangepickerMd.forRoot(),
      NgxPopperModule,
      NgxShimmerLoadingModule,
      NgbTooltipModule
    ],
  exports: [
    ClickTypeFormatterPipe,
    GuideNamePipe,
    KeepIfCompanyIdPipe,
    KeepIfInsiderIdPipe,
    TotalClickMapNotEmptyPipe
  ],
  providers: []
})
export class CompanyModule {
}
