import {HydratedGuide} from './hydrated-guide';
import {AssetSize} from '../../enum/dto/image-size.enum';
import {MediaUtils} from '../../../utils/media-utils';

export class MinimalHydratedGuide extends HydratedGuide {
  onDeserialize() {
    this.loadMinimalImages = true;
    this.images?.forEach(i => {
      if (MediaUtils.isVideo(i.mediaType)) {
        i.sizeOverrides = [AssetSize.Large];
      } else {
        i.sizeOverrides = [AssetSize.Medium];
      }
    });
    super.onDeserialize();
  }
}
