import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../models/base/base-component';
import {GuidesViewModel} from './guides-view-model';

@Component({
  selector: 'app-create-guide',
  templateUrl: './guides.component.html',
  styleUrls: ['./guides.component.scss', '../../../../scss/stylesheet.scss'],
  providers: [GuidesViewModel],
})
export class GuidesComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public vm: GuidesViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy() {
    this.destroy();
  }

}
