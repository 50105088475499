import {KrugoMapMarker} from '../../views/shared/components/map/interfaces/krugo-map-marker';
import {Asset} from '../image/dto/asset';

export class MapPin implements KrugoMapMarker {

  public pinName: string = '';
  public lat: number;
  public lng: number;

  constructor(lat, lng: number) {
    this.lat = lat;
    this.lng = lng;
  }

  isValid(): boolean {
    return this.pinName !== '' || this.lat !== 0 || this.lng !== 0;
  }

  getCoverImage(): Asset {
    return null;
  }

  getDesc(): string {
    return '';
  }

  getImages(): Asset[] {
    return [];
  }

  getLat(): number {
    return this.lat;
  }

  getLng(): number {
    return this.lng;
  }

  getTitle(): string {
    return '';
  }

  getUniqueId(): string {
    return `${this.lat}-${this.lng}`;
  }

  getUrl(): string {
    return '';
  }

  allowPinWindow(): boolean {
    return false;
  }

}
