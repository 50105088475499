import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseModal} from '../../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Font} from '../../../../../models/enum/shared/font.enum';
import {FontUtils} from '../../../../../utils/font-utils';
import {TypeUtils} from '../../../../../utils/type-utils';

@Component({
  selector: 'app-choose-font-modal',
  templateUrl: './choose-font-modal.component.html',
  styleUrls: ['./choose-font-modal.component.scss']
})
export class ChooseFontModalComponent extends BaseModal
  implements OnInit, AfterViewInit, OnDestroy {

  selectedFont: Font = null;
  fonts = FontUtils.getAllFontTypes();
  types = TypeUtils;

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  cancel() {
    this.activeModal.close(null);
  }

  save() {
    this.activeModal.close(this.selectedFont);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
