import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PillItem} from '../../../../../../models/shared/stylesheet/pill-item';

/**
 * @param pillItem: uses pill item to determine internal state, else uses below
 * @param clickable: can click, else no
 * @param selected: added to something, else not
 * @param disabled: disabled, else not
 */
@Component({
  selector: 'app-tag-pill',
  templateUrl: './tag-pill.component.html',
  styleUrls: ['./tag-pill.component.scss']
})
export class TagPillComponent implements OnInit {

  @Input() pillItem: PillItem;
  @Input() clickable = true;
  @Input() selected = false;
  @Input() disabled = false;
  @Output() changed = new EventEmitter(true);
  public text: string;

  constructor() {
  }

  ngOnInit(): void {
    if (this.pillItem) {
      this.clickable = this.pillItem.clickable;
      this.selected = this.pillItem.selected;
      this.disabled = this.pillItem.disabled;
      this.text = this.pillItem.text;
    }
  }

  clicked() {
    if (this.clickable) {
      if (this.pillItem) {
        this.pillItem.selected = !this.pillItem.selected;
      }
      this.selected = !this.selected;
      this.changed.emit(true);
    }
  }

}
