import {Pipe, PipeTransform} from '@angular/core';
import {AuthFlow} from '../../../../models/account/enum/auth-flow.enum';

@Pipe({
  name: 'isAuthFlow'
})
export class IsAuthFlowPipe implements PipeTransform {

  transform(flow: AuthFlow, is: AuthFlow): boolean {
    return flow === is;
  }

}
