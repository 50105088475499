import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AddPlaceViewModel} from './add-place-view-model';
import {BaseComponent} from '../../../../../models/base/base-component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HydratedGuide} from '../../../../../models/guide/dto/hydrated-guide';
import {AddPlaceFlow} from '../../../../../models/guide/enum/add-place-flow.enum';
import {Router} from '@angular/router';
import {MapPin} from '../../../../../models/shared/map-pin';

@Component({
  selector: 'app-add-place-modal',
  templateUrl: './add-place-modal.component.html',
  styleUrls: ['./add-place-modal.component.scss'],
  providers: [AddPlaceViewModel],
})
export class AddPlaceModalComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  guide: HydratedGuide;
  addPlaceFlow = AddPlaceFlow;
  skipToAssignSection: boolean = false;

  constructor(
    public viewModel: AddPlaceViewModel,
    private activeModal: NgbActiveModal,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.viewModel.guide = this.guide;
    this.viewModel.generateSearchItems();
    this.viewModel.generateSearchLoadingOptions();
    this.viewModel.getPlaceCategories();
    this.viewModel.populateGuideCity();
    if (this.skipToAssignSection) {
      this.viewModel.addPlaceFlow = AddPlaceFlow.AssignSection;
    } else {
      this.viewModel.addPlaceFlow = AddPlaceFlow.PlaceSearch;
    }
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.viewModel.placeCreated.subscribe(newPlaceEvent => {
      this.closeModal(newPlaceEvent.updatedGuide);
      this.router.navigate(
        [this.router.url + '/places', newPlaceEvent.newPlaceId]
      ).then();
    });
  }

  closeModal(updatedGuide: HydratedGuide = null) {
    if (updatedGuide) {
      this.activeModal.close(updatedGuide);
    } else {
      this.activeModal.dismiss(null);
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

  togglePlaceLookup() {
    if (this.viewModel.addPlaceFlow === AddPlaceFlow.PlaceSearch) {
      this.viewModel.addPlaceFlow = AddPlaceFlow.PlacePin;
      this.viewModel.setupCustomPlacePinLabelGroup();
      this.viewModel.setupCustomPlacePinFormGroup();
      this.viewModel.autoCompletedLocations = [];
    } else if (this.viewModel.addPlaceFlow === AddPlaceFlow.PlacePin) {
      this.viewModel.addPlaceFlow = AddPlaceFlow.PlaceSearch;
      this.viewModel.customPlacePin = new MapPin(0, 0);
      this.viewModel.canAddCustomPin = false;
    }
  }

}
