<ng-container *ngIf="this.insiderInsight | totalClickMapNotEmpty">
  <div class="table-title">
    {{title}}
  </div>
  <div class="table-container" fxLayout="column">
    <div class="table-header" fxLayout="row" fxLayoutAlign="end stretch">
      <div class="header-guide" fxFlex>
        Guide
      </div>
      <div *ngFor="let keyValue of vm.clickMap$ | async | keyvalue: KeyValueUtils.guideViewOrder"
           class="header-type"
           [style.width.%]="10">
        {{keyValue.key | clickTypeFormatter}}
        <span><img class="header-tool-tip" [ngbTooltip]="getTooltip(keyValue.key)" [src]="'assets/icons/dark/outline/information-circle.svg'" alt="Insight Tooltip"></span>
      </div>
    </div>
    <hr class="item-divider">
    <ng-container *ngIf="!isLoading">
      <ng-container *ngFor="let keyValue of this.insiderInsight?.totalClickMap | keyvalue">
        <div matRipple
             (click)="vm.goToInsights(keyValue.key)"
             class="table-item" fxLayout="row" fxLayoutAlign="end stretch" >
          <div class="table-item-name" fxFlex>
            {{vm.allGuides$ | async | guideName: keyValue.key}}
          </div>
          <div *ngFor="let clickTypeAndNumber of keyValue?.value | keyvalue: KeyValueUtils.guideViewOrder"
               class="table-item-column"
               [style.width.%]="10">
            {{clickTypeAndNumber.value}}
          </div>
        </div>
        <hr class="item-divider">
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isLoading">
      <div *ngFor="let i of [0,1,2,3,4,5,6,7,8]">
        <ngx-shimmer-loading [class]="'shimmer'" [borderRadius]="'0px'" [width]="'100%'" [height]="'36px'"></ngx-shimmer-loading>
        <hr class="item-divider">
      </div>
    </ng-container>
  </div>

</ng-container>
