import {AbstractControl, NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import {FormValidator} from '../../../../../models/protocols/form-validator';
import {Directive} from '@angular/core';

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    if (!value || value === '') {
      return null;
    }

    const validPhoneNumber = {
      pattern: '^(\\+?)[-. ]?([0-9]{1})[-. ]?\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$',
      message: 'Invalid phone number.'
    };
    const phoneRegex = new RegExp(validPhoneNumber.pattern);
    if (!phoneRegex.test(value)) {
      return {phoneNumber: validPhoneNumber.message}
    }

    return null
  }
}

@Directive({
  selector: '[appPhoneValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: PhoneValidatorDirective, multi: true}]

})
export class PhoneValidatorDirective implements FormValidator {

  validate(control: AbstractControl): { [key: string]: any } {
    return phoneValidator()(control);
  }

  errorName(): string {
    return 'phoneNumber';
  }
}

