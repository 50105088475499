import {Injectable, OnDestroy} from '@angular/core';
import {BaseDomainModel} from '../models/base/base-domain-model';
import {InsiderAPI} from '../api/insider-api';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {Insider} from '../models/guide/dto/insider';
import {ToastService} from '../services/toast-service';
import {SessionService} from '../services/session-service';
import {catchError, delay, map, switchMap, tap} from 'rxjs/operators';
import {ImageAPI} from '../api/image-api';
import {GenerateUploadUrlRequest} from '../models/image/requests/generate-upload-url-request';
import {MediaType} from '../models/enum/dto/media-type.enum';

@Injectable({
  providedIn: 'root'
})

export class InsidersDomainModel extends BaseDomainModel implements OnDestroy {

  private userInsider = new BehaviorSubject<Insider>(null);
  public userInsider$ = this.userInsider.asObservable();
  private companies = new BehaviorSubject<Insider[]>(null);
  public companies$ = this.companies.asObservable();

  constructor(
    private insiderApi: InsiderAPI,
    private imageApi: ImageAPI,
    public sessionService: SessionService,
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  private setupBindings() {
    const sessionSub = this.sessionService.sessionContainer.subscribe(s => {
      this.userInsider.next(s?.insider);
      this.companies.next(s?.insider?.companies);
    });
    this.pushSub(sessionSub);
  }

  public updateInsider(insider: Insider): Observable<Insider> {
    return this.insiderApi.UpdateInsider(insider).pipe(
      catchError(e => {
        return throwError(e);
      }),
      tap(resultInsider => {
        this.updateInsiderOnSession(resultInsider);
      }),
    );
  }

  private updateInsiderOnSession(resultInsider: Insider) {
    const sessionInsider = this.sessionService.getInsider();
    if (this.companies?.value?.find(c => c.id === resultInsider.id)) {
      // update companies if changed
      const updatedCompanies = this.companies?.value?.filter(c => c.id !== resultInsider.id);
      updatedCompanies.push(resultInsider);
      sessionInsider.companies = updatedCompanies;
      this.sessionService.setInsider(sessionInsider);
    } else if (sessionInsider.id === resultInsider.id) {
      this.sessionService.setInsider(resultInsider);
    }
  }

  public updateInsiderProfilePicture(insiderId: string, profileImageToUpload: string, fileName: string): Observable<any> {
    return this.uploadInsiderAsset(profileImageToUpload, null, insiderId, fileName).pipe(
      map((res) => {
        setTimeout(_ => this.pollForInsiderProfilePicture(insiderId, fileName), 5000);
        return res;
      }),
      catchError(e => {
        return throwError(e);
      })
    );
  }

  public pollForInsiderProfilePicture(insiderId, fileName: string, remainingAttempts: number = 5) {
    if (remainingAttempts > 0) {
      // poll for updated insider and pass into session service when fileName matches
      this.insiderApi.GetInsider(insiderId).subscribe((updatedInsider) => {
        if (updatedInsider?.profilePicture?.fileName === fileName) {
          // updated asset attached
          this.updateInsiderOnSession(updatedInsider);
        } else {
          // delay and try poll again
          const nextDelay = (6 - remainingAttempts) * 2000; // delay gets longer after each attempt
          setTimeout(_ => this.pollForInsiderProfilePicture(insiderId, fileName, remainingAttempts - 1), nextDelay);
        }
      });
    }
  }


  // Image Handling
  uploadInsiderAsset(image: string, metadata: Map<string, string> = null, insiderId: string, fileName: string = null): Observable<any> {
    const req = new GenerateUploadUrlRequest();
    req.fileName = fileName ? fileName : new Date().getTime() + '.png';
    req.mediaType = MediaType.PNG;
    req.metadata = metadata;
    req.insiderId = insiderId;
    return this.uploadInsiderAssetHelper(req, image);
  }

  private uploadInsiderAssetHelper(req: GenerateUploadUrlRequest, image: string): Observable<any> {
    return this.imageApi.GetInsiderImageUploadUrl(req).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageApi.PutImageUploadUrl(signedUploadUrl.url, image, req.fileName);
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
