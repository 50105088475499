import {AfterViewInit, Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {InsightsViewModel} from './insights-view-model';
import {BehaviorSubject} from 'rxjs';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
  providers: [InsightsViewModel],
})
export class InsightsComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('graphContainer') private graphContainer: ElementRef;
  private observeContainer: ResizeObserver;
  public dimensions = new BehaviorSubject<[number, number]>([0, 0]);

  constructor(
    public vm: InsightsViewModel
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit() {
    this.setupBindings();
  }

  setupBindings() {
    this.observeGraphContainer();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  observeGraphContainer() {
    this.observeContainer = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        const cr = entry.contentRect;
        if (cr.width > 0) {
          this.dimensions.next([cr.width, 400]);
        } else {
          this.dimensions.next([0, 0]);
        }
      }
    });
    this.observeContainer.observe(this.graphContainer.nativeElement);
  }

  ngOnDestroy() {
    this.observeContainer?.disconnect();
    this.destroy();
  }

}
