import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AuthFlow} from '../../../../models/account/enum/auth-flow.enum';
import {BaseComponent} from '../../../../models/base/base-component';
import {AuthViewModel} from '../../viewModels/auth-view-model';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  public authFlows = AuthFlow;

  constructor(
    public viewModel: AuthViewModel,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.parseAuthFlow();
    // Navigate to the create shared page on successful auth
    this.viewModel.authSuccess
      .notNull()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((insider) => {
        if (!!insider && !!insider?.id) {
          this.router.navigate([`user/${insider.id}`]).then(() => {
            this.viewModel.signInRequest.next(null);
            this.viewModel.signUpRequest.next(null);
            this.viewModel.forgotPasswordRequest.next(null);
          });
        }
      });
    // auth navigation
    this.viewModel.nextAuthFlow
      .notNull()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((nextAuthFlow) => {
        switch (nextAuthFlow) {
          case AuthFlow.ConfirmAccount:
            this.router.navigate(['auth/confirm-account']).then(() => {
            });
            break;
          case AuthFlow.SignIn:
            this.router.navigate(['auth/sign-in']).then(() => {
            });
            break;
          case AuthFlow.SignUp:
            this.router.navigate(['auth/sign-up']).then(() => {
            });
            break;
          case AuthFlow.ForgotPassword:
            this.router.navigate(['auth/forgot-password']).then(() => {
            });
            break;
        }
      });
  }

  parseAuthFlow() {
    this.route.url.subscribe((url) => {
      switch (url[url.length - 1].path) {
        case 'sign-in': {
          this.viewModel.authFlow.next(AuthFlow.SignIn);
          break;
        }
        case 'forgot-password': {
          this.viewModel.authFlow.next(AuthFlow.ForgotPassword);
          break;
        }
        case 'confirm-account': {
          this.viewModel.authFlow.next(AuthFlow.ConfirmAccount);
          break;
        }
        case 'sign-up': {
          this.viewModel.authFlow.next(AuthFlow.SignUp);
          break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

}
