import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';

@Component({
  selector: 'app-search-bar-add',
  templateUrl: './search-bar-add.component.html',
  styleUrls: ['./search-bar-add.component.scss']
})
export class SearchBarAddComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() placeHolder: string = 'Search';
  @Input() maxLength: number = 9999;
  @Output() search = new EventEmitter(true);
  @Output() enterClickedOutput = new EventEmitter<string>(true);
  @ViewChild('reset') private reset: ElementRef;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  searchChanged(e) {
    this.search.emit(e.target.value);
  }

  emitEmpty() {
    this.search.emit('');
  }

  clear() {
    this.reset.nativeElement.click();
  }

  enterHit(e) {
    this.enterClickedOutput.emit(e.target.value);
    this.clear();
  }

  plusHit(value: string) {
    this.enterClickedOutput.emit(value);
    this.clear();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
