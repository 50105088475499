import {AfterViewInit, Component, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {Insider} from '../../../../models/guide/dto/insider';
import {ModalUtils} from '../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SocialLinksModalComponent} from './social-links-modal/social-links-modal.component';
import {DeserializeHelper} from '../../../../models/protocols/deserializable';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {FormGroupComponent} from '../form-group/form-group.component';
import {EditProfilePhotoModalComponent} from '../../modals/edit-profile-photo-modal/edit-profile-photo-modal.component';
import {AssetSize} from '../../../../models/enum/dto/image-size.enum';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-insider-settings',
  templateUrl: './insider-settings.component.html',
  styleUrls: ['./insider-settings.component.scss']
})
export class InsiderSettingsComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('descriptionForm') descriptionForm: FormGroupComponent;
  @Input() insider: Insider;
  @Input() profilePictureUpdated = new EventEmitter<boolean>();
  @Output() updateInsider = new EventEmitter<Insider>();
  @Output() imageToUploadChanged = new EventEmitter<string>();

  public profilePicLoadingOpts = LoadingOptions.default();
  public descriptionFormInput: FormInputItem[] = [];
  public formStyling: FormGroupStyling = new FormGroupStyling();
  public formOptions: FormOptions = new FormOptions();
  editDescription = false;

  constructor(private modalService: NgbModal) {
    super();
  }

  ngOnInit(): void {
    this.setupBindings();
    this.setupViews();
  }

  setupViews() {
    this.setupFormItems();
    // Setup profile pic loading options
    this.profilePicLoadingOpts.showLoadingText = false;
    this.profilePicLoadingOpts.backgroundColor = '#EAEAEA';
    this.profilePicLoadingOpts.cornerRadius = '50%';
  }

  setupBindings() {
    // bind to successful profile picture update
    const profSub = this.profilePictureUpdated.pipe(delay(500)).subscribe((_) => {
      // slight delay to avoid flickering the old profile picture
      this.profilePicLoadingOpts.isLoading = false;
    });
    this.pushSub(profSub);
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  getTitle(): string {
    return !!this.insider?.companyName ? 'Profile' : this.insider?.getFullName();
  }

  getDescription(): string {
    return !this.insider?.description ? 'No Description' : this.insider?.description;
  }

  openEditSocialLinksModal() {
    const modalRef = this.modalService.open(
      SocialLinksModalComponent,
      ModalUtils.defaultModalOptions());
    const compInstance = modalRef.componentInstance as SocialLinksModalComponent;
    compInstance.updatedUserInsider = DeserializeHelper.deserializeToInstance(Insider, this.insider);
    modalRef.result.then((updatedInsider) => {
      if (updatedInsider) {
        this.updateInsider.emit(updatedInsider);
      }
    });
  }

  setupFormItems() {
    if (!this.insider.description) {
      this.insider.description = '';
    }
    const descriptionInput = new FormInputItem();
    descriptionInput.inputType = FormInputType.Text;
    descriptionInput.itemType = FormItemType.Textarea;
    descriptionInput.inputName = 'insiderSettingsDescription';
    descriptionInput.label = 'Description';
    descriptionInput.hideLabel = true;
    descriptionInput.placeholder = 'Description';
    descriptionInput.bindingProperty = 'description';
    descriptionInput.customClass = 'edit-insider-description';
    descriptionInput.autoGrowTextArea = true;
    descriptionInput.autoGrowMinRows = 1;
    descriptionInput.autoGrowMaxRows = 5;

    this.descriptionFormInput.push(descriptionInput);
  }

  descriptionClicked() {
    this.editDescription = true;
    const textArea = document.getElementById('insiderSettingsDescription');
    // we need a short delay for the DOM to un-hide the textarea before we focus it.
    setTimeout(() => {
      textArea?.focus();
    }, 50);
  }

  descriptionFocusOut() {
    this.editDescription = false;
    this.descriptionForm.submitForm();
  }

  profilePictureClicked() {
    this.showEditProfilePhotoModal();
  }

  showEditProfilePhotoModal() {
    const modalRef = this.modalService.open(
      EditProfilePhotoModalComponent,
      ModalUtils.defaultModalOptions());
    const compInstance = modalRef.componentInstance as EditProfilePhotoModalComponent;
    compInstance.initialImageSrc = this.insider.profilePicture?.getAssetUrl(AssetSize.Large)?.url;
    modalRef.result.then((image) => {
      if (image) {
        this.profilePicLoadingOpts.isLoading = true;
        this.imageToUploadChanged.emit(image);
      }
    });
  }

  insiderIsCompany(): boolean {
    return !!this.insider.companyName;
  }
}

