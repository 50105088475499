import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ManageTagsViewModel} from './manage-tags-view-model';
import {HydratedGuide} from '../../../../models/guide/dto/hydrated-guide';
import {BaseModal} from '../../../../models/base/base-modal';
import {takeUntil} from 'rxjs/operators';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-tags',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss'],
  providers: [ManageTagsViewModel]
})
export class ManageTagsComponent extends BaseModal
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() guide: HydratedGuide;

  constructor(
    public vm: ManageTagsViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.initGuideInVM();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.vm.dismissModalSubject
      .pipe(takeUntil(this.onDestroy))
      .subscribe((close) => {
        if (close) { this.cancel(); }
      });
  }

  cancel() {
    this.activeModal.close(null);
  }

  private initGuideInVM() {
    this.vm.initGuide(this.guide);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initGuideInVM();
  }

  ngOnDestroy() {
    this.destroy();
  }

}
