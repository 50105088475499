import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {FontHierarchy} from '../../../../models/enum/shared/font-hierarchy.enum';
import {FontType} from '../../../../models/enum/shared/font-type.enum';
import {FontUtils} from '../../../../utils/font-utils';
import {Font} from '../../../../models/enum/shared/font.enum';

@Injectable()
export class FontViewModel extends BaseViewModel implements OnDestroy {

  private primary = new BehaviorSubject<Font>(Font.WorkSans);
  private secondary = new BehaviorSubject<Font>(Font.WorkSans);
  private fontHierarchy = new BehaviorSubject<FontHierarchy>(FontHierarchy.Primary);
  private fontTypeSubject = new BehaviorSubject<FontType>(FontType.Regular);

  public font$ = combineLatest([
    this.fontHierarchy.pipe(distinctUntilChanged()),
    this.primary.pipe(distinctUntilChanged()),
    this.secondary.pipe(distinctUntilChanged())
  ]).pipe(
    debounceTime(1),
    map(([hierarchy, primary, secondary]) => {
      switch (hierarchy) {
        case FontHierarchy.Primary:   return !!primary ? primary.toString() : Font.WorkSans.toString();
        case FontHierarchy.Secondary: return !!secondary ? secondary.toString() : Font.WorkSans.toString();
      }
    }),
    distinctUntilChanged()
  );

  public fontClass = combineLatest([
    this.fontHierarchy.pipe(distinctUntilChanged()),
    this.fontTypeSubject.pipe(distinctUntilChanged()),
    this.primary.pipe(distinctUntilChanged()),
    this.secondary.pipe(distinctUntilChanged())
  ]).pipe(
    debounceTime(1),
    map(([hierarchy, type, primary, secondary]) => {
      switch (hierarchy) {
        case FontHierarchy.Primary:   return FontUtils.getFontClass(type, primary);
        case FontHierarchy.Secondary: return FontUtils.getFontClass(type, secondary);
      }
    }),
    distinctUntilChanged()
  );

  constructor(
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  setType(t: FontType) {
    this.fontTypeSubject.next(t);
  }

  setHierarchy(h: FontHierarchy) {
    this.fontHierarchy.next(h);
  }

  setPrimary(p: Font) {
    this.primary.next(p);
  }

  setSecondary(s: Font) {
    this.secondary.next(s);
  }

  ngOnDestroy() {
    this.destroy();
  }

}
