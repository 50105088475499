export enum AuthFlow {
  SignIn,
  SignUp,
  ForgotPassword,
  ConfirmAccount,
}

export function AuthFlowTitle(af: AuthFlow): string {
  switch (af) {
    case AuthFlow.SignIn:
      return 'Sign in to start creating & curating guides.';
    case AuthFlow.SignUp:
      return 'Sign up to start creating & curating guides.';
    case AuthFlow.ForgotPassword:
      return 'Forgot Password';
    case AuthFlow.ConfirmAccount:
      return 'Confirm your account';
  }
}

export function AuthFlowSubtext(af: AuthFlow): string {
  switch (af) {
    case AuthFlow.SignIn:
      return 'Krugo is the visual marketing software innovative DMOs rely on through the COVID-19 recovery and beyond.';
    case AuthFlow.SignUp:
      return 'Krugo is the visual marketing software innovative DMOs rely on through the COVID-19 recovery and beyond.';
    case AuthFlow.ForgotPassword:
      return 'Fill in the information below and we will get your password reset in no time!';
    case AuthFlow.ConfirmAccount:
      return 'We sent a top secret code to your phone. Enter it in below to confirm your account';
  }
}
