import {Guide} from '../models/guide/dto/guide';
import {GuideSection} from '../models/guide/dto/guide-section';
import {GuideAuthor} from '../models/guide/shared/guide-author';
import {Asset} from '../models/image/dto/asset';
import {GuideFeature} from '../models/guide/dto/guide-feature';


export class SortUtils {

  static sortGuidesByMostRecent = (a: Guide, b: Guide): number => {
    return b?.lastUpdated - a?.lastUpdated;
  }
  static sortGuideSections = (a: GuideSection, b: GuideSection): number => {
    return a?.sortOrder - b?.sortOrder;
  }
  static sortKeyNumberPairs = (a: [string, number], b: [string, number]): number => {
    return a[1] - b[1];
  }
  static sortByAuthor = (a: GuideAuthor, b: GuideAuthor): number => {
    return `${a.title}-${a.subtitle}`.localeCompare(`${b.title}-${b.subtitle}`);
  }
  static sortAssetsByMap(assets: Asset[], orderMap: Map<string, number>): Asset[] {
    assets = assets.filterNulls();
    if (!!orderMap) {
      // Generate tmp with sorted orderMap
      const tmp: [string, number][] = [];
      for (const [key, value] of orderMap.entries()) {
        tmp.push([key, value]);
      }
      tmp.sort(SortUtils.sortKeyNumberPairs);
      // Iterate over sorted orderMap and apply assets
      const sortedAssets = [];
      tmp.forEach(([key, _]) => {
        const nextAsset = assets.find(a => a.md5Hash === key);
        if (!!nextAsset) {
          sortedAssets.push(nextAsset);
        }
      });
      // Iterate over assets and add any that have been missed
      assets.forEach(a => {
        const sortedAsset = sortedAssets.find(sa => sa.md5Hash === a.md5Hash);
        if (!sortedAsset) {
          sortedAssets.push(a);
        }
      });
      return sortedAssets;
    } else {
      return assets;
    }
  }

  static sortGuidesFeaturesByMostPriority = (a: GuideFeature, b: GuideFeature): number => {
    return a?.priority - b?.priority;
  }

}
