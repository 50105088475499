import {DatatableAction} from './datatable-action';

export class DatatableColumn {
  public id: string;
  public title: string;
  public type: DatatableColumnType;
  public getCellValue: (val: any) => any;
  public getNestedCellValue: (val: any) => any;
  public disableSorting: boolean = false;
  public className: string;
  public widthPercent: number;
  public actions: DatatableAction[];
  public getTooltipValue: (val: any) => any;
  public tooltipIconSrc: string;

  constructor(id, title: string,
              type: DatatableColumnType,
              getCellValue: (val: any) => any,
              getNestedCellValue: (val: any) => any) {
    this.id = id;
    this.title = title;
    this.type = type;
    this.getCellValue = getCellValue;
    this.getNestedCellValue = getNestedCellValue;
  }

  getWidth(): string {
    if (this.widthPercent && this.widthPercent > 0) {
      return `${this.widthPercent}%`;
    } else {
      return '';
    }
  }
}

export enum DatatableColumnType {
  Text,
  Checkbox,
  Actions,
  Label,
}
