import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {GuidesDomainModel} from '../../../../../domain-models/guides-domain-model';
import {CustomPlace} from '../../../../../models/guide/dto/custom-place';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {LabelGroupItem, LabelItemType} from '../../../../../models/shared/stylesheet/label-group-item';
import {UploadImageInterface} from '../../../../shared/components/upload-asset/upload-image-interface';
import {CustomFile} from '../../../../../models/shared/custom-file';
import {UploadFileModalComponent} from '../../../../shared/modals/upload-file-modal/upload-file-modal.component';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Asset} from '../../../../../models/image/dto/asset';
import {ToastService} from '../../../../../services/toast-service';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {Searchable} from '../../../../../models/protocols/searchable';
import {HydratedGuide} from '../../../../../models/guide/dto/hydrated-guide';
import {BehaviorSubject, combineLatest, forkJoin} from 'rxjs';
import {MapModalComponent} from '../map-modal/map-modal.component';
import {RemoveSectionComponent} from '../section-options/remove-section/remove-section.component';
import {debounceTime, delay, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroupComponent} from '../../../../shared/components/form-group/form-group.component';
import {CustomError} from '../../../../../models/shared/custom-error';
import {GuideImage} from '../../../../../models/guide/shared/guide-image';
import {ReorderImageModalComponent} from '../reorder-image-modal/reorder-image-modal.component';
import {SortUtils} from '../../../../../utils/sort-utils';

@Injectable()
export class EditPlaceViewModel extends BaseViewModel implements OnDestroy, UploadImageInterface {

  customPlace: CustomPlace = null;
  guide: HydratedGuide;
  initialFiles: CustomFile[] = null;
  featuredImage: CustomFile = null;
  loadingOpts: LoadingOptions = LoadingOptions.default();
  placeImages: GuideImage[] = [];

  placeInfoOptions: FormOptions = new FormOptions();
  placeInfoStyling: FormGroupStyling = new FormGroupStyling();
  placeInfoItems: FormInputItem[] = [];
  labelGroupItems: LabelGroupItem[] = [];
  placeCategories: Searchable[] = [];
  placeFormGroup: FormGroupComponent;
  hydrateInputObject: EventEmitter<any> = new EventEmitter<any>();
  canEdit: boolean = false;
  canSubmit: boolean = false;

  // Listen for guide being edited
  private insiderIdSubject = new BehaviorSubject<string>(null);
  private guideIdSubject = new BehaviorSubject<string>(null);
  private placeIdSubject = new BehaviorSubject<string>(null);
  private guideLoadingMessage = 'Loading your Place!';
  private listenForGuide = this.guideIdSubject.notNull().pipe(
    takeUntil(this.onDestroy),
    debounceTime(100),
    tap(() => {
      this.loadingOpts.addRequest(this.guideLoadingMessage);
    }),
    switchMap(guideId => this.domainModel.getGuides([guideId]))
  ).subscribe(([guide]) => {
    this.loadingOpts.removeRequest(this.guideLoadingMessage);
    this.getPlace(this.placeIdSubject.getValue(), guide);
  });


  constructor(
    private domainModel: GuidesDomainModel,
    private modalService: NgbModal,
    private toastService: ToastService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  setupBindings() {
    this.activatedRoute.params.subscribe(p => {
      if (p?.insiderId) {
        this.insiderIdSubject.next(p.insiderId);
      }
      if (p?.guideId) {
        this.guideIdSubject.next(p.guideId);
      }
      if (p?.placeId) {
        this.placeIdSubject.next(p.placeId);
      }
    });
    this.getPlaceCategories();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  goBack() {
    const current = this.router.parseUrl(this.router.url);
    this.router.navigate(
      [current.root.children.primary.segments.slice(0, 6).join('/')]
    ).then();
  }

  getPlace(placeId: string, guide: HydratedGuide) {
    const loadingMessage = 'Loading your place!';
    this.loadingOpts.addRequest(loadingMessage);
    this.guide = guide;
    if (!!this.guide) {
      this.customPlace = this.guide.customPlaces.find(p => p.id === placeId);
      this.customPlace.customDescription = this.guide.contentDescription.get(placeId);
      this.generatePlaceImages();
      this.generatePlaceInfoLabelGroupItems();
      this.loadingOpts.removeRequest(loadingMessage);
    }
  }

  generatePlaceImages() {
    if (this.customPlace.images.length > 0) {
      this.sortPlaceImages(this.customPlace.images);
    }
  }

  sortPlaceImages(assets: Asset[]) {
    if (assets.length > 0) {
      this.placeImages = [];
      const sorted = SortUtils.sortAssetsByMap(assets, this.guide?.imageOrderMap?.get(this.customPlace.id));
      sorted.forEach((asset, i) => {
        const img = new GuideImage(asset, i);
        this.placeImages.push(img);
      });
    }
  }

  generatePlaceInfoLabelGroupItems() {
    const categories = this.domainModel.placeCategories.getValue();
    this.labelGroupItems = [];
    const divider = new LabelGroupItem();
    divider.itemType = LabelItemType.Divider;

    this.labelGroupItems.push(divider);
    const title = new LabelGroupItem();
    title.itemType = LabelItemType.Title;
    title.value = this.customPlace.name;
    this.labelGroupItems.push(title);

    const editButton = new LabelGroupItem();
    editButton.itemType = LabelItemType.ButtonListItem;
    editButton.actionButtonText = 'Edit';
    editButton.actionEmitter.subscribe((_: LabelGroupItem) => {
      this.canEdit = !this.canEdit;
    });
    editButton.actionButtonClass = 'edit-action-button';
    this.labelGroupItems.push(editButton);
    this.labelGroupItems.push(divider);

    const description = new LabelGroupItem();
    description.itemType = LabelItemType.Text;
    description.label = 'Description';
    description.value = this.customPlace.customDescription;
    this.labelGroupItems.push(description);
    this.labelGroupItems.push(divider);

    const url = new LabelGroupItem();
    url.itemType = LabelItemType.Text;
    url.label = 'URL';
    url.value = this.customPlace.url ? this.customPlace.url : 'No URL Provided';
    this.labelGroupItems.push(url);

    const facebookUrl = new LabelGroupItem();
    facebookUrl.itemType = LabelItemType.Text;
    facebookUrl.label = 'Facebook URL';
    facebookUrl.value = this.customPlace.facebookUrl ? this.customPlace.facebookUrl : 'No Facebook URL Provided';
    this.labelGroupItems.push(facebookUrl);

    const instagramUrl = new LabelGroupItem();
    instagramUrl.itemType = LabelItemType.Text;
    instagramUrl.label = 'Instagram URL';
    instagramUrl.value = this.customPlace.instagramUrl ? this.customPlace.instagramUrl : 'No Instagram URL Provided';
    this.labelGroupItems.push(instagramUrl);

    const twitterUrl = new LabelGroupItem();
    twitterUrl.itemType = LabelItemType.Text;
    twitterUrl.label = 'Twitter URL';
    twitterUrl.value = this.customPlace.twitterUrl ? this.customPlace.twitterUrl : 'No Twitter URL Provided';
    this.labelGroupItems.push(twitterUrl);

    this.labelGroupItems.push(divider);

    this.generatePlaceInfoFormItems();
  }

  generatePlaceInfoFormItems() {
    this.placeInfoItems = [];
    const divider = new FormInputItem();
    divider.itemType = FormItemType.Divider;

    this.placeInfoItems.push(divider);
    const title = new FormInputItem();
    title.inputType = FormInputType.Text;
    title.itemType = FormItemType.Textarea;
    title.inputName = 'name';
    title.label = 'Name';
    title.hideLabel = true;
    title.bindingProperty = 'name';
    title.required = true;
    title.placeholder = 'Place Name';
    title.customClass = 'edit-guide-title';
    this.placeInfoItems.push(title);

    const description = new FormInputItem();
    description.itemType = FormItemType.Textarea;
    description.inputType = FormInputType.Text;
    description.inputName = 'description';
    description.label = 'Description';
    description.placeholder = 'Place Description';
    description.bindingProperty = 'customDescription';
    description.required = true;
    description.autoGrowTextArea = true;
    description.autoGrowMinRows = 2.5;
    description.autoGrowMaxRows = 9;
    description.customClass = 'edit-guide-description';
    this.placeInfoItems.push(description);

    const url = new FormInputItem();
    url.itemType = FormItemType.Textarea;
    url.inputType = FormInputType.Text;
    url.inputName = 'url';
    url.label = 'URL';
    url.placeholder = 'Enter the place\'s website URL. (optional)';
    url.bindingProperty = 'url';
    url.customClass = 'edit-guide-description';
    this.placeInfoItems.push(url);

    const facebookUrl = new FormInputItem();
    facebookUrl.itemType = FormItemType.Textarea;
    facebookUrl.inputType = FormInputType.Text;
    facebookUrl.inputName = 'facebookUrl';
    facebookUrl.label = 'Facebook URL';
    facebookUrl.placeholder = 'Enter the place\'s Facebook URL (optional)';
    facebookUrl.bindingProperty = 'facebookUrl';
    facebookUrl.customClass = 'edit-guide-description';
    this.placeInfoItems.push(facebookUrl);

    const instagramUrl = new FormInputItem();
    instagramUrl.itemType = FormItemType.Textarea;
    instagramUrl.inputType = FormInputType.Text;
    instagramUrl.inputName = 'instagramUrl';
    instagramUrl.label = 'Instagram URL';
    instagramUrl.placeholder = 'Enter the place\'s Instagram URL (optional)';
    instagramUrl.bindingProperty = 'instagramUrl';
    instagramUrl.customClass = 'edit-guide-description';
    this.placeInfoItems.push(instagramUrl);

    const twitterUrl = new FormInputItem();
    twitterUrl.itemType = FormItemType.Textarea;
    twitterUrl.inputType = FormInputType.Text;
    twitterUrl.inputName = 'twitterUrl';
    twitterUrl.label = 'Twitter URL';
    twitterUrl.placeholder = 'Enter the place\'s Twitter URL (optional)';
    twitterUrl.bindingProperty = 'twitterUrl';
    twitterUrl.customClass = 'edit-guide-description';
    this.placeInfoItems.push(twitterUrl);
  }

  getPlaceCategories() {
    const categories = this.domainModel.placeCategories.getValue();
    categories.forEach(c => {
      const newCat = {lookupKey: c.parentName, value: c.parentId};
      this.placeCategories.push(newCat);
    });
    this.placeCategories.sort((a, b) => a.lookupKey.localeCompare(b.lookupKey));
  }

  categorySelected(cat: Searchable) {
    this.customPlace.categoryIds = [];
    this.customPlace.categoryIds.push(cat.value);
  }

  placeInfoChanges() {
    let canSubmit = true;
    this.placeInfoItems.forEach((fi) => {
      if (!fi.canSubmit()) {
        canSubmit = false;
        this.canSubmit = canSubmit;
        return;
      }
    });
    if (canSubmit) {
      this.canSubmit = canSubmit;
    }
  }

  getFeaturedImage(): Asset {
    if (this.placeImages.length > 0) {
      return this.placeImages[0].asset;
    } else {
      return null;
    }
  }

  fileList(f: CustomFile[], id?: number) {
    if (f.length > 0) {
      this.initialFiles = f;
      this.openUploadModal();
    }
  }

  openMapModal() {
    const modalRef = this.modalService.open(
      MapModalComponent,
      ModalUtils.defaultModalOptions()
    );
    const markerArray = [];
    markerArray.push(this.customPlace);
    modalRef.componentInstance.mapMarkers = markerArray;
    modalRef.result.then(() => {
    }).catch((_) => {
    });
  }

  openUploadModal() {
    const modalRef = this.modalService.open(
      UploadFileModalComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.componentInstance.initialFiles = this.initialFiles;
    modalRef.result.then((files) => {
      this.uploadImages(files);
    }).catch((_) => {
    });
  }

  uploadImages(files: CustomFile[]) {
    const loadingMessage = 'Uploading Your Files';
    if (!this.loadingOpts.containsRequest(loadingMessage)) {
      this.loadingOpts.addRequest(loadingMessage);
      const uploadQueue = [];
      files.forEach(f => {
        uploadQueue.push(this.domainModel.uploadGuideAsset(f, null, this.customPlace.guideId, this.customPlace.id));
      });
      forkJoin(uploadQueue).pipe(delay(5000), take(1)).subscribe(_ => {
        this.toastService.publishSuccessMessage('Asset(s) Uploaded', null);
        this.pollForUploadedAssets(files, 10, loadingMessage);
      }, (error: CustomError) => {
        this.loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishError(error);
        this.toastService.publishErrorMessage('Failed to upload asset(s). Please try again.', 'Asset Upload');
      });
    }
  }

  pollForUploadedAssets(uploadQueue: CustomFile[], numOfRetries: number, loadingMessage: string) {
    const uploadCopy = uploadQueue;
    const ids = [this.guide.id];
    this.domainModel.getGuides(ids).subscribe(guides => {
      uploadQueue.forEach((file, index) => {
        if (guides[0].images.find(asset => asset.fileName.includes(file.name))) {
          uploadCopy.splice(index, 1);
          if (uploadCopy.length === 0) {
            this.refreshPlace();
            this.loadingOpts.removeRequest(loadingMessage);
            this.initialFiles = [];
            numOfRetries = 0;
          }
        }
      });
      if (numOfRetries > 0 && uploadCopy.length > 0) {
        setTimeout(() => {
          this.pollForUploadedAssets(uploadCopy, numOfRetries - 1, loadingMessage);
        }, 6000);
      } else {
        this.refreshPlace();
        this.loadingOpts.removeRequest(loadingMessage);
        this.initialFiles = [];
        numOfRetries = 0;
      }
    });
  }

  deletePlaceImage(img: Asset) {
    this.domainModel.deleteGuideImage(this.customPlace.guideId, this.customPlace.id, img.md5Hash).subscribe(_ => {
      this.toastService.publishSuccessMessage('Image Deleted', null);
      this.refreshPlace();
    }, err => {
      console.log(err);
    });
  }

  openRemoveModal() {
    const modalRef = this.modalService.open(
      RemoveSectionComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.componentInstance.suggestionNumber = 1;
    modalRef.result.then(() => {
      this.removePlace();
    }).catch((_) => {
    });
  }

  removePlace() {
    this.guide.contentDescription.delete(this.customPlace.id);
    this.guide.sections.forEach(sec => {
      sec?.ids?.delete(this.customPlace.id);
    });
    this.domainModel.deleteGuidePlace(this.customPlace).subscribe(() => {
      this.domainModel.updateGuide(this.guide).subscribe(() => {
        this.toastService.publishSuccessMessage('Place Deleted!', null);
        this.goBack();
      }, err => {
        console.log(err);
        this.toastService.publishErrorMessage(err, 'Failed to Remove Place');
      });
    }, err => {
      console.log(err);
      this.toastService.publishErrorMessage(err, 'Failed to Update Guide');
    });

  }

  saveChanges() {
    // Validate form to bind all input changes to object
    this.hydrateInputObject.next();
    const loadingMessage = 'Updating your place!';
    this.loadingOpts.addRequest(loadingMessage);
    this.guide.contentDescription.delete(this.customPlace.id);
    this.guide.contentDescription.set(this.customPlace.id, this.customPlace.customDescription);
    const updateGuide = this.domainModel.updateGuide(this.guide);
    const updateGuidPlace = this.domainModel.updateGuidePlace(this.customPlace);
    forkJoin([updateGuidPlace, updateGuide]).subscribe(_ => {
      setTimeout(() => {
        this.canEdit = false;
        this.placeFormGroup.resetAbandonDialogState();
        this.refreshPlace();
        this.loadingOpts.removeRequest(loadingMessage);
      }, 1000);
    }, err => {
      console.log(err);
      this.toastService.publishErrorMessage(err, 'Failed to Update Place');
    });
  }

  refreshPlace() {
    const ids = [];
    ids.push(this.guide.id);
    this.domainModel.getGuides(ids).subscribe(guide => {
      this.domainModel.triggerUpdatedGuideMech(guide[0]);
      this.guide = guide[0];
      this.customPlace = this.guide.customPlaces.find(p => p.id === this.placeIdSubject.getValue());
      this.customPlace.customDescription = this.guide.contentDescription.get(this.placeIdSubject.getValue());
      this.toastService.publishSuccessMessage('Place Updated!', null);
    }, err => {
      console.log(err);
      this.toastService.publishErrorMessage(err, 'Unable to Update Place');
    });
  }

  cancel() {
    this.canEdit = false;
    this.placeFormGroup.resetAbandonDialogState();
  }

  openReorderImageModal() {
    const oldImageOrder = Object.assign([], this.placeImages);
    const modalRef = this.modalService.open(
      ReorderImageModalComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.componentInstance.images = this.placeImages;
    modalRef.result.then((updatedImages) => {
      this.generateImagePriorityMap();
    }, rej => {
      this.placeImages = oldImageOrder;
    }).catch((_) => {
    });
  }

  generateImagePriorityMap() {
    const imagePrioMap = new Map<string, number>();
    this.placeImages.forEach(img => {
      imagePrioMap.set(img.asset.md5Hash, img.sortOrder);
    });
    if (!!this.guide.imageOrderMap.get(this.customPlace.id)) {
      this.guide.imageOrderMap.delete(this.customPlace.id);
    }
    this.guide.imageOrderMap.set(this.customPlace.id, imagePrioMap);
    this.saveChanges();
  }

}
