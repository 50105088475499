import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IFrameMessagingService {

  private iFrameHeight = new BehaviorSubject<string>('Height:100vh');
  public iFrameHeight$ = this.iFrameHeight.asObservable();

  constructor(
  ) {
    window.addEventListener('message', (event) => {
      if (event.data?.toString()?.includes('Height:')) {
        const height = event.data.replace('Height:', '');
        this.iFrameHeight.next(height);
      }
      return;
    }, false);
  }

}
