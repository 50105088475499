import {AbstractControl, ValidatorFn} from '@angular/forms';
import {FormValidator} from '../../../../../models/protocols/form-validator';
import {Searchable} from '../../../../../models/protocols/searchable';

export function searchForValidator(items: Searchable[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (items.find(i => i.lookupKey === control.value) || !control.value) {
      return null
    }
    return { searchFor: 'Item must be contained within search list.' };
  }
}

export class SearchForValidatorDirective implements FormValidator {

  items: Searchable[] = [];

  constructor(i: Searchable[]) {
    this.items = i;
  }

  validate(control: AbstractControl): { [key: string]: any } {
    return searchForValidator(this.items)(control);
  }

  errorName(): string {
    return 'searchFor';
  }
}

