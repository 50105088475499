import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PillItem} from '../../../../../models/shared/stylesheet/pill-item';
import {Asset} from '../../../../../models/image/dto/asset';

/**
 * @param pillItem: uses pill item to determine internal state, else uses below
 * @param clickable: can click, else no
 * @param selected: added to something, else not
 * @param disabled: disabled, else not
 */
@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss']
})
export class PillComponent implements OnInit {

  @Input() pillItem: PillItem;
  @Input() pillSubtext: string = null;
  @Input() pillIcon: Asset = null;
  @Input() disableActiveClass: boolean = false;
  @Input() clickable = true;
  @Input() selected = false;
  @Input() disabled = false;
  @Output() pillClicked = new EventEmitter<[string, boolean]>(true); // name, toggled;
  public text: string;

  constructor() {
  }

  ngOnInit(): void {
    if (this.pillItem) {
      this.clickable = this.pillItem.clickable;
      this.selected = this.pillItem.selected;
      this.disabled = this.pillItem.disabled;
      this.text = this.pillItem.text;
    }
  }

  clicked() {
    if (this.clickable) {
      this.selected = !this.selected;
      this.pillClicked.emit([this.text, this.selected]);
    }
  }

}
