import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, ReplaySubject, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {SessionService} from '../session-service';
import {catchError} from 'rxjs/operators';
import {AccountAPI} from '../../api/account-api';
import 'rxjs-compat/add/operator/map';
import {AccountDomainModel} from '../../domain-models/account-domain-model';
import {Session} from '../../models/account/dto/session';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {

  private refreshObservable: ReplaySubject<Session>;
  private ignoredRequests = ['/sign-in', '/sign-up', '/resend-code', '/reset-forgotten-password', '/confirm-account', '/resend-confirmation-code'];

  constructor(
    private router: Router,
    private session: SessionService,
    private accountAPI: AccountAPI,
    private accountDomainModel: AccountDomainModel
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.ignoredRequests.map(r => request.url.includes(r)).filter(v => v).length > 0) {
      request = this.addAuthenticationToken(request);
      return next.handle(request);
    } else {
      request = this.addAuthenticationToken(request);
      return next.handle(request).pipe(
        catchError(err => {
          if (err?.error?.message === 'Invalid Token!') {
            const req = this.session.getRefreshSessionReq();
            if (req) {
              // Stall all incoming requests that wont have a valid token
              if (!this.refreshObservable) {
                this.refreshObservable = new ReplaySubject<Session>(1);
                this.refreshObservable.bind(this.accountDomainModel.isAuthenticated(true));
              }

              // Refresh Session
              return new Observable<HttpEvent<any>>(subscriber => {
                this.refreshObservable.subscribe((sess) => {
                  if (!sess) {
                    this.router.navigate(['/auth/sign-in']);
                  }
                  this.refreshObservable = null;
                  next.handle(this.addAuthenticationToken(request, sess?.accessToken)).subscribe(response => {
                    subscriber.next(response);
                  }, e => {
                    subscriber.error(e);
                  });
                }, error => {
                  this.refreshObservable = null;
                  this.session.destroySession.next(true);
                  subscriber.error(error);
                });
              });
            } else {
              // Kill session and navigate to logout
              this.session.destroySession.next(true);
            }
          }
          return throwError(err);
        })
      );
    }
  }

  addAuthenticationToken(request, newToken?: string) {
    if (newToken) {
      return request.clone({
        headers: this.createHeaders(newToken, request.headers)
      });
    } else {
      const token = this.session.getAuthToken();
      return request.clone({
        headers: this.createHeaders(token, request.headers)
      });
    }
  }

  private createHeaders(token: string,
                        headers: HttpHeaders,
  ): HttpHeaders {
    if (!headers.get('Content-Type')) {
      headers = headers.append('Content-Type', 'application/json');
    }
    if (!headers.get('Accept')) {
      headers = headers.append('Accept', 'application/json');
    }
    if (!headers.get('UserId') && this.session.getKrugoId()) {
      headers = headers.append('UserId', this.session.getKrugoId());
    }
    if (!headers.get('ClientSecret')) {
      headers = headers.append('ClientSecret', environment.cognitoClientSecret);
    }
    if (!headers.get('Platform')) {
      headers = headers.append('Platform', 'API_Web');
    }
    if (!headers.get('Token') && token) {
      headers = headers.append('Token', token);
    }
    if (!headers.get('DeviceId')) {
      if (!this.session.getDeviceId()) {
        this.session.setDeviceId();
      }
      headers = headers.append('DeviceId', this.session.getDeviceId());
    }
    return headers;
  }
}
