import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {GuidesDomainModel} from '../../../../../domain-models/guides-domain-model';
import {BaseModalViewModel} from '../../../../../models/base/base-modal-view-model';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CreateFlowTitle, CreateGuideFlow} from '../../../../../models/guide/enum/create-guide-flow.enum';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {Guide} from '../../../../../models/guide/dto/guide';
import {GuideAuthor} from '../../../../../models/guide/shared/guide-author';
import {Searchable} from '../../../../../models/protocols/searchable';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {ToastService} from '../../../../../services/toast-service';
import {SortUtils} from '../../../../../utils/sort-utils';


@Injectable()
export class BaseCreateViewModel extends BaseModalViewModel implements OnDestroy {
  guide: Guide = new Guide();
  createFlow: CreateGuideFlow;
  activeModal: NgbActiveModal = null;
  guideAuthors: GuideAuthor[] = null;
  selectedAuthor: GuideAuthor = null;
  cities: Searchable[] = [];
  recentCities: Searchable[] = [];
  selectedCity: Searchable = null;

  // City Search Form
  citySearchItems: FormInputItem[] = [];
  citySearchOptions: FormOptions = new FormOptions();
  citySearchStyling: FormGroupStyling = new FormGroupStyling();

  // Add Guide Info Form
  addGuideInfoItems: FormInputItem[] = [];
  addGuideInfoOptions: FormOptions = new FormOptions();
  addGuideInfoStyling: FormGroupStyling = new FormGroupStyling();
  canSubmitGuide: boolean = false;
  hydrateInputObject: EventEmitter<any> = new EventEmitter<any>();

  loadingOpts: LoadingOptions = LoadingOptions.default();

  constructor(
    private domainModel: GuidesDomainModel,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private toastService: ToastService,
  ) {
    super(router, modalService);
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  ngOnDestroy() {
    this.destroy();
  }

  setupBindings() {
  }

  goBack() {
    if (this.createFlow.valueOf() > 0) {
      this.createFlow = this.createFlow.valueOf() - 1;
    }
  }

  closeModal() {
    this.activeModal.dismiss(null);
  }

  getTitle(): string {
    return CreateFlowTitle(this.createFlow);
  }

  generateGuideAuthors(id: string) {
    this.guideAuthors = [];
    const adminOf = this.domainModel.session.getInsider()?.adminCompanyIds;
    const idIsCompany = adminOf.contains(id);

    if (!idIsCompany) {
      const self = new GuideAuthor();
      self.companyId = null;
      self.krugoId = this.domainModel.session.getKrugoId();
      self.profilePicture = this.domainModel.session.getInsider()?.profilePicture;
      self.subtitle = null;
      self.title = this.domainModel.session.getUser()?.getFullName();
      this.guideAuthors.push(self);
    }

    const insiderCompanies = this.domainModel.session.getInsider()?.companies;
    if (!!insiderCompanies) {
      insiderCompanies.forEach(company => {
        const guideAuthor = new GuideAuthor();
        guideAuthor.title = company?.companyName;
        guideAuthor.profilePicture = company?.profilePicture;
        guideAuthor.companyId = company?.id;
        guideAuthor.krugoId = this.domainModel.session.getKrugoId();
        guideAuthor.subtitle = 'Contributed by ' + this.domainModel.session.getUser()?.getFullName();
        if (idIsCompany) {
          if ((guideAuthor.companyId === id)) {
            this.guideAuthors.push(guideAuthor);
          }
        } else {
          this.guideAuthors.push(guideAuthor);
        }
      });
  }

    if (adminOf.length > 0) {
      adminOf.filter(cId => this.guideAuthors.map(a => a.companyId).unique().contains(cId)).forEach(adminOfCompanyId => {
        const guideAuthor = Object.assign(new GuideAuthor(), this.guideAuthors.find(author => author.companyId === adminOfCompanyId));
        guideAuthor.krugoId = this.guideAuthors.find(author => author.companyId === adminOfCompanyId).companyId;
        guideAuthor.subtitle = null;
        if (idIsCompany) {
          if ((guideAuthor.companyId === id)) {
            this.guideAuthors.push(guideAuthor);
          }
        } else {
          this.guideAuthors.push(guideAuthor);
        }
      });
    }

    // Sort by name
    this.guideAuthors.sort(SortUtils.sortByAuthor);
  }

  authorSelected(author: GuideAuthor) {
    this.guide.companyId = author?.companyId;
    this.guide.insiderId = author?.krugoId;
    this.selectedAuthor = author;
    this.guide.showCompanyInsider = !!author?.subtitle;
    this.getRecentCities();
    this.createFlow = CreateGuideFlow.ChooseCity;
  }

  // City Search Form
  generateCitySearchItems() {
    this.citySearchItems = [];

    const city = new FormInputItem();
    city.itemType = FormItemType.Input;
    city.inputType = FormInputType.Search;
    city.inputName = 'citySearch';
    city.label = 'Choose a city';
    city.hideLabel = true;
    city.valueChanged.notNull().subscribe((val) => {
      this.citySelected(val);
    });
    city.placeholder = 'Choose a city';
    city.bindingProperty = 'cityId';
    city.customError = new Map<string, string>([
      ['searchFor', 'Not a valid city'],
    ]);
    city.searchable = this.cities;
    this.citySearchItems.push(city);
  }

  getCities() {
    const loadingMessage = 'Getting Cities';
    if (!this.loadingOpts.containsRequest(loadingMessage)) {
      this.loadingOpts.addRequest(loadingMessage);
      const cities = this.domainModel.cities.getValue();
      cities.forEach(c => {
        const newCity = {lookupKey: c.city + ', ' + c.state, value: c.id};
        this.cities.push(newCity);
      });
      this.loadingOpts.removeRequest(loadingMessage);
    }
  }

  getRecentCities() {
    this.recentCities = [];
    const recentGuides = this.domainModel.getRecentGuides(this.selectedAuthor?.companyId ?
      this.selectedAuthor.companyId : this.selectedAuthor.krugoId);
    let recentCityIds = [];
    recentGuides?.forEach(g => {
      recentCityIds.push(g.cityId);
    });
    recentCityIds = [...new Set(recentCityIds)];
    const cities = this.domainModel.cities.getValue();
    recentCityIds.forEach(id => {
      const c = cities.find(city => city.id === id);
      if (!!c) {
        const newCity = {lookupKey: c.city + ', ' + c.state, value: c.id};
        this.recentCities.push(newCity);
      }
    });
  }

  citySelected(ev: Searchable) {
    this.selectedCity = ev;
    this.guide.cityId = ev.value;
    this.createFlow = CreateGuideFlow.AddGuideInfo;
  }

  // Add Guide Info Form
  generateAddGuideInfoItems() {
    this.addGuideInfoItems = [];

    const title = new FormInputItem();
    title.itemType = FormItemType.Input;
    title.inputType = FormInputType.Text;
    title.inputName = 'title';
    title.label = 'Title';
    title.hideLabel = true;
    title.customClass = 'add-guide-info-title';
    title.placeholder = 'Add a title...';
    title.bindingProperty = 'title';
    title.required = true;
    this.addGuideInfoItems.push(title);

    const description = new FormInputItem();
    description.itemType = FormItemType.Textarea;
    description.inputType = FormInputType.Text;
    description.inputName = 'description';
    description.label = 'Description';
    description.hideLabel = true;
    description.placeholder = 'Guide Description';
    description.bindingProperty = 'description';
    description.customClass = 'add-guide-description';
    description.autoGrowTextArea = true;
    description.autoGrowMinRows = 2;
    description.autoGrowMaxRows = 11;
    description.required = true;
    this.addGuideInfoItems.push(description);
  }

  guideInfoChanges() {
    let canSubmit = true;
    this.addGuideInfoItems.forEach((fi) => {
      if (!fi.canSubmit()) {
        canSubmit = false;
        return;
      }
    });
    if (canSubmit) {
      // Validate form to bind all input changes to object
      this.hydrateInputObject.next();
    }
    this.canSubmitGuide = canSubmit;
  }

  submitGuide() {
    const loadingMessage = 'Creating your Guide!';
    if (!this.loadingOpts.containsRequest(loadingMessage)) {
      this.loadingOpts.addRequest(loadingMessage);
      this.domainModel.createGuide(this.guide).subscribe(guide => {
        setTimeout(() => {
          this.dismissModalSubject.next(guide);
          this.loadingOpts.removeRequest(loadingMessage);
        }, 1000);
      }, error => {
        this.loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishError(error);
      });
    }
  }


}
