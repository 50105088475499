<div class="modal-body">
  <div class="modal-title">Guide Map</div>
  <div class="map-container my-4">
    <app-map *ngIf="mapMarkers.length === 1"
             [mapMarkers]="mapMarkers"
             [selectedMarker]="mapMarkers[0]"
             [fitBounds]="false"
             [zoomLevel]="17"
             [lng]="mapMarkers[0].getLng()"
             [lat]="mapMarkers[0].getLat()">
    </app-map>
    <app-map *ngIf="mapMarkers.length > 1"
             [mapMarkers]="mapMarkers"
             [selectedMarker]="mapMarkers[0]">
    </app-map>
  </div>
  <div class="footer-button">
    <button (click)="done()" class="custom-button preferred-button">Done</button>
  </div>
</div>
