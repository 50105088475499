import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {GuideViewModel} from './guide-view-model';
import {CustomFile} from '../../../../models/shared/custom-file';
import {GuideSection} from '../../../../models/guide/dto/guide-section';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ComponentCanDeactivate} from '../../../../models/protocols/component-can-deactivate';
import {FormGroupComponent} from '../../../shared/components/form-group/form-group.component';
import {GuideStatus} from '../../../../models/guide/enum/guide-status.enum';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss', '../../../../../scss/stylesheet.scss'],
  providers: [GuideViewModel]
})
export class GuideComponent extends BaseComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  @ViewChild('formGroupComponent') formGroupComponent: FormGroupComponent;

  featuredImage: CustomFile = null;
  private featuredImageRatio = new BehaviorSubject<number>(1);
  public featuredImageRatio$ = this.featuredImageRatio.pipe(distinctUntilChanged(), debounceTime(250));
  GuideStatus: typeof GuideStatus = GuideStatus;

  public popperModifier = {
    flip: {
      behavior: ['right', 'bottom', 'top']
    }
  };
  public popperStyles = {
    'background-color': '#FFFFFF',
    'z-index': 99
  };


  constructor(
    public viewModel: GuideViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupBindings();
  }

  ngOnDestroy() {
    this.destroy();
    this.viewModel.destroy();
  }

  setupBindings() {
  }

  setupViews() {
  }

  renameSection(s: GuideSection) {
    this.viewModel.openRenameModal(s);
  }

  rearrangeSection(s: GuideSection) {
    this.viewModel.openRearrangeModal(s);
  }

  setFeaturedRatio(x) {
    this.featuredImageRatio.next(x);
  }

  removeSection(s: GuideSection) {
    this.viewModel.openRemoveModal(s);
  }

  canDeactivate(): boolean | Promise<any> {
    return !this.formGroupComponent?.shouldDisplayAbandonDialog() ?? true;
  }

}
