import {Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {GuidesDomainModel} from '../../../domain-models/guides-domain-model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BaseCreateGuideComponent} from '../../shared/modals/create-guide/components/base-create-guide/base-create-guide.component';
import {ModalUtils} from '../../../utils/modal-utils';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {debounceTime, map, takeUntil} from 'rxjs/operators';
import {SessionService} from '../../../services/session-service';
import {Guide} from '../../../models/guide/dto/guide';


@Injectable()
export class GuidesViewModel extends BaseViewModel implements OnDestroy {

  private associatedInsiderId = new BehaviorSubject<string>(null);
  public associatedInsiderId$ = combineLatest([
    this.associatedInsiderId,
    this.session.sessionContainer.pipe(map(sess => sess?.insider?.id))
  ]).pipe(debounceTime(1), map(([associated, loggedIn]) => {
      if (!!associated) {
        return associated;
      } else {
        return loggedIn;
      }
    }));
  public companies$ = this.session.sessionContainer.notNull().pipe(map(s => s.insider?.companies));
  public openCreateModal = new Subject<boolean>();
  private openMechanism = combineLatest([
    this.associatedInsiderId$,
    this.openCreateModal,
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
    .subscribe(([id, open]) => {
      if (open) {
        this.openCreateGuideModal(id);
        this.openCreateModal.next(false);
      }
    });

  constructor(
    private domainModel: GuidesDomainModel,
    private session: SessionService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  setupBindings() {
    this.listenForParams();
  }

  private listenForParams() {
    this.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
      this.initFromUrl(params);
    });
  }

  initFromUrl(params: Params) {
    const companyId = params.companyId;
    if (!!companyId) {
      this.associatedInsiderId.next(companyId);
    }
  }

  openCreateGuideModal(id: string) {
    const modalRef = this.modalService.open(
      BaseCreateGuideComponent,
      ModalUtils.defaultModalOptions()
    );
    const compInstance = modalRef.componentInstance as BaseCreateGuideComponent;
    compInstance.associatedId = id;
    modalRef.result.then((guide) => {
      if (guide instanceof Guide) {
        this.router.navigate(
          [`edit/${!!guide.companyId ? guide.companyId : guide.insiderId}/${guide.id}`],
          { relativeTo: this.route }
        ).then(_ => {});
      }
    }).catch((_) => {});
  }

  ngOnDestroy() {
    this.destroy();
  }

}
