<div class="container-fluid">
  <div class="row">
    <div class="auth-wrapper">
      <div class="img-container col-8">
        <img class="img" src="/assets/sign-in/mitch-walker-DEmKSeNvDcs-unsplash.jpg" alt="">
        <div class="city-name-plate" fxLayout="column" fxLayoutAlign="center end">
          <div class="city">Seattle, WA</div>
          <div class="city">Mitch Walker</div>
        </div>
      </div>
      <div class="auth-action-container col-4">
        <!--  Sign In Flow  -->
        <app-sign-in *ngIf="(viewModel.authFlow$ | async | isAuthFlow : authFlows.SignIn)">
        </app-sign-in>
        <!--  Sign Up Flow  -->
        <app-sign-up *ngIf="(viewModel.authFlow$ | async | isAuthFlow : authFlows.SignUp)">
        </app-sign-up>
        <!--  Confirm Flow  -->
        <app-confirm-account *ngIf="(viewModel.authFlow$ | async | isAuthFlow : authFlows.ConfirmAccount)">
        </app-confirm-account>
        <!--  Password Reset Flow  -->
        <app-forgot-password *ngIf="(viewModel.authFlow$ | async | isAuthFlow : authFlows.ForgotPassword)">
        </app-forgot-password>
        <div class="help-container">
          <span>Need help? Shoot us an email at: <a class="text-link" href="mailto:hello@krugoapp.com">hello@krugoapp.com</a></span>
        </div>
      </div>
    </div>
  </div>
</div>
