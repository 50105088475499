import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {InsidersDomainModel} from '../../../domain-models/insiders-domain-model';
import {Insider} from '../../../models/guide/dto/insider';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {LoadingSpinnerSize} from '../../../models/enum/shared/loading-spinner-size.enum';
import {ToastService} from '../../../services/toast-service';
import {DeserializeHelper} from '../../../models/protocols/deserializable';

@Injectable()
export class ProfileViewModel extends BaseViewModel implements OnDestroy {

  updatedUserInsider: Insider = null;
  public loadingOpts: LoadingOptions;
  public profilePictureUploadCompleted = new EventEmitter<boolean>();
  public newProfilePictureFileName: string;

  constructor(
    private dm: InsidersDomainModel,
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupLoadingSpinner();
    this.setupBindings();
  }

  setupBindings() {
    // Listen for updates on insider object
    const insiderSub = this.dm.userInsider$.subscribe(insider => {
      this.updatedUserInsider = DeserializeHelper.deserializeToInstance(Insider, insider);
      // use the same profile picture instance as the original so the bindings dont break
      this.updatedUserInsider.profilePicture = insider.profilePicture;
      if (insider?.profilePicture?.fileName === this.newProfilePictureFileName) {
        this.newProfilePictureFileName = '';
        this.profilePictureUploadCompleted.emit(true);
      }
    });
    this.pushSub(insiderSub);
  }

  updateInsiderObject(i: Insider) {
    this.updatedUserInsider = i;
  }

  updateInsider() {
    this.loadingOpts.isLoading = true;
    this.dm.updateInsider(this.updatedUserInsider).subscribe(result => {
      this.toastService.publishSuccessMessage('Changes have been saved to your profile.', null);
      this.updatedUserInsider = result;
      this.loadingOpts.isLoading = false;
    });
  }

  private setupLoadingSpinner() {
    this.loadingOpts = LoadingOptions.default();
    this.loadingOpts.showLoadingText = false;
    this.loadingOpts.isLoading = false;
    this.loadingOpts.spinnerSize = LoadingSpinnerSize.Medium;
    this.loadingOpts.backgroundColor = '#FCFCFC';
    this.loadingOpts.spinnerColor = '#222222';
  }

  ngOnDestroy() {
    this.destroy();
  }

  updateInsiderProfilePicture(imageData: string) {
    const newFileName = new Date().getTime() + '.png';
    this.dm.updateInsiderProfilePicture(this.updatedUserInsider.id, imageData, newFileName).subscribe((_) => {
      this.newProfilePictureFileName = newFileName;
      this.toastService.publishSuccessMessage('Profile picture uploaded.', null);
    }, (err) => {
      this.toastService.publishError(err);
      this.profilePictureUploadCompleted.emit(true);
    });
  }

}
