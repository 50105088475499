import {KeyValue} from '@angular/common';
import {GuideClickType} from '../models/guide/enum/guide-click-type.enum';

export class KeyValueUtils {
  // Order by descending property key
  static keyAscOrder = (a: KeyValue<GuideClickType, number>, b: KeyValue<GuideClickType, number>): number => {
    return a.key.toString().localeCompare(b.key.toString());
  }

  static guideViewOrder = (a: KeyValue<GuideClickType, number>, b: KeyValue<GuideClickType, number>): number => {
    return b.key.toString().localeCompare(String(a.key.toString() === 'GuideClickType_Guide'));
  }
}
