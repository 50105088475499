<div>
  <app-loading
    [hidden]="!vm?.loadingOpts?.isLoading"
    [options]="vm?.loadingOpts">
  </app-loading>
  <div class="main-title">
    {{vm.updatedCompany?.getFullName()}}
  </div>
  <div class="divider"></div>
  <div class="container mb-5">
    <div class="row mx-0">
      <div class="col-8 mx-auto">
        <div fxLayout="row" fxLayoutAlign=" center">
          <div fxFlex class="settings-title">
            Settings
          </div>
          <button matRipple
                  fxFlex="none" class="custom-button preferred-button float-right"
                  (click)="saveChangesClicked()">
            Save Changes
          </button>
        </div>
        <div class="divider"></div>
        <app-insider-settings
          [insider]="vm.updatedCompany"
          [profilePictureUpdated]="vm.profilePictureUploadCompleted"
          (updateInsider)="updateInsiderObject($event)"
          (imageToUploadChanged)="vm.updateInsiderProfilePicture($event)">
        </app-insider-settings>
      </div>
    </div>
  </div>
  <div class="container mb-5">
    <div class="row mx-0">
      <div class="col-8 mx-auto">
        <div class="divider"></div>
        <app-insider-design
          [insider]="vm.updatedCompany">
        </app-insider-design>
        <div class="divider my-4"></div>
        <app-copy-embed-code
          [title]="'Embed Code'"
          [ctaText]="'Copy code'"
          [insider]="vm.updatedCompany">
        </app-copy-embed-code>
        <div class="divider my-4"></div>
        <button matRipple
                class="custom-button preferred-button float-right"
                (click)="saveChangesClicked()">
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>

