<div [ngClass]="{ 'modal-body': modalStyle }" >
  <div class="modal-subtitle mb-3">
    Choose Author
    <button *ngIf="modalStyle" matRipple (click)="this.close()" style="float: right" class="custom-button text-button">Cancel</button>
  </div>
  <div class="item-list">
    <div *ngFor="let author of this.viewModel.guideAuthors"
         matRipple
         class="item"
         [ngClass]="{'disabled': isCurrentAuthor(author)}"
         (click)="authorSelected(author)">
      <div class="custom-profile-image">
        <app-asset [borderRadius]="'50%'" [asset]="author.profilePicture"></app-asset>
      </div>
      <div class="author-name">
        {{author.title}}
        <div class="author-name-subtext">
          {{author.subtitle}}
        </div>
      </div>
    </div>
  </div>
</div>
