import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {AccountDomainModel} from '../../../domain-models/account-domain-model';
import {AuthFlow, AuthFlowSubtext, AuthFlowTitle} from '../../../models/account/enum/auth-flow.enum';
import {BehaviorSubject, concat, Observable, Subject} from 'rxjs';
import {SignInRequest} from '../../../models/account/requests/sign-in-request';
import {HydratedUser} from '../../../models/account/dto/hydrated-user';
import {SignUpRequest} from '../../../models/account/requests/sign-up-request';
import {ForgotPasswordRequest} from '../../../models/account/requests/forgot-password-request';
import {CodeDeliveryDetails} from '../../../models/account/dto/code-delivery-details';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {ToastService} from '../../../services/toast-service';
import {Checkbox} from '../../../models/shared/stylesheet/checkbox';
import {FormInputItem, FormInputType, FormItemType} from '../../../models/shared/stylesheet/form-input-item';
import {PasswordValidatorDirective} from '../../shared/components/form-group/validators/password-validator.directive';
import {FormOptions} from '../../../models/shared/stylesheet/form-options';
import {FormGroupStyling} from '../../../models/shared/stylesheet/form-group-styling';
import {Insider} from '../../../models/guide/dto/insider';
import {InsidersDomainModel} from '../../../domain-models/insiders-domain-model';
import {concatMap, debounceTime, map, takeUntil} from 'rxjs/operators';


@Injectable()
export class AuthViewModel extends BaseViewModel implements OnDestroy {

  nextAuthFlow: BehaviorSubject<AuthFlow> = new BehaviorSubject<AuthFlow>(null);
  authSuccess: Subject<any> = new Subject<any>();

  signInRequest: BehaviorSubject<SignInRequest> = new BehaviorSubject<SignInRequest>(null);
  signUpRequest: BehaviorSubject<SignUpRequest> = new BehaviorSubject<SignUpRequest>(null);
  forgotPasswordRequest: BehaviorSubject<ForgotPasswordRequest> = new BehaviorSubject<ForgotPasswordRequest>(null);

  loadingOpts: LoadingOptions = LoadingOptions.default();

  // Sign Up Form
  canSubmitSignUpForm: boolean = false;
  hydrateInputObject: EventEmitter<any> = new EventEmitter<any>();
  signUpCheckboxes: Checkbox[] = [];
  signUpFormItems: FormInputItem[] = [];
  signUpReq: SignUpRequest = new SignUpRequest();
  signUpFormOptions: FormOptions = new FormOptions();
  signUpFormStyling: FormGroupStyling = new FormGroupStyling();

  // Auth flow
  public authFlow = new BehaviorSubject<AuthFlow>(AuthFlow.SignIn);
  public authFlow$ = this.authFlow.pipe(debounceTime(100));
  public authFlowTitle$ = this.authFlow.notNull().pipe(map(it => AuthFlowTitle(it)));
  public authFlowSubText$ = this.authFlow.notNull().pipe(map(it => AuthFlowSubtext(it)));


  constructor(
    private accountDomainModel: AccountDomainModel,
    private insiderDomainModel: InsidersDomainModel,
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  setupBindings() {
    // Bind to SessionContainer to navigate to necessary Auth flow
    this.accountDomainModel.session.sessionContainer.notNull()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((sess) => {
        if (!sess?.user?.accountConfirmed) {
          // Navigate the user to the confirm number flow
          this.nextAuthFlow.next(AuthFlow.ConfirmAccount);
        } else if (sess?.user?.session?.validSession() && !!sess?.insider) {
          // Navigate the user to the create page
          this.authSuccess.next(sess.insider);
        } else if (sess?.codeDeliveryDetails) {
          // Navigate the user to the reset password page
          this.nextAuthFlow.next(AuthFlow.ForgotPassword);
        }
      });
  }

  // Auth Methods

  signIn(req: SignInRequest): Observable<HydratedUser> {
    req.username = '+' + req.username;
    this.signInRequest.next(req);
    return this.accountDomainModel.signIn(req)
      .pipe(concatMap( user => this.getInsider(user.krugoId).map(() => user)));
  }

  signUp() {
    const req = this.formatSignupRequest(this.signUpReq);
    this.signUpRequest.next(req);
    const loadingMess = 'Signing you up!';
    if (!this.loadingOpts.containsRequest(loadingMess)) {
      this.loadingOpts.addRequest(loadingMess);
      this.accountDomainModel.signUp(req).subscribe(_ => {
        this.loadingOpts.removeRequest(loadingMess);
      }, error => {
        this.toastService.publishError(error);
        this.loadingOpts.removeRequest(loadingMess);
      });
    }
  }

  sendForgotPasswordCode(req: ForgotPasswordRequest): Observable<CodeDeliveryDetails> {
    req.username = '+' + req.username;
    this.signInRequest.next(null);
    this.signUpRequest.next(null);
    this.forgotPasswordRequest.next(req);
    return this.accountDomainModel.sendForgotPasswordCode(req.username);
  }

  resetForgottenPassword(req: SignUpRequest): Observable<HydratedUser> {
    const populatedReq = new ForgotPasswordRequest();
    populatedReq.username = this.forgotPasswordRequest.value.username;
    populatedReq.password = this.forgotPasswordRequest.value.password;
    populatedReq.deviceId = this.accountDomainModel.session.getDeviceId();
    populatedReq.code = req.confirmationCode;
    return this.accountDomainModel.resetForgottenPassword(populatedReq)
      .pipe(concatMap( user => this.getInsider(user.krugoId).map(() => user)));
  }

  confirmCode(req: SignUpRequest): Observable<HydratedUser> {
    const sessReq = this.signUpRequest.value ?? this.newSignUpRequestFromSignIn();
    sessReq.confirmationCode = req.confirmationCode;
    sessReq.KrugoId = this.accountDomainModel?.session?.sessionContainer?.value?.user?.krugoId;
    return this.accountDomainModel.confirmAccount(sessReq)
      .pipe(concatMap( user => this.getInsider(user.krugoId).map(() => user)));
  }

  resendCode(): Observable<HydratedUser> {
    const req = this.signUpRequest.value;
    return this.accountDomainModel.resendCode(req);
  }

  formatSignupRequest(req: SignUpRequest): SignUpRequest {
    req.phoneNumber = '+' + req.phoneNumber;
    const splitNames = req.firstName.split(' ');
    req.firstName = splitNames[0];
    req.lastName = splitNames[1];
    return req;
  }

  newSignUpRequestFromSignIn(): SignUpRequest {
    const req = new SignUpRequest();
    const sessionUser = this.accountDomainModel.session.getUser();
    req.KrugoId = sessionUser?.krugoId;
    req.phoneNumber = sessionUser?.phoneNumber;
    req.firstName = sessionUser?.firstName;
    req.lastName = sessionUser?.lastName;
    req.password = this.signInRequest.value.password ?? this.signUpRequest.value.password;
    return req;
  }

  // Sign Up Form Init

  signUpFormChanges() {
    let canSubmit = true;
    this.signUpFormItems.forEach((fi) => {
      if (!fi.canSubmit() || !this.signUpCheckboxes.every(ch => ch.checked)) {
        canSubmit = false;
        return;
      }
    });
    if (canSubmit) {
      // Validate form to bind all input changes to object
      this.hydrateInputObject.next();
    }
    this.canSubmitSignUpForm = canSubmit;
  }

  setupSignUpFormItems() {
    this.signUpFormItems = [];

    const fullName = new FormInputItem();
    fullName.itemType = FormItemType.Input;
    fullName.inputType = FormInputType.Text;
    fullName.inputName = 'fullname';
    fullName.label = 'Full Name';
    fullName.hideLabel = true;
    fullName.placeholder = 'Full Name';
    fullName.bindingProperty = 'firstName';
    fullName.required = true;
    this.signUpFormItems.push(fullName);

    const phoneNumInput = new FormInputItem();
    phoneNumInput.itemType = FormItemType.Input;
    phoneNumInput.inputName = 'phoneNumber';
    phoneNumInput.label = 'Phone Number';
    phoneNumInput.hideLabel = true;
    phoneNumInput.placeholder = 'Phone Number';
    phoneNumInput.bindingProperty = 'phoneNumber';
    phoneNumInput.inputType = FormInputType.Tel;
    phoneNumInput.required = true;
    phoneNumInput.inputMask = ['1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.signUpFormItems.push(phoneNumInput);

    const password = new FormInputItem();
    password.itemType = FormItemType.Input;
    password.inputType = FormInputType.Password;
    password.inputName = 'password';
    password.label = 'Password';
    password.hideLabel = true;
    password.placeholder = 'Password';
    password.bindingProperty = 'password';
    password.required = true;
    password.customValidator = new PasswordValidatorDirective();
    this.signUpFormItems.push(password);

    const confirmPassword = new FormInputItem();
    confirmPassword.itemType = FormItemType.Input;
    confirmPassword.inputType = FormInputType.Password;
    confirmPassword.inputName = 'confirmPassword';
    confirmPassword.label = 'Confirm Password';
    confirmPassword.hideLabel = true;
    confirmPassword.placeholder = 'Confirm Password';
    confirmPassword.bindingProperty = 'confirmPassword';
    confirmPassword.required = true;
    confirmPassword.mustMatchInputName = 'password';
    confirmPassword.customValidator = new PasswordValidatorDirective();
    this.signUpFormItems.push(confirmPassword);

    this.signUpCheckboxes = this.generateSignUpCheckboxes();
  }

  generateSignUpCheckboxes(): Checkbox[] {
    const checkboxes = [];
    let openLink = `<a href="https://www.krugoapp.com/terms-of-service">`;
    let closeLink = `</a>`;
    const tosCheckbox = new Checkbox(`I have read the ${openLink}Terms of Service${closeLink}`);
    checkboxes.push(tosCheckbox);
    openLink = `<a href="https://www.krugoapp.com/privacy-policy">`;
    closeLink = `</a>`;
    const privacyCheckbox = new Checkbox(`I have read the ${openLink}Privacy Policy${closeLink}`);
    checkboxes.push(privacyCheckbox);

    return checkboxes;
  }

  getInsider(krugoId: string): Observable<Insider> {
    return this.accountDomainModel.getInsider(krugoId);
  }

}
