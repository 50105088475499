import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {TabBarComponent} from '../../../shared/components/tab-bar/tab-bar.component';
import {SegmentedGuidesViewModel} from './segmented-guides-view-model';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-segmented-guides',
  templateUrl: './segmented-guides.component.html',
  styleUrls: ['./segmented-guides.component.scss'],
  providers: [SegmentedGuidesViewModel],
})
export class SegmentedGuidesComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('tabController') private tabController: TabBarComponent;
  /**
   * can be a company id or an insider id, pulls in guides associated with this id.
   * If left blank, pull in all guides.
   */
  @Input() public associatedInsiderId: string;
  @Input() public fxFill: boolean = false;

  constructor(
    public vm: SegmentedGuidesViewModel,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.vm.setInsiderId(this.associatedInsiderId);
  }

  ngAfterViewInit() {
    this.setupBindings();
  }

  setupBindings() {
    this.setupFragmentCapture();
  }

  private setupFragmentCapture() {
    // Capture the fragment if available and navigates to a specific tab
    const fragSub = this.route.fragment.notNull().subscribe(fragment => {
      switch (fragment.toLowerCase()) {
        case 'drafts': {
          this.vm.updateSelectedTab(0);
          break;
        }
        case 'pending': {
          this.vm.updateSelectedTab(1);
          break;
        }
        case 'approved': {
          this.vm.updateSelectedTab(2);
          break;
        }
        case 'archived': {
          this.vm.updateSelectedTab(3);
          break;
        }
      }
    });
    this.pushSub(fragSub);
    //
    const tabNumberSub = this.vm.selectedTab.notNull().subscribe(n => {
      this.tabController.setSavedTab(n);
    });
    this.pushSub(tabNumberSub);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.associatedInsiderId) {
      this.vm.setInsiderId(this.associatedInsiderId);
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

}
