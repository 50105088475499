import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {MapViewModel} from './map-view-model';
import {MapStyling} from './styling/map-styling';
import {KrugoMapMarker} from './interfaces/krugo-map-marker';
import MapTypeStyle = google.maps.MapTypeStyle;
import {MapPin} from '../../../../models/shared/map-pin';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [MapViewModel],
})
export class MapComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() mapMarkers: KrugoMapMarker[];
  @Input() selectedMarker: KrugoMapMarker;
  @Input() boundsPadding: number = 50;
  @Input() zoomLevel: number = null;
  @Input() lng: number = null;
  @Input() lat: number = null;
  @Input() fitBounds: boolean = true;
  @Input() allowPinDrop: boolean = false;
  @Output() pinDropped = new EventEmitter<MapPin>();

  openedWindow: number = 0;

  constructor(
    public vm: MapViewModel
  ) {
    super();
  }

  ngOnInit(): void {
    this.vm.setSelectedMapMarker(this.selectedMarker);
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  getBoundsPadding(): google.maps.Padding {
    return JSON.parse(`{
      "top": ${this.boundsPadding},
      "bottom": ${this.boundsPadding},
      "left": ${this.boundsPadding},
      "right": ${this.boundsPadding}
    }`);
  }

  getMapStyling(): MapTypeStyle[] {
    return JSON.parse(MapStyling.json());
  }

  mapRdy(map: google.maps.Map) {
    this.vm.setMap(map);
  }

  openWindow(id) {
    this.openedWindow = id;
  }

  isInfoWindowOpen(id) {
    return this.openedWindow === id;
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  mapClicked($event) {
    if (this.allowPinDrop && $event.coords) {
      this.selectedMarker = new MapPin($event.coords.lat, $event.coords.lng);
      this.mapMarkers = [this.selectedMarker];
      this.pinDropped.emit(this.selectedMarker as MapPin);
    }
  }

}
