import { Component, OnInit } from '@angular/core';
import {BaseModal} from '../../../../../models/base/base-modal';
import {GuideFeature} from '../../../../../models/guide/dto/guide-feature';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rearrange-category-modal',
  templateUrl: './rearrange-category-modal.component.html',
  styleUrls: ['./rearrange-category-modal.component.scss',
    '../../../../guides/components/guide/add-place-modal/add-place-modal.component.scss']
})
export class RearrangeCategoryModalComponent extends BaseModal implements OnInit {
  categories: GuideFeature[] = [];
  canSubmit: boolean = true;

  constructor(
    private activeModal: NgbActiveModal
  ) {
    super();
  }

  ngOnInit(): void {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  submit() {
    this.activeModal.close(this.categories);
  }

  setupBindings() {
  }

  setupViews() {
  }

}
