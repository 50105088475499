import {Directive} from '@angular/core';
import {FormValidator} from '../../../../../models/protocols/form-validator';
import {AbstractControl, NG_VALIDATORS, ValidatorFn} from '@angular/forms';

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    if (!value || value === '') {
      return null;
    }
    // validate length
    if (value.length < 6) {
      return {'password': 'Password must be at least 6 characters.'};
    }

    // Validate no white space
    const containsSpaces = {
      pattern: '^[\\S]+$',
      message: 'Password may not contain spaces.'
    };
    const spacesRegexp = new RegExp(containsSpaces.pattern);
    if (!spacesRegexp.test(value)) {
      return {'password': containsSpaces.message};
    }

    // validate lower case
    const containsLowercase = {
      pattern: '(?=.*[a-z])',
      message: 'Password must contain a lower case character.'
    };
    const containsLowercaseRegexp = new RegExp(containsLowercase.pattern);
    if (!containsLowercaseRegexp.test(value)) {
      return {'password': containsLowercase.message};
    }

    // validate number
    const containsNumber = {
      pattern: '(?=.*\\d)',
      message: 'Password must contain a number.'
    };
    const containsNumberRegexp = new RegExp(containsNumber.pattern);
    if (!containsNumberRegexp.test(value)) {
      return {'password': containsNumber.message};
    }

    return null;
  };
}


@Directive({
  selector: '[appPasswordValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true}]

})
export class PasswordValidatorDirective implements FormValidator {
  validate(control: AbstractControl): { [key: string]: any } {
    return passwordValidator()(control);
  }

  errorName(): string {
    return 'password';
  }
}
