import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../../models/base/base-component';
import {BaseCreateViewModel} from '../../viewmodels/base-create-view-model';

@Component({
  selector: 'app-add-guide-info',
  templateUrl: './add-guide-info.component.html',
  styleUrls: ['./add-guide-info.component.scss',
    './../../../../../../../scss/stylesheet.scss',
    './../base-create-guide/base-create-guide.component.scss'
  ]
})
export class AddGuideInfoComponent extends BaseComponent implements OnInit {

  constructor(
    public viewModel: BaseCreateViewModel
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupBindings();
    this.setupViews();
  }

  setupBindings() {
  }

  setupViews() {
    this.viewModel.generateAddGuideInfoItems();
  }

}
