<div>
  <div class="main-title">
    Profile
  </div>
  <div class="divider"></div>
  <div class="container mb-5">
    <div class="row mx-0">
      <div class="col-8 mx-auto">
        <app-loading
          [hidden]="!vm?.loadingOpts?.isLoading"
          [options]="vm?.loadingOpts">
        </app-loading>
        <app-insider-settings
          [insider]="vm.updatedUserInsider"
          [profilePictureUpdated]="vm.profilePictureUploadCompleted"
          (updateInsider)="updateInsiderObject($event)"
          (imageToUploadChanged)="vm.updateInsiderProfilePicture($event)">
        </app-insider-settings>
        <div class="divider my-4"></div>
        <button matRipple
                class="custom-button preferred-button float-right"
                (click)="saveChangesClicked()">
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>

