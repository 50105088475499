import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {InsightsDomainModel} from '../../../../domain-models/insights-domain-model';
import {ActivatedRoute, Params} from '@angular/router';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {SessionService} from '../../../../services/session-service';
import {ModalUtils} from '../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InsightInfoModalComponent} from './insight-info-modal/insight-info-modal.component';

@Injectable()
export class InsightsViewModel extends BaseViewModel {

  private associatedInsiderId = new BehaviorSubject<string>(null);
  public associatedInsiderId$ = this.associatedInsiderId.asObservable();
  public companyIds$ = this.session.sessionContainer.notNull().pipe(map(s => s.insider?.companies?.map(c => c.id)));
  public insiderId$ = this.session.sessionContainer.notNull().pipe(map(s => s.insider?.id));

  // page title
  public pageTitle$ = combineLatest([
    this.associatedInsiderId$,
    this.session.sessionContainer.notNull().pipe(map(s => s.insider))
  ]).pipe(map(([id, insider]) => {
    if (insider?.id === id) {
      return insider.getFullName();
    } else {
      if (!!insider?.companies && insider?.companies?.length > 0) {
        return insider.companies.find(c => c.id === id)?.getFullName();
      }
    }
  }));

  constructor(
    private dm: InsightsDomainModel,
    private session: SessionService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  setupBindings() {
    this.listenForParams();
  }

  private listenForParams() {
    this.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
      this.initFromUrl(params);
    });
  }

  initFromUrl(params: Params) {
    const companyId = params.companyId;
    if (!!companyId) {
      this.associatedInsiderId.next(companyId);
    }
  }

  openInsightInfoModal() {
    const modalRef = this.modalService.open(
      InsightInfoModalComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.result.then(() => {
    }).catch((_) => {
    });
  }

}
