<div class="search-guides-container" fxLayout="column">
  <div class="filter-container" fxLayout="row" fxLayoutAlign=" center">
    <app-search-bar #searchBarComponent class="search-bar-width" (search)="vm.searchString.next($event)"></app-search-bar>
    <app-tag-pill-filter class="tag-bar"
                         [pillsIn]="vm.tags$|async"
                         (pillOut)="vm.filterTagSelected($event)"
                         [style.overflow-x]="'auto'"
                         [fxFlex]="'grow'">
      Search Filters
    </app-tag-pill-filter>
  </div>
  <app-guides-grid class="block mt-32px mb-32px"
                   [associatedInsiderId]="vm.associatedInsiderId$|async"
                   [type]="vm.guidesGridType$|async"
                   [guides]="vm.filteredGuides$|async"
                   [hidden]="(vm.filteredGuides$|async)?.length < 1">
  </app-guides-grid>
  <app-segmented-empty-state #emptyState
                             [hidden]="(vm.filteredGuides$|async)?.length > 0"
                             [loading]="vm.loadingGuides$|async"
                             [emptyStateHeight]="'50vh'"
                             [title]="vm.emptyStateTitle$|async"
                             [desc]="vm.emptyStateDesc$|async"
                             [buttonText]="vm.emptyStateButton$|async"
                             (buttonClicked)="vm.emptyStateButtonPress.next(true)">
  </app-segmented-empty-state>
</div>
