
export enum CreateGuideFlow {
  ChooseAuthor,
  ChooseCity,
  AddGuideInfo,
}

export function CreateFlowTitle(cf: CreateGuideFlow): string {
  switch (cf) {
    case CreateGuideFlow.AddGuideInfo:
      return 'Add Guide Info';
    case CreateGuideFlow.ChooseCity:
      return 'Create Guide';
    case CreateGuideFlow.ChooseAuthor:
      return 'Create Guide';
  }
}
