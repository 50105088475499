import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';
import {FontViewModel} from './font-view-model';
import {BaseDirective} from '../../../../models/base/base-directive';
import {FontHierarchy} from '../../../../models/enum/shared/font-hierarchy.enum';
import {FontType} from '../../../../models/enum/shared/font-type.enum';
import {takeUntil} from 'rxjs/operators';
import {Font} from '../../../../models/enum/shared/font.enum';

@Directive({
  selector: '[appFont]',
  providers: [FontViewModel]
})
export class FontDirective extends BaseDirective
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() primary: Font;
  @Input() secondary: Font;
  @Input() hierarchy: FontHierarchy = FontHierarchy.Primary;
  @Input() type: FontType = FontType.Regular;
  @Output() fontName = new EventEmitter<string>(true);

  constructor(
    public vm: FontViewModel,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.vm.setPrimary(this.primary);
    this.vm.setSecondary(this.secondary);
    this.vm.setHierarchy(this.hierarchy);
    this.vm.setType(this.type);
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.vm.fontClass
      .pipe(takeUntil(this.onDestroy))
      .subscribe(fontClass => {
      if (fontClass) {
        this.renderer.addClass(this.el.nativeElement, fontClass);
      }
    });

    this.vm.font$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(font => this.fontName.emit(font));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.primary) {
      this.vm.setPrimary(this.primary);
    }
    if (changes.secondary) {
      this.vm.setSecondary(this.secondary);
    }
    if (changes.type) {
      this.vm.setType(this.type);
    }
    if (changes.hierarchy) {
      this.vm.setHierarchy(this.hierarchy);
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
