import {ChartDataPoint} from './chart-data-point';
import {Deserializable} from '../../../../../models/protocols/deserializable';

export class ChartData implements Deserializable {
  name: string;
  series: ChartDataPoint[];

  constructor(name?: string) {
    this.name = name;
  }

  onDeserialize() {
  }
}
