import {HydratedUser} from '../account/dto/hydrated-user';
import {DateUtils} from '../../utils/date-utils';
import {CodeDeliveryDetails} from '../account/dto/code-delivery-details';
import {Cachable} from '../protocols/cachable';
import {DefaultCacheKey} from '../enum/shared/default-cache-key.enum';
import {DeserializeHelper} from '../protocols/deserializable';
import {CachePolicy} from '../enum/shared/cachable-image-policy.enum';
import {Insider} from '../guide/dto/insider';

export class SessionContainer implements Cachable {
  // Shared properties
  public user: HydratedUser;
  public codeDeliveryDetails: CodeDeliveryDetails;
  public insider: Insider;
  // Session Settings
  public sessionStartTime: number;
  public rememberSession: boolean;
  public cachedTime: number;

  constructor(user?: HydratedUser) {
    this.user = user;
  }

  public validSession(): boolean {
    let valid = false;
    if (this.user &&
      this.user.session &&
      this.user.session.validSession()) {
      valid = true;
    }
    return valid;
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneWeek();
  }

  cacheKey(): string {
    return DefaultCacheKey.SessionContainer;
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Service;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  onDeserialize() {
    this.user = DeserializeHelper.deserializeToInstance(HydratedUser, this.user);
    this.codeDeliveryDetails = DeserializeHelper.deserializeToInstance(CodeDeliveryDetails, this.codeDeliveryDetails);
    this.insider = DeserializeHelper.deserializeToInstance(Insider, this.insider);
  }

}
