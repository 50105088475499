import {Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {GuidesDomainModel} from '../../../../../domain-models/guides-domain-model';
import {ChartDataAs} from '../models/chart-data-as.enum';
import {GuideClickType} from '../../../../../models/guide/enum/guide-click-type.enum';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, shareReplay, tap} from 'rxjs/operators';
import {ChartData} from '../models/chart-data';
import {KrugoDateRange} from '../krugo-date-picker/models/krugo-date-range';
import {DistinctUtils} from '../../../../../utils/distinct.utils';
import {InsightsDomainModel} from '../../../../../domain-models/insights-domain-model';
import {InsiderInsightReq} from '../../../../../models/guide/requests/insider-insight-req';
import {DateUtils} from '../../../../../utils/date-utils';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {InsiderInsight} from '../../../../../models/guide/dto/insider-insight';

@Injectable()
export class InsiderInsightChartViewModel extends BaseViewModel implements OnDestroy {

  // Popper
  public popperModifier = {
    flip: {
      behavior: ['right', 'bottom', 'top']
    }
  };
  public popperStyles = {
    'background-color': '#FFFFFF',
    padding: 0
  };

  // Loading Spinner
  public loadingOpts: LoadingOptions = LoadingOptions.default();

  // Selected Insight Type
  private selectedClickType = new BehaviorSubject<GuideClickType>(GuideClickType.Guide);
  public selectedClickType$ = this.selectedClickType.asObservable();

  // Date range
  private selectedDateRange = new BehaviorSubject<KrugoDateRange>(KrugoDateRange.last30Days());
  public dateRangeString$ = this.selectedDateRange.notNull()
    .pipe(
      distinctUntilChanged(DistinctUtils.distinctKrugoDateRange),
      map(dates => dates.getDateRangeString()),
    );

  // Chart Data
  private insiderId = new BehaviorSubject<string>(null);
  private companyId = new BehaviorSubject<string>(null);
  private dataAs = new BehaviorSubject<ChartDataAs>(ChartDataAs.GuideViews);
  /** returns list because chart can technically plot multiple insights on one graph - future proofing. */
  private insiderInsights = combineLatest([
    this.insiderId,
    this.companyId,
    this.selectedDateRange.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctKrugoDateRange)),
    this.insightsDomainModel.insiderInsights$
  ]).pipe(
      debounceTime(1),
      map(([insiderId, companyId, dates, insights]) => {
        let key = '';
        if (!!insiderId) {
          key = DateUtils.buildDateKey(insiderId, dates.getDateRangeString());
        } else if (!!companyId) {
          key = DateUtils.buildDateKey(companyId, dates.getDateRangeString());
        }
        if (!!insights && !!key) {
          const insight = insights.get(key);
          if (!!insight) {
            return [insight];
          } else {
            if (!!insiderId) {
              const getDataReq = new InsiderInsightReq(false, insiderId, dates.getDateRangeString(), dates.startDate, dates.endDate, false);
              this.insightsDomainModel.addInsiderInsightReq(getDataReq);
            } else if (!!companyId) {
              const getDataReq = new InsiderInsightReq(true, companyId, dates.getDateRangeString(), dates.startDate, dates.endDate, false);
              this.insightsDomainModel.addInsiderInsightReq(getDataReq);
            }
          }
        }
        return null;
      }),
      shareReplay(1)
    );
  public insiderInsights$ = this.insiderInsights;
  public insightsLoaded$ = this.insiderInsights.pipe(map(insights => !!insights));
  /** Determines what cards are shown above chart (guide views, social media view, etc) */
  public clickMap$ = this.insiderInsights.pipe(map(insights => {
    if (!!insights && insights.length > 0) {
      const total = InsiderInsight.emptyMap();
      insights[0]?.totalClickMap?.forEach((parentValue, _) => {
        parentValue?.forEach((childValue, childKey) => {
          total.set(childKey, (total.get(childKey) + childValue));
        });
      });
      if (total) { return total; } else { return InsiderInsight.emptyMap(); }
    } else {
      return InsiderInsight.emptyMap();
    }
  }));
  public chartData$ = combineLatest([
    this.dataAs,
    this.insiderInsights,
    this.guidesDomainModel.allGuides$
  ]).pipe(debounceTime(1), map(([dataAs, insiderInsights, guides]) => {
    if (!!insiderInsights && insiderInsights.length > 0 && !!guides) {
      const totalData: ChartData[] = insiderInsights.map(insight => {
        switch (dataAs) {
          case ChartDataAs.GuideViews: return insight.translateGuideViewsToChartData();
          case ChartDataAs.WebsiteViews: return insight.translateWebsiteViewsToChartData();
          case ChartDataAs.FacebookViews: return insight.translateFacebookViewsToChartData();
          case ChartDataAs.InstagramViews: return insight.translateInstagramViewsToChartData();
          case ChartDataAs.TwitterViews: return insight.translateTwitterViewsToChartData();
          case ChartDataAs.Aggregation: return [insight.translateToAggregateChartData()];
          case ChartDataAs.Separate: return insight.translateToSeparateLineChartData();
        }
      }).flatMap(data => data);
      return totalData;
    } else {
      return [] as ChartData[];
    }
  }));

  constructor(
    private guidesDomainModel: GuidesDomainModel,
    private insightsDomainModel: InsightsDomainModel
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupLoadingSpinner();
  }

  private setupLoadingSpinner() {
    this.loadingOpts.isLoading = true;
    this.loadingOpts.backgroundColor = '#FCFCFC';
    this.loadingOpts.showLoadingText = true;
    this.loadingOpts.spinnerColor = '#222222';
    this.loadingOpts.loadingText = 'Loading Insights';
    this.loadingOpts.color = '#222222';
  }

  setChartDataAs(x: ChartDataAs) {
    this.dataAs.next(x);
  }

  setCompanyId(id: string) {
    this.companyId.next(id);
  }

  setInsiderId(id: string) {
    this.insiderId.next(id);
  }

  changeSelectedClickType(type: GuideClickType) {
    this.selectedClickType.next(type);
    switch (type) {
      case GuideClickType.Guide: this.dataAs.next(ChartDataAs.GuideViews); break;
      case GuideClickType.Facebook: this.dataAs.next(ChartDataAs.FacebookViews); break;
      case GuideClickType.Instagram: this.dataAs.next(ChartDataAs.InstagramViews); break;
      case GuideClickType.Twitter: this.dataAs.next(ChartDataAs.TwitterViews); break;
      case GuideClickType.Website: this.dataAs.next(ChartDataAs.WebsiteViews); break;
    }
  }

  datesChosen(dates: any) {
    this.selectedDateRange.next(new KrugoDateRange(dates.chosenLabel, dates.startDate, dates.endDate));
  }

  ngOnDestroy() {
    this.destroy();
  }

}
