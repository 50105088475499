import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {HttpClient} from '@angular/common/http';
import {Insider} from '../models/guide/dto/insider';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {PlaceCategory} from '../models/guide/dto/place-category';
import {City} from '../models/shared/city';
import {AutoCompletedLocation} from '../models/shared/auto-completed-location';

@Injectable({
  providedIn: 'root'
})
export class SharedAPI implements LoggableAPI {
  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
    private http: HttpClient,
  ) {
  }

  // Variables

  public serviceName = 'Shared';

  public GetPlaceCategories(): Observable<PlaceCategory[]> {
    const url = Endpoints.GetPlaceCategories();
    return this.apiClient.getArr(PlaceCategory, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetPlaceCategories', err));
        return throwError(err);
      })
    );
  }

  public GetCities(countryCode: string): Observable<City[]> {
    const url = Endpoints.GetCities(countryCode);
    return this.apiClient.getArr(City, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCities', err));
        return throwError(err);
      })
    );
  }

  public AutoCompleteLocation(lat, lng: number, lookup: string): Observable<AutoCompletedLocation[]> {
    const url = Endpoints.AutoCompleteLocation(lat, lng, lookup);
    return this.apiClient.getArr(AutoCompletedLocation, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AutoCompleteLocation', err));
        return throwError(err);
      })
    );
  }

}
