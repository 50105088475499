import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {GuidesDomainModel} from '../../../../../domain-models/guides-domain-model';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AutoCompletedLocation} from '../../../../../models/shared/auto-completed-location';
import {Subject} from 'rxjs';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {CustomPlace} from '../../../../../models/guide/dto/custom-place';
import {Searchable} from '../../../../../models/protocols/searchable';
import {HydratedGuide} from '../../../../../models/guide/dto/hydrated-guide';
import {AddPlaceFlow, AddPlaceFlowTitle} from '../../../../../models/guide/enum/add-place-flow.enum';
import {GuideSection} from '../../../../../models/guide/dto/guide-section';
import {City} from '../../../../../models/shared/city';
import {ConfirmationOptions} from '../../../../../models/shared/stylesheet/confirmation-options';
import {ConfirmationModalComponent} from '../../../../shared/components/confirmation-modal/confirmation-modal.component';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LabelGroupItem} from '../../../../../models/shared/stylesheet/label-group-item';
import {Coordinates} from '../../../../../models/shared/coordinates';
import {MapPin} from '../../../../../models/shared/map-pin';
import {KrugoMapMarker} from '../../../../shared/components/map/interfaces/krugo-map-marker';

@Injectable()
export class AddPlaceViewModel extends BaseViewModel implements OnDestroy {

  guide: HydratedGuide;
  addPlaceFlow: AddPlaceFlow;
  loadingOpts: LoadingOptions = LoadingOptions.default();

  // Place Searching
  searchOptions: FormOptions = new FormOptions();
  searchStyling: FormGroupStyling = new FormGroupStyling();
  searchItems: FormInputItem[] = [];
  autoCompletedLocations: AutoCompletedLocation[];
  searchLoadingOpts: LoadingOptions = new LoadingOptions();
  guideCity: City = null;
  labelGroupItems: LabelGroupItem[] = [];
  customPlacePin: MapPin = new MapPin(0, 0);
  customPinFormItems: FormInputItem[] = [];
  formStyling: FormGroupStyling = new FormGroupStyling();
  formOptions: FormOptions = new FormOptions();
  hydrateCustomPinObject: EventEmitter<any> = new EventEmitter<any>();
  canAddCustomPin: boolean = false;

  // Place Info
  placeInfoOptions: FormOptions = new FormOptions();
  placeInfoStyling: FormGroupStyling = new FormGroupStyling();
  placeInfoItems: FormInputItem[] = [];
  placeCategories: Searchable[] = [];
  hydratePlaceInfoObject: EventEmitter<any> = new EventEmitter<any>();
  canAddToGuide: boolean = false;

  // Add Section
  addSectionOptions: FormOptions = new FormOptions();
  addSectionStyling: FormGroupStyling = new FormGroupStyling();
  addSectionItems: FormInputItem[] = [];
  hydrateSectionObject: EventEmitter<any> = new EventEmitter<any>();
  canCreateNewSection: boolean = false;
  newSection = new GuideSection();

  newCustomPlace: CustomPlace = null;
  createdCustomPlaceId: string = null;
  newCustomPlaceDescription: string = null;
  mapMarkers: KrugoMapMarker[] = [];
  placeCreated: EventEmitter<{ updatedGuide: HydratedGuide, newPlaceId: string }> =
    new EventEmitter<{ updatedGuide: HydratedGuide, newPlaceId: string }>();

  private debouncer: Subject<string> = new Subject<string>();

  constructor(
    private guidesDomainModel: GuidesDomainModel,
    private modalService: NgbModal,
  ) {
    super();
    this.debouncer.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(val => {
        this.getAutoCompletedLocations(val);
      });
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  setupBindings() {
  }

  goBack() {
    if (this.addPlaceFlow.valueOf() > 0) {
      const backCount = this.customPlacePin.isValid() ? 1 : 2;
      this.addPlaceFlow = this.addPlaceFlow.valueOf() - backCount;
    }
  }

  generateSearchItems() {
    this.searchItems = [];

    const searchInput = new FormInputItem();
    searchInput.itemType = FormItemType.Input;
    searchInput.inputType = FormInputType.Text;
    searchInput.label = 'Place';
    searchInput.hideLabel = true;
    searchInput.required = true;
    searchInput.inputName = 'Place';
    searchInput.placeholder = 'Search for a place';
    searchInput.customClass = 'auto-complete-search-input';
    this.searchItems.push(searchInput);

  }

  generatePlaceInfoItems() {
    this.placeInfoItems = [];
    this.placeInfoOptions.performNonEmptyInitialValidation = false;

    const description = new FormInputItem();
    description.itemType = FormItemType.Textarea;
    description.inputType = FormInputType.Text;
    description.label = 'Description';
    description.hideLabel = true;
    description.bindingProperty = 'customDescription';
    description.inputName = 'Description';
    description.required = true;
    description.placeholder = 'Write a description...';
    description.customClass = 'add-place-description';
    description.autoGrowTextArea = true;
    description.autoGrowMinRows = 2.5;
    description.autoGrowMaxRows = 9;
    this.placeInfoItems.push(description);

    const website = new FormInputItem();
    website.itemType = FormItemType.Input;
    website.inputType = FormInputType.Text;
    website.label = 'Website';
    website.inputName = 'Website';
    website.hideLabel = true;
    website.bindingProperty = 'url';
    website.placeholder = 'Add Website URL (Optional)';
    this.placeInfoItems.push(website);

    const facebookUrl = new FormInputItem();
    facebookUrl.itemType = FormItemType.Input;
    facebookUrl.inputType = FormInputType.Text;
    facebookUrl.label = 'Facebook';
    facebookUrl.inputName = 'Facebook';
    facebookUrl.hideLabel = true;
    facebookUrl.bindingProperty = 'facebookUrl';
    facebookUrl.placeholder = 'Add Facebook URL (Optional)';
    this.placeInfoItems.push(facebookUrl);

    const instagramUrl = new FormInputItem();
    instagramUrl.itemType = FormItemType.Input;
    instagramUrl.inputType = FormInputType.Text;
    instagramUrl.label = 'Instagram';
    instagramUrl.inputName = 'Instagram';
    instagramUrl.hideLabel = true;
    instagramUrl.bindingProperty = 'instagramUrl';
    instagramUrl.placeholder = 'Add Instagram URL (Optional)';
    this.placeInfoItems.push(instagramUrl);

    const twitterUrl = new FormInputItem();
    twitterUrl.itemType = FormItemType.Input;
    twitterUrl.inputType = FormInputType.Text;
    twitterUrl.label = 'Twitter';
    twitterUrl.inputName = 'Twitter';
    twitterUrl.hideLabel = true;
    twitterUrl.bindingProperty = 'twitterUrl';
    twitterUrl.placeholder = 'Add Twitter URL (Optional)';
    this.placeInfoItems.push(twitterUrl);

    const address = new FormInputItem();
    address.itemType = FormItemType.Input;
    address.inputType = FormInputType.Text;
    address.label = 'Address';
    address.inputName = 'Address';
    address.hideLabel = true;
    address.bindingProperty = 'address';
    address.editable = false;
    this.placeInfoItems.push(address);
  }

  generateSearchLoadingOptions() {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'rgba(222, 222, 222, 0)';
    opt.topMarginRem = -18;
    opt.spinnerColor = '#FFCE00';
    opt.color = '#333333';
    opt.showLoadingText = true;
    opt.fullscreen = false;
    opt.init();
    this.searchLoadingOpts = opt;
  }

  searchChanged() {
    if (this.searchItems[0].getValue().length >= 3) {
      this.debouncer.next(this.searchItems[0].getValue());
    }
  }

  populateGuideCity() {
    this.guideCity = this.guidesDomainModel.cities.getValue().find(city => city.id === this.guide.cityId);
  }

  getAutoCompletedLocations(searchQuery: string) {
    const loadingMessage = 'Searching for your place';
    if (!this.searchLoadingOpts.containsRequest(loadingMessage)) {
      this.searchLoadingOpts.addRequest(loadingMessage);
      this.guidesDomainModel.autoCompleteLocation(this.guideCity.lat, this.guideCity.lng, searchQuery).subscribe(places => {
        this.autoCompletedLocations = places;
        this.searchLoadingOpts.removeRequest(loadingMessage);
      }, error => {
        console.log(error);
        this.searchLoadingOpts.removeRequest(loadingMessage);
      });
    }
  }

  placeClicked(place: AutoCompletedLocation) {
    this.generateCustomPlace(place);
  }

  generateCustomPlace(place: AutoCompletedLocation) {
    const cp = new CustomPlace();
    cp.address = place.address;
    cp.city = place.city;
    cp.guideId = this.guide.id;
    cp.coordinates = place.coordinates;
    cp.name = place.name;
    cp.countryCode = place.countryCode;
    cp.stateCode = place.state;
    cp.categoryIds = [];
    this.newCustomPlace = cp;
    this.mapMarkers.push(cp);
    this.addPlaceFlow = AddPlaceFlow.PlaceInfo;
    this.generatePlaceInfoItems();
  }

  generateCustomPlaceFromPin() {
    const cp = new CustomPlace();
    cp.guideId = this.guide.id;
    cp.coordinates = new Coordinates(this.customPlacePin.getLat(), this.customPlacePin.getLng());
    cp.name = this.customPlacePin?.pinName ?? 'Unnamed Custom Place';
    cp.categoryIds = [];
    this.newCustomPlace = cp;
    this.mapMarkers.push(cp);
    this.addPlaceFlow = AddPlaceFlow.PlaceInfo;
    this.generatePlaceInfoItems();
  }

  getPlaceCategories() {
    const categories = this.guidesDomainModel.placeCategories.getValue();
    categories.forEach(c => {
      const newCat = {lookupKey: c.parentName, value: c.parentId};
      this.placeCategories.push(newCat);
    });
    this.placeCategories.sort((a, b) => a.lookupKey.localeCompare(b.lookupKey));
  }

  placeInfoChanged() {
    let canSubmit = true;
    this.placeInfoItems.forEach((fi) => {
      if (!fi.canSubmit()) {
        canSubmit = false;
        return;
      }
    });
    if (canSubmit) {
      // Validate form to bind all input changes to object
      this.hydratePlaceInfoObject.next();
      this.canAddToGuide = canSubmit;
      this.newCustomPlaceDescription = this.placeInfoItems[0].getValue();
    }
  }

  categorySelected(cat: Searchable) {
    this.newCustomPlace.categoryIds.push(cat.value);
  }

  addPlaceToGuide() {
    const loadingMessage = 'Adding this place to your guide!';
    if (!this.loadingOpts.containsRequest(loadingMessage)) {
      this.loadingOpts.addRequest(loadingMessage);
      this.guidesDomainModel.createGuidePlace(this.newCustomPlace).subscribe(guide => {
        if (guide.sections && guide.sections?.length > 0) {
          const assignedPlaceIds = guide.sections.reduce((acc, val) => [...acc, ...val?.ids.keys()], []);
          guide.customPlaces.forEach(p => {
            if (!assignedPlaceIds.contains(p.id)) {
              this.createdCustomPlaceId = p.id;
            }
          });
        } else { // else there is only one custom place in the guide
          this.createdCustomPlaceId = guide.customPlaces[0].id;
        }
        this.loadingOpts.removeRequest(loadingMessage);
      }, err => {
        console.log(err);
        this.loadingOpts.removeRequest(loadingMessage);
      });
    }
    this.addPlaceFlow = AddPlaceFlow.AssignSection;
  }

  getTitle(): string {
    if (this.addPlaceFlow === AddPlaceFlow.PlaceInfo) {
      return this.newCustomPlace?.name;
    }
    return AddPlaceFlowTitle(this.addPlaceFlow);
  }

  addNewSectionFlow() {
    this.addPlaceFlow = AddPlaceFlow.CreateSection;
    this.generateNewSectionItems();
  }

  generateNewSectionItems() {
    this.addSectionItems = [];
    const section = new FormInputItem();
    section.required = true;
    section.label = 'Section';
    section.placeholder = 'Section Name';
    section.hideLabel = true;
    section.itemType = FormItemType.Input;
    section.inputType = FormInputType.Text;
    section.bindingProperty = 'title';
    section.inputName = 'Section';
    this.addSectionItems.push(section);
  }

  sectionTitleChanged() {
    let canSubmit = true;
    this.addSectionItems.forEach((fi) => {
      if (!fi.canSubmit()) {
        canSubmit = false;
        return;
      }
    });
    if (canSubmit) {
      // Validate form to bind all input changes to object
      this.hydrateSectionObject.next();
      this.canCreateNewSection = canSubmit;
    }
  }

  createNewSection() {
    if (this.guide.sections) {
      this.guide.sections.push(this.newSection);
    } else {
      this.guide.sections = [];
      this.guide.sections.push(this.newSection);
    }
    this.sectionSelected(this.newSection);
    this.newSection = new GuideSection();
  }

  sectionSelected(s: GuideSection) {
    const highestSortOrder = Math.max(...s.ids.values());
    if (!!this.createdCustomPlaceId) {
      const customPlaceIdCopy = `${this.createdCustomPlaceId}`;
      s.ids.set(customPlaceIdCopy, highestSortOrder + 1);
      if (this.guide.contentDescription) {
        this.guide.contentDescription.set(customPlaceIdCopy, this.newCustomPlaceDescription);
      } else {
        this.guide.contentDescription = new Map<string, string>();
        this.guide.contentDescription.set(customPlaceIdCopy, this.newCustomPlaceDescription);
      }
      const loadingMessage = 'Updating your Guide!';
      if (!this.loadingOpts.containsRequest(loadingMessage)) {
        this.loadingOpts.addRequest(loadingMessage);
        this.guidesDomainModel.updateGuide(this.guide).subscribe(updatedGuide => {
          this.loadingOpts.removeRequest(loadingMessage);
          this.placeCreated.emit({newPlaceId: customPlaceIdCopy, updatedGuide});
          this.destroy();
          this.createdCustomPlaceId = null;
          this.guide = null;
        }, err => {
          console.log(err);
          this.loadingOpts.removeRequest(loadingMessage);
        });
      }
    }
  }

  checkForDuplicatePlace() {
    let potentialDuplicate: boolean;
    if (this.newCustomPlace.address) {
      potentialDuplicate = this.guide.customPlaces.some(p => p.address === this.newCustomPlace.address);
    } else {
      potentialDuplicate = this.guide.customPlaces.some(p => p.coordinates.lat === this.newCustomPlace.coordinates.lat &&
        p.coordinates.lng === this.newCustomPlace.coordinates.lng );
    }
    if (potentialDuplicate &&
      (this.guide.customPlaces.find(p => p.name === this.newCustomPlace.name))) {
      const modalOptions = new ConfirmationOptions();
      modalOptions.title = 'Duplicate Place';
      modalOptions.bodyText = 'Looks like this place has already been added to your guide! We recommend that a ' +
        'place is only added to a guide once.';
      modalOptions.cancelButtonClass = 'grey-button';
      modalOptions.continueButtonClass = 'preferred-button';
      modalOptions.cancelText = 'Cancel';
      modalOptions.continueText = 'Add Anyway';

      const modalRef = this.modalService.open(
        ConfirmationModalComponent,
        ModalUtils.defaultModalOptions()
      );
      modalRef.componentInstance.confirmationOptions = modalOptions;
      modalRef.result.then((result) => {
        if (result) {
          this.addPlaceToGuide();
        }
      }).catch((_) => {
      });
    } else {
      this.addPlaceToGuide();
    }
  }

  updateCustomPlacePin(m: MapPin) {
    if (!!this.customPlacePin?.pinName) {
      // maintain existing name
      m.pinName = this.customPlacePin?.pinName;
    }
    this.customPlacePin = m;
    this.setupCustomPlacePinLabelGroup();
    this.handleCustomPinFormChanges();
  }

  public getCustomPlacePinArray(): KrugoMapMarker[] {
    const p = this.getCustomPlacePin();
    if (!!p) {
      return [p];
    } else {
      return [];
    }
  }

  public getCustomPlacePin(): KrugoMapMarker {
    if (this.customPlacePin.lat !== 0 && this.customPlacePin.lng !== 0) {
      return this.customPlacePin;
    } else {
      return null;
    }
  }

  public setupCustomPlacePinLabelGroup() {
    const lat = new LabelGroupItem();
    lat.label = 'Latitude';
    lat.value = this.customPlacePin?.getLat() !== 0 ? this.customPlacePin.getLat().toString() : '-';
    const lng = new LabelGroupItem();
    lng.label = 'Longitude';
    lng.value = this.customPlacePin?.getLng() !== 0 ? this.customPlacePin.getLng().toString() : '-';
    this.labelGroupItems = [lat, lng];
  }

  public setupCustomPlacePinFormGroup() {
    const name = new FormInputItem();
    name.itemType = FormItemType.Input;
    name.inputType = FormInputType.Text;
    name.label = 'Name';
    name.required = true;
    name.inputName = 'Place Name';
    name.bindingProperty = 'pinName';
    name.placeholder = 'Where is this?';
    this.customPinFormItems = [name];
  }

  public handleCustomPinFormChanges() {
    let canSubmit = true;
    this.customPinFormItems.forEach((fi) => {
      if (!fi.canSubmit()) {
        canSubmit = false;
        return;
      }
    });
    if (!this.customPlacePin?.pinName || this.customPlacePin?.lat === 0 || this.customPlacePin?.lng === 0) {
      canSubmit = false;
    }
    // Always hydrate the object to update the name
    this.hydrateCustomPinObject.next();
    if (canSubmit) {
      // Validate form to bind all input changes to object
      this.canAddCustomPin = canSubmit;
    }
  }

}

