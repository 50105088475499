import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseModal} from '../../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Insider} from '../../../../../models/guide/dto/insider';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {FormGroupComponent} from '../../form-group/form-group.component';

@Component({
  selector: 'app-social-links-modal',
  templateUrl: './social-links-modal.component.html',
  styleUrls: ['./social-links-modal.component.scss']
})
export class SocialLinksModalComponent extends BaseModal implements OnInit, AfterViewInit, OnDestroy {

  public updatedUserInsider: Insider;
  public facebookUrlFormInput: FormInputItem[] = [];
  public instagramUrlFormInput: FormInputItem[] = [];
  public twitterUrlFormInput: FormInputItem[] = [];
  public websiteUrlFormInput: FormInputItem[] = [];
  public embedWebsiteUrlFormInput: FormInputItem[] = [];
  public formStyling: FormGroupStyling = new FormGroupStyling();
  public formOptions: FormOptions = new FormOptions();

  @ViewChild('facebookUrlForm') facebookUrlForm: FormGroupComponent;
  @ViewChild('instagramUrlForm') instagramUrlForm: FormGroupComponent;
  @ViewChild('twitterUrlForm') twitterUrlForm: FormGroupComponent;
  @ViewChild('websiteUrlForm') websiteUrlForm: FormGroupComponent;
  @ViewChild('embedWebsiteUrlForm') embedWebsiteUrlForm: FormGroupComponent;

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupBindings();
    this.setupViews();
  }

  ngOnDestroy() {
    this.destroy();
  }

  ngAfterViewInit() {
  }

  cancel() {
    this.activeModal.close(false);
  }

  save() {
    this.facebookUrlForm.submitForm();
    this.instagramUrlForm.submitForm();
    this.twitterUrlForm.submitForm();
    this.websiteUrlForm.submitForm();
    this.embedWebsiteUrlForm.submitForm();
  }

  setupBindings() {

  }

  setupViews() {
    this.setupFormItems();
  }

  setupFormItems() {
    if (!this.updatedUserInsider.facebookUrl) {
      this.updatedUserInsider.facebookUrl = '';
    }
    if (!this.updatedUserInsider.instagramUrl) {
      this.updatedUserInsider.instagramUrl = '';
    }
    if (!this.updatedUserInsider.twitterUrl) {
      this.updatedUserInsider.twitterUrl = '';
    }
    if (!this.updatedUserInsider.websiteUrl) {
      this.updatedUserInsider.websiteUrl = '';
    }
    const facebookInput = new FormInputItem();
    facebookInput.itemType = FormItemType.Input;
    facebookInput.inputName = 'facebookUrl';
    facebookInput.label = 'Facebook';
    facebookInput.hideLabel = true;
    facebookInput.placeholder = 'Facebook';
    facebookInput.bindingProperty = 'facebookUrl';
    facebookInput.inputType = FormInputType.Text;
    this.facebookUrlFormInput.push(facebookInput);

    const instagramInput = new FormInputItem();
    instagramInput.itemType = FormItemType.Input;
    instagramInput.inputName = 'instagramUrl';
    instagramInput.label = 'Instagram';
    instagramInput.hideLabel = true;
    instagramInput.placeholder = 'Instagram';
    instagramInput.bindingProperty = 'instagramUrl';
    instagramInput.inputType = FormInputType.Text;
    this.instagramUrlFormInput.push(instagramInput);

    const twitterInput = new FormInputItem();
    twitterInput.itemType = FormItemType.Input;
    twitterInput.inputName = 'twitterUrl';
    twitterInput.label = 'Twitter';
    twitterInput.hideLabel = true;
    twitterInput.placeholder = 'Twitter';
    twitterInput.bindingProperty = 'twitterUrl';
    twitterInput.inputType = FormInputType.Text;
    this.twitterUrlFormInput.push(twitterInput);

    const websiteInput = new FormInputItem();
    websiteInput.itemType = FormItemType.Input;
    websiteInput.inputName = 'websiteUrl';
    websiteInput.label = 'Website';
    websiteInput.hideLabel = true;
    websiteInput.placeholder = 'Website';
    websiteInput.bindingProperty = 'websiteUrl';
    websiteInput.inputType = FormInputType.Text;
    this.websiteUrlFormInput.push(websiteInput);

    if (this.insiderIsCompany()) {
      const embeddedWebsiteInput = new FormInputItem();
      embeddedWebsiteInput.itemType = FormItemType.Input;
      embeddedWebsiteInput.inputName = 'embeddedWebsiteUrl';
      embeddedWebsiteInput.label = 'Embedded Website URL';
      embeddedWebsiteInput.hideLabel = true;
      embeddedWebsiteInput.placeholder = 'The URL to view your embedded guides.';
      embeddedWebsiteInput.bindingProperty = 'embeddedWebsiteUrl';
      embeddedWebsiteInput.inputType = FormInputType.Text;
      this.embedWebsiteUrlFormInput.push(embeddedWebsiteInput);
    }
  }

  formSubmitted(_: Insider) {
    this.activeModal.close(this.updatedUserInsider);
  }

  insiderIsCompany(): boolean {
    return !!this.updatedUserInsider.companyName;
  }
}
