import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {HydratedGuide} from '../../../../models/guide/dto/hydrated-guide';
import {ActivatedRoute, Router} from '@angular/router';
import {Guide} from '../../../../models/guide/dto/guide';
import {GuidesGridType} from './guides-grid-type.enum';
import {TypeUtils} from '../../../../utils/type-utils';
import {GuidesGridViewModel} from './guides-grid-view-model';
import {ModalUtils} from '../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RejectGuideModalComponent} from '../reject-guide-modal/reject-guide-modal.component';

@Component({
  selector: 'app-guides-grid',
  templateUrl: './guides-grid.component.html',
  styleUrls: ['./guides-grid.component.scss'],
  providers: [GuidesGridViewModel],
})
export class GuidesGridComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() guides: HydratedGuide[];
  @Input() type: GuidesGridType = GuidesGridType.ThreeWide;
  @Input() associatedInsiderId: string;
  types = TypeUtils;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private vm: GuidesGridViewModel,
    public modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  guideClicked(guide: Guide) {
    this.router.navigate(
      [`edit/${!!guide.companyId ? guide.companyId : guide.insiderId}/${guide.id}`],
      { relativeTo: this.route }
    ).then();
  }

  approveGuide(guide: HydratedGuide) {
    this.vm.approveGuide(guide);
  }

  rejectGuide(guide: HydratedGuide) {
    const modalRef = this.modalService.open(
      RejectGuideModalComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.result.then((msg) => {
      if (msg) {
        this.vm.rejectGuide(guide, msg);
      }
    });
  }

}
