import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SettingsViewModel} from './settings-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {Insider} from '../../../../models/guide/dto/insider';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [SettingsViewModel],
})
export class SettingsComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public vm: SettingsViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  updateInsiderObject(i: Insider) {
    this.vm.updateInsiderObject(i);
  }

  ngOnDestroy() {
    this.destroy();
  }

  saveChangesClicked() {
    this.vm.updateInsiderCompany();
  }
}
