import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {SessionChallenge} from './session-challenge';
import {DateUtils} from '../../../utils/date-utils';

export class Session implements Deserializable {
  public accessToken: string;
  public expiresIn: number;
  public idToken: string;
  public refreshToken: string;
  public tokenType: string;
  public challenge: SessionChallenge;

  private expiresAt: number;

  public onDeserialize() {
    this.challenge = DeserializeHelper.deserializeToInstance(SessionChallenge, this.challenge);
    // Set expires at value
    this.expiresAt = DateUtils.currentTimestamp() + this.expiresIn;
  }

  public validSession(): boolean {
    return this.accessToken && this.expiresAt > DateUtils.currentTimestamp();
  }

}
