import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {Coordinates} from '../../shared/coordinates';
import {Asset} from '../../image/dto/asset';
import {GuideChildObject} from '../../protocols/guide-child-object';
import {Orderable} from '../../protocols/orderable';
import {KrugoMapMarker} from '../../../views/shared/components/map/interfaces/krugo-map-marker';

export class CustomPlace implements Deserializable, GuideChildObject, KrugoMapMarker, Orderable {
  public guideId: string;
  public id: string;
  public name: string;
  public address: string;
  public city: string;
  public stateCode: string;
  public countryCode: string;
  public postalCode: string;
  public coordinates: Coordinates;
  public url: string = '';
  public instagramUrl: string = '';
  public facebookUrl: string = '';
  public twitterUrl: string = '';
  public categoryIds: string[] = [];
  // Inherited
  public images: Asset[] = [];
  public customDescription: string;
  // Not From Api
  public sortOrder: number;
  public selectedCategory: string;

  onDeserialize() {
    this.coordinates = DeserializeHelper.deserializeToInstance(Coordinates, this.coordinates);
    this.categoryIds = Array.from(this.categoryIds || []);
    this.images = [];
  }

  setAssets(imageMap: Map<string, string[]>, assets: Asset[]) {
    if (!this.images) {
      this.images = [];
    }
    this.images = [];
    const imageHashes = imageMap?.get(this.id);
    if (imageHashes) {
      imageHashes.forEach((hash) => {
        const asset = assets.find(a => a.md5Hash === hash);
        if (asset) {
          this.images.push(asset);
        }
      });
    }
  }

  setCustomDescription(descMap: Map<string, string>) {
    if (descMap) {
      const customDesc = descMap[this.id];
      if (customDesc) {
        this.customDescription = customDesc;
      }
    }
  }

  /** Krugo Map Marker Interface */

  getDesc(): string {
    return this.customDescription;
  }

  getLat(): number {
    return this.coordinates.lat;
  }

  getLng(): number {
    return this.coordinates.lng;
  }

  getTitle(): string {
    return this.name;
  }

  getUniqueId(): string {
    return this.id;
  }

  allowPinWindow(): boolean {
    return true;
  }

  getCoverImage(): Asset {
    if (this.images.length > 0) {
      return this.images[0];
    }
    return undefined;
  }

  getImages(): Asset[] {
    return this.images;
  }

  getUrl(): string {
    return this.url;
  }

  getOrderValue(): number {
    return this.sortOrder;
  }

  getOrderableTitle(): string {
    return this.name;
  }

  getOrderableAsset(): Asset {
    return null;
  }

  getOrderableUniqueId(): string {
    return this.id;
  }

  setOrderableValue(val: number) {
    this.sortOrder = val;
  }

}
