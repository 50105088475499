<form (ngSubmit)="submitForm()"
      [formGroup]="form"
      [ngClass]="{'custom-textfield-form': styling.includePadding}">
  <div class="custom-form-container">

    <ng-container *ngFor="let item of formItems; let itemIndex = index;">

      <!--   Hidden Item - used to maintain odd/even count when using overrideFullWidth   -->
      <div *ngIf="item.itemType === itemTypes.Hidden"
           class="form-input-container">
      </div>

      <!--   Divider Item   -->
      <div *ngIf="item.itemType === itemTypes.Divider"
           class="form-input-container">
        <div [ngClass]="item.customClass"
             class="form-input-divider"></div>
      </div>

      <!--   Title Item   -->
      <div *ngIf="item.itemType === itemTypes.Title"
           class="form-input-container">
        <div [ngStyle]="{'margin' : styling.numberColumns == 2 ? '0 0.75rem' : '0' }"
             class="form-input-title">
          {{ item.label }}
        </div>
        <div *ngIf="item.tooltipText && item.tooltipText !== ''"
             class="tooltip-container">
          <img [ngbTooltip]="item.tooltipText"
               [placement]="getTooltipPlacement(itemIndex)"
               [src]="'assets/icons/dark/outline/information-circle.svg'" alt="">
        </div>
      </div>

      <div class="row custom-form-group {{ item.inputHasButton ? null : item.customClass }}"
           *ngIf="item.itemType !== itemTypes.Divider && item.itemType !== itemTypes.Title && item.itemType !== itemTypes.Hidden"
           [ngClass]="{
              'has-error': item.hasErrors(),
              'two-column' : styling.numberColumns === 2 && !item.overrideFullWidth,
              'two-column-expand-last' : styling.numberColumns === 69,
              'single-line-input': item.itemType === itemTypes.Switch
           }">

        <div [ngClass]="{ 'inline' : item.inlineLabel }"
             class="custom-form-group-item">
          <label [hidden]="item.hideLabel || item.itemType === itemTypes.Switch"
                 [ngClass]="{ 'inline-label' : item.inlineLabel }"
                 class="control-label custom-textfield-label"
                 [ngStyle]="{ 'width' : item.inlineLabelWidthPercent*2+'%' }"
                 for="{{ item.inputName }}">
            {{ item.label }}
            <span *ngIf="item.required && item.showRequiredAsterisk"
                  class="required-star"> *</span>
            <span *ngIf="item.tooltipText && item.tooltipText !== ''"
                  class="tooltip-container">
              <img [ngbTooltip]="item.tooltipText"
                   [placement]="getTooltipPlacement(itemIndex)"
                   [src]="'assets/icons/dark/outline/information-circle.svg'"
                   alt="">
            </span>
          </label>

          <div [ngClass]="{ 'flex' : item.inputHasButton }"
               class="input-container">
            <!--   Input Item   -->
            <div *ngIf="item.itemType === itemTypes.Input"
                 [ngClass]="{ 'inline-textfield-container': item.inlineLabel,
               'inline-button-container': item.inputHasButton}"
                 class="form-input-container">
              <div class="input-container-block">
                <input (change)="!settingUpForm ? item.handleSearchableItemSelected($event) : null"
                       (keyup)="handleInputKeyUp($event)"
                       [attr.disabled]="!item.enabled ? true : null"
                       [attr.list]="item.inputName.concat('List')"
                       [ngClass]="{
                      'search-textfield' : item.inputType === 'search',
                      'inline-textfield': item.inlineLabel }"
                       [ngStyle]="{
                      'background' : (item.inputType === 'color') ? form.get(item.inputName).value || ' #FCFCFC' : '#FCFCFC',
                       'border-bottom': item.hasErrors() ? '1px solid #FA4545' : ''}"
                       [readOnly]="!item.editable"
                       [type]="item.inputType === inputTypes.Search ? 'text' : item.inputType"
                       class="form-control custom-textfield {{item.customClass}}"
                       formControlName="{{ item.inputName }}"
                       id="{{ item.inputName }}"
                       name="{{ item.inputName }}"
                       [step]="item.inputStep"
                       [textMask]="{mask: item.inputMask ? item.inputMask : false}"
                       [autocapitalize]="item.autoCapitalize"
                       [autocomplete]="item.autoComplete ? 'on' : 'off'"
                       placeholder="{{ item.placeholder }}">
                <datalist id="{{item.inputName}}" *ngIf="item.inputType === inputTypes.Search"
                          [id]="item.inputName.concat('List')">
                  <option *ngFor="let sItem of item.searchable"
                          value="{{sItem.lookupKey}}">
                  </option>
                </datalist>
                <small [ngStyle]="{'opacity': item.hasErrors() ? 1.0 : 0}"
                       class="help-block">
                  <span> {{item.getErrorMessage()}} </span>
                </small>
              </div>
            </div>

            <!--    Dropdown Item    -->
            <div *ngIf="item.itemType === itemTypes.Dropdown"
                 [ngClass]="{ 'inline-textfield-container': item.inlineLabel,
                'inline-button-container': item.inputHasButton}"
                 class="form-input-container">
              <div class="input-container-block">
                <select
                  (ngModelChange)="!settingUpForm ? item.handleValueChanged() : null"
                  [attr.disabled]="(!item.enabled) ? true : null"
                  class="form-control custom-dropdown"
                  formControlName="{{ item.inputName }}"
                  id="{{ item.inputName }}"
                  name="{{ item.inputName }}">
                  <option *ngIf="item.placeholder" disabled selected value="{{ item.dropdownIsObject ? 'null' : '' }}">
                    {{ item.placeholder }}
                  </option>
                  <option *ngFor="let drop of item.dropdownOptions" [ngValue]="drop.getSelectionValue()">
                    {{drop.getSelectionTitle()}}
                  </option>
                </select>
                <small [ngStyle]="{'opacity': item.hasErrors() ? 1.0 : 0}"
                       class="help-block">
                  <span> {{item.getErrorMessage()}} </span>
                </small>
              </div>
            </div>

            <!--    Switch Item    -->
            <div *ngIf="item.itemType === itemTypes.Switch"
                 [ngClass]="{ 'inline-textfield-container': item.inlineLabel }"
                 class="form-input-container form-input-switch">
              <div class="input-container-block">

                <div class="custom-switch-container">
                  <div class="switch-label-container">
                    {{ item.label }}
                    <div *ngIf="item.tooltipText && item.tooltipText !== ''"
                         class="tooltip-container">
                      <img [ngbTooltip]="item.tooltipText"
                           [placement]="getTooltipPlacement(itemIndex)"
                           [src]="'assets/icons/dark/outline/information-circle.svg'" alt="">
                    </div>
                  </div>

                  <label class="custom-switch">
                    <input (change)="!settingUpForm ? item.handleValueChanged() : null"
                           [value]="item.getValue()"
                           [checked]="item.getValue()"
                           [attr.disabled]="(!item.enabled) ? true : null"
                           class="form-control custom-switch-input"
                           formControlName="{{ item.inputName }}"
                           id="{{ item.inputName }}"
                           name="{{ item.inputName }}"
                           type="checkbox">
                    <span [ngClass]="{ 'disabled' : !item.enabled }"
                          class="custom-slider"></span>
                  </label>
                </div>

              </div>
            </div>

            <!--    Textarea Item    -->
            <div *ngIf="item.itemType === itemTypes.Textarea"
                 [ngClass]="{ 'inline-textfield-container': item.inlineLabel}"
                 class="form-input-container">
        <textarea class="form-control custom-text-area {{item.customClass}}"
                  cdkTextareaAutosize="{{item.autoGrowTextArea}}"
                  cdkAutosizeMinRows="{{item.autoGrowMinRows}}"
                  cdkAutosizeMaxRows="{{item.autoGrowMaxRows}}"
                  [ngStyle]="{'border-bottom': item.hasErrors() ? '1px solid #FA4545' : ''}"
                  (keyup)="handleInputKeyUp($event)"
                  (change)="!settingUpForm ? item.handleValueChanged() : null"
                  formControlName="{{ item.inputName }}"
                  name="{{ item.inputName }}"
                  id="{{ item.inputName }}"
                  placeholder="{{ item.placeholder }}"
                  rows="1"
                  [attr.disabled]="!item.enabled ? true : null"
                  [readOnly]="!item.editable"></textarea>
              <small [ngStyle]="{'opacity': item.hasErrors() ? 1.0 : 0}"
                     class="help-block">
                <span> {{item.getErrorMessage()}} </span>
              </small>
            </div>

            <!--    Checkbox Group Item    -->
            <div *ngIf="item.itemType === itemTypes.CheckboxGroup"
                 [ngClass]="{ 'inline-textfield-container': item.inlineLabel}"
                 class="form-input-container">
              <div class="grouped-checkbox-container">
                <app-checkbox-container
                  (changed)="groupedCheckboxesChanged(item, $event)"
                  [checkBoxContainerOptions]="item.groupCheckboxOptions"
                  [checkBoxes]="item.groupCheckboxes">
                </app-checkbox-container>
              </div>
              <small [ngStyle]="{'opacity': item.hasErrors() ? 1.0 : 0}"
                     class="help-block">
                <span> {{item.getErrorMessage()}} </span>
              </small>
            </div>

            <!--    Form input button - Available for input or dropdown   -->
            <div [hidden]="!item.inputHasButton"
                 class="form-input-button-container">
              <button (click)="item.inputButtonClicked.emit($event)"
                      [disabled]="!item.enabled"
                      [ngClass]="{ 'disabled' : !item.enabled }"
                      class="custom-button {{ item.inputHasButton ? item.customClass : null }}">
                {{ item.inputButtonText }}
              </button>
            </div>
          </div>

        </div>
      </div>
    </ng-container>

    <!--   End Form Checkbox     -->
    <div *ngIf="options.includeEndFormCheckbox"
         [ngClass]="styling.checkboxClass"
         class="form-input-container form-end-checkbox-container">
      <app-checkbox
        (clickEvent)="checkboxClicked($event)"
        [checked]="checkboxValue"
        [label]="styling.checkboxLabel">
      </app-checkbox>
    </div>

    <div *ngIf="
      styling.resetButtonText !== '' ||
      styling.cancelButtonText !== '' ||
      styling.submitButtonText !== '' ||
      styling.secondaryButtonText !== ''"
         class="form-group-buttons-container">
      <div class="form-group primary-button-container"
           [ngStyle]="{'float':styling.primaryButtonFloat}">
        <button (click)="resetForm()" [hidden]="styling.resetButtonText === ''"
                [ngStyle]="{ 'margin' : styling.getButtonMargin() }"
                class="custom-button outlined-button"
                type="reset">
          {{ styling.resetButtonText }}
        </button>
        <button (click)="cancelForm()"
                [hidden]="styling.cancelButtonText === ''"
                [ngStyle]="{ 'margin' : styling.getButtonMargin() }"
                class="custom-button neutral-button"
                type="button">
          {{ styling.cancelButtonText }}
        </button>
        <button (click)="submitForm()"
                [disabled]="!canSubmitForm()"
                [hidden]="styling.submitButtonText === ''"
                [ngClass]="{ 'disabled' : !canSubmitForm() }"
                [ngStyle]="{ 'margin' : styling.getButtonMargin() }"
                class="custom-button preferred-button"
                type="submit">
          {{ styling.submitButtonText }}
        </button>
      </div>

      <div class="form-group secondary-button-container"
           [ngStyle]="{'float':styling.secondaryButtonFloat}">
        <button (click)="secondaryButtonPressed.emit()"
                [hidden]="styling.secondaryButtonText === ''"
                [ngStyle]="{ 'margin' : styling.getButtonMargin(true) }"
                class="custom-button {{ styling.secondaryButtonClass }}"
                type="button">
          {{ styling.secondaryButtonText }}
        </button>
      </div>
    </div>

  </div>
</form>
