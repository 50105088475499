import {Guide} from '../models/guide/dto/guide';
import {HydratedGuide} from '../models/guide/dto/hydrated-guide';
import {GuideStatus} from '../models/guide/enum/guide-status.enum';

export class FilterUtils {

  static GuidesByInsiderId = (g: HydratedGuide, insiderId: string) => {
    if (!!insiderId) {
      return g.insiderId === insiderId || g.companyId === insiderId;
    } else { return true; }
  }

  static GuidesByCityId = (g: Guide, cId: string): boolean => {
    if (!!cId) {
      return cId.toLowerCase() === g.cityId.toLowerCase();
    } else { return true; }
  }

  static GuidesByGuideName = (g: Guide, gName: string): boolean => {
    if (!!gName) {
      return g.title.toLowerCase().includes(gName.toLowerCase());
    } else { return true; }
  }

  static GuidesByInsiderName = (g: HydratedGuide, iName: string): boolean => {
    if (!!iName) {
      const fullName = g.insider.firstName + g.insider.lastName;
      return fullName.toLowerCase().includes(iName.toLowerCase());
    } else { return true; }
  }

  static GuidesByCompanyName = (g: HydratedGuide, cName: string): boolean => {
    if (!!cName) {
      if (!!g.company) {
        return g.company.companyName.toLowerCase().includes(cName.toLowerCase());
      } else { return false; }
    } else {
      return true;
    }
  }

  static GuidesById = (g: Guide, id: string): boolean => {
    if (!!id) {
      return g.id.toLowerCase().includes(id.toLowerCase());
    } else { return true; }
  }

  static GuidesByStatus = (g: Guide, status: string): boolean => {
    if (!!status) {
      if ((status.toLowerCase() === GuideStatus.Draft.toLowerCase()) && (g.status.toLowerCase() === GuideStatus.Declined.toLowerCase())) {
        return true;
      }
      return g.status.toLowerCase() === status.toLowerCase();
    } else { return true; }
  }

  static GuidesByTags = (g: HydratedGuide, tags: string[]): boolean => {
    if (!!tags && tags.length > 0) {
      return g.tags.intersection(tags).length > 0;
    } else { return true; }
  }

}
