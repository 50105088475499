<div class="modal-body">
  <div class="modal-title mb-3">You Have Unsaved Changes</div>
  <p class="mb-4">You have made changes to this page that haven't been saved. You can either abandon these changes or
    keep editing
    this page.</p>
  <div class="footer-button">
    <button matRipple (click)="abandonChanges()" class="custom-button destructive-button">Abandon changes</button>
    <button matRipple (click)="keepEditing()" class="custom-button preferred-button ml-8px">Keep editing</button>
  </div>
</div>
