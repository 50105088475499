import {GuideStatus} from '../models/guide/enum/guide-status.enum';
import {CompanySubRoute} from '../views/default/sidebar-nav/company-sub-route.enum';
import {FontType} from '../models/enum/shared/font-type.enum';
import {FontHierarchy} from '../models/enum/shared/font-hierarchy.enum';
import {GuidesGridType} from '../views/guides/components/guides-grid/guides-grid-type.enum';

export class TypeUtils {
  static GuideStatus: typeof GuideStatus = GuideStatus;
  static CompanySubRoute: typeof CompanySubRoute = CompanySubRoute;
  static FontHierarchy: typeof FontHierarchy = FontHierarchy;
  static FontType: typeof FontType = FontType;
  static GuidesGridType: typeof GuidesGridType = GuidesGridType;
}
