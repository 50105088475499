<ngx-daterangepicker-material #calendar class="date-picker"
                              [maxSpan]="true"
                              [locale]="locale"
                              [ranges]="ranges"
                              [autoApply]="false"
                              [maxDate]="maxDate"
                              [linkedCalendars]="true"
                              [alwaysShowCalendars]="true"
                              [showCustomRangeLabel]="true"
                              (rangeClicked)="rangeClicked()"
                              (choosedDate)="datesChosen.emit($event)"
                              (startDateChanged)="startDateChanges($event)">
</ngx-daterangepicker-material>
