<form class="search-bar-container"
      (reset)="emitEmpty()">
    <label for="searchBar" hidden>Search Label</label>
    <input class="text-field search-text-field"
           id="searchBar"
           name="searchBar"
           placeholder="{{placeHolder}}"
           (input)="searchChanged($event)"
           type="text">
    <button #reset
            class="clear-search"
            type="reset">
      CLEAR
    </button>
</form>
