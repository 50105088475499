<div class="reorder-container"
     (cdkDropListDropped)="drop($event)"
     cdkDropList>
  <div class="reorder-item"
       *ngFor="let item of items"
       cdkDrag>
    <div *ngIf="!useImages" class="reorder-value ellipsis-nowrap-text">
      {{item.getOrderableTitle()}}
    </div>
    <div *ngIf="useImages" class="reorder-image-container">
      <div class="reorder-value-image">
        <app-asset [asset]="item.getOrderableAsset()" [borderRadius]="'0.5rem'"></app-asset>
      </div>
    </div>
    <div class="reorder-icon">
      <img [src]="'assets/icons/dark/section.svg'" alt="">
    </div>
    <div class="reorder-placeholder" *cdkDragPlaceholder></div>
  </div>
</div>
