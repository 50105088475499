import {Asset} from '../models/image/dto/asset';
import {Guide} from '../models/guide/dto/guide';
import {HydratedGuide} from '../models/guide/dto/hydrated-guide';
import {Insider} from '../models/guide/dto/insider';
import {KrugoDateRange} from '../views/company/components/insights/krugo-date-picker/models/krugo-date-range';
import {KrugoMapMarker} from '../views/shared/components/map/interfaces/krugo-map-marker';

export class DistinctUtils {

  public static distinctKrugoMapMarker = (prev: KrugoMapMarker, curr: KrugoMapMarker) => prev?.getUniqueId() === curr?.getUniqueId();
  public static distinctAsset = (prev: Asset, curr: Asset) => {
    // ignore placeholder images that are initially set
    const nonPlaceholder = (prev instanceof Asset && curr instanceof Asset);
    return nonPlaceholder && prev?.md5Hash === curr?.md5Hash;
  }

  public static distinctKrugoDateRange = (prev: KrugoDateRange, curr: KrugoDateRange) => {
    return prev.getDateRangeString() === curr.getDateRangeString();
  }

  public static distinctGuide = (prev: Guide, curr: Guide) => {
    return (prev?.id === curr?.id) && (prev?.cachedTime === curr?.cachedTime) && (prev?.lastUpdated === (curr?.lastUpdated));
  }

  public static distinctInsider = (prev: Insider, curr: Insider) => {
    return (prev?.id === curr?.id) && (prev?.cachedTime === curr?.cachedTime);
  }

  public static distinctGuides = (prev: Guide[], curr: Guide[]) => {
    const prevId = prev?.map(g => `${g.id}-${g.cachedTime}-${g.lastUpdated}`).sort().join(',');
    const currId = curr?.map(g => `${g.id}-${g.cachedTime}-${g.lastUpdated}`).sort().join(',');
    return prevId === currId;
  }

  public static distinctHydratedGuides = (prev: HydratedGuide[], curr: HydratedGuide[]) => {
    return DistinctUtils.distinctGuides(prev, curr);
  }

  public static distinctListOfStrings = (prev: string[], curr: string[]) => {
    const sameLength = (prev || []).length === (curr || []).length;
    const notEqual = !(prev || []).map(pId => {
      for (const cId in (curr || [])) {
        if (pId === cId) {
          return true;
        }
      }
      return false;
    }).includes(false);
    return sameLength && !notEqual;
  }
}

