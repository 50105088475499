import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {SearchGuideViewModel} from './search-guide-view-model';
import {HydratedGuide} from '../../../../models/guide/dto/hydrated-guide';
import {takeUntil} from 'rxjs/operators';
import {GuideStatus} from '../../../../models/guide/enum/guide-status.enum';
import {SearchBarComponent} from '../../../shared/components/search-bar/search-bar.component';
import {GuidesGridType} from '../guides-grid/guides-grid-type.enum';

@Component({
  selector: 'app-search-guides',
  templateUrl: './search-guides.component.html',
  styleUrls: ['./search-guides.component.scss'],
  providers: [SearchGuideViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchGuidesComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() associatedInsiderId: string;
  @Input() status: GuideStatus;
  @Input() gridType: GuidesGridType = GuidesGridType.ThreeWide;
  @Output() guides = new EventEmitter<HydratedGuide[]>(true);
  @ViewChild('searchBarComponent') searchBar: SearchBarComponent;

  constructor(
    public vm: SearchGuideViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setDataInViewModel();
    // guidesSubs
    this.vm.filteredGuides$.pipe(takeUntil(this.onDestroy)).subscribe(guides => this.guides.emit(guides));
    this.vm.clearSearchBar.pipe(takeUntil(this.onDestroy)).subscribe(_ => this.searchBar.clear());
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDataInViewModel();
  }

  private setDataInViewModel() {
    this.vm.setGuidesGridType(this.gridType);
    this.vm.setAssociatedInsiderId(this.associatedInsiderId);
    this.vm.setStatusType(this.status);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
