import {Guide} from './guide';
import {Insider} from './insider';
import {Asset} from '../../image/dto/asset';
import {Performer} from './performer';
import {Place} from './place';
import {CustomPlace} from './custom-place';
import {Accommodation} from './accommodation';
import {DeserializeHelper} from '../../protocols/deserializable';
import {Cachable} from '../../protocols/cachable';
import {KrugoMapMarker} from '../../../views/shared/components/map/interfaces/krugo-map-marker';
import {SortUtils} from '../../../utils/sort-utils';

export class HydratedGuide extends Guide implements KrugoMapMarker, Cachable {
  public images: Asset[] = [];
  public performer: Performer;
  public places: Place[];
  public featuredGuide: boolean;
  public customPlaces: CustomPlace[];
  public stays: Accommodation[];
  public insider: Insider;
  public company: Insider;
  public viewCount: number;
  public favoriteCount: number;

  // Not From API
  protected loadMinimalImages: boolean;

  onDeserialize() {
    super.onDeserialize();
    if (this.loadMinimalImages === undefined) {
      this.loadMinimalImages = false;
    }
    this.images = (DeserializeHelper.deserializeArray(Asset, this?.images) ?? []);
    this.performer = DeserializeHelper.deserializeToInstance(Performer, this?.performer);
    this.places = (DeserializeHelper.deserializeArray(Place, this?.places) ?? []);
    this.customPlaces = (DeserializeHelper.deserializeArray(CustomPlace, this?.customPlaces) ?? []);
    this.stays = (DeserializeHelper.deserializeArray(Accommodation, this?.stays) ?? []);
    this.insider = DeserializeHelper.deserializeToInstance(Insider, this?.insider);
    this.company = DeserializeHelper.deserializeToInstance(Insider, this?.company);


    this.sortGuideImages();
    if (this.loadMinimalImages) {
      const guidePhoto = this.getGuidePhoto();
      this.images =  guidePhoto ? [guidePhoto] : [];
    }

    // Assign images and contentDescription to child objects
    if (this.performer) {
      this.performer.setAssets(this.imageMap, this.images);
      this.performer.setCustomDescription(this.contentDescription);
    }
    if (this.places && this.places.length > 0 && this.imageMap) {
      this.places.forEach(p => {
        p.setAssets(this.imageMap, this.images);
        p.setCustomDescription(this.contentDescription);
      });
    }
    if (this.customPlaces && this.customPlaces.length > 0 && this.imageMap) {
      this.customPlaces.forEach(p => {
        p.setAssets(this.imageMap, this.images);
        p.setCustomDescription(this.contentDescription);
      });
    }
    if (this.stays && this.stays.length > 0 && this.imageMap) {
      this.stays.forEach(p => {
        p.setAssets(this.imageMap, this.images);
        p.setCustomDescription(this.contentDescription);
      });
    }
    this.places?.forEach(p => {
      p.setAssets(this.imageMap, this.images);
      p.setCustomDescription(this.contentDescription);
    });
    this.customPlaces?.forEach(p => {
      p.setAssets(this.imageMap, this.images);
      p.setCustomDescription(this.contentDescription);
    });
    this.stays?.forEach(p => {
      p.setAssets(this.imageMap, this.images);
      p.setCustomDescription(this.contentDescription);
    });
  }

  public hasCompany(): boolean {
    return !!this.company;
  }

  public hasInsider(): boolean {
    return !!this.insider;
  }

  public getCompanyOrInsider(): Insider {
    return !!this.company ? this.company : this.insider;
  }

  public guideByString(): string {
    if (!!this.company) {
      if (this.showCompanyInsider) {
        return `${this.company.companyName} by ${this.insider.getFullName()}`;
      } else {
        return `${this.company.companyName}`;
      }
    } else {
      return `${this.insider.getFullName()}`;
    }
  }

  sortGuideImages() {
    const guideImageOrderMap = this.imageOrderMap?.get(this.id);
    this.images = SortUtils.sortAssetsByMap(this.images, guideImageOrderMap);
  }

  public getGuidePhoto(): Asset {
    const guideImageSortOrder = this.imageOrderMap?.get(this.id);
    if (!!guideImageSortOrder && guideImageSortOrder.size > 0) {
      // the images have already been sorted based on guideImageSortOrder
      return this.images[0];
    } else {
      const photoHash = this.imageMap?.get(this.id)?.find(x => !!x);
      if (!!photoHash) {
        return this.images?.find(it => it.md5Hash === photoHash);
      }
    }
    return this.images[0];
  }

  findPlanItem(id: string): KrugoMapMarker {
    return this.getPlanItems().find(it => it.getUniqueId() === id);
  }

  getPlanItems(): KrugoMapMarker[] {
    return (this.customPlaces.map(it => it as KrugoMapMarker));
  }

  /** Map Marker Interface */

  getLat(): number {
    return 50.4452;
  }

  getLng(): number {
    return Number.parseInt(this.id, 10) + -104.6189;
  }

  getTitle(): string {
    return this.title;
  }

  getDesc(): string {
    return this.description;
  }

  getUniqueId(): string {
    return this.id;
  }

  getCoverImage(): Asset {
    return this.getGuidePhoto();
  }

  getImages(): Asset[] {
    return this.images;
  }

  getUrl(): string {
    return '';
  }

  allowPinWindow(): boolean {
    return true;
  }

}
