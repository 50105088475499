<div id="header" class="header" fxLayout="row">
  <div class="header-img" fxFlex="none" fxFlexAlign=" center"></div>
  <div fxFlex fxLayout="row" fxLayoutAlign="end end">
    <div *ngFor="let i of [0,1,2,3]" class="header-nav-item">
    </div>
  </div>
</div>
<div class="iframe-container"
     [style.height]="vm.loadingOpts.isLoading ? '75vh' : 'auto'">
  <app-loading
    [hidden]="!vm.loadingOpts.isLoading"
    [options]="vm.loadingOpts">
  </app-loading>
  <div id="mainDiv" class="embedded-container" style="min-height:50vh;">
    <iframe #iFrame
            id="krugo-partners-iframe"
            [hidden]="vm.loadingOpts.isLoading"
            [src]="(vm.iFrameSrc$|async)"
            (load)="this.vm.loadingOpts.isLoading=false"
            class="iFrame"
            width="100%"
            height="100%"
            allow="clipboard-read; clipboard-write"></iframe>
  </div>
</div>
<div id="footer" class="footer"></div>
