import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ChooseFontButtonViewModel} from './choose-font-button-view-model';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FontHierarchy} from '../../../../../models/enum/shared/font-hierarchy.enum';
import {FontType} from '../../../../../models/enum/shared/font-type.enum';
import {Font} from '../../../../../models/enum/shared/font.enum';

@Component({
  selector: 'app-choose-font-button',
  templateUrl: './choose-font-button.component.html',
  styleUrls: ['./choose-font-button.component.scss'],
  providers: [ChooseFontButtonViewModel],
})
export class ChooseFontButtonComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() primary: Font;
  @Input() secondary: Font;
  @Input() hierarchy: FontHierarchy;
  @Input() type: FontType;
  @Input() subHeaderText;
  name: string;

  constructor(
    public vm: ChooseFontButtonViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
