import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {SortUtils} from '../../../utils/sort-utils';
import {Orderable} from '../../protocols/orderable';
import {Asset} from '../../image/dto/asset';

export class GuideSection implements Deserializable, Orderable {
  public ids: Map<string, number> = new Map<string, number>(); // Map<Id, position>
  public sortOrder: number;
  public title: string = null;
  // Not from api
  public sortedIds: string[];

  onDeserialize() {
    if (!(this.ids instanceof Map)) {
      this.ids = DeserializeHelper.deserializeGenericMap<string, number>(this.ids);
    }
    // Sort ids
    const tmp = [];
    for (const [key, value] of this.ids.entries()) { tmp.push([key, value]); }
    tmp.sort(SortUtils.sortKeyNumberPairs);
    this.sortedIds = tmp.map(it => it[0]);
  }

  getOrderValue(): number {
    return this.sortOrder;
  }

  getOrderableTitle(): string {
    return this.title;
  }

  getOrderableUniqueId(): string {
    return this.ids + this.title + this.sortOrder;
  }

  setOrderableValue(val: number) {
    this.sortOrder = val;
  }

  getOrderableAsset(): Asset {
    return null;
  }
}
