import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {AnimatorService} from '../../../../services/animator/animator.service';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {Action, ActionType} from '../../../../models/shared/stylesheet/action';
import {SessionService} from '../../../../services/session-service';
import {ActionService} from '../../../../services/action/action.service';

@Injectable()
export class DashboardViewModel extends BaseViewModel {

  constructor(
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

}
