<div appDragScroll
     class="horizontal-card-scroller">
  <app-insight-card-loading *ngFor="let i of [0,1,2,3,4]"
                            [hidden]="!loading">
  </app-insight-card-loading>
  <app-insight-card *ngFor="let keyValue of map | keyvalue: KeyValueUtils.guideViewOrder; let i = index; let last = last;"
                    [hidden]="loading"
                    [clickType]="keyValue.key"
                    [value]="keyValue.value"
                    [selected]="selected"
                    (click)="cardClicked.emit(keyValue.key)">
  </app-insight-card>
</div>
