import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UploadImageInterface} from '../../components/upload-asset/upload-image-interface';
import {CustomFile} from '../../../../models/shared/custom-file';

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss',
    './../../../../../scss/stylesheet.scss'
  ]
})
export class UploadFileModalComponent implements OnInit, UploadImageInterface {

  filesToUpload: CustomFile[] = [];
  initialFiles: CustomFile[] = null;

  constructor(
    private activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.dismiss(null);
  }

  fileList(f: CustomFile[], id: number) {
    this.filesToUpload = f;
  }

  uploadItems() {
    if (this.initialFiles?.length > 0) {
      // this.filesToUpload.concat(this.initialFiles) does not work
      this.initialFiles.forEach(f => {
        this.filesToUpload.push(f);
      });
    }
    this.activeModal.close(this.filesToUpload);
  }

}
