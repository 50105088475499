import {environment} from '../../environments/environment';
import {GuideStatus} from '../models/guide/enum/guide-status.enum';
import {InsiderInsightReq} from '../models/guide/requests/insider-insight-req';
import {GuideInsightReq} from '../models/guide/requests/guide-insight-req';
import {GuideFeature} from '../models/guide/dto/guide-feature';

export class Endpoints {

  // Session

  static encodeParam(s: string): string {
    return encodeURIComponent(s);
  }

  static SignIn(): string {
    return environment.accountServiceUrl + `/sign-in`;
  }

  static SignUp(): string {
    return environment.accountServiceUrl + `/sign-up`;
  }

  static ConfirmCode(): string {
    return environment.accountServiceUrl + `/confirm-code`;
  }

  static ResendCode(): string {
    return environment.accountServiceUrl + `/resend-confirmation-code`;
  }

  static SendForgotPasswordCode(phoneNumber: string): string {
    return environment.accountServiceUrl + `/forgot-password-code?PhoneNumber=${phoneNumber}`;
  }

  static ResetForgottenPassword(): string {
    return environment.accountServiceUrl + `/reset-forgotten-password`;
  }

  static SignOut(): string {
    return environment.accountServiceUrl + `/sign-out`;
  }

  static RefreshSession(): string {
    return environment.accountServiceUrl + `/refresh-session`;
  }


  // Image

  static DeleteGuideImage(guideId, id, md5Hash: string): string {
    return environment.guideServiceUrl + `/delete-guide-image?GuideId=${guideId}&Id=${id}&MD5Hash=${md5Hash}`;
  }

  static GetGuideImageUploadUrl(fileName, guideId, id: string): string {
    return environment.guideServiceUrl + `/get-image-upload-url?FileName=${fileName}&GuideId=${guideId}&Id=${id}`;
  }

  static GetInsiderImageUploadUrl(fileName, id: string): string {
    return environment.partnerGuideServiceUrl + `/get-insider-image-upload-url?FileName=${fileName}&Id=${id}`;
  }

  static GetAsset(id, md5: string): string {
    return environment.partnerGuideServiceUrl + `/get-asset?Id=${id}&Md5Hash=${md5}`;
  }

  // Guides

  static AddGuideView(): string {
    return environment.guideServiceUrl + `/add-guide-view`;
  }

  static CreateGuide(): string {
    return environment.guideServiceUrl + `/create-guide`;
  }

  static CreateGuidePlace(): string {
    return environment.guideServiceUrl + `/create-guide-place`;
  }

  static DeleteGuidePlace(guideId, id: string): string {
    return environment.guideServiceUrl + `/delete-guide-place?GuideId=${guideId}&Id=${id}`;
  }

  static DeleteGuide(id: string): string {
    return environment.guideServiceUrl + `/delete-guide?Id=${id}`;
  }

  static GetCompanyGuides(companyId: string, status?: GuideStatus): string {
    if (!!status) {
      return environment.guideServiceUrl + `/get-company-guides?CompanyId=${companyId}&Status=${status}`;
    } else {
      return environment.guideServiceUrl + `/get-company-guides?CompanyId=${companyId}`;
    }
  }

  static GetGuides(ids: string[]): string {
    return environment.guideServiceUrl + `/get-guides?Ids=${ids.join(',')}`;
  }

  static GetGuidePlace(guideId: string, placeId: string) {
    return environment.guideServiceUrl + `/get-guide-place?GuideId=${guideId}&Id=${placeId}`;
  }

  static GetInsiderGuides(insiderId: string, status?: GuideStatus): string {
    if (!!status) {
      return environment.guideServiceUrl + `/get-insider-guides?InsiderId=${insiderId}&Status=${status}`;
    } else {
      return environment.guideServiceUrl + `/get-insider-guides?InsiderId=${insiderId}`;
    }
  }

  static GetNearbyGuides(lat, lng, radius: number, status: GuideStatus): string {
    return environment.guideServiceUrl + `/get-nearby-guides?Lng=${lng}&Lat=${lat}&Radius=${radius}&Status=${status}`;
  }

  static UpdateGuide(): string {
    return environment.guideServiceUrl + `/update-guide`;
  }

  static UpdateGuidePlace(): string {
    return environment.guideServiceUrl + `/update-guide-place`;
  }

  // Insiders

  static CreateInsider(): string {
    return environment.guideServiceUrl + `/create-insider`;
  }

  static GetCompanyInsiders(companyId: string): string {
    return environment.guideServiceUrl + `/get-company-insiders?CompanyId=${companyId}`;
  }

  static GetInsider(id: string): string {
    return environment.guideServiceUrl + `/get-insider?Id=${id}`;
  }

  static UpdateInsider(): string {
    return environment.guideServiceUrl + `/update-insider`;
  }

  // Partner Guide

  static AddCompanyInsider(): string {
    return environment.partnerGuideServiceUrl + `/add-company-insider`;
  }

  static UpdateCompanyInsider(): string {
    return environment.partnerGuideServiceUrl + `/update-company-insider`;
  }

  static GetCompanyInsiderLookups(companyId: string): string {
    return environment.partnerGuideServiceUrl + `/get-company-insider-lookups?CompanyId=${companyId}`;
  }

  static DeleteCompanyInsider(companyId, insiderId: string): string {
    return environment.partnerGuideServiceUrl + `/delete-company-insider?CompanyId=${companyId}&InsiderId=${insiderId}`;
  }

  static AutoCompleteLocation(lat, lng: number, lookup: string): string {
    return environment.partnerGuideServiceUrl + `/auto-complete-location?Lng=${lng}&Lat=${lat}&Lookup=${lookup}`;
  }

  static GetCities(countryCode: string): string {
    return environment.partnerGuideServiceUrl + `/get-cities?countrycode=${countryCode}`;
  }

  static GetGuideInsights(req: GuideInsightReq): string {
    return environment.partnerGuideServiceUrl +
      `/get-guide-insights?UniqueImpressions=${req.uniqueImpressions}&guideId=${req.guideId}` +
      `&startTime=${req.startTime}&endTime=${req.endTime}`;
  }

  static GetInsiderInsights(req: InsiderInsightReq): string {
    if (!!req.companyId) {
      return environment.partnerGuideServiceUrl +
        `/get-insider-insights?UniqueImpressions=${req.uniqueImpressions}&companyId=${req.companyId}` +
        `&startTime=${req.startTime}&endTime=${req.endTime}`;
    } else {
      return environment.partnerGuideServiceUrl +
        `/get-insider-insights?UniqueImpressions=${req.uniqueImpressions}&insiderId=${req.insiderId}` +
        `&startTime=${req.startTime}&endTime=${req.endTime}`;
    }
  }

  static GetPlaceCategories(): string {
    return environment.partnerGuideServiceUrl + `/get-place-categories`;
  }

  // Guide Features

  static CreateGuideFeature(): string {
    return environment.partnerGuideServiceUrl + '/create-company-guide-feature';
  }

  static UpdateGuideFeature(): string {
    return environment.partnerGuideServiceUrl + '/update-company-guide-feature';
  }

  static DeleteGuideFeature(guideFeature: GuideFeature): string {
    return environment.partnerGuideServiceUrl + `/delete-company-guide-feature?InsiderId=${guideFeature.insiderId}&Id=${guideFeature.id}`;
  }

  static GetCompanyGuideFeatures(insiderId: string): string {
    return environment.partnerGuideServiceUrl + `/get-company-guide-features?InsiderId=${insiderId}`;
  }


}

