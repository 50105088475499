import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {GuidesDomainModel} from '../../../../../domain-models/guides-domain-model';
import {ToastService} from '../../../../../services/toast-service';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {LoadingSpinnerSize} from '../../../../../models/enum/shared/loading-spinner-size.enum';
import {GuideFeature} from '../../../../../models/guide/dto/guide-feature';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {BehaviorSubject} from 'rxjs';
import {PillItem} from '../../../../../models/shared/stylesheet/pill-item';
import {CustomError} from '../../../../../models/shared/custom-error';

@Injectable()
export class EditGuideFeatureViewModel extends BaseViewModel implements OnDestroy {

  public title: string;
  public cta: string;
  public loadingOpts: LoadingOptions;
  public guideFeature: GuideFeature;
  public tagPills = new BehaviorSubject<PillItem[]>([]);
  public closeModal = new BehaviorSubject<boolean>(null);

  // Form
  public canSubmitForm: boolean = false;
  public formItems: FormInputItem[] = [];
  public formOptions: FormOptions = new FormOptions();
  public formStyling: FormGroupStyling = new FormGroupStyling();
  public hydrateForm: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private guidesDm: GuidesDomainModel,
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupLoadingSpinner();
    this.setupBindings();
  }

  private setupLoadingSpinner() {
    this.loadingOpts = LoadingOptions.default();
    this.loadingOpts.isLoading = false;
    this.loadingOpts.spinnerSize = LoadingSpinnerSize.Medium;
    this.loadingOpts.backgroundColor = '#FCFCFC';
    this.loadingOpts.spinnerColor = '#222222';
  }

  setupBindings() {
  }

  ngOnDestroy() {
    this.destroy();
  }

  public setGuideFeature(gf: GuideFeature, insiderId: string) {
    if (!!gf) {
      this.guideFeature = gf;
    } else {
      this.guideFeature = Object.assign(new GuideFeature(), null);
    }
    this.guideFeature.insiderId = insiderId;
    if (!!this.guideFeature.id) {
      this.title = 'Edit Guide Feature';
      this.cta = 'Save';
    } else {
      this.title = 'Create Guide Feature';
      this.cta = 'Create';
    }
    this.updateTagPills();
  }

  addTag(tag: string) {
    tag = tag.trim().toLowerCase();
    if (!!tag && !this.guideFeature.tags.contains(tag)) {
      this.guideFeature.tags.push(tag);
      this.guideFeature.tags.unique(true);
    }
    this.updateTagPills();
    this.formChanges();
  }

  removeTag([tag, _]: [string, boolean]) {
    tag = tag.trim().toLowerCase();
    if (!!tag) {
      const deleteIndex = this.guideFeature.tags.indexOf(tag);
      if (deleteIndex > -1) {
        this.guideFeature.tags.splice(deleteIndex, 1);
      }
      this.guideFeature.tags.unique(true);
    }
    this.updateTagPills();
    this.formChanges();
  }

  private updateTagPills() {
    if (this.guideFeature?.tags.length > 0) {
      this.tagPills.next([...this.guideFeature.tags].map(t => new PillItem(t, true, true)));
    } else {
      this.tagPills.next([]);
    }
  }

  public saveGuideFeature() {
    if (!!this.guideFeature.id) {
      // save existing
      this.updateGuideFeature();
    } else {
      // create new guide feature
      this.createGuideFeature();
    }
  }

  private updateGuideFeature() {
    const lm = 'Updating Guide Feature';
    if (!this.loadingOpts.containsRequest(lm)) {
      this.loadingOpts.addRequest(lm);
      this.guidesDm.updateGuideFeature(this.guideFeature).subscribe((_) => {
        this.loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Guide Feature Updated', 'Success');
        this.closeModal.next(true);
      }, (error: CustomError) => {
        this.loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
      });
    }
  }

  private createGuideFeature() {
    const lm = 'Creating Guide Feature';
    if (!this.loadingOpts.containsRequest(lm)) {
      this.loadingOpts.addRequest(lm);
      this.guidesDm.createGuideFeature(this.guideFeature).subscribe((_) => {
        this.loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Guide Feature Created', 'Success');
        this.closeModal.next(true);
      }, (error: CustomError) => {
        this.loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
      });
    }
  }

  formChanges() {
    let canSubmit = true;
    this.formItems.forEach((fi) => {
      if (!fi.canSubmit()) {
        canSubmit = false;
        return;
      }
    });
    if (this.guideFeature.tags.length === 0) {
      canSubmit = false;
    }
    if (canSubmit) {
      // Validate form to bind all input changes to object
      this.hydrateForm.next();
      this.canSubmitForm = canSubmit;
    }
  }

}
