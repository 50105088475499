<div class="create-guide-modal">
  <div class="title-bar">
    <div class="modal-title">
      Rename {{oldSectionName}}
    </div>
    <button (click)="closeModal()" class="custom-button text-button">Cancel</button>
  </div>
  <div class="add-place-action-container">
    <app-form-group (formChanges)="sectionNameChanged()"
                    [formItems]="sectionNameItem"
                    [options]="sectionNameOptions"
                    [styling]="sectionNameStyling"
                    [formObject]="sectionName"
                    [hydrateInputObject]="hydrateSectionNameObject">
    </app-form-group>
  </div>
  <div class="section-options-footer-container">
    <hr>
    <div class="button-container">
      <button class="custom-button grey-button" (click)="closeModal()">Cancel</button>
      <button class="custom-button preferred-button ml-2"
              (click)="submit()"
              [disabled]="!canSubmit"
              [ngClass]="{ 'disabled' : !canSubmit}"
      >Rename Section
      </button>
    </div>
  </div>
</div>
