export enum AddPlaceFlow {
  PlaceSearch,
  PlacePin,
  PlaceInfo,
  AssignSection,
  CreateSection
}

export function AddPlaceFlowTitle(apf: AddPlaceFlow, placeName: string = null): string {
  switch (apf) {
    case AddPlaceFlow.PlaceSearch:
      return 'Add A Place';
    case AddPlaceFlow.PlacePin:
      return 'Add A Place';
    case AddPlaceFlow.PlaceInfo:
      return placeName;
    case AddPlaceFlow.AssignSection:
      return 'Choose A Section';
    case AddPlaceFlow.CreateSection:
      return 'New Section';
  }
}
