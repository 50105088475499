import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {Asset} from '../../image/dto/asset';
import {Coordinates} from '../../shared/coordinates';
import {GuideChildObject} from '../../protocols/guide-child-object';

export class Place implements Deserializable, GuideChildObject {
  public placeId: string;
  public address: string;
  public categoryIds: string[];
  public city: string;
  public coordinates: Coordinates;
  public countryCode: string;
  public createdAt: number;
  public facebookAccount: string;
  public foursquareUrl: string;
  public instagramAccount: string;
  public lastUpdated: number;
  public likeCount: number;
  public priceTier: number;
  public mainPhoto: Asset;
  public name: string;
  public phoneNumber: string;
  public postalCode: string;
  public rating: number;
  public ratingColor: string;
  public stateCode: string;
  public twitterAccount: string;
  public url: string;
  // Inherited
  public images: Asset[] = [];
  public customDescription: string;

  onDeserialize() {
    this.categoryIds = Array.from(this.categoryIds || []);
    this.coordinates = DeserializeHelper.deserializeToInstance(Coordinates, this.coordinates);
    this.mainPhoto = DeserializeHelper.deserializeToInstance(Asset, this.mainPhoto);
  }

  setAssets(imageMap: Map<string, string[]>, assets: Asset[]) {
    if (!this.images) {
      this.images = [];
    }
    const imageHashes = imageMap?.get(this.placeId);
    if (imageHashes) {
      imageHashes?.forEach((hash) => {
        const asset = assets?.find(a => a.md5Hash === hash);
        if (asset) {
          this.images.push(asset);
        }
      });
    }
  }

  setCustomDescription(descMap: Map<string, string>) {
    const customDesc = descMap?.get(this.placeId);
    if (customDesc) {
      this.customDescription = customDesc;
    }
  }
}
