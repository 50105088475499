export class StringifyUtils {

  static mapDataType = 'Map';

  static replacer(key, value) {
    const ignoredKeys: string[] = ['srcUrl', 'loading'];
    const originalObject = this[key];
    if (ignoredKeys.includes(key)) {
      return undefined;
    } else if (originalObject instanceof Map) {
      const obj = Object.create(null);
      for (const [k, v] of originalObject) {
        obj[k] = v;
      }
      return obj;
    } else {
      return value;
    }
  }

}
