import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Tabbable} from '../../../../../models/protocols/tabbable';
import {DraftsViewModel} from './drafts-view-model';
import {TypeUtils} from '../../../../../utils/type-utils';

@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss'],
  providers: [DraftsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraftsComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy, Tabbable {

  @Input() associatedInsiderId: string;
  types = TypeUtils;

  constructor(
    public vm: DraftsViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
