<div class="modal-header header-container modal-title">
  Choose a Font
</div>
<div class="modal-body">
  <div *ngFor="let font of fonts"
       matRipple
       appSelectFont
       [font]="font"
       [type]="types.FontType.Regular"
       [class.base]="font !== selectedFont"
       [class.selected]="font === selectedFont"
       (click)="selectedFont = font"
       class="font-button">
    {{font|titlecase}}
  </div>
</div>
<div class="modal-footer footer-container" fxLayout="row" fxLayoutAlign="flex-end center">
  <hr class="mb-32px">
  <button matRipple
          (click)="cancel()"
          class="custom-button grey-button mr-3">
    Cancel
  </button>
  <button matRipple
          (click)="save()"
          class="custom-button preferred-button">
    Save
  </button>
</div>

