<div class="create-guide-modal">
  <div class="title-bar">
    <div class="modal-title">
      Rearrange
    </div>
    <button matRipple (click)="closeModal()" class="custom-button text-button">Cancel</button>
  </div>
  <div class="add-place-action-container">
    <mat-tab-group dynamicHeight>
      <mat-tab label="Places">
        <ng-template mat-tab-label>
          <span class="tab-title">Places</span>
        </ng-template>
        <app-reorder-list
          [items]="customPlaces">
        </app-reorder-list>
      </mat-tab>
      <mat-tab label="Sections">
        <ng-template mat-tab-label>
          <span class="tab-title">Sections</span>
        </ng-template>
        <app-reorder-list
        [items]="sections">
        </app-reorder-list>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="section-options-footer-container">
    <hr>
    <div class="button-container">
      <button matRipple class="custom-button grey-button" (click)="closeModal()">Cancel</button>
      <button matRipple
              class="custom-button preferred-button ml-2"
              (click)="submit()"
              [disabled]="!canSubmit"
              [ngClass]="{ 'disabled' : !canSubmit}"
      >Save
      </button>
    </div>
  </div>
</div>
