<div class="map-container">
  <app-icon-generator *ngFor="let m of mapMarkers; let i = index"
                      [mapMarker]="m"
                      [selectedMarker]="vm.selectedMarker|async"
                      (src)="vm.addSrcUrl($event, m)"
                      [index]="i+1">
  </app-icon-generator>
  <agm-map #map
           class="map"
           [fitBounds]="fitBounds"
           [zoom]="zoomLevel"
           [latitude]="lat"
           [longitude]="lng"
           [fitBoundsPadding]="getBoundsPadding()"
           [styles]="getMapStyling()"
           [disableDefaultUI]="true"
           (mapReady)="mapRdy($event)"
           (mapClick)="mapClicked($event)">
    <agm-marker *ngFor="let m of mapMarkers; let i = index"
                [latitude]="m?.getLat()"
                [agmFitBounds]="true"
                [longitude]="m?.getLng()"
                [iconUrl]="(vm.urlSrcMap$|async).get(m.getUniqueId())"
                [markerDraggable]="false"
                (markerClick)="openWindow(m.getUniqueId())">
      <agm-info-window
        *ngIf="m?.allowPinWindow()"
        [isOpen]="isInfoWindowOpen(m.getUniqueId())"
        [latitude]="m?.getLat()"
        [longitude]="m.getLng()">
        <div class="window-content">
          {{m.getTitle()}}
        </div>
      </agm-info-window>
    </agm-marker>
  </agm-map>
</div>
