import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthViewModel} from '../../../viewModels/auth-view-model';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Router} from '@angular/router';
import {AuthFlow} from '../../../../../models/account/enum/auth-flow.enum';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: [
    './sign-up.component.scss',
    '../auth.component.scss',
    '../../../../../../scss/stylesheet.scss']
})
export class SignUpComponent extends BaseComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    public viewModel: AuthViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
    this.setupBindings();
  }

  ngOnDestroy() {
    this.destroy();
  }

  setupViews() {
    this.viewModel.setupSignUpFormItems();
  }

  setupBindings() {
  }

  signIn() {
    this.viewModel.nextAuthFlow.next(AuthFlow.SignIn);
  }


  submitForm() {
    this.viewModel.signUp();
  }

}
