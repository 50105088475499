<!-- Company Logo -->
<div class="row">
  <div class="col-4">
    <p class="default-semi-bold f16px"> {{ rowTitle }}</p>
    <p class="default-regular f14px"> {{ rowDescription }}</p>
  </div>
  <div class="col-4">
    <!-- Form -->
    <app-upload-asset #uploadContainer
                      *ngIf="(!logo && !logoToUpload)"
                      [allowImage]="true"
                      [allowVideo]="false"
                      [id]="id"
                      [maxAssets]="1"
                      [parentHandler]="this">
    </app-upload-asset>
    <img *ngIf="logo || logoToUpload"
         [src]="logoToUpload ? (logoToUpload.url) : (existingLogo | async)"
         alt="logo"
         class="logo">
    <div>
      <button (click)="openReplaceLogoModal()"
              *ngIf="(logo || logoToUpload)"
              class="custom-button neutral-button float-left mt-8px">
        Replace Image
      </button>
      <button (click)="openDeleteLogoModal()"
              *ngIf="logo"
              class="custom-button destructive-button float-left mt-8px">
        Delete Image
      </button>
    </div>
  </div>
  <div class="col-4"></div>
</div>
