import {Deserializable, DeserializeHelper} from '../protocols/deserializable';
import {Asset} from '../image/dto/asset';
import {Selectable} from '../protocols/selectable';

export class Location implements Deserializable, Selectable {
  public id: number;
  public name: string;
  public address: string;
  public city: string;
  public state: string;
  public stateCode: string;
  public country: string;
  public countryCode: string;
  public lastModified: number;
  public logo: Asset;
  public altLogo: Asset;

  public onDeserialize() {
    this.logo = DeserializeHelper.deserializeToInstance(Asset, this.logo);
    this.altLogo = DeserializeHelper.deserializeToInstance(Asset, this.altLogo);
  }

  getSelectionTitle(): any {
    return this.name;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getFullAddress(): string {
    return `${this.address}, ${this.getCityProv()}`;
  }

  getCityProv(): string {
    return `${this.city}, ${this.stateCode}`;
  }
}
