import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PillItem} from '../../../../../models/shared/stylesheet/pill-item';

@Component({
  selector: 'app-pill-container',
  templateUrl: './pill-container.component.html',
  styleUrls: ['./pill-container.component.scss']
})
export class PillContainerComponent implements OnInit {

  @Input() pills: PillItem[] = [];
  @Output() pillClicked = new EventEmitter<[string, boolean]>(true); // name, toggled

  constructor() { }

  ngOnInit(): void {
  }

}
