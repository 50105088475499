import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {KrugoMapMarker} from '../interfaces/krugo-map-marker';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  public selectedMarker = new BehaviorSubject<KrugoMapMarker>(null);

  constructor() {}

  changeSelectedMapMarker(m: KrugoMapMarker) {
    this.selectedMarker.next(m);
  }

}
