import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'multiplyBy'
})
export class MultiplyByPipe implements PipeTransform {

  transform(n: number, by: number): number {
    if (by !== null && by !== undefined) {
      return n * by;
    } else {
      return n;
    }
  }

}
