<app-insight-cards [style.display]="'block'"
                   [style.margin-top.rem]="2"
                   [loading]="!(vm.insightsLoaded$|async)"
                   [map]="vm.clickMap$|async"
                   [selected]="vm.selectedClickType$|async"
                   (cardClicked)="vm.changeSelectedClickType($event)">
</app-insight-cards>
<div class="chart-container">
  <div fxLayout="row" fxLayoutAlign="space-between center"  [style.margin-bottom.rem]="1.5">
    <div fxLayout="column" fxLayoutAlign="center">
      <div class="chart-title">{{vm.selectedClickType$|async|clickTypeFormatter}}</div>
      <div class="chart-date-range">{{vm.dateRangeString$|async}}</div>
    </div>
    <popper-content #popperContent [style.z-index]="100">
      <app-krugo-date-picker [maxDateRange]="32"
                             (datesChosen)="vm.datesChosen($event); popperContent.hide()">
      </app-krugo-date-picker>
    </popper-content>
    <button matRipple
         popper
         class="custom-button grey-button"
         [popper]="popperContent"
         [popperShowOnStart]="false"
         [popperTrigger]="'click'"
         [popperModifiers]="vm.popperModifier"
         [popperHideOnClickOutside]="true"
         [popperHideOnScroll]="true"
         [popperPreventOverflow]="false"
         [popperStyles]="vm.popperStyles"
         [popperPlacement]="'bottom-end'">
      <img class="options" [src]="'assets/icons/dark/outline/calendar.svg'" alt="">Change Dates</button>
  </div>
  <div class="loading-container"
       [hidden]="(vm.insightsLoaded$|async)">
    <app-loading
      [hidden]="!vm.loadingOpts?.isLoading"
      [options]="vm.loadingOpts">
    </app-loading>
  </div>
  <ngx-charts-line-chart [hidden]="!(vm.insightsLoaded$|async)"
                         [view]="dimension"
                         [scheme]="colorScheme"
                         [legend]="showLegend"
                         [showXAxisLabel]="showXAxisLabel"
                         [trimXAxisTicks]="trimXAxisTicks"
                         [showYAxisLabel]="showYAxisLabel"
                         [trimYAxisTicks]="trimYAxisTicks"
                         [xAxis]="hasXAxis"
                         [yAxis]="hasYAxis"
                         [xAxisLabel]="xAxisLabel"
                         [yAxisLabel]="yAxisLabel"
                         [timeline]="showTimeLine"
                         [results]="vm.chartData$|async"
                         (select)="onSelect($event)"
                         (activate)="onActivate($event)"
                         (deactivate)="onDeactivate($event)">
  </ngx-charts-line-chart>
</div>

