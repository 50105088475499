import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationOptions} from '../../../../models/shared/stylesheet/confirmation-options';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent extends BaseModal
  implements OnInit, AfterViewInit, OnDestroy {

  public confirmationOptions: ConfirmationOptions;

  @ViewChild('modalBody') modalBody: HTMLDivElement;

  constructor(
    private activeModal: NgbActiveModal,
    public router: Router,
    public modalService: NgbModal,
  ) {
    super();
  }

  setConfirmationOptions(opts: ConfirmationOptions) {
    this.confirmationOptions = opts;
  }

  ngOnInit(): void {
    this.setupBindings();
    this.setupViews();
  }

  ngOnDestroy() {
    this.destroy();
  }

  ngAfterViewInit() {
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  cancel() {
    this.activeModal.close(false);
  }

  continue() {
    this.activeModal.close(true);
  }

  setupBindings() {
    this.bindToBackNav();
  }

  bindToBackNav() {
    const backNavSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });
    this.pushSub(backNavSub);
  }

  setupViews() {
  }

}
