import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {InsightsDomainModel} from '../../../../../domain-models/insights-domain-model';
import {ActivatedRoute, Params} from '@angular/router';
import {debounceTime, map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Breadcrumb} from '../../../../../models/shared/stylesheet/breadcrumb';
import {SessionService} from '../../../../../services/session-service';
import {Insider} from '../../../../../models/guide/dto/insider';
import {GuidesDomainModel} from '../../../../../domain-models/guides-domain-model';
import {Guide} from '../../../../../models/guide/dto/guide';

@Injectable()
export class GuideInsightsViewModel extends BaseViewModel {

  // bread crumbs
  public breadCrumbs: Breadcrumb[] = [];

  // Url data
  private insiderId = new BehaviorSubject<string>(null);
  public insiderId$ = this.insiderId.asObservable();
  private companyId = new BehaviorSubject<string>(null);
  public companyId$ = this.companyId.asObservable();
  private guideId = new BehaviorSubject<string>(null);
  public guideId$ = this.guideId.asObservable();

  // guide
  private guide$ = combineLatest([
    this.guideId$,
    this.guidesDomainModel.allGuides$,
  ]).pipe(map(([id, guides]) => guides?.find(guide => guide.id === id)));

  // insider
  private insider$ = combineLatest([
    this.insiderId$,
    this.session.sessionContainer.notNull().pipe(map(s => s.insider))
  ]).pipe(map(([id, insider]) => {
    if (insider?.id === id) { return insider; }
    else { return null; }
  }));

  // company
  private company$ = combineLatest([
    this.companyId$,
    this.session.sessionContainer.notNull().pipe(map(s => s.insider?.companies))
  ]).pipe(map(([id, companies]) => companies?.find(c => c.id === id)));

  // page title
  public pageTitle$ = combineLatest([
    this.insider$,
    this.company$
  ]).pipe(map(([insider, company]) => {
    if (!!insider) {
      return insider.getFullName();
    } else if (!!company) {
      return company.getFullName();
    } else {
      return 'Insights';
    }
  }));

  constructor(
    private session: SessionService,
    private guidesDomainModel: GuidesDomainModel,
    private insightsDomainModel: InsightsDomainModel,
    private route: ActivatedRoute,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  setupBindings() {
    this.listenForParams();
    this.makeBreadCrumbs();
  }

  private makeBreadCrumbs() {
    combineLatest([
      this.insider$,
      this.company$,
      this.guide$,
    ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
      .subscribe(([insider, company, guide]) => {
        this.setupBreadcrumbs(guide, insider, company);
      });
  }

  public setupBreadcrumbs(guide?: Guide, insider?: Insider, company?: Insider) {
    this.breadCrumbs = [];
    if (!!guide && !!insider) {
      const insiderBC = new Breadcrumb(
        `${insider.getFullName()} Insights`,
        `${insider.id}/insights`,
      );
      insiderBC.popBack = true;
      this.breadCrumbs.push(insiderBC);
      const guideInsightsBC = new Breadcrumb(
        `Insights for ${guide.title}`,
        `${insider.id}/insights/${guide.id}/insights`,
      );
      guideInsightsBC.active = true;
      this.breadCrumbs.push(guideInsightsBC);
    } else if (!!guide && !!company) {
      const companyBC = new Breadcrumb(
        `${company.getFullName()} Insights`,
        `company/${company.id}/insights`,
      );
      this.breadCrumbs.push(companyBC);
      const guideInsightsBC = new Breadcrumb(
        `Insights for ${guide.title}`,
        `${company.id}/insights/${guide.id}/insights`,
      );
      guideInsightsBC.active = true;
      this.breadCrumbs.push(guideInsightsBC);
    }
  }

  private listenForParams() {
    this.route.params.pipe(takeUntil(this.onDestroy)).subscribe(params => {
      this.initFromUrl(params);
    });
  }

  initFromUrl(params: Params) {
    const insiderId = params.insiderId;
    if (!!insiderId) {
      this.insiderId.next(insiderId);
    }
    const companyId = params.companyId;
    if (!!companyId) {
      this.companyId.next(companyId);
    }
    const guideId = params.guideId;
    if (!!guideId) {
      this.guideId.next(guideId);
    }
  }

}
