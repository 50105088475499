import {Injectable, OnDestroy} from '@angular/core';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {GuidesDomainModel} from '../../../../domain-models/guides-domain-model';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from '../../../../services/toast-service';
import {HydratedGuide} from '../../../../models/guide/dto/hydrated-guide';
import {BaseModalViewModel} from '../../../../models/base/base-modal-view-model';
import {CustomError} from '../../../../models/shared/custom-error';
import {BehaviorSubject} from 'rxjs';
import {PillItem} from '../../../../models/shared/stylesheet/pill-item';

@Injectable()
export class ManageTagsViewModel extends BaseModalViewModel implements OnDestroy {

  public loadingOpts: LoadingOptions;
  private guide: HydratedGuide;
  private guideTags: Set<string>;
  public tagPills = new BehaviorSubject<PillItem[]>([]);

  constructor(
    private domainModel: GuidesDomainModel,
    public router: Router,
    public modalService: NgbModal,
    private toastService: ToastService,
  ) {
    super(router, modalService);
    this.init();
  }

  init(): void {
    this.setupLoadingSpinner();
  }

  private setupLoadingSpinner() {
    this.loadingOpts = LoadingOptions.default();
    this.loadingOpts.loadingText = '';
    this.loadingOpts.isLoading = false;
  }

  initGuide(g: HydratedGuide) {
    this.guide = g;
    this.guideTags = new Set(Array.from(g.tags || []).map(s => this.trimLowerCase(s)));
    this.updateTagPills();
  }

  addTag(tag: string) {
    tag = this.trimLowerCase(tag);
    if (!!tag) {
      this.guideTags.add(this.trimLowerCase(tag));
      this.updateTagPills();
    }
  }

  removeTag([tag, _]: [string, boolean]) {
    tag = this.trimLowerCase(tag);
    if (!!tag) {
      this.guideTags.delete(this.trimLowerCase(tag));
      this.updateTagPills();
    }
  }

  private updateTagPills() {
    if (this.guideTags.size > 0) {
      this.tagPills.next([...this.guideTags].map(t => new PillItem(t, true, true)));
    } else {
      this.tagPills.next([]);
    }
  }

  trimLowerCase(s: string): string {
    return s.trim().toLowerCase();
  }

  save() {
    if (!!this.guide) {
      this.guide.tags = [...this.guideTags];
      this.loadingOpts.addRequest('Updating Tags');
      this.domainModel.updateGuide(this.guide).subscribe(_ => {
        this.loadingOpts.removeRequest('Updating Tags');
        this.toastService.publishSuccessMessage('Updated Successfully', 'Guide Tags');
        this.dismissModalSubject.next(true);
      }, (e: CustomError) => {
        this.loadingOpts.removeRequest('Updating Tags');
        this.toastService.publishError(e);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
