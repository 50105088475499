import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {PreviewGuidesViewModel} from './preview-guides-view-model';

@Component({
  selector: 'app-preview-guides',
  templateUrl: './preview-guides.component.html',
  styleUrls: ['./preview-guides.component.scss'],
  providers: [PreviewGuidesViewModel],
})
export class PreviewGuidesComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('iFrame') iFrame: ElementRef;

  constructor(
    public vm: PreviewGuidesViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
