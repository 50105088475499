export class ModalUtils {

  public static chooseFontModalOptions(): any {
    return ModalUtils.mediumStatic();
  }

  public static confirmationModalOptions(): any {
    return ModalUtils.mediumStatic();
  }

  public static defaultModalOptions(): any {
    return ModalUtils.mediumStatic();
  }

  public static addNewPlaceModalOptions(): any {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
      keyboard: false
    };
  }

  private static mediumStatic(): any {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
    };
  }

  private static largeStatic(): any {
    return {
      size: 'lg',
      backdrop: 'static',
      centered: true,
    };
  }


}
