<app-loading
  [hidden]="!loadingOpts.isLoading"
  [options]="loadingOpts">
</app-loading>

<img (click)="navigateToRoot()" [src]="'assets/logo/kru-wordmark.svg'" alt="logo"
     class="nav-logo">
<ul class="nav-list custom-menu-nav">
  <ng-container *ngFor="let navItem of (navItems|async)">
    <li
      (click)="(!!navItem.company || !!navItem.user) ? navItemSelected(navItem,types.CompanySubRoute.Guides) : navItemSelected(navItem)"
      class="nav-item active custom-nav-item"
      [ngClass]="{ 'passive-selection' : navItem.locationPicker,
          'active' : isNavItemActive(navItem),
          'animating' : navItem.animating,
          'company-nav-item' : (!!navItem.company || !!navItem.user)
        }">
      <div *ngIf="!!navItem?.company || !!navItem?.user" class="nav-item-icon companyPP">
        <app-asset *ngIf="!!navItem?.user" [asset]="navItem?.user?.profilePicture" [borderRadius]="'50%'"></app-asset>
        <app-asset *ngIf="!!navItem?.company" [asset]="navItem?.company?.profilePicture"
                   [borderRadius]="'50%'"></app-asset>
      </div>
      <div *ngIf="!navItem.company && !navItem.user" class="nav-item-icon">
        <img [src]="navItem.iconSrc" alt="icon" class="nav-bar-icon">
        <img [src]="navItem.iconFilledSrc" alt="icon-filled" class="nav-bar-icon-filled">
      </div>
      <div class="nav-item-label"
           [ngClass]="{'multi-line-label': multilineNavLabel(navItem)}">
        {{navItem.name}}
      </div>
      <div *ngIf="getBadgeContentForNavItem(navItem)"
           class="nav-item-notification-pill">
        {{getBadgeContentForNavItem(navItem)}}
      </div>
      <div *ngIf="navItem.locationPicker"
           class="location-picker">
        <img alt="icon" class="nav-bar-icon" src="../../../../assets/icons/light/selector.svg">
      </div>
      <ul [hidden]="!((!!navItem?.company || !!navItem?.user) && isNavItemActive(navItem))"
          class="company-list">
        <li class="sub-nav-item"
            (click)="navItemSelected(navItem, types.CompanySubRoute.Guides);$event.stopPropagation()"
            [ngClass]="{ 'passive-selection' : navItem.locationPicker,
              'sub-active' : isNavItemActive(navItem, types.CompanySubRoute.Guides),
              'animating' : navItem.animating
            }">Guides
        </li>
        <li *ngIf="!!navItem?.company"
            class="sub-nav-item"
            (click)="navItemSelected(navItem, types.CompanySubRoute.GuideFeatures);$event.stopPropagation()"
            [ngClass]="{ 'passive-selection' : navItem.locationPicker,
              'sub-active' : isNavItemActive(navItem, types.CompanySubRoute.GuideFeatures),
              'animating' : navItem.animating
            }">Guide Categories
        </li>
        <li *ngIf="!!navItem?.company"
            class="sub-nav-item"
            (click)="navItemSelected(navItem, types.CompanySubRoute.PreviewGuides);$event.stopPropagation()"
            [ngClass]="{ 'passive-selection' : navItem.locationPicker,
              'sub-active' : isNavItemActive(navItem, types.CompanySubRoute.PreviewGuides),
              'animating' : navItem.animating
            }">Preview Guides
        </li>
        <li class="sub-nav-item"
            (click)="navItemSelected(navItem, types.CompanySubRoute.Insights);$event.stopPropagation()"
            [ngClass]="{ 'passive-selection' : navItem.locationPicker,
              'sub-active' : isNavItemActive(navItem, types.CompanySubRoute.Insights),
              'animating' : navItem.animating
            }">Insights
        </li>
        <li *ngIf="!!navItem?.company"
            class="sub-nav-item"
            (click)="navItemSelected(navItem, types.CompanySubRoute.Contributors);$event.stopPropagation()"
            [ngClass]="{ 'passive-selection' : navItem.locationPicker,
              'sub-active' : isNavItemActive(navItem, types.CompanySubRoute.Contributors),
              'animating' : navItem.animating
            }">Contributors
        </li>
        <li *ngIf="!!navItem?.user"
            class="sub-nav-item"
            (click)="navItemSelected(navItem, types.CompanySubRoute.Profile);$event.stopPropagation()"
            [ngClass]="{ 'passive-selection' : navItem.locationPicker,
              'sub-active' : isNavItemActive(navItem, types.CompanySubRoute.Profile),
              'animating' : navItem.animating
            }">Profile
        </li>
        <li *ngIf="!!navItem?.company"
            class="sub-nav-item"
            (click)="navItemSelected(navItem, types.CompanySubRoute.Settings);$event.stopPropagation()"
            [ngClass]="{ 'passive-selection' : navItem.locationPicker,
              'sub-active' : isNavItemActive(navItem, types.CompanySubRoute.Settings),
              'animating' : navItem.animating
            }">Settings
        </li>
      </ul>
    </li>
  </ng-container>
</ul>
