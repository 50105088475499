import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {LocalizedPrice} from '../../shared/localized-price';
import {Asset} from '../../image/dto/asset';

export class Room implements Deserializable {
  public bathroomCount: number;
  public bedroomCount: number;
  public description: string;
  public facilities: string;
  public id: number;
  public localizedMinPrice: LocalizedPrice;
  public localizedMaxPrice: LocalizedPrice;
  public maxPersons: number;
  public maxPrice: string;
  public minPrice: string;
  public name: string;
  public photos: Asset[];
  public roomSize: string;
  public roomType: string;
  public roomTypeId: number;

  onDeserialize() {
    this.localizedMinPrice = DeserializeHelper.deserializeToInstance(LocalizedPrice, this.localizedMinPrice);
    this.localizedMaxPrice = DeserializeHelper.deserializeToInstance(LocalizedPrice, this.localizedMaxPrice);
    this.photos = DeserializeHelper.deserializeArray(Asset, this.photos);
  }
}
