<div class="asset-container"
     [style.border-radius]="borderRadius">
  <img *ngIf="!(loading$|async) && (asset?.isImage() || !asset)"
       #image
       class="asset-attrs"
       (load)="ratio.emit(image.naturalWidth/image.naturalHeight);"
       [style.object-fit]="scaleFit ? 'contain' : 'cover'"
       [style.border-radius]="borderRadius"
       [src]="distinctAsset$|async"
       alt=""/>
  <video *ngIf="!(loading$|async) && (asset?.isVideo() || !asset)"
         #video
         class="video-attrs"
         [src]="distinctAsset$|async"
         [autoplay]="autoplay"
         [loop]="loopVideo"
         [style.object-fit]="scaleFit ? 'contain' : 'cover'"
         [style.border-radius]="borderRadius"
         (loadedmetadata)="duration.emit(video.duration); ratio.emit((video.videoWidth/video.videoHeight) || 1);"
         (ended)="this.videoEnded.emit($event.returnValue)"
         [muted]="true">
  </video>
  <div *ngIf="asset && asset?.urls === null && asset?.md5Hash === ''"
       [style.border-radius]="borderRadius"
       [style.object-fit]="scaleFit ? 'contain' : 'cover'"
       class="place-holder">
  </div>
  <div *ngIf="loading$|async"
       #loading
       class="asset-attrs">
    <ngx-shimmer-loading [width]="'100%'"
                         [height]="'100%'"
                         [shape]="'rect'"
                         [borderRadius]="borderRadius">
    </ngx-shimmer-loading>
  </div>
</div>
