import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {ActivatedRoute} from '@angular/router';
import {Insider} from '../../../../models/guide/dto/insider';
import {ToastService} from '../../../../services/toast-service';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {LoadingSpinnerSize} from '../../../../models/enum/shared/loading-spinner-size.enum';
import {InsidersDomainModel} from '../../../../domain-models/insiders-domain-model';
import {DeserializeHelper} from '../../../../models/protocols/deserializable';


@Injectable()
export class SettingsViewModel extends BaseViewModel implements OnDestroy {

  title: string;
  updatedCompany: Insider;
  public companyId: string;
  public loadingOpts: LoadingOptions;
  public profilePictureUploadCompleted = new EventEmitter<boolean>();
  public newProfilePictureFileName: string;

  constructor(
    private dm: InsidersDomainModel,
    private route: ActivatedRoute,
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupLoadingSpinner();
    this.setupBindings();
  }

  setupBindings() {
    this.loadingOpts.isLoading = true;
    const paramsSub = this.route.params.firstNotNull().subscribe(params => {
      this.companyId = params.companyId;
      this.bindToCompanyInsider();
    });
    this.pushSub(paramsSub);
  }

  private bindToCompanyInsider() {
    const companySub = this.dm.companies$.subscribe(companies => {
      const insiderCompany = companies?.find(c => c.id === this.companyId);
      this.updatedCompany = DeserializeHelper.deserializeToInstance(Insider, insiderCompany);
      // keep binding to original picture
      this.updatedCompany.profilePicture = insiderCompany.profilePicture;
      this.loadingOpts.isLoading = false;
      if (insiderCompany?.profilePicture?.fileName === this.newProfilePictureFileName) {
        this.newProfilePictureFileName = '';
        this.profilePictureUploadCompleted.emit(true);
      }
    });
    this.pushSub(companySub);
  }

  private setupLoadingSpinner() {
    this.loadingOpts = LoadingOptions.default();
    this.loadingOpts.showLoadingText = false;
    this.loadingOpts.isLoading = false;
    this.loadingOpts.spinnerSize = LoadingSpinnerSize.Medium;
    this.loadingOpts.backgroundColor = '#FCFCFC';
    this.loadingOpts.spinnerColor = '#222222';
  }

  updateInsiderObject(i: Insider) {
    this.updatedCompany = i;
  }

  updateInsiderCompany() {
    this.loadingOpts.isLoading = true;
    this.dm.updateInsider(this.updatedCompany).subscribe(result => {
      this.toastService.publishSuccessMessage('Changes have been saved.', null);
      this.updatedCompany = result;
      this.loadingOpts.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  updateInsiderProfilePicture(imageData: string) {
    const newFileName = new Date().getTime() + '.png';
    this.dm.updateInsiderProfilePicture(this.updatedCompany.id, imageData, newFileName).subscribe((_) => {
      this.newProfilePictureFileName = newFileName;
      this.toastService.publishSuccessMessage('Profile picture uploaded.', null);
    }, (err) => {
      this.toastService.publishError(err);
      this.profilePictureUploadCompleted.emit(true);
    });
  }

}
