import {MediaType} from '../../enum/dto/media-type.enum';

export class GenerateUploadUrlRequest {
  public mediaType: MediaType;
  public mediaId: string;
  public fileName: string;
  public guideId: string;
  public placeId: string;
  public insiderId: string;
  public metadata: Map<string, string>;
}
