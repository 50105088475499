import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Tabbable} from '../../../../../models/protocols/tabbable';
import {TypeUtils} from '../../../../../utils/type-utils';
import {CompanyPendingViewModel} from './company-pending-view-model';

@Component({
  selector: 'app-company-pending',
  templateUrl: './company-pending.component.html',
  styleUrls: ['./company-pending.component.scss'],
  providers: [CompanyPendingViewModel],
})
export class CompanyPendingComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy, Tabbable {

  @Input() associatedInsiderId: string;
  types = TypeUtils;

  constructor(
    public vm: CompanyPendingViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
