import {AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseModal} from '../../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {takeUntil} from 'rxjs/operators';
import {AddContributorViewModel} from './add-contributor-view-model';
import {FormGroupComponent} from '../../../../shared/components/form-group/form-group.component';
import {CompanyInsiderReq} from '../../../../../models/guide/requests/company-insider-req';

@Component({
  selector: 'app-add-contributor',
  templateUrl: './add-contributor.component.html',
  styleUrls: ['./add-contributor.component.scss'],
  providers: [AddContributorViewModel],
})
export class AddContributorComponent extends BaseModal
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('addContributorForm') addContributorForm: FormGroupComponent;
  constructor(
    public vm: AddContributorViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.vm.dismissModalSubject
      .pipe(takeUntil(this.onDestroy))
      .subscribe((close) => {
        if (close) { this.cancel(); }
      });
  }

  cancel() {
    this.activeModal.close(null);
  }

  addContributorClicked() {
    this.addContributorForm.submitForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  formSubmitted(req: CompanyInsiderReq) {
    this.vm.addContributor();
  }
}
