import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DefaultLayoutComponent} from './views/default/default-layout/default-layout.component';
import {AppAsideModule, AppSidebarModule} from '@coreui/angular';
import {DashboardModule} from './views/dashboard/dashboard.module';
import {SidebarNavComponent} from './views/default/sidebar-nav/sidebar-nav.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {StylesheetModule} from './views/stylesheet/stylesheet.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AuthInterceptorInterceptor} from './services/interceptors/auth-interceptor.interceptor';
import {SharedModule} from './views/shared/shared.module';
import {RouteReuseStrategy} from '@angular/router';
import {CacheRouteReuseStrategy} from './services/strategy/cache-route-reuse.strategy';
import {GuidesModule} from './views/guides/guides.module';
import {ProfileModule} from './views/profile/profile.module';
import {AccountModule} from './views/account/account.module';
import {CompanyModule} from './views/company/company.module';
import {HomeModule} from './views/home/home.module';
import {AgmCoreModule} from '@agm/core';
import {NgxPopperModule} from 'ngx-popper';
import {environment} from '../environments/environment';
import {FlexLayoutModule} from '@angular/flex-layout';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    ...APP_CONTAINERS,
    SidebarNavComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppSidebarModule,
    AppAsideModule,
    DashboardModule,
    GuidesModule,
    ProfileModule,
    CompanyModule,
    HomeModule,
    AccountModule,
    StylesheetModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey, libraries: ['places']
    }),
    NgxPopperModule.forRoot({}),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      maxOpened: 5,
      toastClass: 'custom-toast ngx-toastr'
    }),
    SharedModule,
    FlexLayoutModule,
  ],
  providers: [
    NgbActiveModal,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
