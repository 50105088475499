import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {GuideFeaturesViewModel} from './guide-features-view-model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalUtils} from '../../../../utils/modal-utils';
import {BaseComponent} from '../../../../models/base/base-component';
import {GuideFeature} from '../../../../models/guide/dto/guide-feature';
import {EditGuideFeatureComponent} from './edit-guide-feature/edit-guide-feature.component';
import {ConfirmationModalComponent} from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import {ConfirmationOptions} from '../../../../models/shared/stylesheet/confirmation-options';

@Component({
  selector: 'app-guide-features',
  templateUrl: './guide-features.component.html',
  styleUrls: ['./guide-features.component.scss'],
  providers: [GuideFeaturesViewModel],
})
export class GuideFeaturesComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  // Popper Data
  public popperModifier = {
    flip: {
      behavior: ['right', 'bottom', 'top']
    }
  };
  public popperStyles = {
    'background-color': '#FFFFFF'
  };

  constructor(
    public vm: GuideFeaturesViewModel,
    private modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.vm.bindToCompany();
  }

  ngOnDestroy() {
    this.destroy();
  }

  openDeleteGuideFeatureModal(gf: GuideFeature) {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = `Delete '${gf.title}'?`;
    opts.bodyText = 'Are you sure you want to delete this guide feature?';
    opts.cancelText = 'Cancel';
    opts.continueText = 'Delete Guide Feature';
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((cont) => {
      if (cont) {
        this.vm.deleteGuideFeature(gf);
      }
    });
  }

  openEditGuideFeatureModal(gf: GuideFeature) {
    const modalRef = this.modalService.open(
      EditGuideFeatureComponent,
      ModalUtils.defaultModalOptions());
    const compInstance = modalRef.componentInstance as EditGuideFeatureComponent;
    compInstance.viewModel.setGuideFeature(gf, this.vm.company.id);
    modalRef.result.then(() => {
    });
  }

}
