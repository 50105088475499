<div class="insights-container" fxLayout="column">
  <div fxLayout="column" fxLayoutAlign="center flex-start">
    <div class="insight-title">
      {{vm.pageTitle$|async}}
    </div>
    <div class="header-divider"></div>
  </div>
  <app-breadcrumbs class="breadcrumbs"
    [breadcrumbs]="vm.breadCrumbs">
  </app-breadcrumbs>
  <div #graphContainer class="graph-container">
    <app-guide-insight-chart #chart
                               [dimension]="dimensions$|async"
                               [xAxisLabel]="'Date'"
                               [yAxisLabel]="'Time'"
                               [showXAxisLabel]="false"
                               [showYAxisLabel]="false"
                               [guideId]="vm.guideId$|async"
                               [showLegend]="false">
    </app-guide-insight-chart>
  </div>
</div>
