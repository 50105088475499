import {LoggableAPI} from '../models/protocols/loggable-api';
import {Injectable} from '@angular/core';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError, map} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {GuideStatus} from '../models/guide/enum/guide-status.enum';
import {Guide} from '../models/guide/dto/guide';
import {HydratedGuide} from '../models/guide/dto/hydrated-guide';
import {CustomPlace} from '../models/guide/dto/custom-place';
import {GuideInsightReq} from '../models/guide/requests/guide-insight-req';
import {GuideInsight} from '../models/guide/dto/guide-insight';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {DeserializeHelper} from '../models/protocols/deserializable';
import {GuideFeature} from '../models/guide/dto/guide-feature';
import {MinimalHydratedGuide} from '../models/guide/dto/minimal-hydrated-guide';

@Injectable({
  providedIn: 'root'
})
export class GuideAPI implements LoggableAPI {
  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
    private http: HttpClient,
  ) {
  }

  // Variables

  public serviceName = 'Guide';

  // Guide

  public CreateGuide(guide: Guide): Observable<HydratedGuide> {
    const url = Endpoints.CreateGuide();
    return this.apiClient.postObj(HydratedGuide, url, guide).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateGuide', err));
        return throwError(err);
      })
    );
  }

  public CreateGuidePlace(guidePlace: CustomPlace): Observable<HydratedGuide> {
    const url = Endpoints.CreateGuidePlace();
    return this.apiClient.postObj(HydratedGuide, url, guidePlace).pipe(
      catchError(e => {
        console.log(e);
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateGuidePlace', err));
        return throwError(err);
      })
    );
  }

  public DeleteGuidePlace(guidePlace: CustomPlace): Observable<string> {
    const url = Endpoints.DeleteGuidePlace(guidePlace.guideId, guidePlace.id);
    return this.apiClient.deleteStr(url, null, null, 'text').pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteGuidePlace', err));
        return throwError(err);
      })
    );
  }

  public DeleteGuide(guide: Guide): Observable<string> {
    const url = Endpoints.DeleteGuide(guide.id);
    return this.apiClient.deleteStr(url, null, null, 'text').pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteGuide', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyGuides(companyId: string, status?: GuideStatus): Observable<MinimalHydratedGuide[]> {
    const url = Endpoints.GetCompanyGuides(companyId, status);
    return this.apiClient.recursiveGet<MinimalHydratedGuide>(MinimalHydratedGuide, url, null, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyGuides', err));
        return throwError(err);
      })
    );
  }

  public GetGuides(ids: string[]): Observable<HydratedGuide[]> {
    const url = Endpoints.GetGuides(ids);
    return this.apiClient.getArr(HydratedGuide, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetGuides', err));
        return throwError(err);
      })
    );
  }

  public GetGuidePlace(guideId: string, placeId: string): Observable<CustomPlace> {
    const url = Endpoints.GetGuidePlace(guideId, placeId);
    return this.apiClient.getObj(CustomPlace, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetGuidePlace', err));
        return throwError(err);
      })
    );
  }

  public GetInsiderGuides(insiderId: string, status?: GuideStatus): Observable<HydratedGuide[]> {
    const url = Endpoints.GetInsiderGuides(insiderId, status);
    return this.apiClient.getArr(HydratedGuide, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetInsiderGuides', err));
        return throwError(err);
      })
    );
  }

  public UpdateGuide(guide: HydratedGuide): Observable<HydratedGuide> {
    const url = Endpoints.UpdateGuide();
    return this.apiClient.postObj(HydratedGuide, url, guide).pipe(
      catchError(e => {
        console.log(e);
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateGuide', err));
        return throwError(err);
      })
    );
  }

  public UpdateGuidePlace(place: CustomPlace): Observable<CustomPlace> {
    const url = Endpoints.UpdateGuidePlace();
    return this.apiClient.postObj(CustomPlace, url, place).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateGuide', err));
        return throwError(err);
      })
    );
  }

  public GetGuideInsights(req: GuideInsightReq): Observable<GuideInsight> {
    const url = Endpoints.GetGuideInsights(req);
    return this.apiClient.getObj(GuideInsight, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetGuideInsights', err));
        return throwError(err);
      })
    );
  }

  public CreateGuideFeature(guideFeature: GuideFeature): Observable<GuideFeature> {
    const url = Endpoints.CreateGuideFeature();
    return this.apiClient.postObj(GuideFeature, url, guideFeature).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateGuideFeature', err));
        return throwError(err);
      })
    );
  }

  public UpdateGuideFeature(guideFeature: GuideFeature): Observable<GuideFeature> {
    const url = Endpoints.UpdateGuideFeature();
    return this.apiClient.postObj(GuideFeature, url, guideFeature).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateGuideFeature', err));
        return throwError(err);
      })
    );
  }

  public DeleteGuideFeature(guideFeature: GuideFeature): Observable<string> {
    const url = Endpoints.DeleteGuideFeature(guideFeature);
    return this.apiClient.deleteStr(url, null, null, 'text').pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteGuideFeature', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyGuideFeatures(insiderId: string): Observable<GuideFeature[]> {
    const url = Endpoints.GetCompanyGuideFeatures(insiderId);
    return this.apiClient.getArr(GuideFeature, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyGuideFeatures', err));
        return throwError(err);
      })
    );
  }

}

