import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GuideSection} from '../../../../../../models/guide/dto/guide-section';
import {CustomPlace} from '../../../../../../models/guide/dto/custom-place';
import {BaseComponent} from '../../../../../../models/base/base-component';

@Component({
  selector: 'app-rearrange-section',
  templateUrl: './rearrange-section.component.html',
  styleUrls: ['./rearrange-section.component.scss', '../../add-place-modal/add-place-modal.component.scss']
})
export class RearrangeSectionComponent extends BaseComponent implements OnInit {
  sections: GuideSection[] = [];
  selectedSection: GuideSection = null;
  customPlaces: CustomPlace[] = [];
  canSubmit: boolean = true;

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.formatPlaces();
  }

  formatPlaces() {
    let sectionIds = new Map<string, number>();
    this.sections.forEach(s => {
      sectionIds = new Map([...s.ids, ...sectionIds]);
    });
    this.customPlaces.forEach(p => {
      p.sortOrder = sectionIds.get(p.id);
    });
    this.customPlaces.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  submit() {
    this.customPlaces.forEach(p => {
      this.selectedSection.ids.set(p.id, p.sortOrder);
    });
    const deleteSectionIndex = this.sections.map(s => s.ids).indexOf(this.selectedSection.ids);
    this.sections.splice(deleteSectionIndex, 1);
    this.sections.push(this.selectedSection);
    this.activeModal.close(this.sections);
  }

  setupBindings() {
  }

  setupViews() {
  }

}
