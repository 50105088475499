import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {GuideClickType} from '../enum/guide-click-type.enum';
import {DateUtils} from '../../../utils/date-utils';

export class TimeWindowInsight implements Deserializable {
  public startTime: number;
  public endTime: number;
  public clickMap: Map<GuideClickType, number>;

  onDeserialize() {
    if (!(this.clickMap instanceof Map)) {
      this.clickMap = DeserializeHelper.deserializeGenericMap<GuideClickType, number>(this.clickMap);
    }
  }

  getWindowSize(): WindowSize {
    const deltaTime = this.endTime - this.startTime;
    if (deltaTime < DateUtils.unixOneDay()) {
      return WindowSize.Hour;
    } else if (deltaTime < DateUtils.unixOneMonth()) {
      return WindowSize.Day;
    } else if (deltaTime < DateUtils.unixOneYear()) {
      return WindowSize.Month;
    } else {
      return WindowSize.Year;
    }
  }

  getTimeAsString(t = WindowTime.Start): string {
    let date: string = '';
    let d: number = 0;
    switch (t) {
      case WindowTime.Start: d = this.startTime; break;
      case WindowTime.End: d = this.endTime; break;
    }
    switch (this.getWindowSize()) {
      case WindowSize.Hour:
        date = DateUtils.formatUnixToDateTimeNoYear(d);
        break;
      case WindowSize.Day:
        date = DateUtils.formatUnixToShorthandDate(d);
        break;
      case WindowSize.Month:
        date = DateUtils.formatUnixToDate(d);
        break;
      case WindowSize.Year:
        date = DateUtils.formatToUnixYear(d);
        break;
    }
    return date;
  }

}

export enum WindowTime {
  Start = 'Start',
  End = 'End'
}

export enum WindowSize {
  Hour = 'Hour',
  Day = 'Day',
  Month = 'Month',
  Year = 'Year'
}
