<div class="modal-body">
  <app-loading
    [hidden]="!vm?.loadingOpts?.isLoading"
    [options]="vm?.loadingOpts">
  </app-loading>
  <div class="modal-title">Manage Tags</div>
  <app-search-bar-add class="block mt-16px"
                      [placeHolder]="'Add Tag Here...'"
                      [maxLength]="25"
                      (enterClickedOutput)="vm.addTag($event)"></app-search-bar-add>
  <app-pill-container class="modal-description mt-16px"
                      [pills]="vm.tagPills|async"
                      (pillClicked)="this.vm.removeTag($event)">
  </app-pill-container>
  <app-segmented-empty-state [hidden]="(vm.tagPills|async)?.length > 0"
                             [title]="'Guide Tags'" [emptyStateHeight]="'30vh'"
                             [desc]="'Use guide tags to help organize your guides based on key words.'"
                             [loading]="false">
  </app-segmented-empty-state>
  <hr class="mb-32px">
  <div class="modal-footer-flex-end">
    <button matRipple (click)="cancel()" class="custom-button grey-button">Cancel</button>
    <button matRipple (click)="vm.save()" class="custom-button preferred-button ml-8px">Save</button>
  </div>
</div>
