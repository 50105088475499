import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {Insider} from '../models/guide/dto/insider';
import {CompanyInsiderReq} from '../models/guide/requests/company-insider-req';
import {CompanyInsiderLookup} from '../models/guide/dto/company-insider-lookup';
import {InsiderInsight} from '../models/guide/dto/insider-insight';
import {InsiderInsightReq} from '../models/guide/requests/insider-insight-req';

@Injectable({
  providedIn: 'root'
})
export class InsiderAPI implements LoggableAPI {
  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
    private http: HttpClient,
  ) {
  }

  // Variables

  public serviceName = 'Insider';

  // Insider

  public CreateInsider(insider: Insider): Observable<Insider> {
    const url = Endpoints.CreateInsider();
    return this.apiClient.postObj(Insider, url, insider).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateInsider', err));
        return throwError(err);
      })
    );
  }

  public UpdateInsider(insider: Insider): Observable<Insider> {
    const url = Endpoints.UpdateInsider();
    return this.apiClient.postObj(Insider, url, insider).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateInsider', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyInsiders(companyId: string): Observable<Insider[]> {
    const url = Endpoints.GetCompanyInsiders(companyId);
    return this.apiClient.getArr(Insider, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyInsiders', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyInsiderLookups(companyId: string): Observable<CompanyInsiderLookup[]> {
    const url = Endpoints.GetCompanyInsiderLookups(companyId);
    return this.apiClient.getArr(CompanyInsiderLookup, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyInsiderLookups', err));
        return throwError(err);
      })
    );
  }

  public GetInsider(id: string): Observable<Insider> {
    const url = Endpoints.GetInsider(id);
    return this.apiClient.getObj(Insider, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetInsider', err));
        return throwError(err);
      })
    );
  }

  public AddCompanyInsider(req: CompanyInsiderReq): Observable<CompanyInsiderLookup[]> {
    const url = Endpoints.AddCompanyInsider();
    return this.apiClient.postArr(CompanyInsiderLookup, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'AddCompanyInsider', err));
        return throwError(err);
      })
    );
  }

  public UpdateCompanyInsider(req: CompanyInsiderReq): Observable<CompanyInsiderLookup[]> {
    const url = Endpoints.UpdateCompanyInsider();
    return this.apiClient.postArr(CompanyInsiderLookup, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateCompanyInsider', err));
        return throwError(err);
      })
    );
  }

  public DeleteCompanyInsider(companyId: string, insiderId: string): Observable<string> {
    const url = Endpoints.DeleteCompanyInsider(companyId, insiderId);
    return this.apiClient.deleteStr(url, null, null, 'text').pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteCompanyInsider', err));
        return throwError(err);
      })
    );
  }

  public GetInsiderInsights(req: InsiderInsightReq): Observable<InsiderInsight> {
    const url = Endpoints.GetInsiderInsights(req);
    return this.apiClient.getObj(InsiderInsight, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetInsiderInsights', err));
        return throwError(err);
      })
    );
  }

}
