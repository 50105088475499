import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EditPlaceViewModel} from './edit-place-view-model';
import {BaseComponent} from '../../../../../models/base/base-component';
import {ComponentCanDeactivate} from '../../../../../models/protocols/component-can-deactivate';
import {FormGroupComponent} from '../../../../shared/components/form-group/form-group.component';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-edit-place',
  templateUrl: './edit-place.component.html',
  styleUrls: ['./edit-place.component.scss',
    '../../../../../../scss/stylesheet.scss',
    '../guide.component.scss'],
  providers: [EditPlaceViewModel],
})
export class EditPlaceComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy, ComponentCanDeactivate {

  @ViewChild('formGroupComponent') formGroupComponent: FormGroupComponent;
  private featuredImageRatio = new BehaviorSubject<number>(1);
  public featuredImageRatio$ = this.featuredImageRatio.pipe(distinctUntilChanged(), debounceTime(250));

  constructor(
    public viewModel: EditPlaceViewModel,
  )
  {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.viewModel.placeFormGroup = this.formGroupComponent;
  }

  canDeactivate(): boolean | Promise<any> {
    return !this.formGroupComponent?.shouldDisplayAbandonDialog() ?? true;
  }

  setFeaturedRatio(x) {
    this.featuredImageRatio.next(x);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
