import {Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {TabBarItem} from '../../../../models/shared/stylesheet/tab-bar-item';
import {DraftsComponent} from './drafts/drafts.component';
import {PendingComponent} from './pending/pending.component';
import {ApprovedComponent} from './approved/approved.component';
import {ArchivedComponent} from './archived/archived.component';
import {GuidesDomainModel} from '../../../../domain-models/guides-domain-model';
import {debounceTime, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {SessionService} from '../../../../services/session-service';
import {CompanyPendingComponent} from './company-pending/company-pending.component';
import {SegmentedGuidesDomainModel} from '../../../../domain-models/segmented-guides-domain-model';

@Injectable()
export class SegmentedGuidesViewModel extends BaseViewModel implements OnDestroy {

  public tabs = new BehaviorSubject<TabBarItem[]>([]);
  public insiderId = new BehaviorSubject<string>('');
  private adminCompanyIds = this.session.sessionContainer.pipe(map(sess => sess?.insider?.adminCompanyIds));

  public selectedTab = combineLatest([
    this.insiderId,
    this.segDM.positions
  ]).pipe(takeUntil(this.onDestroy), debounceTime(100))
    .pipe(map(([id, positions]) => positions.get(id)));

  // update selected tab
  private updateSelected = new BehaviorSubject<number>(null);
  private updateSelectedMech = combineLatest([
    this.insiderId.notNull(),
    this.updateSelected
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([insiderId, n]) => {
      if (n !== null && n !== undefined && n > -1) {
        this.segDM.updatePosition(insiderId, n);
        this.updateSelected.next(null);
      }
    });

  private listenToInsiderId = combineLatest([
    this.insiderId,
    this.adminCompanyIds,
    this.selectedTab
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1), distinctUntilChanged())
    .subscribe(([id, adminIds, selected]) => {
      if (!!adminIds && adminIds?.contains(id)) {
        this.setupTabs(true, selected);
      } else {
        this.setupTabs(false, selected);
      }
    });

  constructor(
    private dm: GuidesDomainModel,
    private session: SessionService,
    private segDM: SegmentedGuidesDomainModel,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  private setupTabs(isCompany = false, selected: number) {
    const tabs = [];
    const drafts = new TabBarItem(DraftsComponent, 1, 'Drafts', null);
    let pending: TabBarItem;
    if (isCompany) {
      pending = new TabBarItem(CompanyPendingComponent, 2, 'Pending', null);
    } else {
      pending = new TabBarItem(PendingComponent, 2, 'Pending', null);
    }
    const approved = new TabBarItem(ApprovedComponent, 3, 'Approved', null);
    const archived = new TabBarItem(ArchivedComponent, 4, 'Archived', null);
    if (selected > -1) {
      switch (selected) {
        case 1: {
          pending.active = true;
          break;
        }
        case 2: {
          approved.active = true;
          break;
        }
        case 3: {
          archived.active = true;
          break;
        }
        default: {
          drafts.active = true;
          break;
        }
      }
    }
    tabs.push(drafts, pending, approved, archived);
    this.tabs.next(tabs);
  }

  public setInsiderId(id: string) {
    this.insiderId.next(id);
  }

  public updateSelectedTab(n: number) {
    this.updateSelected.next(n);
  }

  ngOnDestroy() {
    this.destroy();
  }

}
