import {ApiClient} from './api-client';
import {Observable, throwError} from 'rxjs';
import {HydratedUser} from '../models/account/dto/hydrated-user';
import {Endpoints} from './endpoints';
import {SignInRequest} from '../models/account/requests/sign-in-request';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {LoggingService} from '../services/logging-service';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {CustomError} from '../models/shared/custom-error';
import {SignOutRequest} from '../models/account/requests/sign-out-request';
import {User} from '../models/account/dto/user';
import {RefreshSessionRequest} from '../models/account/requests/refresh-session-request';
import {SignUpRequest} from '../models/account/requests/sign-up-request';
import {SessionService} from '../services/session-service';
import {CodeDeliveryDetails} from '../models/account/dto/code-delivery-details';
import {ForgotPasswordRequest} from '../models/account/requests/forgot-password-request';

@Injectable({
  providedIn: 'root',
})
export class AccountAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
    private sessionService: SessionService
  ) {
  }

  // Variables

  public serviceName = 'Account';

  // Session

  SignIn(req: SignInRequest): Observable<HydratedUser> {
    const url = Endpoints.SignIn();
    return this.apiClient.postObj<HydratedUser>(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SignIn', err));
        return throwError(err);
      })
    );
  }

  SignUp(req: SignUpRequest): Observable<HydratedUser> {
    const url = Endpoints.SignUp();
    return this.apiClient.postObj<HydratedUser>(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SignUp', err ));
        return throwError(err);
      })
    );
  }

  ConfirmCode(req: SignUpRequest): Observable<HydratedUser> {
    const url = Endpoints.ConfirmCode();
    return this.apiClient.postObj<HydratedUser>(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ConfirmCode', err ));
        return throwError(err);
      })
    );
  }

  ResendCode(req: SignUpRequest): Observable<HydratedUser> {
    const url = Endpoints.ResendCode();
    const headers = new Headers().append('UserId', this.sessionService.getKrugoId());
    return this.apiClient.getObj<HydratedUser>(HydratedUser, url, headers).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ResendCode', err ));
        return throwError(err);
      })
    );
  }

  SendForgotPasswordCode(phoneNumber: string): Observable<CodeDeliveryDetails> {
    const url = Endpoints.SendForgotPasswordCode(phoneNumber);
    return this.apiClient.getObj<CodeDeliveryDetails>(CodeDeliveryDetails, url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SendForgotPasswordCode', err ));
        return throwError(err);
      })
    );
  }

  ResetForgottenPassword(req: ForgotPasswordRequest): Observable<HydratedUser> {
    const url = Endpoints.ResetForgottenPassword();
    return this.apiClient.postObj<HydratedUser>(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'ResetForgottenPassword', err ));
        return throwError(err);
      })
    );
  }

  SignOut(req: SignOutRequest): Observable<any> {
    const url = Endpoints.SignOut();
    return this.apiClient.postObj<any>(User, url, req, null, 'text').pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SignOut', err));
        return throwError(err);
      })
    );
  }

  RefreshSession(req: RefreshSessionRequest): Observable<HydratedUser> {
    const url = Endpoints.RefreshSession();
    return this.apiClient.postObj<HydratedUser>(HydratedUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'RefreshSession', err));
        return throwError(err);
      })
    );
  }

}
