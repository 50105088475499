import {
  AfterViewInit,
  Component,
  EventEmitter,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  Output
} from '@angular/core';
import {BaseComponent} from '../../../../../../models/base/base-component';
import {KrugoMapMarker} from '../../interfaces/krugo-map-marker';

@Component({
  selector: 'app-icon-generator',
  templateUrl: './icon-generator.component.html',
  styleUrls: ['./icon-generator.component.scss']
})
export class IconGeneratorComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('myCanvas', {static: true}) myCanvas: ElementRef;
  @Input() mapMarker: KrugoMapMarker;
  @Input() fillColor: string = '#FFCE00';
  @Input() index: number = 1;
  @Input() fontColor: string = '#333333';
  @Input() selectedMarker: KrugoMapMarker;
  @Output() src = new EventEmitter<string>(true);
  public context: CanvasRenderingContext2D;
  private radius = 16;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.drawIcon();
    this.setupBindings();
  }

  drawIcon() {
    // circle
    this.context = this.myCanvas.nativeElement.getContext('2d');
    this.context.canvas.width = this.radius * 2.1;
    this.context.canvas.height = Math.ceil(this.radius * 2 * 1.19);
    this.context.beginPath();
    this.context.arc(this.radius, this.radius, this.radius, 0, 2 * Math.PI, false);
    if (this.mapMarker?.getUniqueId() === this.selectedMarker?.getUniqueId()) {
      this.context.fillStyle = this.shadeColor(this.fillColor, -20);
    } else {
      this.context.fillStyle = this.fillColor;
    }
    this.context.fill();
    // diamond
    this.context.save();
    this.context.translate(this.context.canvas.width / 2, this.context.canvas.height / 2);
    this.context.rotate(Math.PI / 4);
    this.context.translate(-(this.radius / 2), -(this.radius / 2));
    this.context.fillRect(this.radius / 2.8, this.radius / 2.8, this.radius, this.radius);
    this.context.restore();
    if (this.index !== null && this.index !== undefined && this.index > -1) { this.addNumber(); }
    // output image
    this.src.emit(this.context.canvas.toDataURL('image/png'));
  }

  addNumber() {
    this.context.textAlign = 'center';
    this.context.fillStyle = this.fontColor;
    this.context.fillText(this.index.toString(10), this.radius, this.radius * 1.3);
  }

  private shadeColor(col, amt) {

    col = col.replace(/^#/, '');
    if (col.length === 3) {
      col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];
    }

    let [r, g, b] = col.match(/.{2}/g);
    ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt]);

    r = Math.max(Math.min(255, r), 0).toString(16);
    g = Math.max(Math.min(255, g), 0).toString(16);
    b = Math.max(Math.min(255, b), 0).toString(16);

    const rr = (r.length < 2 ? '0' : '') + r;
    const gg = (g.length < 2 ? '0' : '') + g;
    const bb = (b.length < 2 ? '0' : '') + b;

    return `#${rr}${gg}${bb}`;
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.drawIcon();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
