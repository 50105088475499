import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {Asset} from '../../image/dto/asset';
import {InsiderOptions} from './insider-options';
import {Cachable} from '../../protocols/cachable';
import {DateUtils} from '../../../utils/date-utils';
import {CachePolicy} from '../../enum/shared/cachable-image-policy.enum';

export class Insider implements Deserializable, Cachable {
    public companies: Insider[];
    public description: string;
    public facebookUrl: string;
    public firstName: string;
    public id: string;
    public instagramUrl: string;
    public lastName: string;
    public profilePicture: Asset;
    public twitterUrl: string;
    public websiteUrl: string;
    public embeddedWebsiteUrl: string;
    public companyName: string;
    public adminCompanyIds: string[] = [];
    public options: InsiderOptions;

    // Caching
    cachedTime: number;

    static buildArrayCacheKey(companyId: string): string {
        return `Insiders-${companyId}`;
    }

    static buildCacheKey(id: string): string {
        return `Insider-${id}`;
    }

    getFullName(): string {
        if (!!this.companyName) {
            return this.companyName;
        }
        return `${this.firstName} ${this.lastName}`;
    }

    onDeserialize() {
        this.companies = DeserializeHelper.deserializeArray(Insider, this.companies);
        this.profilePicture = DeserializeHelper.deserializeToInstance(Asset, this.profilePicture);
        this.adminCompanyIds = Array.from(this.adminCompanyIds || []);
        this.options = DeserializeHelper.deserializeToInstance(InsiderOptions, this.options);
    }

    cacheExpirySeconds(): number {
        return DateUtils.unixOneDay();
    }

    cacheKey(): string {
        return Insider.buildCacheKey(this.id);
    }

    imageCachePolicy(): CachePolicy {
        return CachePolicy.Session;
    }

    isExpired(): boolean {
        const expiresAt = this.cachedTime + this.cacheExpirySeconds();
        return DateUtils.currentTimestamp() > expiresAt;
    }

}
