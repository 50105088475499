import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DefaultLayoutComponent} from './views/default/default-layout/default-layout.component';
import {AuthGuard} from './services/guards/auth.guard';
import {PermissionGuard} from './services/guards/permission.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'guides',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Krugo Partners Dashboard'
    },
    children: [
      {
        path: 'guides',
        loadChildren: () => import('./views/guides/guides.module').then(m => m.GuidesModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
      {
        path: 'stylesheet',
        loadChildren: () => import('./views/stylesheet/stylesheet.module').then(m => m.StylesheetModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
      {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
      {
        path: 'company',
        loadChildren: () => import('./views/company/company.module').then(m => m.CompanyModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
      {
        path: 'user',
        loadChildren: () => import('./views/company/company.module').then(m => m.CompanyModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
