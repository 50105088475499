import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DatatableOptions} from '../../../../models/shared/stylesheet/datatable-options';
import {DatatableColumn, DatatableColumnType} from '../../../../models/shared/stylesheet/datatable-column';
import {DatatableDataProvider} from 'src/app/models/protocols/datatable-data-provider';
import {Subscribable} from '../../../../models/base/subscribable';
import {DatatableData} from '../../../../models/protocols/datatable-data';
import {DatatableAction} from '../../../../models/shared/stylesheet/datatable-action';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DatatableFilterOption} from '../../../../models/shared/stylesheet/datatable-filter-option';
import {Selectable} from '../../../../models/protocols/selectable';
import {DatatableFilter} from '../../../../models/shared/datatable-filter';
import '../../../../utils/array.extensions';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: [
    './data-table.component.scss',
    '../form-group/form-group.component.scss',
    '../drop-down/drop-down.component.scss',
    '../checkboxes/checkbox/checkbox.component.scss',
    '../checkboxes/checkbox-container/checkbox-container.component.scss'
  ]
})
export class DataTableComponent extends Subscribable implements OnInit, OnDestroy, OnChanges {

  @Input() public tableOptions: DatatableOptions = new DatatableOptions();
  @Input() public tableData: DatatableDataProvider;
  @Input() public tableFilter: DatatableFilter;
  @Input() public resetTable = new EventEmitter();
  @Input() public filterApplied = new EventEmitter();
  @Input() public updateTableData: EventEmitter<DatatableDataProvider> = new EventEmitter<DatatableDataProvider>();

  @Output() public rowClicked: EventEmitter<DatatableData> = new EventEmitter<DatatableData>();
  @Output() public nestedRowClicked: EventEmitter<DatatableData> = new EventEmitter<DatatableData>();
  @Output() public rowActionClicked: EventEmitter<[DatatableAction, DatatableData]> = new EventEmitter<[DatatableAction, DatatableData]>();
  @Output() public nestedRowActionClicked: EventEmitter<[DatatableAction, DatatableData]> =
    new EventEmitter<[DatatableAction, DatatableData]>();

  public columnTypes = DatatableColumnType;
  // Table Sorting
  private sortedColumn: DatatableColumn = null;
  private ascending: boolean;
  // Table Filtering
  public searchQueryStringChanged: Subject<string> = new Subject<string>();
  // Pagination
  private page: number = 0;
  public numberOfEntriesString: string = '25';
  private numberOfEntries: number = 25;
  private maxNumberOfPages: number = 5;
  // Nesting
  public expandedIndex: number = null;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.tableReset();
    this.setupBindings();
    this.setTableDefaults();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.tableReset();
  }

  setTableDefaults() {
    if (this.tableOptions.defaultEntryCount > 0) {
      this.numberOfEntriesString = this.tableOptions.defaultEntryCount.toString();
      this.entriesChanged(this.numberOfEntriesString);
    }
  }

  setupBindings() {
    // Bind reset event emitter
    const resetSub = this.resetTable.subscribe((_) => {
      this.tableReset();
    });
    this.pushSub(resetSub);
    // Bind reset event emitter
    const filterSub = this.filterApplied.subscribe((_) => {
      this.filterChanged();
    });
    this.pushSub(filterSub);
    // Bind new data
    const updateSub = this.updateTableData.subscribe((newTableData: DatatableDataProvider) => {
      this.tableData = newTableData;
      this.tableReset();
    });
    this.pushSub(updateSub);
    // Debounce the search input
    const debounceSearch = this.searchQueryStringChanged.pipe(
      debounceTime(325),
      distinctUntilChanged())
      .subscribe(val => {
        this.tableFilter.searchQueryString = val;
        this.filterChanged();
      });
    this.pushSub(debounceSearch);
  }

  // Table Action

  public onCustomFilterChange(cf: DatatableFilterOption, selOpt: Selectable) {
    cf.selectedOption = selOpt;
    this.tableFilter.customFilters.forEach((f) => {
      if (f.updateActiveOptions) {
        f.updateActiveOptions(selOpt, f);
      }
    });
    this.filterChanged();
  }

  public onQueryStringChange(e): void {
    if ((e.key === 'Backspace' && this.tableFilter.searchQueryString === '') || this.tableFilter.searchQueryString === '') {
      this.filterChanged();
      this.searchQueryStringChanged.next('');
    } else {
      this.searchQueryStringChanged.next(this.tableFilter.searchQueryString);
    }
  }

  public handleRowClicked(rowData: DatatableData, rowIndex: number): void {
    if (this.tableOptions.rowExpansionDisabled) {
      this.rowClicked.emit(rowData);
    } else {
      if (this.expandedIndex === rowIndex) {
        this.expandedIndex = null;
      } else {
        this.expandedIndex = rowIndex;
      }
    }
  }

  public handleNestedRowClicked(nestedRowData: DatatableData): void {
    if (this.tableOptions.selectNestedRowOnClick) {
      this.selectSingleOptionClicked(!this.isChecked(nestedRowData), nestedRowData);
    } else {
      this.nestedRowClicked.emit(nestedRowData);
    }
  }

  public handleRowActionClicked(event, action: DatatableAction, rowData: DatatableData): void {
    event.stopPropagation();
    this.rowActionClicked.next([action, rowData]);
  }

  public handleNestedRowActionClicked(event, action: DatatableAction, rowData: DatatableData): void {
    event.stopPropagation();
    this.nestedRowActionClicked.next([action, rowData]);
  }

  // Checkbox

  public selectAllClicked(event) {
    const checked = event.target.checked;
    this.tableOptions.bulkEditPercentageChecked = (this.tableData.displayedData.length === 0) ? 0 : checked ? 1 : 0;
    if (checked) {
      // select all displayed items
      this.tableData.displayedData.forEach((d) => {
        const selectedIds: string[] = d.getChildrenUniqueIds();
        selectedIds.forEach((id) => {
          if (!this.tableOptions.bulkEditSelectedIds.contains(id)) {
            this.tableOptions.bulkEditSelectedIds.push(id);
          }
        });
      });
    } else {
      // deselect all displayed items
      this.tableData.displayedData.forEach((d) => {
        const selectedIds: string[] = d.getChildrenUniqueIds();
        selectedIds.forEach((id) => {
          const removeIndex = this.tableOptions.bulkEditSelectedIds.indexOf(id);
          if (removeIndex > -1) {
            this.tableOptions.bulkEditSelectedIds.splice(removeIndex, 1);
          }
        });
      });
    }
  }

  public selectSingleOptionClicked(event: any, rowData: DatatableData) {
    let checked = false;
    if (typeof event === 'boolean') {
      checked = event;
    } else {
      checked = event.target.checked;
    }
    const selectedIds: string[] = rowData.getChildrenUniqueIds();
    selectedIds.forEach((id) => {
      this.selectItem(checked, id);
    });
    this.tableOptions.bulkEditSelectedIds.sort();
    this.setCheckAllState();
  }

  public setCheckAllState() {
    // check number of checked items from displayedData
    let checkedCount = 0;
    this.tableData.displayedData.forEach((d) => {
      const selectedIds: string[] = d.getChildrenUniqueIds();
      selectedIds.forEach((id) => {
        if (this.tableOptions.bulkEditSelectedIds.contains(id)) {
          checkedCount++;
        }
      });
    });
    let displayedDataNestedCount = 0;
    this.tableData.displayedData.forEach((d) => {
      displayedDataNestedCount += d.getChildrenUniqueIds().length;
    });
    this.tableOptions.bulkEditPercentageChecked = (displayedDataNestedCount === 0) ? 0 : checkedCount / displayedDataNestedCount;
  }

  public isChecked(rowData: DatatableData) {
    if (rowData.getChildrenUniqueIds().length > 0) {
      // check if all children are checked
      let allChildrenChecked = true;
      rowData.getChildrenUniqueIds().forEach((nid) => {
        if (!this.tableOptions.bulkEditSelectedIds.includes(nid) && !this.tableOptions.preselectedIds.includes(nid)) {
          allChildrenChecked = false;
        }
      });
      return allChildrenChecked;
    } else {
      return false;
    }
  }

  public isPreselected(rowData: DatatableData): boolean {
    // current use case is only for nested items with 1 child, but would support both
    let allChildrenPreselected = true;
    rowData.getChildrenUniqueIds().forEach((nid) => {
      if (!this.tableOptions.preselectedIds.includes(nid)) {
        allChildrenPreselected = false;
      }
    });
    return allChildrenPreselected;
  }

  public isNestedParentIndeterminate(rowData: DatatableData) {
    const childCount = rowData.getChildrenUniqueIds().length;
    let selectedChildCount = 0;
    rowData.getChildrenUniqueIds().forEach((childId) => {
      if (this.tableOptions.bulkEditSelectedIds.includes(childId) || this.tableOptions.preselectedIds.includes(childId)) {
        selectedChildCount++;
      }

    });
    const percentSelected = selectedChildCount / childCount;
    return percentSelected > 0 && percentSelected < 1;
  }

  public getBulkEditSelectionCount(): string {
    if (this.tableOptions.bulkEditSelectedIds.length > 0) {
      return `(${this.tableOptions.bulkEditSelectedIds.length})`;
    } else {
      return '';
    }
  }

  private selectItem(checked: boolean, id: string) {
    const indexPosition = this.tableOptions.bulkEditSelectedIds.indexOf(id);
    if (checked && indexPosition === -1) {
      // add index
      this.tableOptions.bulkEditSelectedIds.push(id);
    } else if (!checked && indexPosition > -1) {
      // remove index
      this.tableOptions.bulkEditSelectedIds.splice(indexPosition, 1);
    }
  }

  // Table Layout Options

  public resetSearchAndFilters() {
    this.clearSearch();
    this.clearFilters();
  }

  public clearFilters() {
    this.tableFilter.clearFilters();
    this.tableReset();
  }

  public getCellContent(column: DatatableColumn, rowData: any): string {
    if (column.type === DatatableColumnType.Label) {
      // No default value if value is empty string
      return column.getCellValue(rowData);
    } else {
      return column.getCellValue(rowData) || '--';
    }
  }

  public getNestedCellContent(column: DatatableColumn, nestedRowData: any): string {
    if (column.type === DatatableColumnType.Label) {
      // No default value if value is empty string
      return column.getNestedCellValue(nestedRowData);
    } else {
      return column.getNestedCellValue(nestedRowData) || '--';
    }
  }

  public getTooltipContent(column: DatatableColumn, nestedRowData: any): string {
    if (column.getTooltipValue) {
      return column.getTooltipValue(nestedRowData);
    } else {
      return null;
    }
  }

  public getCellClassName(column: DatatableColumn, rowIndex: number): string {
    const cellVal = this.getCellContent(column, rowIndex);
    if (cellVal && cellVal !== '' && cellVal !== '--') {
      return column.className || '';
    } else {
      return '';
    }
  }

  public headerClicked(column: DatatableColumn): void {
    if (column.disableSorting) {
      return;
    }
    this.expandedIndex = null;
    this.resetPagination();

    if (this.sortedColumn === column && this.ascending === true) {
      this.ascending = false;
    } else if (this.sortedColumn === null) {
      this.sortedColumn = column;
      this.ascending = true;
    } else if (this.sortedColumn !== column) {
      this.sortedColumn = column;
      this.ascending = true;
    } else {
      this.sortedColumn = null;
      this.ascending = false;
    }

    if (this.sortedColumn !== null) {
      this.sortByColumn();
    } else {
      this.tableReset();
    }
  }

  public isAscending(column: any): boolean {
    return this.sortedColumn === column && this.ascending;
  }

  public isDescending(column: any): boolean {
    return this.sortedColumn === column && !this.ascending;
  }

  public filterChanged(): void {
    this.expandedIndex = null;
    if (!this.tableFilter.searchQueryString) {
      this.tableFilter.searchQueryString = '';
    }
    // Filter based on query string
    const searchVal = this.tableFilter.searchQueryString.toLowerCase();
    this.resetPagination();
    let filterByCheckbox = false;
    if (this.tableFilter.enableFilterCheckbox) {
      filterByCheckbox = this.tableFilter.checkbox.checked;
    }
    this.tableData.filterData(searchVal, this.tableOptions.columns, filterByCheckbox, this.tableFilter.customFilters);
    if (this.sortedColumn) {
      this.sortByColumn();
    }
    this.paginate();
    // Scroll to top
  }

  public entriesChanged(entries: string): void {
    this.numberOfEntries = parseInt(entries, 10);
    this.tableReset();
  }

  // Filtering

  public clearSearch(): void {
    this.tableFilter.searchQueryString = '';
    this.filterChanged();
  }

  // Pagination

  public beginAmount(): number {
    return this.page * this.numberOfEntries;
  }

  public endAmount(): number {
    return this.page * this.numberOfEntries + this.numberOfEntries;
  }

  public nextDisabled(): boolean {
    return this.page + 1 >= this.numberOfPages();
  }

  public previousDisabled(): boolean {
    return this.page === 0;
  }

  public startDisabled(): boolean {
    return this.previousDisabled();
  }

  public endDisabled(): boolean {
    return this.nextDisabled();
  }

  public previousClicked(): void {
    if (this.page > 0) {
      this.page--;
      this.paginate();
    }
  }

  public nextClicked(): void {
    if (this.page + 1 < this.numberOfPages()) {
      this.page++;
      this.paginate();
    }
  }

  public pageClicked(page): void {
    const selected = parseInt(page, 10) - 1;
    this.page = isNaN(selected) ? this.numberOfPages() - 1 : selected;
    this.paginate();
  }

  public endClicked(): void {
    this.page = this.numberOfPages() - 1;
    this.paginate();
  }

  public startClicked(): void {
    this.page = 0;
    this.paginate();
  }

  public isActivePage(page): boolean {
    return this.page === parseInt(page, 10) - 1;
  }

  public pages(): string[] {
    const total = this.numberOfPages();
    const arr = [];
    const sidePadding = 2;
    const maxRun = 3;
    let start;
    let end;

    if (total <= this.maxNumberOfPages) {
      start = 0;
      end = total;
    } else if (this.page >= total - maxRun) {
      start = total - this.maxNumberOfPages;
      end = total;
    } else if (this.page >= maxRun) {
      start = this.page - sidePadding;
      end = start + this.maxNumberOfPages;
    } else if (this.page < maxRun) {
      start = 0;
      end = this.maxNumberOfPages;
    }

    for (let i = start + 1; i < end + 1; i++) {
      arr.push(i.toString());
    }
    return arr;
  }

  private sortByColumn(): void {
    this.tableData.sortByColumn(this.sortedColumn, this.ascending);
    this.paginate();
  }

  private resetPagination(): void {
    this.page = 0;
  }

  private numberOfPages(): number {
    if (this.tableData.filteredData && this.numberOfEntries) {
      return Math.ceil(this.tableData.filteredData.length / this.numberOfEntries);
    } else {
      return 0;
    }
  }

  private paginate(): void {
    const begin = this.beginAmount();
    const end = this.endAmount();
    this.tableData.displayedData = this.tableData.filteredData.slice(begin, end);
    this.expandedIndex = null;
    this.setCheckAllState();
  }

  //  No Results

  public getNoResultsTitle(): string {
    if (this.tableFilter.searchQueryString && this.tableFilter.searchQueryString.length > 0) {
      return `No results for "${this.tableFilter.searchQueryString}"`;
    } else {
      return `No results matching the active filters.`;
    }
  }

  public getNoResultsText(): string {
    if (this.tableFilter.searchQueryString?.length > 0) {
      return `It doesn't look like this product exists in your inventory. Try a different search term.`;
    } else {
      return `It doesn't look like this product exists in your inventory. Try modifying your filters.`;
    }
  }

  private tableReset(): void {
    if (this.tableData.data) {
      this.tableData.resetData();
      this.filterChanged();
      this.paginate();
    }
  }

}
