import {DateUtils} from '../../../utils/date-utils';

export class InsiderInsightReq {

  public companyId: string;
  public insiderId: string;
  public when: string;
  public startTime: number;
  public endTime: number;
  public uniqueImpressions: boolean;

  constructor(
    isCompany: boolean = true,
    insiderId: string,
    when: string,
    startTime: moment.Moment,
    endTime: moment.Moment,
    uniqueImpressions: boolean
  ) {
    if (isCompany) {
      this.companyId = insiderId;
    } else {
      this.insiderId = insiderId;
    }
    this.insiderId = insiderId;
    this.when = when;
    this.startTime = startTime.unix();
    this.endTime = endTime.unix();
    this.uniqueImpressions = uniqueImpressions;
  }

  buildKey(): string {
    if (!!this.companyId) {
      return DateUtils.buildDateKey(this.companyId, this.when);
    } else {
      return DateUtils.buildDateKey(this.insiderId, this.when);
    }
  }

  equalityCheck(other: InsiderInsightReq) {
    return this.insiderId === other.insiderId &&
      this.when === other.when &&
      this.startTime === other.startTime &&
      this.endTime === other.endTime &&
      this.uniqueImpressions === other.uniqueImpressions;
  }

}
