<app-loading
  [hidden]="!viewModel.loadingOpts.isLoading"
  [options]="viewModel?.loadingOpts">
</app-loading>

<div class="create-guide-modal">
  <img *ngIf="viewModel.createFlow.valueOf() > 0" (click)="viewModel.goBack()" [src]="'assets/icons/dark/arrow-left.svg'">
  <div class="title-bar">
    <div class="modal-title">
      {{viewModel.getTitle()}}
    </div>
    <button matRipple (click)="this.viewModel.closeModal()" class="custom-button text-button">Cancel</button>
  </div>
  <div class="create-guide-action-container">
    <!--    Choose Author Flow-->
    <app-choose-author *ngIf="viewModel.createFlow === createGuideFlow.ChooseAuthor"
                       [associatedId]="associatedId"
                       (selectedAuthor)="viewModel.authorSelected($event)">
    </app-choose-author>
    <!--    Choose City Flow-->
    <app-choose-city *ngIf="viewModel.createFlow === createGuideFlow.ChooseCity">
    </app-choose-city>
    <!--    Add Guide Info Flow-->
    <app-add-guide-info *ngIf="viewModel.createFlow === createGuideFlow.AddGuideInfo">
    </app-add-guide-info>
  </div>
</div>
