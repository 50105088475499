import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {UploadImageInterface} from '../../components/upload-asset/upload-image-interface';
import {CustomFile} from '../../../../models/shared/custom-file';

@Component({
  selector: 'app-edit-profile-photo-modal',
  templateUrl: './edit-profile-photo-modal.component.html',
  styleUrls: ['./edit-profile-photo-modal.component.scss']
})
export class EditProfilePhotoModalComponent extends BaseModal implements OnInit, AfterViewInit, OnDestroy, UploadImageInterface {

  croppedPngBase64: string = null;
  roundedOverlay = true;
  uploadedImage: CustomFile = null;
  initialImageSrc: string = null;
  replacingImage = false;

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupBindings();
    this.setupViews();
  }

  ngOnDestroy() {
    this.destroy();
  }

  ngAfterViewInit() {
  }

  cancel() {
    this.activeModal.close(false);
  }

  save() {
    this.activeModal.close(this.croppedPngBase64);
  }

  setupBindings() {

  }

  setupViews() {

  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedPngBase64 = event.base64;
  }

  imageLoaded() {
  }

  cropperReady() {
  }

  loadImageFailed() {
  }

  fileList(f: CustomFile[], id?: number) {
    if (f.length > 0) {
      this.uploadedImage = f[0];
    }
  }
}
