import {Deserializable} from '../../protocols/deserializable';
import {Font} from '../../enum/shared/font.enum';

export class InsiderOptions implements Deserializable {
  public primaryFont: Font;
  public secondaryFont: Font;
  public primaryColor: string;
  public secondaryColor: string;

  onDeserialize() {
    if (!this.primaryColor) {
      this.primaryColor = '#FFCE00';
    }
  }
}
