import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Orderable} from '../../../../models/protocols/orderable';
import {CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

const DragConfig = {
  zIndex: 1051 // More than a modal z-index
};

@Component({
  selector: 'app-reorder-list',
  templateUrl: './reorder-list.component.html',
  styleUrls: ['./reorder-list.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }]
})

export class ReorderListComponent implements OnInit {

  @Input() public items: Orderable[];
  @Input() public orderPrefix: number = 0; // amount to append to list items based on section grouping
  @Input() public useImages: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<Orderable[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.setSortedPriority();
  }

  setSortedPriority() {
    this.items.forEach((item, index) => {
      item.setOrderableValue(this.orderPrefix + index);
    });
  }

}
