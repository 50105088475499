import {Asset} from '../../image/dto/asset';
import {Orderable} from '../../protocols/orderable';

export class GuideImage implements Orderable{
  public asset: Asset;
  public sortOrder: number;

  constructor(asset: Asset, sortOrder: number) {
    this.asset = asset;
    this.sortOrder = sortOrder;
  }

  getOrderValue(): number {
    return this.sortOrder;
  }

  getOrderableTitle(): string {
    return this.asset.fileName;
  }

  getOrderableUniqueId(): string {
    return this.asset.md5Hash;
  }

  onDeserialize() {
  }

  setOrderableValue(val: number) {
    this.sortOrder = val;
  }

  getOrderableAsset(): Asset {
    return this.asset;
  }
}
