export class BlobUtils {
  static b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    if (!!b64Data && !!contentType) {
      let base64Data: string;
      if (contentType === 'image/jpg') { // I think this is safe to hardcode as we will never upload a jpg, only jpeg
        contentType = 'image/jpeg'; // this can be deleted after April 9th 2020
      }
      const replacementString = 'data:' + contentType + ';base64,';
      base64Data = b64Data.replace(replacementString, '');
      let byteCharacters: string;
      try {
        byteCharacters = atob(base64Data);
      } catch (e) {
        console.log(e);
        return null;
      }
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, {type: contentType});
    } else {
      return null;
    }
  }
}
