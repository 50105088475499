import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {Gender} from '../enum/gender.enum';
import {UserProvider} from '../enum/user-provider.enum';
import {Coordinates} from '../../shared/coordinates';

export class User implements Deserializable {
  public krugoId: string;
  public dateCreated: number;
  public providers: UserProvider[];
  public gender: Gender;
  public phoneNumber: string;
  public email: string;
  public userName: string;
  public firstName: string;
  public lastName: string;
  public hasProfilePicture: boolean;
  public profilePictureModeration: string;
  public phoneNumberConfirmed: boolean;
  public emailConfirmed: boolean;
  public accountConfirmed: boolean;
  public pushEndpoint: string;
  public lastSession: number;
  public lastLocation: Coordinates;
  public canAccessGuides: string;

  public onDeserialize() {
    this.lastLocation = DeserializeHelper.deserializeToInstance(Coordinates, this.lastLocation);
  }

  getFullName(): string {
    return `${this?.firstName} ${this?.lastName}`;
  }
}
