import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SessionContainer} from '../models/shared/session-container';
import {RefreshSessionRequest} from '../models/account/requests/refresh-session-request';
import {HydratedUser} from '../models/account/dto/hydrated-user';
import {CodeDeliveryDetails} from '../models/account/dto/code-delivery-details';
import {SignOutRequest} from '../models/account/requests/sign-out-request';
import {CacheService} from './cache-service';
import {DefaultCacheKey} from '../models/enum/shared/default-cache-key.enum';
import {DateUtils} from '../utils/date-utils';
import {v4 as uuidv4} from 'uuid';
import {Insider} from '../models/guide/dto/insider';
import {DeserializeHelper} from '../models/protocols/deserializable';

@Injectable({
  providedIn: 'root'
})

export class SessionService {

  // Behaviour Subjects
  public refreshingSession: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public sessionContainer: BehaviorSubject<SessionContainer> = new BehaviorSubject<SessionContainer>(null);
  public destroySession: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private deadSession: boolean = true;

  constructor(
    private cacheService: CacheService,
  ) {
    this.setupBindings();

  }

  setupBindings() {
    this.sessionContainer.notNull().subscribe((sess) => {
      this.setDeviceId();
      if (sess && this.sessionContainer.getValue().rememberSession) {
        // save session to persistent cache
        this.cacheService.cacheObject(DefaultCacheKey.SessionContainer, sess, true);
      }
      // save session to session cache
      this.cacheService.cacheObject(DefaultCacheKey.SessionContainer, sess);
    });

    this.destroySession.notNull().subscribe((shouldDestroy) => {
      if (shouldDestroy) {
        this.deadSession = true;
        this.cacheService.clearSessionCache();
        this.cacheService.clearPersistentCache();
        this.sessionContainer.next(null);
      }
    });
  }

  getCachedSession(): SessionContainer {
    // Get user session from cache, checking session cache first
    let sess: SessionContainer;
    sess = this.cacheService.getCachedObject<SessionContainer>(SessionContainer, DefaultCacheKey.SessionContainer);
    if (!sess) {
      // Check the persistent cache for a session
      sess = this.cacheService.getCachedObject<SessionContainer>(SessionContainer, DefaultCacheKey.SessionContainer, true);
    }
    this.deadSession = false;
    this.sessionContainer.next(sess);
    return sess;
  }

  // Getters

  liveSession(): boolean {
    return !this.deadSession;
  }

  getUser(): HydratedUser {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user;
    }
    return null;
  }

  getKrugoId(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.krugoId;
    }
    return null;
  }

  getUserEmail(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.email;
    }
    return '';
  }

  getAuthToken(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.session?.accessToken;
    }
    return '';
  }

  getRefreshToken(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.session?.refreshToken;
    }
    return '';
  }

  getSessionChallengeToken(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.session?.challenge.authSession;
    }
    return '';
  }

  getDeviceId(): string {
    return this.cacheService.getCachedGeneric('DeviceId', true) ?? null;
  }

  getInsider(): Insider {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().insider;
    }
  }

  getRefreshSessionReq(sess?: SessionContainer): RefreshSessionRequest {
    if (sess) {
      const userId = sess.user?.krugoId;
      const refreshToken = sess.user?.session?.refreshToken;
      return new RefreshSessionRequest(userId, this.getDeviceId(), refreshToken);
    } else if (this.sessionContainer.getValue()) {
      const userId = this.getKrugoId();
      const refreshToken = this.getRefreshToken();
      return new RefreshSessionRequest(userId, this.getDeviceId(), refreshToken);
    } else {
      return null;
    }
  }

  getSignOutReq(): SignOutRequest {
    if (this.sessionContainer.getValue()) {
      const krugoId = this.getKrugoId();
      const accessToken = this.getAuthToken();
      return new SignOutRequest(krugoId, accessToken);
    } else {
      return null;
    }
  }


  // Setters

  setUser(u: HydratedUser, newSession: boolean, rememberSession: boolean = false) {
    const sessCopy = Object.assign(new SessionContainer(), this.sessionContainer.getValue());
    sessCopy.user = u;
    sessCopy.rememberSession = rememberSession;
    if (newSession) {
      sessCopy.sessionStartTime = DateUtils.currentTimestamp();
    }
    this.deadSession = false;
    this.sessionContainer.next(sessCopy);
  }

  setDeliveryDetails(d: CodeDeliveryDetails) {
    const sessCopy = DeserializeHelper.deserializeToInstance(SessionContainer, this.sessionContainer.getValue());
    if (!!sessCopy) {
      sessCopy.codeDeliveryDetails = d;
      this.sessionContainer.next(sessCopy);
    }
  }

  setDeviceId() {
    if (!this.cacheService.getCachedGeneric('DeviceId', true)) {
      this.cacheService.cacheGeneric('DeviceId', this.generateDeviceId(), true);
    }
  }

  setInsider(i: Insider) {
    const sessCopy = DeserializeHelper.deserializeToInstance(SessionContainer, this.sessionContainer.getValue());
    sessCopy.insider = i;
    this.sessionContainer.next(sessCopy);
  }

  generateDeviceId(): string {
    return `uuidv4();`
  }

}
