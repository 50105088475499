import {DatatableColumn} from './datatable-column';

export class DatatableOptions {
  public columns: DatatableColumn[] = [];
  public nestedColumns: DatatableColumn[] = [];
  // Nested table
  public rowExpansionDisabled: boolean = false;
  // Bulk Selection
  public bulkEditPercentageChecked: number = 0;
  public bulkEditSelectedIds: string[] = [];
  public preselectedIds: string[] = [];
  public selectNestedRowOnClick: boolean = false;
  // Headers
  public stickyModalHeader: boolean = false;
  public hideNestedHeader: boolean = true;
  // Footer
  public hideFooter: boolean = false;
  // Filters
  public defaultEntryCount: number = 25;
}
