import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {DaterangepickerComponent} from 'ngx-daterangepicker-material';
import {BaseComponent} from '../../../../../models/base/base-component';
import {DateUtils} from '../../../../../utils/date-utils';

@Component({
  selector: 'app-krugo-date-picker',
  templateUrl: './krugo-date-picker.component.html',
  styleUrls: ['./krugo-date-picker.component.scss']
})
export class KrugoDatePickerComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('calendar') calendar: DaterangepickerComponent;
  @Output() datesChosen = new EventEmitter<any>(true);
  @Input() maxDateRange: number = 90;

  // Data for ngx-datepicker
  public ranges = DateUtils.defaultRanges();
  public locale = {
    format: 'MMM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: DateUtils.defaultCalendarFormat(), // default is format value
    direction: 'ltr', // could be rtl
    weekLabel: 'W',
    separator: ' to ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Apply', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 1 // first day is monday
  };
  public maxDate: moment.Moment = moment();

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  startDateChanges(d: any) {
    const inMoment = d.startDate;
    if (moment.isMoment(inMoment)) {
      const today = moment();
      const highestRange = inMoment.clone().add(this.maxDateRange, 'days');
      if (highestRange.isBefore(today)) {
        this.maxDate = highestRange;
      } else {
        this.maxDate = today;
      }
    }
  }

  rangeClicked() {
    this.calendar.updateCalendars();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
