<div class="modal-body">
    <div class="modal-title mt-16px">Edit Social Links</div>
    <div class="social-links-form-container">
        <div class="social-links-form">
            <div class="social-links-form-img">
                <img [src]="'assets/icons/dark/solid/facebook-logo.svg'" alt="">
            </div>
            <app-form-group
                #facebookUrlForm
                (secondaryButtonPressed)="cancel()"
                [formItems]="facebookUrlFormInput"
                [options]="formOptions"
                [formObject]="updatedUserInsider"
                [styling]="formStyling">
            </app-form-group>
        </div>
        <div class="social-links-form">
            <div class="social-links-form-img">
                <img [src]="'assets/icons/dark/solid/instagram-logo.svg'" alt="">
            </div>
            <app-form-group
                #instagramUrlForm
                (formSubmitted)="formSubmitted($event)"
                (secondaryButtonPressed)="cancel()"
                [formItems]="instagramUrlFormInput"
                [options]="formOptions"
                [formObject]="updatedUserInsider"
                [styling]="formStyling">
            </app-form-group>
        </div>
        <div class="social-links-form">
            <div class="social-links-form-img">
                <img [src]="'assets/icons/dark/solid/twitter-logo.svg'" alt="">
            </div>
            <app-form-group
                #twitterUrlForm
                (secondaryButtonPressed)="cancel()"
                [formItems]="twitterUrlFormInput"
                [options]="formOptions"
                [formObject]="updatedUserInsider"
                [styling]="formStyling">
            </app-form-group>
        </div>
        <div class="social-links-form">
            <div class="social-links-form-img">
                <img [src]="'assets/icons/dark/solid/laptop.svg'" alt="">
            </div>
            <app-form-group
                #websiteUrlForm
                (secondaryButtonPressed)="cancel()"
                [formItems]="websiteUrlFormInput"
                [options]="formOptions"
                [formObject]="updatedUserInsider"
                [styling]="formStyling">
            </app-form-group>
        </div>
        <div class="social-links-form"
             [hidden]="!insiderIsCompany()">
            <div class="social-links-form-img">
                <img [src]="'assets/icons/dark/solid/code.svg'" alt="">
            </div>
            <app-form-group
                #embedWebsiteUrlForm
                (secondaryButtonPressed)="cancel()"
                [formItems]="embedWebsiteUrlFormInput"
                [options]="formOptions"
                [formObject]="updatedUserInsider"
                [styling]="formStyling">
            </app-form-group>
        </div>
    </div>

    <hr class="mb-32px">
    <div class="modal-footer-flex-end">
        <button matRipple
                (click)="cancel()"
                class="custom-button grey-button mr-3">
            Cancel
        </button>
        <button matRipple
                (click)="save()"
                class="custom-button preferred-button">
            Done
        </button>
    </div>
</div>
