import {Deserializable} from '../../protocols/deserializable';
import {Asset} from '../../image/dto/asset';
import {GuideChildObject} from '../../protocols/guide-child-object';

export class Performer implements Deserializable, GuideChildObject {
  public id: string;
  public vividId: number = -1;
  public name: string;
  public eventType: string;
  public eventTypeId: string;
  public primaryCategoryId: string;
  public secondaryCategoryId: string;
  public vividCategoryId: number = -1;
  public isParking: boolean = false;
  public priority: number = -1;
  public slug: string;
  public url: string;
  public eventCount: number = 0;
  public disposable: boolean = true;
  public lastUpdated: number;
  public searchCount: number;
  public nextEventDate: number;
  public lastEventDate: number;
  // Inherited
  public images: Asset[] = [];
  public customDescription: string;

  onDeserialize() {
    this.images = [];
  }

  setAssets(imageMap: Map<string, string[]>, assets: Asset[]) {
    if (!this.images) {
      this.images = [];
    }
    const imageHashes = imageMap?.get(this.id);
    if (imageHashes) {
      imageHashes?.forEach((hash) => {
        const asset = assets?.find(a => a.md5Hash === hash);
        if (asset) {
          this.images.push(asset);
        }
      });
    }
  }

  setCustomDescription(descMap: Map<string, string>) {
    const customDesc = descMap?.get(this.id);
    if (customDesc) {
      this.customDescription = customDesc;
    }
  }

}
