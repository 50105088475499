<div class="design-container">
  <div class="design-title">Design</div>
  <div class="fonts-container">
    <div class="font-title">Fonts</div>
    <app-choose-font-button
      (click)="chooseFontTitle(types.FontHierarchy.Primary)"
      [primary]="insider?.options?.primaryFont"
      [secondary]="insider?.options?.secondaryFont"
      [hierarchy]="types.FontHierarchy.Primary"
      [type]="types.FontType.Bold"
      [subHeaderText]="'Headings'">
    </app-choose-font-button>
    <app-choose-font-button
      (click)="chooseFontTitle(types.FontHierarchy.Secondary)"
      [primary]="insider?.options?.primaryFont"
      [secondary]="insider?.options?.secondaryFont"
      [hierarchy]="types.FontHierarchy.Secondary"
      [type]="types.FontType.Bold"
      [subHeaderText]="'Paragraphs'">
    </app-choose-font-button>
  </div>
  <div class="color-container">
    <div class="color-title">Colors</div>
    <app-choose-color-button
      [(color)]="insider.options.primaryColor">
    </app-choose-color-button>
  </div>
</div>
