import {Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {ActivatedRoute} from '@angular/router';
import {ToastService} from '../../../../services/toast-service';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {LoadingSpinnerSize} from '../../../../models/enum/shared/loading-spinner-size.enum';
import {Insider} from '../../../../models/guide/dto/insider';
import {GuidesDomainModel} from '../../../../domain-models/guides-domain-model';
import {InsidersDomainModel} from '../../../../domain-models/insiders-domain-model';
import {GuideFeature} from '../../../../models/guide/dto/guide-feature';
import {CustomError} from '../../../../models/shared/custom-error';
import {map, take} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RearrangeCategoryModalComponent} from './rearrange-category-modal/rearrange-category-modal.component';
import {ModalUtils} from '../../../../utils/modal-utils';
import {forkJoin} from 'rxjs';
import {SortUtils} from '../../../../utils/sort-utils';

@Injectable()
export class GuideFeaturesViewModel extends BaseViewModel implements OnDestroy {

  public title: string;
  public company: Insider;
  public loadingOpts: LoadingOptions;
  private featuresCopy: GuideFeature[] = [];

  public guideFeatures$ = this.guidesDm.guideFeaturesMap.asObservable().pipe(
    map((gfMap) => {
      this.featuresCopy = Object.assign([], gfMap.get(this.company.id)?.sort(SortUtils.sortGuidesFeaturesByMostPriority));
      return gfMap.get(this.company.id)?.sort(SortUtils.sortGuidesFeaturesByMostPriority) ?? [];
    })
  );

  constructor(
    private insidersDm: InsidersDomainModel,
    private guidesDm: GuidesDomainModel,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private modalService: NgbModal
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupLoadingSpinner();
    this.setupBindings();
  }

  private setupLoadingSpinner() {
    this.loadingOpts = LoadingOptions.default();
    this.loadingOpts.showLoadingText = false;
    this.loadingOpts.isLoading = false;
    this.loadingOpts.spinnerSize = LoadingSpinnerSize.Medium;
    this.loadingOpts.backgroundColor = '#FCFCFC';
    this.loadingOpts.spinnerColor = '#222222';
  }

  setupBindings() {
    this.loadingOpts.isLoading = true;
    this.bindToCompany();
  }

  bindToCompany() {
    // Bind to companies
    this.route.params.firstNotNull().subscribe(params => {
      this.insidersDm.companies$.firstNotNull().subscribe(companies => {
        this.company = companies.find(c => c.id === params.companyId);
        this.setupWithCurrentCompany();
      });
    });
  }

  setupWithCurrentCompany() {
    this.title = this.company.companyName;
    const gfSub = this.guidesDm.getInsiderGuideFeatures(this.company.id).subscribe(_ => {
      this.loadingOpts.isLoading = false;
    }, (_) => {
      this.loadingOpts.isLoading = false;
    });
    this.pushSub(gfSub);
  }

  ngOnDestroy() {
    this.destroy();
  }

  deleteGuideFeature(gf: GuideFeature) {
    const lm = 'Deleting Guide Feature';
    if (!this.loadingOpts.containsRequest(lm)) {
      this.loadingOpts.addRequest(lm);
      this.guidesDm.deleteGuideFeature(gf).subscribe((_) => {
        this.loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Guide Feature Deleted', 'Success');
      }, (error: CustomError) => {
        this.loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
      });
    }
  }

  openRearrangeFeaturesModal() {
    const oldOrder = Object.assign([], this.featuresCopy);
    const modalRef = this.modalService.open(
      RearrangeCategoryModalComponent,
      ModalUtils.defaultModalOptions()
    );
    modalRef.componentInstance.categories = this.featuresCopy;
    modalRef.result.then((updatedFeatures) => {
      this.updateGuideFeatures(updatedFeatures);
    }, rej => {
      this.featuresCopy = oldOrder;
    });
  }

  updateGuideFeatures(updatedFeatures: GuideFeature[]) {
    const queue = [];
    const lm = 'Updating Guide Feature(s)';
    if (!this.loadingOpts.containsRequest(lm)) {
      this.loadingOpts.addRequest(lm);
      updatedFeatures.forEach(feat => {
        queue.push(this.guidesDm.updateGuideFeature(feat));
      });
      forkJoin(queue).pipe(take(1)).subscribe(_ => {
        this.toastService.publishSuccessMessage('Guide Feature(s) Updated', 'Success');
        this.loadingOpts.removeRequest(lm);
      }, (err: CustomError) => {
        this.loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
      });
    }
  }
}
