import {AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2} from '@angular/core';

@Directive({
  selector: '[appDragScroll]'
})
export class DragScrollDirective
  implements AfterViewInit, OnDestroy {

  public destroyMouseLeaveListener: () => void;
  public destroyMouseDownListener: () => void;
  public destroyMouseMoveListener: () => void;
  public destroyMouseUpListener: () => void;
  public pos = { top: 0, left: 0, x: 0, y: 0 };

  constructor(
    private renderer2: Renderer2,
    private el: ElementRef,
  ) {
  }

  ngAfterViewInit(): void {
    this.destroyMouseLeaveListener = this.renderer2.listen(
      this.el.nativeElement, 'mouseleave', this.mouseLeaveHandler.bind(this)
    );
    this.destroyMouseDownListener = this.renderer2.listen(
      this.el.nativeElement, 'mousedown', this.mouseDownHandler.bind(this)
    );
  }

  public mouseLeaveHandler() {
    this.mouseUpHandler();
  }

  public mouseDownHandler(e) {
    this.el.nativeElement.style.cursor = 'grabbing';
    this.el.nativeElement.style.userSelect = 'none';

    this.pos = {
      left: this.el.nativeElement.scrollLeft,
      top: this.el.nativeElement.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    this.destroyMouseMoveListener = this.renderer2.listen(this.el.nativeElement, 'mousemove', this.mouseMoveHandler.bind(this));
    this.destroyMouseUpListener = this.renderer2.listen(this.el.nativeElement, 'mouseup', this.mouseUpHandler.bind(this));
  }

  public mouseMoveHandler(e) {
    // How far the mouse has been moved
    const dx = e.clientX - this.pos.x;
    const dy = e.clientY - this.pos.y;

    // Scroll the element
    this.el.nativeElement.scrollTo(this.pos.left - dx, this.pos.top - dy);
  }

  public mouseUpHandler() {
    this.el.nativeElement.style.cursor = 'grab';
    this.el.nativeElement.style.removeProperty('user-select');
    if (!!this.destroyMouseMoveListener) {
      this.destroyMouseMoveListener();
      this.destroyMouseMoveListener = null;
    }
    if (!!this.destroyMouseUpListener) {
      this.destroyMouseUpListener();
      this.destroyMouseUpListener = null;
    }
  }

  ngOnDestroy() {
    this.destroyMouseDownListener();
    this.destroyMouseLeaveListener();
  }

}
