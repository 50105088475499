/* tslint:disable:no-string-literal */
import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import 'firebase/analytics';
import {CanDeactivateState} from './services/guards/can-deactivate.guard';
import {filter, map, tap} from 'rxjs/operators';
import {environment} from '../environments/environment';
import firebase from 'firebase/app';
import {LoggingService} from './services/logging-service';
import {ScreenService} from './services/screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public isMobile$ = this.screenService.connectToIsMobile();
  public isNotMobile$ = this.screenService.connectToIsMobile().pipe(map(it => !it));

  constructor(
    private screenService: ScreenService,
    private router: Router,
    private loggingService: LoggingService,
    private el: ElementRef,
  ) {
    // Config for Firebase
    const firebaseApp = firebase.initializeApp(environment.firebaseConfig);
    const userProperties: { [key: string]: any; } = {};
    userProperties['Platform'] = 'Dashboard';
    firebase.analytics().setUserProperties(userProperties);
    this.loggingService.SetFirebaseAppInstance(firebaseApp);

    // Config for Abandon changes on back button
    window.onpopstate = () => CanDeactivateState.defendAgainstBrowserBackButton = true;
    router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      tap(() => CanDeactivateState.defendAgainstBrowserBackButton = false)
    ).subscribe();
  }

  ngOnInit() {
    this.onResize();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenService.setIsMobile((this.el.nativeElement as HTMLElement).getBoundingClientRect().width < ScreenService.MOBILE_WIDTH);
  }
}
