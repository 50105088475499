import { Component, OnInit } from '@angular/core';
import {BaseModal} from '../../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GuideImage} from '../../../../../models/guide/shared/guide-image';

@Component({
  selector: 'app-reorder-image-modal',
  templateUrl: './reorder-image-modal.component.html',
  styleUrls: ['./reorder-image-modal.component.scss', '../add-place-modal/add-place-modal.component.scss']
})
export class ReorderImageModalComponent extends BaseModal implements OnInit {
  images: GuideImage[] = [];
  canSubmit: boolean = true;

  constructor(
    private activeModal: NgbActiveModal
  ) {
    super();
  }

  ngOnInit(): void {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  submit() {
    this.activeModal.close(this.images);
  }

  setupBindings() {
  }

  setupViews() {
  }

}
