<div class="rearrange-assets-modal">
  <div class="title-bar">
    <div class="modal-title">
      Reorder Categories
    </div>
    <button matRipple (click)="cancel()" class="custom-button text-button">Cancel</button>
  </div>
  <div class="reorder-action-container">
    <app-reorder-list
      [items]="categories">
    </app-reorder-list>
  </div>
  <div class="rearrange-assets-footer-container">
    <hr>
    <div class="button-container">
      <button matRipple class="custom-button grey-button" (click)="cancel()">Cancel</button>
      <button matRipple
              class="custom-button preferred-button ml-2"
              (click)="submit()"
              [disabled]="!canSubmit"
              [ngClass]="{ 'disabled' : !canSubmit}"
      >Save
      </button>
    </div>
  </div>
</div>
