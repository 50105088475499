import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() placeHolder: string = 'Search';
  @Output() search = new EventEmitter(true);
  @ViewChild('reset') private reset: ElementRef;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  searchChanged(e) {
    this.search.emit(e.target.value);
  }

  emitEmpty() {
    this.search.emit('');
  }

  clear() {
    this.reset.nativeElement.click();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
