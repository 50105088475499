import {BaseViewModel} from '../../../../models/base/base-view-model';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {KrugoMapMarker} from './interfaces/krugo-map-marker';
import {MapService} from './services/map.service';

@Injectable()
export class MapViewModel extends BaseViewModel implements OnDestroy {
  private urlSrcMap = new BehaviorSubject<Map<string, string>>(new Map());
  public urlSrcMap$ = this.urlSrcMap.asObservable();
  private map = new BehaviorSubject<google.maps.Map>(null);
  public selectedMarker = this.mapService.selectedMarker;


  constructor(
    private mapService: MapService,
  ) {
    super();
    this.init();
  }

  addSrcUrl(url: string, m: KrugoMapMarker) {
    const myMap = this.urlSrcMap.value;
    myMap.set(m.getUniqueId(), url);
    this.urlSrcMap.next(myMap);
  }

  setMap(m: google.maps.Map) {
    this.map.next(m);
  }

  setSelectedMapMarker(m: KrugoMapMarker) {
    this.mapService.changeSelectedMapMarker(m);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
