import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {DropDownMenuItem, DropDownMenuSection} from '../../../../models/shared/stylesheet/drop-down-menu-section';
import {GuideOption} from './guide-option.enum';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {GuidesDomainModel} from '../../../../domain-models/guides-domain-model';
import {HydratedGuide} from '../../../../models/guide/dto/hydrated-guide';
import {SessionService} from '../../../../services/session-service';
import {map, takeUntil} from 'rxjs/operators';
import {GuideStatus} from '../../../../models/guide/enum/guide-status.enum';
import {Guide} from '../../../../models/guide/dto/guide';
import {ToastService} from '../../../../services/toast-service';
import {TypeUtils} from '../../../../utils/type-utils';

@Injectable()
export class GuideCardViewModel extends BaseViewModel {

  // Insider Id
  private associatedInsiderId = new BehaviorSubject<string>(null);

  // Guide
  private guide = new BehaviorSubject<HydratedGuide>(null);

  // Guide error status
  public isInErrorStatus$ = this.guide.pipe(map(guide => {
    return guide?.status.toLowerCase() === GuideStatus.Declined.toLowerCase();
  }));
  public errorMessage$ = this.guide.pipe(map(guide => {
    if (guide?.statusNote) {
      return `${guide?.statusNote}`;
    } else {
      return 'Rejected';
    }
  }));

  // Guide private status
  public isPrivate$ = this.guide.pipe(map(guide => {
    return guide?.isPrivate ?? false;
  }));

  // Popper Data
  public dropDownMenuSections$ = this.guide.pipe(map(guide => {
    return this.getMenuSection(guide);
  }));

  public popperModifier = {
    flip: {
      behavior: ['right', 'bottom', 'top']
    }
  };
  public popperStyles = {
    'background-color': '#FFFFFF'
  };

  // Featured Button
  public featuredHidden$ = combineLatest([
    this.sessionService.sessionContainer,
    this.guide,
    this.isPrivate$
  ]).pipe(
    takeUntil(this.onDestroy),
    map(([session, guide, isPrivate]) => {
      if (!!guide.company && guide.status === TypeUtils.GuideStatus.Approved && !isPrivate) {
        return !session?.insider?.adminCompanyIds.contains(guide.company.id);
      } else {
        return true;
      }
    })
  );

  constructor(
    private dm: GuidesDomainModel,
    private sessionService: SessionService,
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  private getMenuSection(guide?: Guide): DropDownMenuSection {
    const menuItems: DropDownMenuItem[] = [];

    menuItems.push(new DropDownMenuItem('Edit', GuideOption.Edit));

    menuItems.push(new DropDownMenuItem('Tags', GuideOption.Tags));

    if (guide?.status === GuideStatus.Approved) {
      if (guide?.isPrivate) {
        menuItems.push(new DropDownMenuItem('Mark as Public', GuideOption.MarkAsPublic));
      } else {
        menuItems.push(new DropDownMenuItem('Mark as Private', GuideOption.MarkAsPrivate));
      }
    }

    menuItems.push(new DropDownMenuItem('Delete', GuideOption.Delete, new Set<string>().add('red-text')));
    return new DropDownMenuSection(null, menuItems);
  }

  setFeaturedGuide(g: HydratedGuide) {
    this.dm.setFeaturedGuide(g);
  }

  setGuide(g: HydratedGuide) {
    this.guide.next(g);
  }

  setAssociatedInsiderId(insiderId: string) {
    this.associatedInsiderId.next(insiderId);
  }

  updateGuidePrivateStatus(isPrivate: boolean) {
    const updateGuide = this.guide.value;
    updateGuide.isPrivate = isPrivate;
    if (isPrivate) {
      updateGuide.featuredGuide = false;
    }
    this.dm.updateGuide(updateGuide).subscribe(guide => {
      this.guide.next(guide);
      this.toastService.publishSuccessMessage('Guide Updated', null);
    }, err => {
      console.log(err);
      this.toastService.publishErrorMessage(err, 'Unable to Update Guide');
    });
  }
}
