<div class="modal-body">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{ confirmationOptions?.title }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Modal Body -->
    <div class="confirmation-text">
      {{ confirmationOptions?.bodyText }}
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end"
       style="padding-top: 0;">
    <button matRipple
            (click)="cancel()"
            [ngClass]="confirmationOptions?.cancelButtonClass"
            class="custom-button">
      {{ confirmationOptions?.cancelText }}
    </button>
    <button matRipple
            (click)="continue()"
            [ngClass]="confirmationOptions?.continueButtonClass"
            class="custom-button ml-2">
      {{ confirmationOptions?.continueText }}
    </button>
  </div>
</div>
