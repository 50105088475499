import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {GuideInsightChartViewModel} from './guide-insight-chart-view-model';
import {ChartDataAs} from '../models/chart-data-as.enum';

@Component({
  selector: 'app-guide-insight-chart',
  templateUrl: './guide-insight-chart.component.html',
  styleUrls: ['./guide-insight-chart.component.scss'],
  providers: [GuideInsightChartViewModel],
})
export class GuideInsightChartComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  /** [width, height] If dimension not set, graph fills parent */
  @Input() dimension: [number, number];
  @Input() showLegend: boolean = true;
  @Input() showTimeLine: boolean = true;

  /** Color Scheme for line data */
  @Input() colorScheme = {
    domain: ['#222222', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  // X Axis
  @Input() hasXAxis: boolean = true;
  @Input() xAxisLabel: string;
  @Input() showXAxisLabel: boolean = true;
  @Input() trimXAxisTicks: boolean = false;

  // Y Axis
  @Input() hasYAxis = true;
  @Input() yAxisLabel: string;
  @Input() showYAxisLabel: boolean = true;
  @Input() trimYAxisTicks: boolean = true;

  // Data
  @Input() guideId: string;
  @Input() dateKey: string;
  @Input() chartAs: ChartDataAs = ChartDataAs.GuideViews;

  @Output() dataSelected = new EventEmitter<any>(true);
  @Output() dataActivated = new EventEmitter<any>(true);
  @Output() dataDeactivated = new EventEmitter<any>(true);

  constructor(
    public vm: GuideInsightChartViewModel,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setDataInVM();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  onSelect(data): void {
    this.dataSelected.emit(JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    this.dataActivated.emit(JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    this.dataDeactivated.emit(JSON.parse(JSON.stringify(data)));
  }

  setDataInVM() {
    this.vm.setChartDataAs(this.chartAs);
    this.vm.setGuideId(this.guideId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartData) {
      this.vm.setChartDataAs(this.chartAs);
    }
    if (changes.guideId) {
      this.vm.setGuideId(this.guideId);
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
