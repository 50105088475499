import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {HydratedGuide} from '../../../../models/guide/dto/hydrated-guide';
import {GuideCardViewModel} from './guide-card-view-model';
import {GuideOption} from './guide-option.enum';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalUtils} from '../../../../utils/modal-utils';
import {DeleteGuideComponent} from '../delete-guide/delete-guide.component';
import {ManageTagsComponent} from '../manage-tags/manage-tags.component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-guide-card',
  templateUrl: './guide-card.component.html',
  styleUrls: ['./guide-card.component.scss'],
  providers: [GuideCardViewModel],
})
export class GuideCardComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('image') image: ElementRef;
  @Input() guide: HydratedGuide;
  @Input() associatedInsiderId: string;

  constructor(
    public vm: GuideCardViewModel,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setDataInVM();
  }

  private setDataInVM() {
    this.vm.setGuide(this.guide);
    this.vm.setAssociatedInsiderId(this.associatedInsiderId);
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  onGuideClick(g: HydratedGuide) {
    this.router.navigate(
      [`edit/${!!g.companyId ? g.companyId : g.insiderId}/${g.id}`],
      { relativeTo: this.route }
    ).then(_ => {});
  }

  featuredClicked() {
    this.vm.setFeaturedGuide(this.guide);
  }

  menuItemSelected(item: GuideOption) {
    switch (item) {
      case GuideOption.Edit:
        this.editGuide();
        break;
      case GuideOption.Tags:
        this.openManageTagsModal();
        break;
      case GuideOption.Delete:
        this.openDeleteGuideModal();
        break;
      case GuideOption.MarkAsPublic:
        this.vm.updateGuidePrivateStatus(false);
        break;
      case GuideOption.MarkAsPrivate:
        this.vm.updateGuidePrivateStatus(true);
        break;
    }
  }

  editGuide() {
    this.router.navigate(
      [`edit/${!!this.guide.companyId ? this.guide.companyId : this.guide.insiderId}/${this.guide.id}`],
      { relativeTo: this.route }
    ).then();
  }

  openDeleteGuideModal() {
    const modalRef = this.modalService.open(
      DeleteGuideComponent,
      ModalUtils.defaultModalOptions()
    );
    const compInstance = modalRef.componentInstance as DeleteGuideComponent;
    compInstance.guide = this.guide;
  }

  openManageTagsModal() {
    const modalRef = this.modalService.open(
      ManageTagsComponent,
      ModalUtils.defaultModalOptions()
    );
    const compInstance = modalRef.componentInstance as ManageTagsComponent;
    compInstance.guide = this.guide;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDataInVM();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
