import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {GuideClickType} from '../../../../../models/guide/enum/guide-click-type.enum';

@Component({
  selector: 'app-insight-card',
  templateUrl: './insight-card.component.html',
  styleUrls: ['./insight-card.component.scss']
})
export class InsightCardComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() clickType: GuideClickType;
  @Input() value: number;
  @Input() selected: GuideClickType;

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
