<div class="insights-container" fxLayout="column">
  <div fxLayout="column" fxLayoutAlign="center flex-start">
    <div class="insight-title">{{vm.pageTitle$|async}}</div>
    <div class="header-divider"></div>
    <div class="sub-header">Insights <span><img (click)="vm.openInsightInfoModal()" class="insight-info-icon" [src]="'assets/icons/dark/outline/information-circle.svg'" alt="Information"></span></div>
  </div>
  <div #graphContainer class="graph-container">
    <app-insider-insight-chart #chart
                               [dimension]="dimensions|async"
                               [xAxisLabel]="'Date'"
                               [yAxisLabel]="'Time'"
                               [showXAxisLabel]="false"
                               [showYAxisLabel]="false"
                               [companyId]="vm.associatedInsiderId$ | async | keepIfCompanyId: (vm.companyIds$ | async)"
                               [insiderId]="vm.associatedInsiderId$ | async | keepIfInsiderId: (vm.insiderId$ | async)"
                               [showLegend]="false">
    </app-insider-insight-chart>
  </div>
  <app-guide-insight-table [insiderInsight]="chart.insiderInsight|async"
                           [isLoading]="!(chart.insightsLoaded|async)">
  </app-guide-insight-table>
</div>
