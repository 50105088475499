<div class="datatable-wrapper">

  <!-- Filter Options -->
  <div [ngClass]="{ 'sticky-modal-header' : tableOptions.stickyModalHeader}"
       class="datatable-filters">

    <div class="row">
      <div class="col-sm-12">

        <!-- Search Input -->
        <div *ngIf="tableFilter.enableSearch"
             class="col-sm-12 col-md-6 no-padding table-filter-form">
          <form class="custom-textfield-form pl-0">
            <label class="custom-textfield-label" for="searchQueryString">
              {{ tableFilter.searchLabelText }}
            </label>
            <input (keyup)="onQueryStringChange($event)"
                   [(ngModel)]="tableFilter.searchQueryString"
                   class="custom-textfield search-textfield" id="searchQueryString"
                   name="searchQueryString"
                   placeholder="{{ tableFilter.searchPlaceholderText }}"
                   type="text">
            <button (click)="clearSearch()" class="clear-search" type="reset">
              CLEAR
            </button>
            <span class="custom-textfield-error"></span>
          </form>
        </div>

        <!--   Checkbox Filter     -->
        <div *ngIf="tableFilter.enableFilterCheckbox"
             class="col-sm-6 col-md-3 table-filter-form">
          <app-checkbox
            (clickEvent)="filterChanged()"
            [checkbox]="tableFilter.checkbox"
            [checked]="tableFilter.checkbox?.checked"
            [disabled]="!tableFilter.enableFilterCheckbox"
            [label]="tableFilter.checkbox?.label">
          </app-checkbox>
        </div>

        <!--   Reset Filters     -->
        <div *ngIf="tableFilter.filtersAreActive()"
             class="col-sm-6 col-md-3 table-filter-form clear-filters-container pr-0">
          <button (click)="clearFilters()"
                  class="custom-button outlined-button clear-filters-button">
            Clear Filters
          </button>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">

        <!-- Iterate over custom filters -->
        <ng-container *ngFor="let cf of tableFilter.customFilters; let customFilterIndex = index">
          <div [ngClass]="{'pl-0' : customFilterIndex === 0 }"
               class="col-sm-6 col-md-3 table-filter-form">
            <app-drop-down
              (selection)="onCustomFilterChange(cf, $event)"
              [currentSelection]="cf.selectedOption"
              [dropdowns]="cf.activeFilterOptions"
              [hint]="cf.hintOptions"
              [labelText]="cf.label">
            </app-drop-down>
          </div>
        </ng-container>

        <!-- Pagination Result Count -->
        <div *ngIf="tableFilter.enableResultsDisplayed"
             class="col-sm-6 col-md-3 table-filter-form pr-0">
          <form class="custom-textfield-form"
                style="padding: 0;">
            <label class="custom-textfield-label" for="numberOfResults">
              Number of Results
            </label>
            <select (ngModelChange)="entriesChanged($event)" [(ngModel)]="numberOfEntriesString" class="custom-dropdown"
                    id="numberOfResults" name="numberOfResults">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </form>
        </div>

      </div>
    </div>

  </div>


  <!-- Table Results -->
  <div [ngClass]="{ 'sticky-modal-header' : tableOptions.stickyModalHeader}"
       class="row datatable-content">
    <div class="col-sm-12">

      <table class="table custom-table table-hover">
        <!-- Table Header -->
        <thead>
        <tr *ngIf="tableOptions && tableOptions.columns">
          <th (click)="headerClicked(column)"
              [ngClass]="{ 'disable-sorting' : column.disableSorting,
               'sorting_asc': isAscending(column),
               'sorting_desc': isDescending(column) }"
              *ngFor="let column of tableOptions.columns"
              [ngStyle]="{ 'width': column.getWidth() }"
              class="datatable-header">

            <!-- Bulk Edit Checkbox -->
            <ng-container *ngIf="column.type === columnTypes.Checkbox">
              <label class="custom-checkbox-container" for="checkbox-bulk-all">
                <input (change)="selectAllClicked($event)" [checked]="tableOptions.bulkEditPercentageChecked === 1"
                       class="custom-checkbox"
                       id="checkbox-bulk-all"
                       [ngClass]="{ 'indeterminate': tableOptions.bulkEditPercentageChecked > 0 && tableOptions.bulkEditPercentageChecked < 1 }"
                       name="checkbox-all"
                       type="checkbox">
                <span class="custom-checkbox-label">
                  {{ getBulkEditSelectionCount() }}
                </span>
                <span class="custom-checkmark"></span>
              </label>
            </ng-container>

            <!-- Column Title -->
            <ng-container *ngIf="column.type !== columnTypes.Checkbox">
              {{ column.title }}
            </ng-container>

            <!-- Sorting Icon -->
            <span class="sorting-icon"></span>

          </th>
        </tr>
        </thead>
        <!-- Table Body -->
        <tbody>
        <ng-container *ngFor="let rowData of tableData.displayedData; let rowIndex = index;">
          <tr [ngClass]="{ 'expanded-parent' : rowIndex === expandedIndex}">
            <ng-container *ngFor="let column of tableOptions.columns; let colIndex = index;">

              <!-- Handle DatatableColumnType.Text -->
              <td (click)="handleRowClicked(rowData, rowIndex)"
                  *ngIf="column.type === columnTypes.Text">
                <div [ngClass]="column.className">
                  {{ getCellContent(column, rowData) }}
                  <!-- Add inline tooltip -->
                  <button [hidden]="!getTooltipContent(column, rowData)"
                          [ngbTooltip]="getTooltipContent(column, rowData)"
                          class="inline-tooltip">
                    <img [src]="column.tooltipIconSrc" alt="">
                  </button>
                </div>
              </td>

              <!-- Handle DatatableColumnType.Checkbox -->
              <td *ngIf="column.type === columnTypes.Checkbox"
                  class="checkbox-td">

                <label class="custom-checkbox-container"
                       for="checkbox-{{ rowIndex }}">
                  <input (change)="selectSingleOptionClicked($event, rowData)"
                         [checked]="isChecked(rowData)"
                         [disabled]="false"
                         [ngClass]="{ 'indeterminate': isNestedParentIndeterminate(rowData) }"
                         class="custom-checkbox"
                         id="checkbox-{{ rowIndex }}"
                         name="custom-cb"
                         type="checkbox">
                  <span class="custom-checkmark"></span>
                </label>

              </td>

              <!-- Handle DatatableColumnType.Label -->
              <td (click)="handleRowClicked(rowData, rowIndex)"
                  *ngIf="column.type === columnTypes.Label">
                <div [hidden]="!getCellContent(column, rowData) || getCellContent(column, rowData) === ''"
                     [ngClass]="column.className"
                     class="datatable-label">
                  <span [ngStyle]="{ 'background-color' : rowData.getColor(),
                        'color': rowData.getTextColor() }"
                        class="custom-label neutral-label">
                    {{ getCellContent(column, rowData) }}
                  </span>
                </div>
              </td>

              <!-- Handle DatatableColumnType.Buttons -->
              <td (click)="handleRowClicked(rowData, rowIndex)"
                  *ngIf="column.type === columnTypes.Actions">
                <ng-container *ngFor="let act of column.actions; let actionIndex = index;">
                  <button (click)="handleRowActionClicked($event, act, rowData)"
                          [ngbTooltip]="act.tooltipText"
                          class="datatable-action-button">
                    <img [src]="act.iconSrc" alt="">
                  </button>
                </ng-container>
              </td>

            </ng-container>
          </tr>
          <!-- Nested Data -->
          <tr *ngIf="rowIndex === expandedIndex" class="no-hover">
            <td [colSpan]="tableOptions.nestedColumns.length"
                class="nested-table-data-cell">
              <!-- Nested Data Table -->
              <table class="nested-table custom-table w-100">
                <!-- Nested Table Header -->
                <thead [ngClass]="{ 'hide-header' : tableOptions.hideNestedHeader }"
                       class="nested-table-header">
                <tr>
                  <th *ngFor="let nestedCol of tableOptions.nestedColumns"
                      [ngStyle]="{ 'width': nestedCol.getWidth() }">
                    {{ nestedCol.title }}
                  </th>
                </tr>
                </thead>
                <!-- Nested Table Content -->
                <tr *ngFor="let nestedRowData of tableData.getNestedCellData(rowData); let nestedRowIndex = index;">
                  <ng-container *ngFor="let nestedColumn of tableOptions.nestedColumns; let nestedColIndex = index;">

                    <!-- Handle DatatableColumnType.Text -->
                    <td (click)="handleNestedRowClicked(nestedRowData)"
                        *ngIf="nestedColumn.type === columnTypes.Text">
                      {{ getNestedCellContent(nestedColumn, nestedRowData) }}
                      <!-- Add inline tooltip -->
                      <button [hidden]="!getTooltipContent(nestedColumn, nestedRowData)"
                              [ngbTooltip]="getTooltipContent(nestedColumn, nestedRowData)"
                              class="inline-tooltip">
                        <img [src]="nestedColumn?.tooltipIconSrc" alt="">
                      </button>
                    </td>

                    <!-- Handle DatatableColumnType.Checkbox -->
                    <td *ngIf="nestedColumn.type === columnTypes.Checkbox"
                        class="checkbox-td">

                      <label class="custom-checkbox-container"
                             for="nested-checkbox-{{ nestedRowIndex }}">
                        <input (change)="selectSingleOptionClicked($event, nestedRowData)"
                               [checked]="isChecked(nestedRowData)"
                               [disabled]="isPreselected(nestedRowData)"
                               class="custom-checkbox"
                               id="nested-checkbox-{{ nestedRowIndex }}"
                               name="custom-cb"
                               type="checkbox">
                        <span class="custom-checkmark"></span>
                      </label>

                    </td>

                    <!-- Handle DatatableColumnType.Label -->
                    <td *ngIf="nestedColumn.type === columnTypes.Label">
                      <div
                        [hidden]="!getNestedCellContent(nestedColumn, nestedRowData) || getNestedCellContent(nestedColumn, nestedRowData) === '' "
                        [ngClass]="nestedColumn.className"
                        class="datatable-label">
                        <span [ngStyle]="{ 'background-color' : nestedRowData.getColor(),
                        'color': nestedRowData.getTextColor() }"
                              class="custom-label neutral-label">
                          {{ getNestedCellContent(nestedColumn, nestedRowData) }}
                        </span>
                      </div>
                    </td>

                    <!-- Handle DatatableColumnType.Buttons -->
                    <td *ngIf="nestedColumn.type === columnTypes.Actions">
                      <ng-container *ngFor="let act of nestedColumn.actions; let actionIndex = index;">
                        <button (click)="handleNestedRowActionClicked($event, act, nestedRowData)"
                                [ngbTooltip]="act.tooltipText"
                                class="datatable-action-button">
                          <img [src]="act.iconSrc" alt="">
                        </button>
                      </ng-container>
                    </td>

                  </ng-container>
                </tr>
              </table>
            </td>
          </tr>

        </ng-container>
        </tbody>
      </table>

      <div [hidden]="tableData.displayedData.length > 0 || tableData.data.length === 0"
           class="no-results-container">
        <div class="no-results-title">
          {{ getNoResultsTitle() }}
        </div>
        <div class="no-results-body">
          {{ getNoResultsText() }}
        </div>
        <button (click)="resetSearchAndFilters()"
                class="custom-button preferred-button no-results-button">
          Reset Search
        </button>
      </div>

      <!--   Footer   -->
      <div [hidden]="tableOptions.hideFooter" class="row mt-3">
        <!--   Results Position   -->
        <div class="col-sm-12 col-md-5">
          <div class="position-text">
            Showing
            <span class="position-text-bold">{{beginAmount() + 1}}</span>
            to
            <span
              class="position-text-bold">{{ endAmount() < tableData.filteredData.length ? endAmount() : tableData.filteredData.length}}</span>
            of
            <span class="position-text-bold">{{tableData.filteredData.length}}</span>
            results
          </div>
        </div>
        <!--   Pagination   -->
        <div class="col-sm-12 col-md-7">
          <div class="">
            <ul class="pagination dt_pagination">

              <li [class.disabled]="previousDisabled()" class="paginate_button page-item previous">
                <a (click)="previousClicked()" class="page-link" tabindex="0">
                  <img alt="" class="pagination-arrow" src="../../../../../assets/icons/dark/outline/arrow-left.svg"/>
                </a>
              </li>

              <li *ngFor="let page of this.pages()" [class.active]="isActivePage(page)"
                  class="paginate_button page-item page-item-number">
                <a (click)="pageClicked(page)" class="page-link">
                  {{ page }}
                </a>
              </li>

              <li [class.disabled]="nextDisabled()" class="paginate_button page-item next">
                <a (click)="nextClicked()" class="page-link" data-dt-idx="5"
                   tabindex="0">
                  <img alt="" class="pagination-arrow" src="../../../../../assets/icons/dark/outline/arrow-right.svg"/>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
