import {Component, EventEmitter, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../../models/shared/stylesheet/form-options';

@Component({
  selector: 'app-rename-section',
  templateUrl: './rename-section.component.html',
  styleUrls: ['./rename-section.component.scss', '../../add-place-modal/add-place-modal.component.scss']
})
export class RenameSectionComponent implements OnInit {
  sectionNameItem: FormInputItem[] = [];
  sectionNameOptions: FormOptions = new FormOptions();
  sectionNameStyling: FormGroupStyling = new FormGroupStyling();
  hydrateSectionNameObject: EventEmitter<any> = new EventEmitter<any>();
  canSubmit: boolean = false;
  sectionName: string;
  oldSectionName: string;

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.generateSectionName();
  }

  generateSectionName() {
    this.sectionNameItem = [];
    const section = new FormInputItem();
    section.required = true;
    section.label = 'Section';
    section.placeholder = 'New Section Name';
    section.hideLabel = true;
    section.itemType = FormItemType.Input;
    section.inputType = FormInputType.Text;
    section.bindingProperty = 'title';
    section.inputName = 'Section';
    this.sectionNameItem.push(section);
  }

  sectionNameChanged() {
    let canSubmit = true;
    this.sectionNameItem.forEach((fi) => {
      if (!fi.canSubmit()) {
        canSubmit = false;
        return;
      }
    });
    if (canSubmit) {
      // Validate form to bind all input changes to object
      this.hydrateSectionNameObject.next();
      this.canSubmit = canSubmit;
    }
  }

  closeModal() {
      this.activeModal.dismiss();
  }

  submit() {
    this.activeModal.close(this.sectionNameItem[0].getValue());
  }

}
