import {Deserializable} from '../protocols/deserializable';

export class LocalizedPrice implements Deserializable {
  public baseCurrency: string;
  public baseValue: number;
  public currency: string;
  public exchangeRate: number;
  public value: number;

  onDeserialize() {
  }
}
