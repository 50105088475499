<div class="auth-flow-wrapper">
  <app-loading
    [hidden]="!viewModel?.loadingOpts?.isLoading"
    [options]="viewModel?.loadingOpts">
  </app-loading>
  <div class="auth-flow-container">
    <img [src]="'assets/logo/krugo-bullhorn.svg'">
    <div class="auth-flow-title mt-5">
      {{ viewModel.authFlowTitle$|async }}
    </div>
    <div class="auth-flow-subtext my-4">
      {{ viewModel.authFlowSubText$|async }}

    </div>
    <app-form-group
                    (formChanges)="viewModel?.signUpFormChanges()"
                    [formItems]="this.viewModel.signUpFormItems"
                    [options]="this.viewModel.signUpFormOptions"
                    [formObject]="this.viewModel.signUpReq"
                    [hydrateInputObject]="viewModel?.hydrateInputObject"
                    [styling]="this.viewModel.signUpFormStyling">
    </app-form-group>
    <div class="my-4">
      <ng-container *ngFor="let ch of this.viewModel.signUpCheckboxes; let i = index ">
        <app-checkbox [label]="ch.label"
                      [checkbox]="ch"
                      [innerHTMLLabel]="true"
                      (change)="viewModel.signUpFormChanges()">
        </app-checkbox>
        <span *ngIf="i === 0" class="mb-3">&nbsp;</span>
      </ng-container>
    </div>
    <button matRipple
            class="custom-button preferred-button"
            (click)="submitForm()"
            [disabled]="!this.viewModel.canSubmitSignUpForm"
            [ngClass]="{ 'disabled' : !this.viewModel.canSubmitSignUpForm }"
    >Continue
    </button>
  </div>
  <div class="auth-flow-link">
    Already have an account? <a class="text-link" (click)="signIn()">Sign In</a>
  </div>
</div>
