import {Observable} from 'rxjs';
import {filter, take} from 'rxjs/operators';

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    notNull(): Observable<T>;

    firstNotNull(): Observable<T>;
  }
}

Observable.prototype.notNull =
  function(): Observable<any> {
    return this.pipe(filter(x => x != null));
  };

Observable.prototype.firstNotNull =
  function(): Observable<any> {
    return this.pipe(filter(x => x != null), take(1));
  };
