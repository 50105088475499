import {Component, Input, OnInit} from '@angular/core';
import {Breadcrumb} from '../../../../models/shared/stylesheet/breadcrumb';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() breadcrumbs: Breadcrumb[];

  constructor(
    private router: Router,
    private location: Location
  ) {
  }

  ngOnInit(): void {
  }

  breadcrumbClicked(bc: Breadcrumb) {
    if (!bc.active && !bc.disabled) {
      if (bc.popBack) {
        this.location.back();
      } else {
        this.router.navigateByUrl(bc.targetUrl).then(() => {});
      }
    }
  }

}
