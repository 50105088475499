import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Guide} from '../../../../models/guide/dto/guide';
import {DeleteGuideViewModel} from './delete-guide-view-model';
import {BaseModal} from '../../../../models/base/base-modal';
import {takeUntil} from 'rxjs/operators';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-guide',
  templateUrl: './delete-guide.component.html',
  styleUrls: ['./delete-guide.component.scss'],
  providers: [DeleteGuideViewModel],
})
export class DeleteGuideComponent extends BaseModal
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() guide: Guide;

  constructor(
    public vm: DeleteGuideViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.vm.dismissModalSubject
      .pipe(takeUntil(this.onDestroy))
      .subscribe((close) => {
        if (close) { this.cancel(); }
      });
  }

  cancel() {
    this.activeModal.close(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
