import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {DropDownMenuSection} from '../../../../models/shared/stylesheet/drop-down-menu-section';


@Component({
  selector: 'app-drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropDownMenuComponent implements OnInit {

  @Input() iconSrc: string; // ex - 'assets/icons/light/outline/home.svg'
  @Input() buttonText: string;
  @Input() sections: DropDownMenuSection[];
  @Output() output = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  clicked(i: any) {
    this.output.emit(i);
  }

}
