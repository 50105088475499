import {Deserializable} from '../../protocols/deserializable';

export class CompanyInsiderLookup implements Deserializable {
  public companyId: string;
  public insiderId: string;
  public isCompanyAdmin: boolean;
  public lastUpdated: number;

  onDeserialize() {
  }
}
