<div class="empty-state-container"
     [style.height]="emptyStateHeight"
     fxLayout="column"
     fxLayoutAlign="center center">
  <app-loading
    [hidden]="!loadingOpts?.isLoading"
    [options]="loadingOpts">
  </app-loading>
  <div class="empty-state-inside-container"
       fxLayout="column"
       fxLayoutAlign="center start">
    <div class="title">{{title}}</div>
    <div class="desc">{{desc}}</div>
    <button *ngIf="!!buttonText"
            matRipple
            class="custom-button preferred-button empty-state-button"
            (click)="this.buttonClicked.emit(true)">{{buttonText}}</button>
  </div>
</div>
