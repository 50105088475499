import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseCreateViewModel} from '../../viewmodels/base-create-view-model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CreateGuideFlow} from '../../../../../../models/guide/enum/create-guide-flow.enum';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../../../../../models/base/base-component';

@Component({
  selector: 'app-create-guide',
  templateUrl: './base-create-guide.component.html',
  styleUrls: ['./base-create-guide.component.scss',
    './../../../../../../../scss/stylesheet.scss'
  ]
})
export class BaseCreateGuideComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  createGuideFlow = CreateGuideFlow;
  associatedId: string;

  constructor(
    public viewModel: BaseCreateViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.viewModel.createFlow = CreateGuideFlow.ChooseAuthor;
    this.viewModel.activeModal = this.activeModal;
    this.viewModel.getCities();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    this.viewModel.dismissModalSubject
      .pipe(takeUntil(this.onDestroy))
      .subscribe(guide => this.activeModal.close(guide));
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
