<div class="asset-preview-container">
  <div class="asset-preview">
    <!-- Image Preview -->
    <img [hidden]="!isImage() || !asset"
         [src]="assetSrc | async"
         alt=""
         class="">
    <!-- Video Preview -->
    <video [controls]="true"
           [hidden]="isImage() || !asset"
           [src]="assetSrc | async"
           class="">
    </video>
  </div>

  <div class="asset-info">
    <div class="asset-details">
      <div class="asset-name ellipsis-nowrap-text">
        {{ asset?.fileName }}
      </div>
      <div class="asset-detail">
        <span>Date Uploaded:</span>
        {{ getUploadDate() }}
      </div>
      <div class="asset-detail">
        <span>Asset Type:</span>
        {{ asset?.mediaType }}
      </div>
    </div>
    <div class="asset-preview-buttons">
      <button (click)="tertiaryButtonPressed.emit()"
              [hidden]="!options?.tertiaryButtonText || options?.tertiaryButtonText === ''"
              [ngClass]="options?.tertiaryButtonClass"
              class="custom-button">
        {{ options?.tertiaryButtonText }}
      </button>
      <button (click)="secondaryButtonPressed.emit()"
              [hidden]="!options?.secondaryButtonText || options?.secondaryButtonText === ''"
              [ngClass]="options?.secondaryButtonClass"
              class="custom-button">
        {{ options?.secondaryButtonText }}
      </button>
      <button (click)="primaryButtonPressed.emit()"
              [hidden]="!options?.primaryButtonText || options?.primaryButtonText === ''"
              [ngClass]="options?.primaryButtonClass"
              class="custom-button">
        {{ options?.primaryButtonText }}
      </button>
    </div>
  </div>


</div>
