import {Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {GuidesDomainModel} from '../../../../../domain-models/guides-domain-model';
import {map} from 'rxjs/operators';
import {GuideClickType} from '../../../../../models/guide/enum/guide-click-type.enum';
import {InsiderInsight} from '../../../../../models/guide/dto/insider-insight';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable()
export class GuideInsightTableViewModel extends BaseViewModel implements OnDestroy {

  public allGuides$ = this.guidesDomainModel.allGuides$;
  private insiderInsights = new BehaviorSubject<InsiderInsight>(null);
  public clickMap$ = this.insiderInsights.pipe(map(insights => {
    if (!!insights) {
      const value = insights?.totalClickMap?.values().next().value as Map<GuideClickType, number>;
      if (value) { return value; } else { return InsiderInsight.emptyMap(); }
    } else {
      return InsiderInsight.emptyMap();
    }
  }));

  constructor(
    private guidesDomainModel: GuidesDomainModel,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  setInsiderInsight(ii: InsiderInsight) {
    this.insiderInsights.next(ii);
  }

  goToInsights(guideId: string) {
    this.router.navigate(
      [`${guideId}/insights`],
      { relativeTo: this.route }
    ).then();
  }

  ngOnDestroy() {
    this.destroy();
  }

}
