import {BaseViewModel} from '../../../../models/base/base-view-model';
import {InsidersDomainModel} from '../../../../domain-models/insiders-domain-model';
import {map, takeUntil} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {IFrameMessagingService} from '../../../../services/iframe-messaging.service';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute, Params} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {LoadingSpinnerSize} from '../../../../models/enum/shared/loading-spinner-size.enum';

@Injectable()
export class PreviewGuidesViewModel extends BaseViewModel implements OnDestroy {

  public loadingOpts: LoadingOptions = LoadingOptions.default();

  private insiderId = new BehaviorSubject<string>(null);
  public iFrameSrc$ = this.insiderId.pipe(
    map(insiderId => {
      let link;
      if (environment.production) {
        link = !!insiderId ? `https://embed.krugopartners.com/#/guides/${insiderId}` : '';
      } else {
        link = !!insiderId ? `https://staging.embed.krugopartners.com/#/guides/${insiderId}` : '';
      }
      return this.sanitizer.bypassSecurityTrustResourceUrl(link);
    }),
  );

  constructor(
    private iFrameService: IFrameMessagingService,
    private sanitizer: DomSanitizer,
    private insiderDomainModel: InsidersDomainModel,
    private route: ActivatedRoute,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupLoadingSpinner();
    this.listenForParams();
  }

  private setupLoadingSpinner() {
    this.loadingOpts.isLoading = true;
    this.loadingOpts.backgroundColor = '#FCFCFC';
    this.loadingOpts.showLoadingText = true;
    this.loadingOpts.spinnerSize = LoadingSpinnerSize.Medium;
    this.loadingOpts.spinnerColor = '#222222';
    this.loadingOpts.loadingText = 'Loading Preview';
    this.loadingOpts.color = '#222222';
  }

  private listenForParams() {
    this.route.params
      .pipe(takeUntil(this.onDestroy))
      .subscribe(params => {
        this.initFromUrl(params);
      });
  }

  initFromUrl(params: Params) {
    const companyId = params.companyId;
    if (!!companyId) {
      this.insiderId.next(companyId);
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
