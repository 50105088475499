import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {InsiderInsight} from '../../../../../models/guide/dto/insider-insight';
import {GuideInsightTableViewModel} from './guide-insight-table-view-model';
import {KeyValueUtils} from '../../../../../utils/key-value-utils';
import {GuideClickType} from '../../../../../models/guide/enum/guide-click-type.enum';

@Component({
  selector: 'app-guide-insight-table',
  templateUrl: './guide-insight-table.component.html',
  styleUrls: ['./guide-insight-table.component.scss'],
  providers: [GuideInsightTableViewModel]
})
export class GuideInsightTableComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() title: string = 'All Guides';
  @Input() insiderInsight: InsiderInsight;
  @Input() isLoading: boolean = true;
  KeyValueUtils = KeyValueUtils;

  constructor(
    public vm: GuideInsightTableViewModel
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.vm.setInsiderInsight(this.insiderInsight);
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.insiderInsight) {
      this.vm.setInsiderInsight(this.insiderInsight);
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  getTooltip(type: GuideClickType): string {
    switch (type) {
      case GuideClickType.Facebook:
        return 'The number of times that linked Facebook page(s) have been visited as a result of user navigating from within a guide';
      case GuideClickType.Guide:
        return 'The number of times guide(s) have been viewed';
      case GuideClickType.Instagram:
        return 'The number of times that linked Instagram page(s) have been visited as a result of user navigating from within a guide';
      case GuideClickType.Twitter:
        return 'The number of times that linked Twitter page(s) have been visited as a result of user navigating from within a guide';
      case GuideClickType.Website:
        return 'The number of times that linked website(s) have been visited as a result of user navigating from within a guide';
    }
  }

}
