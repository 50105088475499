<button
  class="custom-pill"
  [ngClass]="{ 'active' : selected && !disableActiveClass, 'hoverable' : clickable && !disabled }"
  [disabled]="disabled"
  (click)="clicked()">
  <div *ngIf="pillIcon" class="pill-icon mr-3">
    <app-asset [borderRadius]="'50%'" [asset]="pillIcon"></app-asset>
  </div>
    <ng-content *ngIf="!text"></ng-content>
    <ng-container *ngIf="text">{{text | lowercase | ucfirst}}</ng-container>
</button>
<div class="pill-subtext mt-2" *ngIf="pillSubtext">{{pillSubtext}}</div>
