import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {EditGuideFeatureViewModel} from './edit-guide-feature-view-model';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';

@Component({
  selector: 'app-edit-guide-feature',
  templateUrl: './edit-guide-feature.component.html',
  styleUrls: ['./edit-guide-feature.component.scss'],
  providers: [EditGuideFeatureViewModel]
})
export class EditGuideFeatureComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public viewModel: EditGuideFeatureViewModel,
    private activeModal: NgbActiveModal,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.setupFormItems();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
    const closeSub = this.viewModel.closeModal.notNull().subscribe(_ => {
      this.closeModal();
    });
    this.pushSub(closeSub);
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.destroy();
  }

  setupFormItems() {
    const formItems: FormInputItem[] = [];
    // Set up form items
    const titleInput = new FormInputItem();
    titleInput.itemType = FormItemType.Input;
    titleInput.inputName = 'title';
    titleInput.label = 'Title';
    titleInput.placeholder = 'Best Summertime Patios';
    titleInput.bindingProperty = 'title';
    titleInput.inputType = FormInputType.Text;
    titleInput.required = true;
    formItems.push(titleInput);

    const descriptionInput = new FormInputItem();
    descriptionInput.itemType = FormItemType.Input;
    descriptionInput.inputName = 'description';
    descriptionInput.label = 'Description';
    descriptionInput.placeholder = 'The top outdoor patios to grab some food and drink';
    descriptionInput.bindingProperty = 'description';
    descriptionInput.inputType = FormInputType.Text;
    descriptionInput.required = true;
    formItems.push(descriptionInput);

    this.viewModel.formItems = formItems;
  }

}
