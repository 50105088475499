<app-loading
  [hidden]="!viewModel.loadingOpts.isLoading"
  [options]="viewModel?.loadingOpts">
</app-loading>

<ng-container>
  <div class="container-fluid edit-content">
    <div class="row">
      <div class="col-6 create-title">
        Create
      </div>
      <div class="ml-auto">
        <button matRipple class="custom-button grey-button" (click)="viewModel.openMapModal()">
          <img [src]="'assets/icons/dark/map.svg'" alt="Map Icon">
          View On Map
        </button>
      </div>
    </div>
    <hr>
    <div class="row mb-5">
      <div class="col-4 offset-2">
        <div class="float-left edit-place-back-button" (click)="viewModel.goBack()" fxLayout="row">
          <img (click)="viewModel.goBack()" [src]="'assets/icons/dark/arrow-left.svg'" alt="Back Arrow">
          <div class="section-title ml-3">Edit Place</div>
        </div>
      </div>
      <div class="col-4 ml-auto">
        <button *ngIf="viewModel.placeImages.length > 1" (click)="viewModel.openReorderImageModal()" class="custom-button">Reorder Images</button>
      </div>
    </div>
    <div class="row">
      <div *ngIf="viewModel.getFeaturedImage()"
           class="col-8 offset-2 featured-image-container"
           fxLayout="row" fxLayoutAlign="start">
        <div>
          <div class="remove-featured-button-container"
               (click)="viewModel.deletePlaceImage(viewModel.placeImages[0].asset)">
            <img class="remove-button-icon" [src]="'assets/icons/dark/x.svg'" alt="remove image">
          </div>
          <app-asset #myself
                     class="featured-image"
                     [borderRadius]="'0.625rem'"
                     (ratio)="setFeaturedRatio($event)"
                     [style.width.px]="(featuredImageRatio$ | async | multiplyBy: myself.el.nativeElement.clientHeight)"
                     [asset]="viewModel.getFeaturedImage()">
          </app-asset>
        </div>
      </div>
      <div *ngIf="!viewModel.getFeaturedImage()" class="col-8 offset-2 image-upload-container mb-5">
        <app-upload-asset
          [uploadAreaCustomClass]="'guideUploadArea'"
          [allowImage]="!viewModel.featuredImage"
          [allowVideo]="!viewModel.featuredImage"
          [maxAssets]="9"
          [displayList]="false"
          [parentHandler]="viewModel">
        </app-upload-asset>
      </div>
      <div class="col-8 offset-2 mt-2" *ngIf="viewModel.placeImages.length > 0">
        <ul class="custom-upload-list">
          <li *ngFor="let img of viewModel.placeImages | slice:1">
            <div class="remove-button-container" (click)="viewModel.deletePlaceImage(img.asset)">
              <img class="remove-button-icon" [src]="'assets/icons/dark/x.svg'" alt="remove image">
            </div>
            <div class="custom-upload-preview-image">
              <app-asset [asset]="img.asset"
                         [borderRadius]="'0.5rem'"></app-asset>
            </div>
          </li>
          <li *ngIf="viewModel.placeImages.length < 9" class="custom-upload-preview-image">
            <img class="add-more-icon" (click)="viewModel.openUploadModal()" [src]="'assets/icons/dark/plus.svg'"
                 alt="plus">
          </li>
        </ul>
      </div>
    </div>
    <div class="row label-group-container">
      <div class="col-8 offset-2">
        <div [hidden]="viewModel.canEdit" class="mt-5">
          <app-label-group
            [labelItems]="viewModel.labelGroupItems">
          </app-label-group>
        </div>
        <div [hidden]="!viewModel.canEdit" class="mt-5">
          <app-form-group #formGroupComponent
                          (formChanges)="viewModel?.placeInfoChanges()"
                          [formItems]="this.viewModel.placeInfoItems"
                          [options]="this.viewModel.placeInfoOptions"
                          [formObject]="this.viewModel.customPlace"
                          [hydrateInputObject]="viewModel?.hydrateInputObject"
                          [styling]="this.viewModel.placeInfoStyling">
          </app-form-group>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="sticky-footer-container" style="padding-left: 30px; padding-right: 30px">
  <hr>
  <div class="footer-button-container">
    <div class="float-left">
      <button matRipple class="custom-button destructive-button" (click)="viewModel.openRemoveModal()">Remove
        Place
      </button>
    </div>
    <div class="save-submit-buttons">
      <div class="save-submit-buttons">
        <button matRipple class="custom-button grey-button" (click)="viewModel.cancel()">Cancel</button>
        <button matRipple class="custom-button preferred-button ml-2"
                [disabled]="!viewModel.canSubmit"
                [ngClass]="{ 'disabled' : !viewModel.canSubmit}"
                (click)="viewModel.saveChanges()">Save Changes
        </button>
      </div>
    </div>
  </div>
</div>
