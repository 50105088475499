import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {LoadingSpinnerSize} from '../../../../models/enum/shared/loading-spinner-size.enum';

@Component({
  selector: 'app-segmented-empty-state',
  templateUrl: './segmented-empty-state.component.html',
  styleUrls: ['./segmented-empty-state.component.scss']
})
export class SegmentedEmptyStateComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() title: string;
  @Input() desc: string;
  @Input() emptyStateHeight: string;
  @Input() loading: boolean;
  loadingOpts = LoadingOptions.default();
  /** Only shows button if buttonText truthy value is true */
  @Input() buttonText: string;
  @Output() buttonClicked = new EventEmitter<boolean>(true);

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.loadingOpts.isLoading = true;
    this.loadingOpts.spinnerSize = LoadingSpinnerSize.Medium;
    this.loadingOpts.backgroundColor = '#FCFCFC';
    this.loadingOpts.spinnerColor = '#222222';
    this.loadingOpts.loadingText = 'Loading Guides';
    this.loadingOpts.color = '#222222';
    this.loadingOpts.showLoadingText = true;
    this.checkLoading();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  private checkLoading() {
    if (this.loadingOpts.isLoading !== this.loading ?? true) {
      this.loadingOpts.isLoading = this.loading ?? true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkLoading();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
