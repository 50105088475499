import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keepIfCompanyId'
})
export class KeepIfCompanyIdPipe implements PipeTransform {

  transform(id: string, companyIds: string[]): string {
    if (!!id && !!companyIds) {
      return companyIds.contains(id) ? id : '';
    }
    return '';
  }

}
