import {BaseModalViewModel} from '../../../../models/base/base-modal-view-model';
import {GuidesDomainModel} from '../../../../domain-models/guides-domain-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Guide} from '../../../../models/guide/dto/guide';
import {Injectable, OnDestroy} from '@angular/core';
import {CustomError} from '../../../../models/shared/custom-error';
import {ToastService} from '../../../../services/toast-service';

@Injectable()
export class DeleteGuideViewModel extends BaseModalViewModel implements OnDestroy {

  public loadingOpts: LoadingOptions;

  constructor(
    private domainModel: GuidesDomainModel,
    public router: Router,
    public modalService: NgbModal,
    private toastService: ToastService,
  ) {
    super(router, modalService);
    this.init();
  }

  init(): void {
    this.setupLoadingSpinner();
  }

  private setupLoadingSpinner() {
    this.loadingOpts = LoadingOptions.default();
    this.loadingOpts.loadingText = 'Deleting Guide';
    this.loadingOpts.isLoading = false;
  }

  deleteGuide(g: Guide) {
    this.loadingOpts.addRequest('Deleting Guide');
    this.domainModel.deleteGuide(g).subscribe(_ => {
      this.loadingOpts.removeRequest('Deleting Guide');
      this.toastService.publishSuccessMessage('Successful.', 'Delete Guide');
      this.cancel();
    }, (error: CustomError) => {
      this.loadingOpts.removeRequest('Deleting Guide');
      this.toastService.publishError(error);
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

}
