<div class="modal-body">
  <div class="modal-title mb-4">
    Edit Profile Picture
  </div>
  <div class="existing-profile-picture" *ngIf="initialImageSrc && !replacingImage">
    <img [src]="initialImageSrc" alt="">
    <button class="custom-button outlined-button mt-3" (click)="replacingImage = true">Replace Image</button>
  </div>
  <div *ngIf="(!initialImageSrc || replacingImage) && !uploadedImage">
    <app-upload-asset
      [allowImage]="true"
      [allowVideo]="false"
      [maxAssets]="1"
      [parentHandler]="this">
    </app-upload-asset>
  </div>
  <div *ngIf="uploadedImage">
    <image-cropper
      class="image-cropper"
      [imageURL]="uploadedImage?.url"
      [maintainAspectRatio]="true"
      [aspectRatio]="1"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      [roundCropper]="roundedOverlay"
      [hideResizeSquares]="false"
      (loadImageFailed)="loadImageFailed()">
    </image-cropper>
  </div>
  <hr class="mb-32px">
  <div class="modal-footer-flex-end">
    <button matRipple
            (click)="cancel()"
            class="custom-button grey-button mr-3">
      Cancel
    </button>
    <button matRipple
            (click)="save()"
            [class.disabled]="!croppedPngBase64"
            class="custom-button preferred-button">
      Save Image
    </button>
  </div>
</div>
