import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-remove-section',
  templateUrl: './remove-section.component.html',
  styleUrls: ['./remove-section.component.scss']
})
export class RemoveSectionComponent implements OnInit {
  suggestionNumber: number = null;

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.dismiss();
  }

  removeSection() {
    this.activeModal.close();
  }

}
