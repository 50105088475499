<div>
  <div class="main-title">
    {{vm.title}}
  </div>
  <div class="divider"></div>
  <div class="container mb-5">
    <div class="row mx-0">
      <div class="col-8 mx-auto">
        <app-loading
          [hidden]="!vm?.loadingOpts?.isLoading"
          [options]="vm?.loadingOpts">
        </app-loading>
        <div class="contributors-section">
          <div class="contributors-section-title">
            Admins
          </div>
          <div class="divider"></div>
          <div class="row mx-0" *ngFor="let c of vm.companyAdmins">
            <div class="col contributor-item">
              <div class="contributor-item-img">
                <app-asset [asset]="c.profilePicture" [borderRadius]="'50%'"></app-asset>
              </div>
              <div class="contributor-item-content">
                <div class="contributor-item-title">
                  {{c.getFullName()}}
                </div>
                <div class="contributor-item-subtitle">
                  Admin
                </div>
              </div>
              <div class="contributor-item-action"
                   matRipple
                   popper
                   [popper]="popperContent"
                   [popperShowOnStart]="false"
                   [popperTrigger]="'click'"
                   [popperModifiers]="popperModifier"
                   [popperHideOnClickOutside]="true"
                   [popperHideOnScroll]="true"
                   [popperPreventOverflow]="false"
                   [popperApplyArrowClass]="'d-none'"
                   [popperApplyClass]="'custom-popper-content'"
                   [popperStyles]="popperStyles"
                   [popperPlacement]="'bottom-end'">
                <img [src]="'assets/icons/dark/solid/dots-horizontal.svg'" alt="options">
              </div>
              <popper-content #popperContent [style.z-index]="1">
                <div role="menuitem">
                  <button matRipple
                          class="custom-dropdown-menu-item"
                          (click)="vm.makeContributor(c); popperContent.hide();">Make Contributor</button>
                </div>
                <div role="menuitem">
                  <button matRipple
                          class="custom-dropdown-menu-item red-text"
                          (click)="openRemoveContributorModal(c); popperContent.hide();">Remove</button>
                </div>
              </popper-content>
            </div>
            <div class="divider"></div>
          </div>
        </div>
        <div class="contributors-section">
          <div class="contributors-section-title">
            Contributors
          </div>
          <div class="divider"></div>
          <div class="row mx-0" *ngFor="let c of vm.companyContributors">
            <div class="col contributor-item">
              <div class="contributor-item-img">
                <app-asset [asset]="c.profilePicture" [borderRadius]="'50%'"></app-asset>
              </div>
              <div class="contributor-item-content">
                <div class="contributor-item-title">
                  {{c.getFullName()}}
                </div>
                <div class="contributor-item-subtitle">
                  Contributor
                </div>
              </div>
              <div class="contributor-item-action"
                   matRipple
                   popper
                   [popper]="popperContent"
                   [popperShowOnStart]="false"
                   [popperTrigger]="'click'"
                   [popperModifiers]="popperModifier"
                   [popperHideOnClickOutside]="true"
                   [popperHideOnScroll]="true"
                   [popperPreventOverflow]="false"
                   [popperApplyArrowClass]="'d-none'"
                   [popperApplyClass]="'custom-popper-content'"
                   [popperStyles]="popperStyles"
                   [popperPlacement]="'bottom-end'">
                <img [src]="'assets/icons/dark/solid/dots-horizontal.svg'" alt="options">
              </div>
              <popper-content #popperContent [style.z-index]="1">
                <div role="menuitem">
                  <button matRipple
                          class="custom-dropdown-menu-item"
                          (click)="vm.makeAdmin(c); popperContent.hide();">Make Admin</button>
                </div>
                <div role="menuitem">
                  <button matRipple
                          class="custom-dropdown-menu-item red-text"
                          (click)="openRemoveContributorModal(c); popperContent.hide();">Remove</button>
                </div>
              </popper-content>
            </div>
            <div class="divider"></div>
          </div>
        </div>
        <div class="mt-4">
          <button matRipple
                  class="custom-button"
                  (click)="openAddContributorModal()">
            <img [src]="'assets/icons/dark/plus.svg'" alt="">
            Add New Contributor
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
