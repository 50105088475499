import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GuidesComponent} from './components/guides.component';
import {GuidesRoutingModule} from './guides-routing.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {BaseCreateViewModel} from '../shared/modals/create-guide/viewmodels/base-create-view-model';
import {SegmentedGuidesComponent} from './components/segmented-guides/segmented-guides.component';
import {DraftsComponent} from './components/segmented-guides/drafts/drafts.component';
import {PendingComponent} from './components/segmented-guides/pending/pending.component';
import {ApprovedComponent} from './components/segmented-guides/approved/approved.component';
import {ArchivedComponent} from './components/segmented-guides/archived/archived.component';
import {GuidesViewModel} from './components/guides-view-model';
import {GuideComponent} from './components/guide/guide.component';
import {GuideViewModel} from './components/guide/guide-view-model';
import {AddPlaceViewModel} from './components/guide/add-place-modal/add-place-view-model';
import {AddPlaceModalComponent} from './components/guide/add-place-modal/add-place-modal.component';
import {FlexModule} from '@angular/flex-layout';
import {SearchGuidesComponent} from './components/search-guides/search-guides.component';
import {GuideTagsComponent} from './components/guide-tags/guide-tags.component';
import {SegmentedEmptyStateComponent} from './components/segmented-empty-state/segmented-empty-state.component';
import {GuidesGridComponent} from './components/guides-grid/guides-grid.component';
import {GuideCardComponent} from './components/guide-card/guide-card.component';
import {TagPillComponent} from './components/guide-tags/pills/pill/tag-pill.component';
import {TagPillFilterComponent} from './components/guide-tags/pills/pill-filter/tag-pill-filter.component';
import {NgStringPipesModule} from 'ngx-pipes';
import {NgxPopperModule} from 'ngx-popper';
import {DeleteGuideComponent} from './components/delete-guide/delete-guide.component';
import {ManageTagsComponent} from './components/manage-tags/manage-tags.component';
import {RenameSectionComponent} from './components/guide/section-options/rename-section/rename-section.component';
import {RearrangeSectionComponent} from './components/guide/section-options/rearrange-section/rearrange-section.component';
import {RemoveSectionComponent} from './components/guide/section-options/remove-section/remove-section.component';
import {EditPlaceComponent} from './components/guide/edit-place/edit-place.component';
import {EditPlaceViewModel} from './components/guide/edit-place/edit-place-view-model';
import {MapModalComponent} from './components/guide/map-modal/map-modal.component';
import {GuidesHeaderNamePipe} from './pipes/guides-header-name.pipe';
import {CompanyPendingComponent} from './components/segmented-guides/company-pending/company-pending.component';
import {RejectGuideModalComponent} from './components/reject-guide-modal/reject-guide-modal.component';
import { MultiplyByPipe } from './pipes/multiply-by.pipe';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { ReorderImageModalComponent } from './components/guide/reorder-image-modal/reorder-image-modal.component';

@NgModule({
  declarations: [
    GuidesComponent,
    SegmentedGuidesComponent,
    DraftsComponent,
    PendingComponent,
    ApprovedComponent,
    ArchivedComponent,
    SegmentedEmptyStateComponent,
    SearchGuidesComponent,
    GuideTagsComponent,
    GuidesGridComponent,
    GuideCardComponent,
    TagPillComponent,
    TagPillFilterComponent,
    DeleteGuideComponent,
    ManageTagsComponent,
    GuideComponent,
    AddPlaceModalComponent,
    RenameSectionComponent,
    RearrangeSectionComponent,
    RemoveSectionComponent,
    EditPlaceComponent,
    MapModalComponent,
    GuidesHeaderNamePipe,
    CompanyPendingComponent,
    RejectGuideModalComponent,
    MultiplyByPipe,
    ReorderImageModalComponent,
  ],
  imports: [
    CommonModule,
    GuidesRoutingModule,
    FormsModule,
    SharedModule,
    FlexModule,
    NgStringPipesModule,
    NgxPopperModule,
    NgbTooltipModule
  ],
  exports: [
    SegmentedGuidesComponent,
    GuidesComponent,
    DraftsComponent,
    PendingComponent,
    ApprovedComponent,
    GuidesGridComponent,
    SegmentedEmptyStateComponent,
  ],
  providers: [
    GuidesViewModel,
    BaseCreateViewModel,
    GuideViewModel,
    AddPlaceViewModel,
    EditPlaceViewModel
  ]
})
export class GuidesModule { }
