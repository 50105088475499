import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {Asset} from '../../../../models/image/dto/asset';
import {BaseComponent} from '../../../../models/base/base-component';
import '../../../../utils/replay-subject.extensions';
import {AssetSize} from '../../../../models/enum/dto/image-size.enum';
import {DistinctUtils} from '../../../../utils/distinct.utils';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent extends BaseComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() asset: Asset;
  @Input() scaleFit: boolean = false;
  @Input() borderRadius: string = '';
  @Input() loopVideo: boolean = true;
  @Input() autoplay: boolean = true;
  @Input() size: AssetSize = AssetSize.Medium;
  @Output() ratio: EventEmitter<number> = new EventEmitter<number>();
  @Output() videoEnded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() duration: EventEmitter<number> = new EventEmitter<number>();
  private subject: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);
  public distinctAsset$ = this.subject.pipe(
    startWith('assets/placeholder/no-image.svg'),
    map(val => { if (!val) { return 'assets/placeholder/no-image.svg'; } return val; }),
    distinctUntilChanged(DistinctUtils.distinctAsset),
  );
  private loading: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public loading$ = this.loading.pipe(startWith(true), debounceTime(250), distinctUntilChanged());

  constructor(
    public el: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
    this.ratio.emit(1);
    this.setupBindings();
  }

  ngAfterViewInit(): void {
  }

  setupBindings() {
    this.imageBinding();
  }

  imageBinding() {
    const imgKey = 'assetKey';
    const imgLoadingKey = 'loadingKey';
    this.destroyImageSub(imgKey);
    this.destroyImageSub(imgLoadingKey);
    let s;
    let ss;
    const assetUrl = this?.asset?.getAssetUrl(this.size);
    if (!!assetUrl) {
      s = this.subject.bind(
        assetUrl.srcUrl.pipe(
          map((url) => {
            if (url && url !== '' && this.asset?.isImage()) { return url; }
            else if (url && url !== '' && this.asset?.isVideo()) { return url; }
            else { return 'assets/placeholder/no-image.svg'; }
          })
        )
      );
      ss = this.loading.bind(assetUrl.loading.asObservable());
    } else {
      this.subject.next(null);
      this.loading.next(false);
    }
    if (s) { this.pushImageSub(imgKey, s); }
    if (ss) { this.pushImageSub(imgLoadingKey, ss); }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.asset) {
      if (this.asset.isVideo() || !this.asset.fileName) {
        // if video or booking.com asset, use Large file size
        this.size = AssetSize.Large;
      }
    }
    const changed = (changes.asset?.previousValue !== undefined && changes.asset?.previousValue !== null);
    const oldHash = changes?.asset?.previousValue?.md5Hash;
    const newHash = changes?.asset?.currentValue?.md5Hash;
    const md5Changed = (changed && oldHash && newHash && (oldHash !== newHash));
    if (md5Changed || !changed) {
      this.setupBindings();
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
