<form class="search-bar-container"
      (reset)="emitEmpty()">
  <label for="searchBar" hidden>Search Label</label>
  <input #textField
         class="text-field search-text-field"
         id="searchBar"
         name="searchBar"
         placeholder="{{placeHolder}}"
         [maxLength]="maxLength"
         (input)="searchChanged($event)"
         (keydown.enter)="enterHit($event)"
         type="text">
  <button class="add-search"
          (click)="plusHit(textField.value)">
    +
  </button>
  <button #reset
          [hidden]="true"
          type="reset">
    CLEAR
  </button>
</form>
