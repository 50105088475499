import {AfterViewInit, Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Insider} from '../../../../../models/guide/dto/insider';
import {BaseComponent} from '../../../../../models/base/base-component';
import {TypeUtils} from '../../../../../utils/type-utils';
import {FontHierarchy} from '../../../../../models/enum/shared/font-hierarchy.enum';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChooseFontModalComponent} from '../choose-font-modal/choose-font-modal.component';
import {Font} from '../../../../../models/enum/shared/font.enum';

@Component({
  selector: 'app-insider-design',
  templateUrl: './insider-design.component.html',
  styleUrls: ['./insider-design.component.scss'],
})
export class InsiderDesignComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() insider: Insider;
  public forceRefresh: boolean = false;
  public types = TypeUtils;

  constructor(
    public modalService: NgbModal,
    public zone: NgZone,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  chooseFontTitle(hierarchy: FontHierarchy) {
    this.openChooseFontModal(hierarchy);
  }

  stopRefresh = () => {
    this.forceRefresh = false;
  }

  private openChooseFontModal(hierarchy: FontHierarchy) {
    const modalRef = this.modalService.open(
      ChooseFontModalComponent,
      ModalUtils.chooseFontModalOptions()
    );
    modalRef.result.then((font: Font) => {
      if (font) {
        switch (hierarchy) {
          case FontHierarchy.Primary: {
            this.insider.options.primaryFont = font;
            break;
          }
          case FontHierarchy.Secondary: {
            this.insider.options.secondaryFont = font;
            break;
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
