<div class="guides-container" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="guide-title">
      {{vm.associatedInsiderId$ | async | guidesHeaderName: (vm.companies$ | async)}}
    </div>
    <button class="custom-button neutral-button"
            (click)="this.vm.openCreateModal.next(true)">
      <img [src]="'assets/icons/light/plus.svg'" alt="">
      Create Guide
    </button>
  </div>
  <app-segmented-guides [associatedInsiderId]="vm.associatedInsiderId$|async"
                        [fxFill]="true"
                        fxLayout="column"
                        fxFlex>
  </app-segmented-guides>
</div>
