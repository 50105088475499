import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import {FormValidator} from '../../../../../models/protocols/form-validator';

export function letterOnlyValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    if (value === '') {
      return null;
    }
    const expression = {
      pattern: '^[a-zA-Z]+$',
      message: 'Letters only and no spaces.'
    };
    const regexp = new RegExp(expression.pattern);
    return !regexp.test(value) ? {'letterOnly': expression.message} : null;
  };
}

@Directive({
  selector: '[appLetterOnlyDirective]',
  providers: [{provide: NG_VALIDATORS, useExisting: LetterOnlyValidatorDirective, multi: true}]
})
export class LetterOnlyValidatorDirective implements FormValidator {
  validate(control: AbstractControl): { [key: string]: any } {
    return letterOnlyValidator()(control);
  }

  errorName(): string {
    return 'letterOnly';
  }
}
