import {BaseViewModel} from '../../../../../models/base/base-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class ChooseFontButtonViewModel extends BaseViewModel {

  constructor(
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

}
