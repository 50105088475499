import {NgModule} from '@angular/core';
import {AccountComponent} from './components/account/account.component';
import {AuthComponent} from './components/auth/auth.component';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: AccountComponent,
    data: {
      title: 'Account'
    }
  },
  {
    path: 'auth/sign-in',
    component: AuthComponent,
    data: {
      title: 'Sign In'
    }
  },
  {
    path: 'auth/sign-up',
    component: AuthComponent,
    data: {
      title: 'Sign Up'
    },
  },
  {
    path: 'auth/confirm-account',
    component: AuthComponent,
    data: {
      title: 'Confirm Account'
    },
  },
  {
    path: 'auth/forgot-password',
    component: AuthComponent,
    data: {
      title: 'Forgot Password'
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
