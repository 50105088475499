import {DateUtils} from '../../../utils/date-utils';

export class GuideInsightReq {

  public guideId: string;
  public dateKey: string;
  public startTime: number;
  public endTime: number;
  public uniqueImpressions: boolean;

  constructor(
    guideId: string,
    dateKey: string,
    startTime: moment.Moment,
    endTime: moment.Moment,
    uniqueImpressions: boolean
  ) {
    this.guideId = guideId;
    this.dateKey = dateKey;
    this.startTime = startTime.unix();
    this.endTime = endTime.unix();
    this.uniqueImpressions = uniqueImpressions;
  }

  buildKey(): string {
    return DateUtils.buildDateKey(this.guideId, this.dateKey);
  }

  equalityCheck(other: GuideInsightReq) {
    return this.guideId === other.guideId &&
      this.dateKey === other.dateKey &&
      this.startTime === other.startTime &&
      this.endTime === other.endTime &&
      this.uniqueImpressions === other.uniqueImpressions;
  }

}
