import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {Selectable} from '../../../../models/protocols/selectable';

@Component({
  selector: 'app-guide-tags',
  templateUrl: './guide-tags.component.html',
  styleUrls: ['./guide-tags.component.scss']
})
export class GuideTagsComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() tags: Selectable[] = [];
  @Input() currentSelection: Selectable;
  @Input() hint: Selectable;
  @Output() selected = new EventEmitter<string>(true);

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  setupBindings() {
  }

  changed() {
    this.selected.emit(this.currentSelection.getSelectionValue());
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
