import {Injectable, OnDestroy} from '@angular/core';
import {BaseDomainModel} from '../models/base/base-domain-model';
import {GuideAPI} from '../api/guide-api';
import {InsiderAPI} from '../api/insider-api';
import {BehaviorSubject, combineLatest, forkJoin, Observable, Subject, zip} from 'rxjs';
import {Insider} from '../models/guide/dto/insider';
import {CompanyInsiderReq} from '../models/guide/requests/company-insider-req';
import {CompanyInsiderLookup} from '../models/guide/dto/company-insider-lookup';
import {Guide} from '../models/guide/dto/guide';
import {HydratedGuide} from '../models/guide/dto/hydrated-guide';
import {CustomPlace} from '../models/guide/dto/custom-place';
import {City} from '../models/shared/city';
import {SharedAPI} from '../api/shared-api';
import {debounceTime, distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';
import {SortUtils} from '../utils/sort-utils';
import {ToastService} from '../services/toast-service';
import {SessionService} from '../services/session-service';
import {AutoCompletedLocation} from '../models/shared/auto-completed-location';
import {PlaceCategory} from '../models/guide/dto/place-category';
import {CacheService} from '../services/cache-service';
import {CustomError} from '../models/shared/custom-error';
import {ImageAPI} from '../api/image-api';
import {GenerateUploadUrlRequest} from '../models/image/requests/generate-upload-url-request';
import {FullImage} from '../models/image/shared/full-image';
import {CustomFile} from '../models/shared/custom-file';
import {UniqueUtils} from '../utils/unique-utils';
import {DistinctUtils} from '../utils/distinct.utils';
import {LoadingOptions} from '../models/shared/loading-options';
import {GuideFeature} from '../models/guide/dto/guide-feature';
import {GuideFeaturesViewModel} from '../views/company/components/guide-features/guide-features-view-model';

@Injectable({
  providedIn: 'root'
})

export class GuidesDomainModel extends BaseDomainModel implements OnDestroy {

  // Loading Options
  public loadingOpts = LoadingOptions.black();

  public placeCategories: BehaviorSubject<PlaceCategory[]> = new BehaviorSubject<PlaceCategory[]>(null);
  public cities: BehaviorSubject<City[]> = new BehaviorSubject<City[]>(null);

  // myGuides - comes from getInsiderGuidesAPI
  private myGuides = new BehaviorSubject<HydratedGuide[]>(null);

  // guide featured
  public guideFeaturesMap = new BehaviorSubject<Map<string, GuideFeature[]>>(new Map<string, GuideFeature[]>());

  // companyGuides - comes from getCompanyGuidesAPI
  private companyMap = new BehaviorSubject<Map<string, HydratedGuide[]>>(new Map<string, HydratedGuide[]>()); // <CompanyId, Guides>

  // All guides funneled together - is a unique list based on guide ids
  private allGuides = new BehaviorSubject<HydratedGuide[]>(null);
  public allGuides$ = this.allGuides.pipe(
    map(guides => guides?.uniqueBy(UniqueUtils.guideId)?.sort(SortUtils.sortGuidesByMostRecent))
  );

  // Set all guides from API
  private funnelGuidesFromAPI = combineLatest([
    this.myGuides,
    this.companyMap
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([myGuides, companyGuides]) => {
      let cGuides: HydratedGuide[] = [];
      companyGuides.forEach((items, _) => {
        cGuides = cGuides.concat(items);
      });
      const allGuides = (myGuides || []).concat(cGuides).uniqueBy(UniqueUtils.guideId);
      if (!!allGuides) {
        this.allGuides.next(allGuides);
      }
    });

  // Sort into company guides
  private userInsider = new BehaviorSubject<Insider>(null);
  private companies = new BehaviorSubject<Insider[]>(null);
  private companyIdsWhereUserIsAdmin = new BehaviorSubject<string[]>(null);

  // Fetch users insider guides from API
  private getUserInsiderGuides = this.userInsider
    .pipe(takeUntil(this.onDestroy), distinctUntilChanged(DistinctUtils.distinctInsider))
    .notNull()
    .subscribe(insider => this.fetchInsiderGuides(insider));

  // Get all guides for companies where user has admin status
  private fetchCompanyGuidesFromAPI = this.companyIdsWhereUserIsAdmin
    .notNull()
    .pipe(takeUntil(this.onDestroy), distinctUntilChanged(DistinctUtils.distinctListOfStrings))
    .subscribe(cIds => this.getUsersCompanyGuides(cIds));

  // Set Featured Mechanism
  private toggleFeaturedSubject = new Subject<HydratedGuide>();
  private featuredMechanism = combineLatest([
    this.toggleFeaturedSubject,
    this.allGuides$
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
    .subscribe(([selected, guides]) => {
      if (!!selected) {
        guides.forEach(g => {
          if (g.id !== selected.id) {
            g.featuredGuide = false;
          } else if (g.id === selected.id) {
            selected.featuredGuide = !selected.featuredGuide;
          }
        });
        this.toggleFeaturedSubject.next(null);
        this.updateFeaturedGuide(selected, guides);
      }
    });

  // Guide Updated Mechanism
  private updatedGuideSubject = new Subject<HydratedGuide>();
  private updatedGuideMechanism = combineLatest([
    this.updatedGuideSubject,
    this.allGuides$
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
    .subscribe(([updated, guides]) => {
      if (!!updated) {
        const index = guides.findIndex(guide => guide.id === updated.id);
        if (index > -1) {
          guides[index] = updated;
        }
        this.updatedGuideSubject.next(null);
        this.allGuides.next(guides);
      }
    });

  // Guide Deleted Mechanism
  private deletedGuideSubject = new Subject<string>();
  private deletedGuideMechanism = combineLatest([
    this.deletedGuideSubject,
    this.allGuides$
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
    .subscribe(([deletedId, guides]) => {
      if (deletedId) {
        const index = guides.findIndex(guide => guide.id === deletedId);
        if (index > -1) {
          guides.splice(index, 1);
        }
        this.deletedGuideSubject.next(null);
        this.allGuides.next(guides);
      }
    });

  // Guide Created Mechanism
  private createdGuideSubject = new Subject<HydratedGuide>();
  private createdGuideMechanism = combineLatest([
    this.createdGuideSubject,
    this.allGuides$
  ]).pipe(takeUntil(this.onDestroy), debounceTime(1))
    .subscribe(([created, guides]) => {
      if (!!created) {
        const createdGuideCacheKey = Guide.buildArrayCacheKey(!!created.companyId ? created.companyId :
          created.insiderId);
        const cachedGuideArray = this.cacheService.getCachedArray<HydratedGuide>(HydratedGuide, createdGuideCacheKey);
        if (cachedGuideArray) {
          this.cacheService.removeCachedObject(createdGuideCacheKey);
          cachedGuideArray.push(created);
          this.cacheService.cacheArray<HydratedGuide>(createdGuideCacheKey, cachedGuideArray);
        } else {
          const newGuideArray = [];
          newGuideArray.push(created);
          this.cacheService.cacheArray<HydratedGuide>(createdGuideCacheKey, newGuideArray);
        }
        this.createdGuideSubject.next(null);
        guides.push(created);
        this.allGuides.next(guides);
      }
    });

  // Listen to Session
  private listenToSession = this.session.sessionContainer
    .pipe(takeUntil(this.onDestroy))
    .subscribe(session => {
      this.userInsider.next(session?.insider);
      this.companies.next(session?.insider?.companies);
      this.companyIdsWhereUserIsAdmin.next(session?.insider?.adminCompanyIds);
    });

  constructor(
    private guideApi: GuideAPI,
    private insiderApi: InsiderAPI,
    private sharedApi: SharedAPI,
    private imageApi: ImageAPI,
    public session: SessionService,
    private toastService: ToastService,
    private cacheService: CacheService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  setupBindings() {
    if ((!this.placeCategories.getValue() || this.placeCategories.getValue()?.length === 0)) {
      this.getPlaceCategories();
    }

    if ((!this.cities.getValue() || this.cities.getValue()?.length === 0)) {
      this.getCities();
    }
    this.allGuides.next([]);
  }

  setFeaturedGuide(g: HydratedGuide) {
    this.toggleFeaturedSubject.next(g);
  }

  private updateFeaturedGuide(selected: HydratedGuide, guides: HydratedGuide[]) {
    this.updateGuide(selected)
      .subscribe(_ => {
        this.toastService.publishSuccessMessage('Updated', 'Featured Guide');
        this.allGuides.next(guides);
      }, (e: CustomError) => this.toastService.publishError(e));
  }

  private fetchInsiderGuides(insider: Insider) {
    this.loadingOpts.addRequest('Fetching Guides');
    this.getInsiderGuides(insider.id).subscribe(guides => {
      this.myGuides.next(guides);
      this.loadingOpts.removeRequest('Fetching Guides');
    }, (e: CustomError) => {
      this.toastService.publishError(e);
      this.loadingOpts.removeRequest('Fetching Guides');
    });
  }

  private getUsersCompanyGuides(cIds: string[]) {
    this.loadingOpts.addRequest('Fetching Company Guides');
    const calls: Observable<HydratedGuide[]>[] = [];
    for (const id of cIds) {
      calls.push(this.getCompanyGuides(id));
    }
    zip(...calls).pipe(
      map(guides => {
        const companyGuides = new Map<string, HydratedGuide[]>();
        cIds.forEach((companyId, index) => {
          companyGuides.set(companyId, guides[index]);
        });
        return companyGuides;
      })
    ).subscribe(companyGuides => {
      this.companyMap.next(companyGuides);
      this.loadingOpts.removeRequest('Fetching Company Guides');
    }, (e: CustomError) => {
      this.toastService.publishError(e);
      this.loadingOpts.removeRequest('Fetching Company Guides');
    });
  }

  // Insider Methods

  createInsider(insider: Insider): Observable<Insider> {
    return this.insiderApi.CreateInsider(insider);
  }

  updateInsider(insider: Insider): Observable<Insider> {
    return this.insiderApi.UpdateInsider(insider);
  }

  getInsider(id: string): Observable<Insider> {
    return this.insiderApi.GetInsider(id);
  }

  getCompanyInsiders(companyId: string): Observable<Insider[]> {
    return this.insiderApi.GetCompanyInsiders(companyId).pipe(
      map((insiders) => {
        this.cacheService.cacheArray<Insider>(Insider.buildArrayCacheKey(companyId), insiders);
        return insiders;
      })
    );
  }

  addCompanyInsider(req: CompanyInsiderReq): Observable<CompanyInsiderLookup[]> {
    return this.insiderApi.AddCompanyInsider(req);
  }

  updateCompanyInsider(req: CompanyInsiderReq): Observable<CompanyInsiderLookup[]> {
    return this.insiderApi.UpdateCompanyInsider(req);
  }

  deleteCompanyInsider(companyId: string, insiderId: string): Observable<string> {
    return this.insiderApi.DeleteCompanyInsider(companyId, insiderId);
  }

  // Guide Methods

  getGuides(ids: string[]): Observable<HydratedGuide[]> {
    return this.guideApi.GetGuides(ids).pipe(map(guides => {
      guides.forEach(guide => this.cacheService.cacheObject(guide.cacheKey(), guide));
      return guides;
    }));
  }

  getRecentGuides(insiderId: string): HydratedGuide[] {
    const cacheKey = Guide.buildArrayCacheKey(insiderId);
    const cachedGuides = this.cacheService.getCachedArray<HydratedGuide>(HydratedGuide, cacheKey);
    if (cachedGuides) {
      return cachedGuides;
    } else {
      return null;
    }
  }

  createGuide(g: Guide): Observable<HydratedGuide> {
    return this.guideApi.CreateGuide(g).pipe(map((guide) => {
      this.createdGuideSubject.next(guide);
      return guide;
    }));
  }

  public getInsiderGuides(iId: string) {
    return this.guideApi.GetInsiderGuides(iId).pipe(
      map((guides) => {
        this.cacheService.cacheArray<HydratedGuide>(Guide.buildArrayCacheKey(iId), guides);
        return guides;
      })
    );
  }

  public getCompanyGuides(cId: string) {
    return this.guideApi.GetCompanyGuides(cId).pipe(
      map((guides) => {
        this.cacheService.cacheArray<HydratedGuide>(Guide.buildArrayCacheKey(cId), guides);
        return guides;
      })
    );
  }

  updateGuide(guide: HydratedGuide): Observable<HydratedGuide> {
    return this.guideApi.UpdateGuide(guide).pipe(
      map(updated => {
        this.cacheService.cacheObject(updated.cacheKey(), updated);
        this.updatedGuideSubject.next(updated);
        return updated;
      })
    );
  }

  triggerUpdatedGuideMech(guide: HydratedGuide) {
    this.updatedGuideSubject.next(guide);
  }

  deleteGuide(guide: Guide): Observable<string> {
    return this.guideApi.DeleteGuide(guide).pipe(
      map(g => {
        this.deletedGuideSubject.next(guide.id);
        return g;
      })
    );
  }

  createGuidePlace(guidePlace: CustomPlace): Observable<HydratedGuide> {
    return this.guideApi.CreateGuidePlace(guidePlace);
  }

  deleteGuidePlace(guidePlace: CustomPlace): Observable<string> {
    return this.guideApi.DeleteGuidePlace(guidePlace);
  }

  updateGuidePlace(guidePlace: CustomPlace): Observable<CustomPlace> {
    return this.guideApi.UpdateGuidePlace(guidePlace);
  }

  deleteGuidePlaces(guidePlaces: CustomPlace[]): Observable<string> {
    return forkJoin(...guidePlaces.map(place => this.guideApi.DeleteGuidePlace(place)));
  }

  // Guide Feature Methods

  public getInsiderGuideFeatures(iId: string): Observable<GuideFeature[]> {
    return this.guideApi.GetCompanyGuideFeatures(iId).pipe(
      map((guideFeatures) => {
        this.cacheService.cacheArray<GuideFeature>(GuideFeature.buildArrayCacheKey(iId), guideFeatures);
        const existingGuideFeaturesMap = this.guideFeaturesMap.getValue();
        existingGuideFeaturesMap.set(iId, guideFeatures);
        this.guideFeaturesMap.next(existingGuideFeaturesMap);
        return guideFeatures;
      })
    );
  }

  public updateGuideFeature(guideFeature: GuideFeature): Observable<GuideFeature> {
    return this.guideApi.UpdateGuideFeature(guideFeature).pipe(
      map((updatedGuideFeature) => {
        this.cacheService.cacheObject<GuideFeature>(updatedGuideFeature.cacheKey(), guideFeature);
        // Replace Guide Feature
        this.replaceGuideFeature(updatedGuideFeature);
        return updatedGuideFeature;
      })
    );
  }

  public createGuideFeature(guideFeature: GuideFeature): Observable<GuideFeature> {
    return this.guideApi.CreateGuideFeature(guideFeature).pipe(
      map((newGuideFeature) => {
        this.cacheService.cacheObject<GuideFeature>(newGuideFeature.cacheKey(), guideFeature);
        // Replace Guide Feature
        this.replaceGuideFeature(newGuideFeature);
        return newGuideFeature;
      })
    );
  }

  public deleteGuideFeature(guideFeature: GuideFeature): Observable<string> {
    return this.guideApi.DeleteGuideFeature(guideFeature).pipe(
      map((_) => {
        this.cacheService.removeCachedObject(guideFeature.cacheKey());
        // Replace Guide Feature
        this.replaceGuideFeature(guideFeature, true);
        return null;
      })
    );
  }

  private replaceGuideFeature(gf: GuideFeature, remove: boolean = false) {
    const guideFeatureMap = this.guideFeaturesMap.getValue();
    const existingGuideFeatures = guideFeatureMap.get(gf.insiderId) ?? [];
    const existingIndex = existingGuideFeatures.findIndex(egf => egf.id === gf.id);
    if (existingIndex > -1 && remove) {
      existingGuideFeatures.splice(existingIndex, 1);
    } else if (existingIndex > -1) {
      existingGuideFeatures[existingIndex] = gf;
    } else if (!remove) {
      existingGuideFeatures.push(gf);
    }
    guideFeatureMap.set(gf.insiderId, existingGuideFeatures);
    this.guideFeaturesMap.next(guideFeatureMap);
  }

  // Image Methods

  deleteGuideImage(guideId: string, id: string, md5: string): Observable<string> {
    return this.imageApi.DeleteGuideImage(guideId, id, md5);
  }

  getAsset(id, md5Hash: string): Observable<FullImage> {
    return this.imageApi.GetAsset(id, md5Hash);
  }

  getBlobFromUrl(url: string): Observable<Blob> {
    return this.imageApi.GetBlobFromUrl(url);
  }

  // Shared Methods

  autoCompleteLocation(lat: number, lng: number, lookup: string): Observable<AutoCompletedLocation[]> {
    return this.sharedApi.AutoCompleteLocation(lat, lng, lookup);
  }

  // Misc Functions

  getPlaceCategories() {
    const cacheKey = PlaceCategory.buildCacheKey();
    const cachedCategories = this.cacheService.getCachedArray<PlaceCategory>(PlaceCategory, cacheKey);
    if (cachedCategories) {
      this.placeCategories.next(cachedCategories);
    } else {
      this.sharedApi.GetPlaceCategories().subscribe(pc => {
        this.cacheService.cacheArray<PlaceCategory>(cacheKey, pc);
        this.placeCategories.next(pc);
      }, (err: CustomError) => {
        console.log(err);
      });
    }
  }

  getCities() {
    const cacheKey = City.buildCacheKey();
    const cachedCities = this.cacheService.getCachedArray<City>(City, cacheKey);
    if (cachedCities) {
      this.cities.next(cachedCities);
    } else {
      this.sharedApi.GetCities('CA').subscribe(c => {
        this.cacheService.cacheArray<City>(cacheKey, c);
        this.cities.next(c);
      }, (err: CustomError) => {
        console.log(err);
      });
    }
  }

  // Image Handling

  uploadGuideAsset(f: CustomFile, metadata: Map<string, string> = null, guideId: string, id: string) {
    const req = new GenerateUploadUrlRequest();
    const ext = f.name.substr(f.name.lastIndexOf('.') + 1);
    f.name = f.name.replace(/[^a-zA-Z0-9]/g, '');
    f.name = f.name.replace(ext, '');
    f.name = f.name + '.' + ext;
    req.fileName = new Date().getTime() + f.name;
    req.mediaType = f.getMediaType();
    req.metadata = metadata;
    req.guideId = guideId;
    req.placeId = id;
    return this.uploadGuideAssetHelper(req, f);
  }

  private uploadGuideAssetHelper(req: GenerateUploadUrlRequest, f: CustomFile): Observable<any> {
    return this.imageApi.GetGuideImageUploadUrl(req).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageApi.PutImageUploadUrl(signedUploadUrl.url, f.url.toString(), req.fileName);
      })
    );
  }


}
