<div class="insight-info-modal">
  <div class="title-bar">
    <div class="modal-title">
      Insights Info
    </div>
    <button matRipple (click)="closeModal()" class="custom-button text-button">Close</button>
  </div>
  <div class="add-place-action-container">
    <ul>
      <li><span class="default-bold">Guide Views:</span> The number of times guide(s) have been viewed</li>
      <li><span class="default-bold">Website Views:</span> The number of times that linked website(s) have been visited as a result of user navigating from within a guide</li>
      <li><span class="default-bold">Twitter Views:</span> The number of times that linked Twitter page(s) have been visited as a result of user navigating from within a guide </li>
      <li><span class="default-bold">Facebook Views:</span> The number of times that linked Facebook page(s) have been visited as a result of user navigating from within a guide</li>
      <li><span class="default-bold">Instagram Views:</span> The number of times that linked Instagram page(s) have been visited as a result of user navigating from within a guide</li>
    </ul>
  </div>
  <div class="section-options-footer-container">
    <hr>
    <div class="button-container">
      <button matRipple class="custom-button grey-button" (click)="closeModal()">Close</button>
    </div>
  </div>
</div>
