<div *ngIf="this.type === types.GuidesGridType.ThreeWide"
     class="grid-container">
  <app-guide-card *ngFor="let guide of guides"
                  [associatedInsiderId]="associatedInsiderId"
                  [guide]="guide">
  </app-guide-card>
</div>
<div *ngIf="this.type === types.GuidesGridType.AcceptReject"
     class="grid-container">
  <div *ngFor="let guide of guides">
    <app-guide-card [associatedInsiderId]="associatedInsiderId"
                    [guide]="guide">
    </app-guide-card>
    <div class="mt-8px">
      <button matRipple
              class="custom-button preferred-button"
              (click)="approveGuide(guide)"><img [src]="'assets/icons/dark/outline/check-circle.svg'" alt="">Approve</button>
      <button matRipple
              class="custom-button grey-button ml-2"
              (click)="rejectGuide(guide)"><img [src]="'assets/icons/dark/solid/x.svg'" alt="">Reject</button>
    </div>
  </div>
</div>
