import {Injectable, OnDestroy} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {GuidesDomainModel} from '../../../../domain-models/guides-domain-model';
import {GuideStatus} from '../../../../models/guide/enum/guide-status.enum';
import {CustomError} from '../../../../models/shared/custom-error';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {ToastService} from '../../../../services/toast-service';
import {HydratedGuide} from '../../../../models/guide/dto/hydrated-guide';

@Injectable()
export class GuidesGridViewModel extends BaseViewModel implements OnDestroy {

  public loadingOpts: LoadingOptions = LoadingOptions.default();

  constructor(
    private dm: GuidesDomainModel,
    private toastService: ToastService,
  ) {
    super();
  }

  approveGuide(g: HydratedGuide) {
    g.status = GuideStatus.Approved;
    this.loadingOpts.addRequest('Approving Guide');
    this.dm.updateGuide(g).subscribe(_ => {
      this.loadingOpts.removeRequest('Approving Guide');
      this.toastService.publishSuccessMessage(`${g.title} has been approved.`,  'Success');
    }, (error: CustomError) => {
      this.loadingOpts.removeRequest('Approving Guide');
      this.toastService.publishError(error);
    });
  }

  rejectGuide(g: HydratedGuide, message: string) {
    g.status = GuideStatus.Declined;
    g.statusNote = message;
    this.loadingOpts.addRequest('Rejecting Guide');
    this.dm.updateGuide(g).subscribe(_ => {
      this.loadingOpts.removeRequest('Rejecting Guide');
      this.toastService.publishWarningMessage(`${g.title} has been rejected.`,  'Completed');
    }, (error: CustomError) => {
      this.loadingOpts.removeRequest('Rejecting Guide');
      this.toastService.publishError(error);
    });
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
