import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {SignInRequest} from '../../../../../models/account/requests/sign-in-request';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthViewModel} from '../../../viewModels/auth-view-model';
import {RouteUtils} from '../../../../../utils/route-utils';
import {AuthFlow} from '../../../../../models/account/enum/auth-flow.enum';
import {LoadingOptions} from '../../../../../models/shared/loading-options';
import {ToastService} from '../../../../../services/toast-service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: [
    './sign-in.component.scss',
    '../auth.component.scss',
    '../../../../../../scss/stylesheet.scss']
})
export class SignInComponent extends BaseComponent implements OnInit, OnDestroy {

  // View
  public signInFormItems: FormInputItem[] = [];
  public formStyling: FormGroupStyling = new FormGroupStyling();
  public formOptions: FormOptions = new FormOptions();
  public req: SignInRequest = new SignInRequest();
  public loadingOpts: LoadingOptions = LoadingOptions.default();

  constructor(
    private router: Router,
    public viewModel: AuthViewModel,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.bindUrlParams();
    this.setupBindings();
    this.setupViews();
  }

  ngOnDestroy() {
    this.destroy();
  }

  setupViews() {
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupBindings() {
  }

  bindUrlParams() {
    this.route.queryParams.subscribe((params) => {
      this.req.username = decodeURIComponent(decodeURIComponent(params.Username || params.username || ''));
      this.req.password = decodeURIComponent(decodeURIComponent(params.Password || params.password || ''));
    });
    this.router.navigate([RouteUtils.cleanedUrlRoute(this.router.url)], {queryParams: {}});
  }

  setupFormItems() {
    this.signInFormItems = [];
    // Set up form items
    const phoneNumInput = new FormInputItem();
    phoneNumInput.itemType = FormItemType.Input;
    phoneNumInput.inputName = 'username';
    phoneNumInput.label = 'Phone Number';
    phoneNumInput.hideLabel = true;
    phoneNumInput.placeholder = 'Phone Number';
    phoneNumInput.bindingProperty = 'username';
    phoneNumInput.inputType = FormInputType.Tel;
    phoneNumInput.required = true;
    phoneNumInput.inputMask = ['1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    this.signInFormItems.push(phoneNumInput);

    const passwordInput = new FormInputItem();
    passwordInput.itemType = FormItemType.Input;
    passwordInput.inputName = 'password';
    passwordInput.label = 'Password';
    passwordInput.hideLabel = true;
    passwordInput.placeholder = 'Password';
    passwordInput.bindingProperty = 'password';
    passwordInput.inputType = FormInputType.Password;
    passwordInput.required = true;
    this.signInFormItems.push(passwordInput);
  }

  setupFormStyling() {
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.submitButtonText = 'Sign In';
    this.formStyling.secondaryButtonFloat = 'right';
    this.formStyling.secondaryButtonText = 'Forgot Password?';
    this.formStyling.secondaryButtonClass = 'text-button';
  }

  formSubmitted(req: SignInRequest) {
    const loadingMessage = 'Signing In';
    if (!this.loadingOpts.containsRequest(loadingMessage)) {
      this.loadingOpts.addRequest(loadingMessage);
      this.viewModel.signIn(req).subscribe((user) => {
        this.loadingOpts.removeRequest(loadingMessage);
      }, error => {
        this.loadingOpts.removeRequest(loadingMessage);
        this.toastService.publishError(error);
      });
    }
  }

  signUp() {
    this.viewModel.nextAuthFlow.next(AuthFlow.SignUp);
  }

  forgotPassword() {
    this.viewModel.nextAuthFlow.next(AuthFlow.ForgotPassword);
  }



}
