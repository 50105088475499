import {Component, OnInit} from '@angular/core';
import {NavItem} from '../../../models/shared/nav-item';
import {NavigationEnd, Router} from '@angular/router';
import {SessionService} from '../../../services/session-service';
import {Viewable} from '../../../models/protocols/viewable';
import '../../../utils/observable.extensions';
import {AccountDomainModel} from '../../../domain-models/account-domain-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {CustomError} from '../../../models/shared/custom-error';
import {ToastService} from '../../../services/toast-service';
import {Routable} from '../../../models/protocols/routable';
import {NavItemId} from '../../../models/enum/shared/nav-item-id.enum';
import {BaseComponent} from '../../../models/base/base-component';
import {BehaviorSubject} from 'rxjs';
import {Insider} from '../../../models/guide/dto/insider';
import {TypeUtils} from '../../../utils/type-utils';
import {CompanySubRoute} from './company-sub-route.enum';

@Component({
  selector: 'app-sidebar-navigation',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss']
})
export class SidebarNavComponent extends BaseComponent implements OnInit, Viewable, Routable {

  public loadingOpts: LoadingOptions = LoadingOptions.default();
  private loggingInReq = 'Logging in';
  public currentLocationName: string = 'Location';
  public currentNavUrl: string;
  public currentCompanyIsSpecialist: boolean = false;
  public badgeContent = new Map<string, string>();
  public navItems = new BehaviorSubject<NavItem[]>(this.getNavItems(null, [], []));
  public types = TypeUtils;

  constructor(
    private router: Router,
    private session: SessionService,
    private accountDomainModel: AccountDomainModel,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initLoadingOpts();
    this.bindUrlParams();
    this.setupBindings();
    this.setupViews();
  }

  private initLoadingOpts() {
    this.loadingOpts.addRequest(this.loggingInReq);
  }

  bindUrlParams() {
    this.currentNavUrl = this.router.url;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.urlChanged(evt);
    });
  }

  setupViews() {
    this.loadingOpts.fullscreen = true;
    this.loadingOpts.zIndex = 100;
  }

  setupBindings() {
    // bind the active location name to the Location menu option
    const locSub = this.session.sessionContainer.notNull().subscribe((session) => {
      const navItems = this.getNavItems(session.insider, session.insider?.companies, session.insider?.adminCompanyIds);
      if (!!session?.insider) {
        this.navItems.next(navItems);
        this.loadingOpts.removeRequest(this.loggingInReq);
      }
    });
    this.pushSub(locSub);
  }

  getNavItems(userInsider: Insider, companies: Insider[], adminIds: string[]): NavItem[] {
    const navItems = [];
    // Home
    // navItems.push({
    //   id: NavItemId.Home,
    //   name: 'Home',
    //   routerPath: '/home',
    //   iconSrc: 'assets/icons/dark/house.svg',
    //   iconFilledSrc: 'assets/icons/light/house.svg',
    //   locationPicker: false,
    // });
    // Guides
    // navItems.push({
    //   id: NavItemId.Guides,
    //   name: 'Guides',
    //   routerPath: '/guides',
    //   iconSrc: 'assets/icons/dark/compass.svg',
    //   iconFilledSrc: 'assets/icons/light/compass.svg',
    //   locationPicker: false,
    // });
    // User
    if (!!userInsider) {
      navItems.push({
        id: NavItemId.User,
        name: userInsider.getFullName(),
        routerPath: `/user/${userInsider.id}`,
        user: userInsider,
        iconSrc: 'assets/icons/dark/plus.svg',
        iconFilledSrc: 'assets/icons/light/plus.svg',
        locationPicker: false,
      });
    }
    // Companies
    if (!!companies && companies.length > 0 && adminIds.length > 0) {
      adminIds.forEach(companyId => {
        const c = companies.find(company => company.id === companyId);
        if (c) {
          navItems.push({
            id: NavItemId.Company,
            name: c.getFullName(),
            routerPath: `/company/${c.id}`,
            company: c,
            iconSrc: 'assets/icons/dark/plus.svg',
            iconFilledSrc: 'assets/icons/light/plus.svg',
            locationPicker: false,
          });
        }
      });
    }
    // Profile
    // navItems.push({
    //   id: NavItemId.Profile,
    //   name: 'Profile',
    //   routerPath: '/profile',
    //   iconSrc: 'assets/icons/dark/person.svg',
    //   iconFilledSrc: 'assets/icons/light/person.svg',
    //   locationPicker: false,
    // });
    // Sign Out
    navItems.push({
      id: NavItemId.SignOut,
      name: 'Sign Out',
      routerPath: 'sign-out',
      iconSrc: 'assets/icons/dark/sign-out.svg',
      iconFilledSrc: 'assets/icons/light/sign-out.svg',
      locationPicker: false
    });
    return navItems;
  }

  navigateToRoot() {
    this.router.navigate(['']).then(() => {
    });
  }

  navItemSelected(navItem: NavItem, subRoute?: CompanySubRoute): boolean {
    if (navItem.routerPath === 'sign-out') {
      const loadingMess = 'Signing Out';
      this.loadingOpts.addRequest(loadingMess);
      this.accountDomainModel.signOut().subscribe((_) => {
        // Add slight delay so all publish subjects can fire and AuthGuard will update
        this.loadingOpts.removeRequest(loadingMess);
        this.router.navigate(['/auth/sign-in']).then(() => {});
      }, (error: CustomError) => {
        this.loadingOpts.removeRequest(loadingMess);
        this.toastService.publishError(error);
        this.router.navigate(['/auth/sign-in']).then(() => {});
      });
    } else if (!!subRoute) {
      this.router.navigate([`${navItem.routerPath}/${subRoute}`]).then(() => {});
    } else {
      this.router.navigate([navItem.routerPath]).then(() => {});
    }
    return true;
  }

  getBadgeContentForNavItem(navItem: NavItem): string {
    return this.badgeContent.get(navItem.routerPath);
  }

  isNavItemActive(navItem: NavItem, subRoute?: CompanySubRoute): boolean {
    if (!!subRoute) {
      return this.currentNavUrl.indexOf(`${navItem.routerPath}/${subRoute}`) === 0;
    } else {
      return this.currentNavUrl.indexOf(navItem.routerPath) === 0;
    }
  }

  private urlChanged(nav: NavigationEnd) {
    this.currentNavUrl = nav.url;
  }

  multilineNavLabel(i: NavItem): boolean {
    return i.name.length > 12;
  }

}
