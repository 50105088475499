export class RefreshSessionRequest {
  public krugoId: string = '';
  public deviceId: string = '';
  public refreshToken: string = '';

  constructor(userId, deviceId, refreshToken: string) {
    this.krugoId = userId;
    this.deviceId = deviceId;
    this.refreshToken = refreshToken;
  }
}
