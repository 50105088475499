import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {KeyValue} from '@angular/common';
import {BaseComponent} from '../../../../../models/base/base-component';
import {GuideClickType} from '../../../../../models/guide/enum/guide-click-type.enum';
import {KeyValueUtils} from '../../../../../utils/key-value-utils';

@Component({
  selector: 'app-insight-cards',
  templateUrl: './insight-cards.component.html',
  styleUrls: ['./insight-cards.component.scss']
})
export class InsightCardsComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() map: Map<GuideClickType, number>;
  @Input() selected: GuideClickType;
  @Input() loading: boolean;
  @Output() cardClicked = new EventEmitter<GuideClickType>(true);
  KeyValueUtils = KeyValueUtils;

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews();
  }

  setupViews() {
  }

  ngAfterViewInit() {
    this.setupBindings();
  }

  setupBindings() {
  }

  // Order by descending property key
  keyAscOrder = (a: KeyValue<GuideClickType, number>, b: KeyValue<GuideClickType, number>): number => {
    return a.key.toString().localeCompare(b.key.toString());
  }

  ngOnDestroy() {
    this.destroy();
  }

}
