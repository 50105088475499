<app-loading
  [hidden]="!loadingOpts?.isLoading"
  [options]="loadingOpts">
</app-loading>
<div [hidden]="isHidden">
  <div (fileDropped)="onFileDropped($event)"
       [hidden]="!allowImage && !allowVideo"
       [ngClass]="{ 'animating' : animating }"
       appDnd
       class="custom-upload-area dark-animation {{uploadAreaCustomClass}}">
    <form class="custom-upload-form">
      <img src="/assets/icons/dark/picture.svg"
           class="custom-upload-icon"
           alt="manage">
      <span class="custom-upload-text">
        <label class="custom-upload-label" for="fileDropRef{{id}}">Upload a File</label> or Drag and Drop</span>
      <span *ngIf="allowImage && allowVideo" class="custom-upload-text secondary-upload-text">
         JPG, PNG, MP4, WAV up to 10MB
      </span>
      <span *ngIf="allowImage && !allowVideo" class="custom-upload-text secondary-upload-text">
         JPG, PNG up to 10MB
      </span>
      <span *ngIf="!allowImage && allowVideo" class="custom-upload-text secondary-upload-text">
         MP4, WAV up to 10MB
      </span>
      <input type="file" #fileDropRef
             class="custom-file-input"
             id="fileDropRef{{id}}"
             name="fileDropRefName{{id}}"
             multiple
             [accept]="viewModel.accept"
             (change)="viewModel.fileBrowseHandler($event.target)">
    </form>
  </div>
  <div [ngStyle]="{ 'padding' : displayList ? '1.25rem 0' : '1rem 0' }"
       class="custom-upload-list-container">
    <ul *ngIf="viewModel.files.length > 0 && displayList"
        [ngClass]="{ 'custom-upload-list' : !modalStyle, 'modal-custom-upload-list' : modalStyle}"
        id="upload-file-list">
        <ng-container *ngIf="!modalStyle">
          <!--    Image Previews For Guide Uploading-->
          <app-upload-item
            *ngFor="let item of viewModel.files"
            [uploadItemInterface]="viewModel"
            [file]="item"
            [modalStyle]="modalStyle">
          </app-upload-item>
        </ng-container>
        <ng-container *ngIf="modalStyle">
          <!--    Image Previews For Modal Uploading-->
          <app-upload-item
            *ngFor="let item of viewModel.files"
            [uploadItemInterface]="viewModel"
            [file]="item"
            [modalStyle]="modalStyle">
          </app-upload-item>
        </ng-container>
    </ul>
  </div>
</div>
