import {GuideClickType} from '../enum/guide-click-type.enum';
import {TimeWindowInsight} from './time-window-insight';
import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {ChartData} from '../../../views/company/components/insights/models/chart-data';
import {ChartDataPoint} from '../../../views/company/components/insights/models/chart-data-point';

export class InsiderInsight implements Deserializable {

  public insiderId: string;
  public startTime: number;
  public endTime: number;
  public totalClickMap: Map<string, Map<GuideClickType, number>>;
  public timeWindows: TimeWindowInsight[];

  static emptyMap(): Map<GuideClickType, number> {
    return new Map()
      .set(GuideClickType.Website, 0)
      .set(GuideClickType.Twitter, 0)
      .set(GuideClickType.Facebook, 0)
      .set(GuideClickType.Instagram, 0)
      .set(GuideClickType.Guide, 0);
  }

  onDeserialize() {
    if (!(this.totalClickMap instanceof Map)) {
      this.totalClickMap = DeserializeHelper.deserializeNestedGenericMap<string, GuideClickType, number>(this.totalClickMap);
    }
    if (!this.totalClickMap) {
      this.totalClickMap = new Map<string, Map<GuideClickType, number>>();
    }
    if (this.totalClickMap.size < 1) {
      this.totalClickMap.set(this.insiderId,  InsiderInsight.emptyMap());
    }
    for (let [, entry] of this.totalClickMap.entries()) {
      if (!entry || entry.size < 1) {
        entry = InsiderInsight.emptyMap();
      }
    }
    this.timeWindows = DeserializeHelper.deserializeArray(TimeWindowInsight, this.timeWindows);
  }

  translateToAggregateChartData(): ChartData {
    const translation = new ChartData('Total');
    translation.series = this.timeWindows.map(window => {
      let yValue = 0;
      window.clickMap.forEach((v, _) => yValue += v);
      return new ChartDataPoint(window.getTimeAsString(), yValue);
    });
    return translation;
  }

  /**
   * @param onlyInclude: if value, only includes these lines, else includes all
   */
  translateToSeparateLineChartData(onlyInclude?: GuideClickType[]): ChartData[] {
    const translations: ChartData[] = [];
    const value = this.totalClickMap?.values().next().value as Map<GuideClickType, number>;
    if (!!value) {
      value?.forEach((v, k) => {
        const chart = new ChartData(k.replace('GuideClickType_', '') + ' Views');
        chart.series = this.timeWindows.map(window => {
          return new ChartDataPoint(window.getTimeAsString(), window.clickMap.get(k));
        });
        if (!!onlyInclude) {
          if (onlyInclude.contains(k)) { translations.push(chart); }
        } else {
          translations.push(chart);
        }
      });
    }
    return translations;
  }

  translateGuideViewsToChartData(): ChartData[] {
    return this.translateToSeparateLineChartData([GuideClickType.Guide]);
  }

  translateWebsiteViewsToChartData(): ChartData[] {
    return this.translateToSeparateLineChartData([GuideClickType.Website]);
  }

  translateFacebookViewsToChartData(): ChartData[] {
    return this.translateToSeparateLineChartData([GuideClickType.Facebook]);
  }

  translateInstagramViewsToChartData(): ChartData[] {
    return this.translateToSeparateLineChartData([GuideClickType.Instagram]);
  }

  translateTwitterViewsToChartData(): ChartData[] {
    return this.translateToSeparateLineChartData([GuideClickType.Twitter]);
  }

}
