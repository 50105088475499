import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {SessionService} from '../services/session-service';
import {SignInRequest} from '../models/account/requests/sign-in-request';
import {AccountAPI} from '../api/account-api';
import {catchError, map} from 'rxjs/operators';
import {HydratedUser} from '../models/account/dto/hydrated-user';
import {SessionContainer} from '../models/shared/session-container';
import {CacheService} from '../services/cache-service';
import {DefaultCacheKey} from '../models/enum/shared/default-cache-key.enum';
import {CustomError} from '../models/shared/custom-error';
import {BaseDomainModel} from '../models/base/base-domain-model';
import {Session} from '../models/account/dto/session';
import {ImageAPI} from '../api/image-api';
import {CachePolicy} from '../models/enum/shared/cachable-image-policy.enum';
import {SignUpRequest} from '../models/account/requests/sign-up-request';
import {CodeDeliveryDetails} from '../models/account/dto/code-delivery-details';
import {ForgotPasswordRequest} from '../models/account/requests/forgot-password-request';
import {InsiderAPI} from '../api/insider-api';
import {Insider} from '../models/guide/dto/insider';

@Injectable({
  providedIn: 'root'
})

export class AccountDomainModel extends BaseDomainModel implements OnDestroy {

  public refreshSessionResult: BehaviorSubject<Session> = new BehaviorSubject<Session>(null);

  constructor(
    public session: SessionService,
    private accountAPI: AccountAPI,
    private imageAPI: ImageAPI,
    private insiderAPI: InsiderAPI,
    private cacheService: CacheService,
  ) {
    super();
    this.init();
  }

  public init() {
    this.setupBindings();
  }

  isAuthenticated(forceRefresh: boolean = false): Observable<Session> {
    // get session container
    let sess: SessionContainer = this.session.sessionContainer.getValue() as SessionContainer;
    if (sess === null || !sess) {
      sess = this.session.getCachedSession();
    }
    // Check session validity and refresh
    if (sess?.validSession() && !this.session.refreshingSession.getValue() && !forceRefresh) {
      // Valid Session
      return of(sess.user.session);
    } else {
      // Attempt to refresh session
      return new Observable<Session>(observer => {
        if (this.session.refreshingSession.getValue()) {
          this.refreshSessionResult.firstNotNull().subscribe((rSess) => {
            observer.next(rSess);
          });
        } else {
          this.session.refreshingSession.next(true);
          const req = this.session.getRefreshSessionReq(sess);
          if (req) {
            this.accountAPI.RefreshSession(req).subscribe((user) => {
              console.log('Session Refresh Successful');
              const remember = this.session.sessionContainer.getValue()?.rememberSession;
              this.session.setUser(user, true, remember);
              setTimeout(() => {
                // Add small delay so the session can set before continuing API calls.
                this.session.refreshingSession.next(false);
                this.refreshSessionResult.next(user.session);
                observer.next(user.session);
              }, 1000);
            }, (error: CustomError) => {
              console.log('Session Refresh Failed');
              this.session.refreshingSession.next(false);
              this.refreshSessionResult.next(null);
              this.session.destroySession.next(true);
              observer.next(null);
            });
          } else {
            this.session.refreshingSession.next(false);
            this.refreshSessionResult.next(null);
            observer.next(null);
          }
        }
      });
    }
  }

  // Auth Methods

  signIn(req: SignInRequest): Observable<HydratedUser> {
    return this.accountAPI.SignIn(req).pipe(
      map((user) => {
        // Clear existing session from cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        // Set the new user in the session
        this.session.setUser(user, true);
        return user;
      })
    );
  }

  signUp(req: SignUpRequest): Observable<HydratedUser> {
    return this.accountAPI.SignUp(req).pipe(
      map((user) => {
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        this.session.setUser(user, true);
        return user;
      })
    );
  }

  confirmAccount(req: SignUpRequest): Observable<HydratedUser> {
    return this.accountAPI.ConfirmCode(req).pipe(
      map((user) => {
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        this.session.setUser(user, false);
        return user;
      })
    );
  }

  sendForgotPasswordCode(phoneNumber: string): Observable<CodeDeliveryDetails> {
    if (!!phoneNumber) {
      return this.accountAPI.SendForgotPasswordCode(phoneNumber).pipe(
        map((cdd) => {
          this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
          this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
          this.session.setDeliveryDetails(cdd);
          return cdd;
        })
      );
    }
  }

  resetForgottenPassword(req: ForgotPasswordRequest): Observable<HydratedUser> {
    return this.accountAPI.ResetForgottenPassword(req).pipe(
      map((user) => {
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        this.session.setUser(user, false);
        return user;
      })
    );
  }

  resendCode(req: SignUpRequest): Observable<HydratedUser> {
    return this.accountAPI.ResendCode(req);
  }

  getInsider(insiderId: string): Observable<Insider> {
    return this.insiderAPI.GetInsider(insiderId).pipe(
      map((insider) => {
        this.session.setInsider(insider);
        return insider;
      })
    );
  }

  signOut(): Observable<any> {
    // TODO: Ensure that the DeviceID is not removed from the persistent cache, not sure what will all be in there so leaving this as todo
    const req = this.session.getSignOutReq();
    return this.accountAPI.SignOut(req).pipe(
      map((r) => {
        // Clear the user session from the persistent cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        this.session.destroySession.next(true);
        return r;
      }),
      catchError(err => {
        // If sign out request fails, we want to kill all caches any destroy session
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        this.session.destroySession.next(true);
        return err;
      })
    );
  }

  private setupBindings() {
    // Bind to destroy session
    const destroySess = this.session.destroySession.notNull().subscribe((_) => {
      this.session.refreshingSession.next(false);
      this.refreshSessionResult.next(null);
    });
    this.pushSub(destroySess);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
