import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TabBarItem} from '../../../../models/shared/stylesheet/tab-bar-item';
import {BaseComponent} from '../../../../models/base/base-component';
import {NavigationExtras, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: [
    './tab-bar.component.scss',
  ]
})
export class TabBarComponent extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @Input() public tabs: TabBarItem[] = [];
  @Input() public associatedInsiderId: string;
  public selectedTab: number;

  private savedSubject = new BehaviorSubject<number>(null);
  private listen = this.savedSubject
    .pipe(takeUntil(this.onDestroy), debounceTime(100), distinctUntilChanged())
    .subscribe((saved) => {
      if (!!saved && saved > -1) {
        this.selectedTab = saved;
        this.tabs.map(t => t.active = false);
        if (this.tabs.length > 0 && (this.tabs.length > this.selectedTab - 1)) {
          this.tabs[this.selectedTab].active = true;
          this.appendFragmentToUrl(this.tabs[this.selectedTab]);
        }
        this.cdr.detectChanges();
      }
    });

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedTab = this.tabs.findIndex(tab => tab.active);
    this.setupViews();
  }

  ngAfterViewInit(): void {
    this.setupBindings();
  }

  tabSelected(index: number): void {
    this.selectedTab = index;
    this.tabs.map(t => t.active = false);
    if (this.tabs.length > 0 && (this.tabs.length > this.selectedTab - 1)) {
      this.tabs[this.selectedTab].active = true;
      this.appendFragmentToUrl(this.tabs[this.selectedTab]);
    }
    this.cdr.detectChanges();
  }

  setSavedTab(index: number): void {
    this.savedSubject.next(index);
  }

  setupBindings() {
  }

  setupViews() {
  }

  private appendFragmentToUrl(tab: TabBarItem) {
    const frag = tab.title.toLowerCase();
    const navigationExtras: NavigationExtras = { fragment: frag };
    const current = this.router.parseUrl(this.router.url);
    this.router.navigate(current.root.segments, navigationExtras).then(_ => {});
  }

  ngOnDestroy(): void {
    this.destroy();
  }

}
