<div class="modal-body">
  <div class="modal-title mb-3">Choose a location</div>

  <app-drop-down
    (selection)="selectionChanged($event)"
    [currentSelection]="activeLocation"
    [dropdowns]="locations">
  </app-drop-down>

  <div class="remember-me-container">
    <app-checkbox
      (clickEvent)="checkRemember($event)"
      [checked]="remember"
      [label]="'Set as default location'">
    </app-checkbox>
  </div>

  <div class="footer-button">
    <button matRipple (click)="cancel()" class="custom-button outlined-button">Cancel</button>
    <button matRipple (click)="save()" class="custom-button preferred-button">Save</button>
  </div>
</div>
