<div class="tacustom-style">
  <!-- dynamicHeight makes tabs nested scrollable -->
  <mat-tab-group dynamicHeight
    [selectedIndex]="selectedTab"
    (selectedIndexChange)="tabSelected($event)"
    animationDuration="0ms"
    class="h100p">

    <mat-tab *ngFor="let tab of tabs; let tabIndex = index">

      <ng-template mat-tab-label>
        <img *ngIf="tab.iconSrc" alt="" class="tab-icon" height="20px" src="{{tab.iconSrc}}" width="20px">
        {{ tab.title }}
      </ng-template>

      <!-- matTabContent allows for lazy loading of tabs -->
      <ng-template class="tab-item-content" matTabContent>
        <app-tab-content [tab]="tab" [associatedInsiderId]="associatedInsiderId"></app-tab-content>
      </ng-template>

    </mat-tab>
  </mat-tab-group>

</div>
