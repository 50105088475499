import {Pipe, PipeTransform} from '@angular/core';
import {Insider} from '../../../models/guide/dto/insider';

@Pipe({
  name: 'guidesHeaderName'
})
export class GuidesHeaderNamePipe implements PipeTransform {

  transform(hasId: string, companies: Insider[]): string {
    const hasIncomingData = !!hasId && !!companies && companies.length > 0;
    const isCompany = companies?.map(c => c?.id)?.intersection([hasId]).length > 0;
    if (hasIncomingData && isCompany) {
      return companies?.find(c => c.id === hasId)?.getFullName();
    }
    return 'Guides';
  }

}
