import {Pipe, PipeTransform} from '@angular/core';
import {Guide} from '../../../models/guide/dto/guide';

@Pipe({
  name: 'guideName'
})
export class GuideNamePipe implements PipeTransform {

  transform(guides: Guide[], id: string): string {
    if (!!guides && guides.length > 0) {
      return guides.find(g => g.id === id)?.title;
    }
    return '';
  }

}
